import { store } from 'react-notifications-component';

export const showNotification = (title, message, type) => {
    store.addNotification({
        title,
        message,
        type,
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 4000,
            onScreen: true
        }
    });
}

export const showDataSavedNotification = () => {
    showNotification(
        'Data Saved!',
        'Your data has been saved successfully!',
        'success'
    );
}

export const showDataDeletedNotification = () => {
    showNotification(
        'Element removed!',
        'The element has been removed successfully!',
        'success'
    );
}

export const showErrorNotification = () => {
    showNotification(
        'Error',
        'An error has occurred',
        'danger'
    );
}

export const showCompleteDataNotification = () => {
    showNotification(
        'Complete the information',
        'Complete all the required fields',
        'info'
    );
}

export function findPermission(permssions, name) {
    let permission = [];
    for (let i = 0; i < permssions.length; i++) {
        if(permssions[i]['name'] === name) {
            for (let j = 0; j < permssions[i]['subnames'].length; j++) {
                permission[permssions[i]['subnames'][j]['name']] = permssions[i]['subnames'][j]['selected'];
            }
        }
    }
    return permission;
}
