import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';
import FlatDrawImposition from './FlatDrawImposition';
import MultiPageDrawImposition from './MultiPageDrawImposition';
import EstimatePricing from './EstimatePricing';

class EstimateMultiPage extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingEstimate: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        handleEstimate: PropTypes.func,
        isLoadingEstimate: PropTypes.bool,
        estimateResults: PropTypes.array,
        handleSaveEstimate: PropTypes.array,
        activeProduct: PropTypes.object,
        handleAccessoryClick: PropTypes.func,
        estimateAccessories: PropTypes.array,
        handleUpdateOutsideService: PropTypes.func,
        handleAddOutsideService: PropTypes.func,
        handleDeleteOutsideService: PropTypes.func,
        information: PropTypes.object,
        options: PropTypes.object,
        fetching: PropTypes.object
    };

    renderInput(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col', loadingName = null) {
        const { formValues, formErrors, handleUpdateFormValue, fetching } = this.props;

        let loadingIcon = null;
        if (loadingName != null && fetching[loadingName]) {
            loadingIcon = <div className="loading-icon"><i className="fas fa-circle-notch fa-spin" /></div>;
        }

        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
                {loadingIcon}
            </div>
        );
    }

    renderTypes() {
        return this.renderSelect('Product Type', 'estimate_type', [
            { label: 'Flat', value: 1 },
            { label: 'Multi-Page', value: 2 },
            { label: 'Digital', value: 3 },
            { label: 'Large Format', value: 4 },
            { label: 'Data Processing', value: 5 },
            { label: 'Inkjet', value: 6 },
            { label: 'Inserting', value: 7 },
            { label: 'Certified Mail Program', value: 8 },
            { label: 'Art Department', value: 9 },
            { label: 'Handwork Department', value: 10 },
            { label: 'Pressure Seal Forms', value: 11 },
            { label: 'Index Tabs', value: 12 },
            { label: 'Standard Labels', value: 13 },
            { label: 'NCR Forms', value: 14 }
        ], true);
    }

    renderProductCategories(type) {
        const { options } = this.props;

        let categoriesOptions = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < options['productCategories'].length; i++) {
            if (parseInt(options['productCategories'][i]['type']) === parseInt(type)) {
                categoriesOptions.push(options['productCategories'][i]);
            }
        }

        return this.renderSelect('Product Category', 'estimate_product_category_id', categoriesOptions, true);
    }

    renderInteriorInks() {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) !== 2) {
            return null;
        }

        return (
            this.renderSelect('Interior Inks', 'estimate_interior_inks', [
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 }
            ], true)
        );
    }

    renderInteriorCoating() {
        const { formValues, information } = this.props;

        if (parseInt(formValues['estimate_type']) !== 2) {
            return null;
        }

        let coatingOptions = [{ label: 'None', value: '' }];
        for (let i = 0; i < information['inks'].length; i++) {
            coatingOptions.push({ label: information['inks'][i]['name'], value: information['inks'][i]['id'] });
        }

        return (
            this.renderSelect('Coating', 'estimate_interior_coating', coatingOptions, false)
        );
    }

    renderFrontInks() {
        const { formValues } = this.props;

        let label = 'Front Ink';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            label = 'Cover Front Ink';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        return (
            this.renderSelect(label, 'estimate_front_inks', [
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 }
            ], true)
        );
    }

    renderFrontCoatings() {
        const { formValues, information } = this.props;

        let label = 'Coating Front';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            label = 'Cover Coating Front';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        let coatingOptions = [{ label: 'None', value: '' }];
        for (let i = 0; i < information['inks'].length; i++) {
            coatingOptions.push({ label: information['inks'][i]['name'], value: information['inks'][i]['id'] });
        }

        return (
            this.renderSelect(label, 'estimate_front_coating', coatingOptions, false)
        );
    }

    renderBackInks() {
        const { formValues } = this.props;

        let label = 'Back Ink';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            label = 'Cover Back Ink';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        return (
            this.renderSelect(label, 'estimate_back_inks', [
                { label: '0', value: 0 },
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 }
            ], true)
        );
    }

    renderBackCoatings() {
        const { formValues, information } = this.props;

        let label = 'Coating Back';
        let draw = false;
        if (parseInt(formValues['estimate_type']) !== 2) {
            draw = true;
        } else if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            label = 'Cover Coating Back';
            draw = true;
        }

        if (!draw) {
            return null;
        }

        let coatingOptions = [{ label: 'None', value: '' }];
        for (let i = 0; i < information['inks'].length; i++) {
            coatingOptions.push({ label: information['inks'][i]['name'], value: information['inks'][i]['id'] });
        }

        return (
            this.renderSelect(label, 'estimate_back_coating', coatingOptions, false)
        );
    }

    renderCoverPress(pressesOptions) {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            return (
                this.renderSelect('Press Cover', 'estimate_cover_press_id', pressesOptions, true)
            );
        }

        return null;
    }

    renderCoverPaperFields() {
        const { formValues, options } = this.props;

        if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            let paperOptions = [];
            if (formValues['estimate_front_coating'] !== '' && formValues['estimate_back_coating'] !== '') {
                for (let i = 0; i < options['coverPapers'].length; i++) {
                    if (parseInt(options['coverPapers'][i]['coating_one_side']) === 0) {
                        paperOptions.push(options['coverPapers'][i]);
                    }
                }
            } else {
                paperOptions = options['coverPapers'];
            }

            return [
                this.renderSelect('Paper Category for Cover', 'estimate_cover_paper_category_id', options['coverPaperCategories'], true, 'col', 'coverPaperCategories'),
                this.renderSelect('Paper Weight for Cover', 'estimate_cover_paper_weight', options['coverPaperWeights'], true, 'col', 'coverPaperWeights'),
                <div className="w-100" key="w100"></div>,
                this.renderSelect('Paper for Cover', 'estimate_cover_paper_id', paperOptions, true, 'col', 'coverPapers')
            ];
        }

        return null;
    }

    renderSecondRunningStyle(activePress) {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) === 2) {
            let runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'SheetWise', value: 'SW' }, { label: 'Work and Turn', value: 'WT' }];
            if (activePress != null && parseInt(activePress['is_perfecting'])) {
                runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'Perfecting', value: 'Perfecting' }];
            }

            return (
                this.renderSelect('Running Style Leftover', 'estimate_second_running_style', runningStyleOptions, true)
            );
        }

        return null;
    }

    renderCoverRunningStyle(activePress) {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            let runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'SheetWise', value: 'SW' }, { label: 'Work and Turn', value: 'WT' }];
            if (activePress != null && parseInt(activePress['is_perfecting'])) {
                runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'Perfecting', value: 'Perfecting' }];
            }

            return (
                this.renderSelect('Running Style Cover', 'estimate_cover_running_style', runningStyleOptions, true)
            );
        }

        return null;
    }

    hasCover() {
        const { formValues } = this.props;
        return [2, 5, 7, 9].includes(parseInt(formValues['estimate_multi_page_type']));
    }

    renderOutsideServices() {
        const { handleAddOutsideService, handleDeleteOutsideService, formValues, handleUpdateOutsideService } = this.props;
        let table = null;
        if (formValues['estimate_outside_services'].length !== 0) {
            table = (
                <table id="outside-services-table" className="table table-sm table-striped">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Cost</th>
                            <th>Markup</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {formValues['estimate_outside_services'].map((service, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <input type="text" value={service['description']}
                                            onChange={handleUpdateOutsideService.bind(this, 'description', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={service['cost']}
                                            onChange={handleUpdateOutsideService.bind(this, 'cost', index)} />
                                    </td>
                                    <td>
                                        <input type="text" value={service['percentage_markup']}
                                            onChange={handleUpdateOutsideService.bind(this, 'percentage_markup', index)} />
                                    </td>
                                    <td>
                                        <i className="fas fa-trash" onClick={handleDeleteOutsideService.bind(this, index)} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        }
        return (
            <div className="card mb-2 mr-1 pb-0">
                <div className="card-header">
                    Outside Services
                </div>
                <div className="card-body pt-1 pb-0">
                    <div className="row">
                        <div className="col">
                            <div className="add-outside-services" onClick={handleAddOutsideService}><i className="fas fa-plus" /> Add</div>
                            <div className="clearfix"></div>
                            {table}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderProductOptionsCard() {
        const { options } = this.props;
        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Product Options
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {this.renderTypes()}
                        {this.renderProductCategories(2)}
                        <div className="w-100"></div>
                        <div className="estimate-divider"></div>
                        <div className="w-100"></div>
                        {this.renderSelect('Choose from our Product Catalog', 'estimate_product_id', options['products'], false)}
                        <div className="w-100"></div>
                        <div className="estimate-divider"></div>
                        <div className="w-100"></div>
                        {this.renderInput('Description', 'estimate_product_description', 'text', true)}
                        <div className="w-100"></div>
                        <div className="estimate-divider"></div>
                        <div className="w-100"></div>
                        {this.renderSelect('Publication Type', 'estimate_multi_page_type', [
                            { label: 'Self-Cover', value: 1 },
                            { label: 'Plus-Cover', value: 2 },
                            { label: 'Perfect Bind', value: 3 },
                            { label: 'Staple Binding No Cover', value: 4 },
                            { label: 'Staple Binding With Cover', value: 5 },
                            { label: 'Coil Binding No Cover', value: 6 },
                            { label: 'Coil Binding With Cover', value: 7 },
                            { label: 'Spiral Binding No Cover', value: 8 },
                            { label: 'Spiral Binding With Cover', value: 9 }
                        ], true)}
                        {this.renderInput('Pages', 'estimate_pages', 'number', true)}
                        <div className="w-100"></div>
                        <div className="estimate-divider"></div>
                        <div className="w-100"></div>
                        {this.renderInput('Finish Width', 'estimate_width', 'decimal', true)}
                        {this.renderInput('Finish Height', 'estimate_height', 'decimal', true)}
                        {this.renderSelect('Binding Edge', 'estimate_binding_edge', [
                            { label: 'Width', value: 1 },
                            { label: 'Height', value: 2 }
                        ], true)}
                        <div className="w-100"></div>
                        <div className="estimate-divider"></div>
                        <div className="w-100"></div>
                        {this.renderInteriorInks()}
                        {this.renderSelect('Bleed', 'estimate_bleed', [
                            { label: 'None', value: 0 },
                            { label: '1/8', value: 0.125 },
                            { label: '1/4', value: 0.25 }
                        ], true)}
                        <div className="w-100"></div>
                        <div className="estimate-divider"></div>
                        <div className="w-100"></div>
                        {this.renderFrontInks()}
                        {this.renderBackInks()}
                        <div className="w-100"></div>
                    </div>
                </div>
            </div>
        );
    }

    renderPressCard() {
        const { options, information, formValues } = this.props;

        let activePress = null;
        let secondActivePress = null;
        for (let i = 0; i < information['presses'].length; i++) {
            if (parseInt(formValues['estimate_press_id']) === parseInt(information['presses'][i]['id'])) {
                activePress = information['presses'][i];
            }
            if (parseInt(formValues['estimate_second_press_id']) === parseInt(information['presses'][i]['id'])) {
                secondActivePress = information['presses'][i];
            }
        }

        let coverActivePress = null;
        for (let i = 0; i < information['presses'].length; i++) {
            if (parseInt(formValues['estimate_cover_press_id']) === parseInt(information['presses'][i]['id'])) {
                coverActivePress = information['presses'][i];
            }
        }

        let runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'SheetWise', value: 'SW' }, { label: 'Work and Turn', value: 'WT' }];
        if (activePress != null && parseInt(activePress['is_perfecting'])) {
            runningStyleOptions = [{ label: 'Select option', value: '' }, { label: 'Perfecting', value: 'Perfecting' }];
        }

        let style = {};
        if (parseInt(formValues['estimate_multi_page_type']) === 5) {
            style = { minHeight: 242 };
        } else if (parseInt(formValues['estimate_multi_page_type']) === 6) {
            style = { minHeight: 130 };
        } else if (parseInt(formValues['estimate_multi_page_type']) === 7) {
            style = { minHeight: 298 };
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Press
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0" style={style}>
                    <div className="row no-gutters">
                        {this.renderCoverPress(options['presses'])}
                        {this.renderCoverRunningStyle(coverActivePress)}
                        <div className="w-100"></div>
                        {this.renderSelect('Press Interior', 'estimate_press_id', options['presses'], true)}
                        {this.renderSelect('Running Style Interior', 'estimate_running_style', runningStyleOptions, true)}
                        <div className="w-100"></div>
                        {this.renderSelect('Press Leftover', 'estimate_second_press_id', options['presses'], true)}
                        {this.renderSecondRunningStyle(secondActivePress)}
                        <div className="w-100"></div>
                    </div>
                </div>
            </div>
        );
    }

    renderInteriorPaperCard() {
        const { options, formValues } = this.props;

        let paperOptions = [];
        if (formValues['estimate_interior_coating'] !== '') {
            for (let i = 0; i < options['papers'].length; i++) {
                if (parseInt(options['papers'][i]['coating_one_side']) === 0) {
                    paperOptions.push(options['papers'][i]);
                }
            }
        } else {
            paperOptions = options['papers'];
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Paper Interior
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {this.renderSelect('Paper Category', 'estimate_paper_category_id', options['paperCategories'], true, 'col', 'paperCategories')}
                        {this.renderSelect('Paper Weight', 'estimate_paper_weight', options['paperWeights'], true, 'col', 'paperWeights')}
                        <div className="w-100"></div>
                        {this.renderSelect('Paper', 'estimate_paper_id', paperOptions, true, 'col', 'papers')}
                        {this.renderInteriorCoating()}
                    </div>
                </div>
            </div>
        );
    }

    renderCoverPaperCard() {
        const { formValues, options } = this.props;

        if (parseInt(formValues['estimate_type']) === 2 && this.hasCover()) {
            let paperOptions = [];
            if (formValues['estimate_front_coating'] !== '' && formValues['estimate_back_coating'] !== '') {
                for (let i = 0; i < options['coverPapers'].length; i++) {
                    if (parseInt(options['coverPapers'][i]['coating_one_side']) === 0) {
                        paperOptions.push(options['coverPapers'][i]);
                    }
                }
            } else {
                paperOptions = options['coverPapers'];
            }

            return (
                <div className="card mb-2 mr-2 pb-2">
                    <div className="card-header">
                        Paper Cover
                    </div>
                    <div className="card-body pt-1 pb-0 pl-0 pr-0">
                        <div className="row no-gutters">
                            {this.renderSelect('Paper Category', 'estimate_cover_paper_category_id', options['coverPaperCategories'], true, 'col', 'coverPaperCategories')}
                            {this.renderSelect('Paper Weight', 'estimate_cover_paper_weight', options['coverPaperWeights'], true, 'col', 'coverPaperWeights')}
                            <div className="w-100"></div>
                            {this.renderSelect('Paper', 'estimate_cover_paper_id', paperOptions, true, 'col', 'coverPapers')}
                            <div className="w-100"></div>
                            {this.renderFrontCoatings()}
                            {this.renderBackCoatings()}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    renderBinderyOptionsCard() {
        const { options } = this.props;
        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Bindery Options
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {this.renderSelect('Drilling Holes', 'estimate_drilling_holes', [
                            { label: 'None', value: 0 },
                            { label: '1', value: 1 },
                            { label: '2', value: 2 },
                            { label: '3', value: 3 },
                            { label: '4', value: 4 }
                        ], false)}
                        {this.renderSelect('Shrink-Wrapping', 'estimate_shrink_wrapping_type_id', options['shrinkWrappingTypes'], false)}
                        <div className="w-100"></div>
                    </div>
                </div>
            </div>
        );
    }

    renderHandlingOptionsCard() {
        const { options, formValues } = this.props;

        let style = {};
        if (parseInt(formValues['estimate_multi_page_type']) === 2 || parseInt(formValues['estimate_multi_page_type']) === 4 || parseInt(formValues['estimate_multi_page_type']) === 9) {
            style = { minHeight: 158 };
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Handling Options
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0" style={style}>
                    <div className="row no-gutters">
                        {this.renderSelect('Banding', 'estimate_banding_type_id', options['bandingTypes'], false)}
                        {this.renderSelect('Wrapping', 'estimate_wrapping_type_id', options['wrappingTypes'], false)}
                        <div className="w-100"></div>
                        {this.renderSelect('Package', 'estimate_package_id', options['packages'], false)}
                        {this.renderSelect('Hand Gathering', 'estimate_use_hand_gathering', [
                            { label: 'Yes', value: 1 },
                            { label: 'No', value: 0 }
                        ], false)}
                        <div className="w-100"></div>
                    </div>
                </div>
            </div>
        );
    }

    renderImpositions() {
        const { estimateResults, formValues } = this.props;

        let numberUp = null;
        let numberOut = null;
        let imposition = null;
        if (estimateResults.length > 0 && estimateResults[0]['results'].length > 0) {
            let results = estimateResults[0]['results'];
            if (parseInt(formValues['estimate_type']) === 2) {
                let impositions = [];
                if (this.hasCover()) {
                    impositions.push(
                        <div>
                            <div className="imposition-name">Cover:</div>
                            <FlatDrawImposition
                                result={estimateResults[0]['cover_results'][0]}
                            />
                        </div>
                    );
                }

                for (let i = 0; i < results[0]['impositions_information'].length; i++) {
                    let impositionName = results[0]['impositions_information'][i]['paper_information']['imposition_name']
                        + ' (' + results[0]['impositions_information'][i]['paper_information']['imposition_out'] + '-Up)';
                    impositions.push(
                        <div>
                            <div className="imposition-name">{impositionName}</div>
                            <MultiPageDrawImposition
                                imposition={results[0]['impositions_information'][i]}
                                quoteInformation={estimateResults[0]['quote_information']}
                            />
                        </div>
                    );
                }

                imposition = (
                    <div className="col">
                        {impositions}
                    </div>
                );
            } else {
                imposition = (
                    <div className="col">
                        <FlatDrawImposition
                            result={results[0]}
                        />
                    </div>
                );
            }
        }

        let style = {};
        if (parseInt(formValues['estimate_multi_page_type']) === 1 || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 8) {
            style = { minHeight: 477 };
        } else if (parseInt(formValues['estimate_multi_page_type']) === 2 || parseInt(formValues['estimate_multi_page_type']) === 9) {
            style = { minHeight: 459 };
        } else if (parseInt(formValues['estimate_multi_page_type']) === 4) {
            style = { minHeight: 347 };
        } else if (parseInt(formValues['estimate_multi_page_type']) === 5) {
            style = { minHeight: 529 };
        } else if (parseInt(formValues['estimate_multi_page_type']) === 6) {
            style = { minHeight: 361 };
        } else if (parseInt(formValues['estimate_multi_page_type']) === 7) {
            style = { minHeight: 585 };
        }

        return (
            <div className="card mb-2 mr-1 pb-2">
                <div className="card-header">
                    Imposition
                </div>
                <div className="card-body pt-1 pb-0" style={style}>
                    <div className="row">
                        {numberUp}
                        <div className="w-100"></div>
                        {numberOut}
                        <div className="w-100"></div>
                        {imposition}
                    </div>
                </div>
            </div>
        );
    }

    renderCoilOptionsCard() {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) !== 2 || ![6, 7].includes(parseInt(formValues['estimate_multi_page_type']))) {
            return;
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Coil Options
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {this.renderSelect('Coil Diameter - Sheet Cap. - Thickness', 'estimate_coil_option', [
                            { label: 'Select option', value: '' },
                            { label: '5/16 - 60 sht - 1/4"', value: '5/16 - 60 sht - 1/4"' },
                            { label: '3/8 - 75 sht - 5/16"', value: '3/8 - 75 sht - 5/16"' },
                            { label: '7/16 - 90 sht - 3/8"', value: '7/16 - 90 sht - 3/8"' },
                            { label: '1/2 - 105 sht - 7/16"', value: '1/2 - 105 sht - 7/16"' },
                            { label: '9/16 - 120 sht - 1/2"', value: '9/16 - 120 sht - 1/2"' },
                            { label: '5/8 - 135 sht - 9/16"', value: '5/8 - 135 sht - 9/16"' },
                            { label: '3/4 - 160 sht - 5/8"', value: '3/4 - 160 sht - 5/8"' },
                            { label: '7/8 - 190 sht - 3/4"', value: '7/8 - 190 sht - 3/4"' },
                            { label: '1 - 220 sht - 7/8"', value: '1 - 220 sht - 7/8"' }
                        ], false)}
                        <div className="w-100"></div>
                        {this.renderSelect('Coil Color', 'estimate_coil_color', [
                            { label: 'Select option', value: '' },
                            { label: 'Black', value: 'Black' },
                            { label: 'Blue', value: 'Blue' },
                            { label: 'Clear', value: 'Clear' },
                            { label: 'White', value: 'White' },
                            { label: 'Red', value: 'Red' },
                            { label: 'Navy', value: 'Navy' },
                            { label: 'Maroon', value: 'Maroon' }
                        ], false)}
                        <div className="w-100"></div>
                    </div>
                </div>
            </div>
        );
    }

    renderStaplingOptionsCard() {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) !== 2 || ![4, 5].includes(parseInt(formValues['estimate_multi_page_type']))) {
            return;
        }

        return (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Stapling Options
                </div>
                <div className="card-body pt-1 pb-0 pl-0 pr-0">
                    <div className="row no-gutters">
                        {this.renderSelect('Number of Staples', 'estimate_staples_number', [
                            { label: 'Select option', value: '' },
                            { label: '1', value: 1 },
                            { label: '2', value: 2 }
                        ], false)}
                        {this.renderSelect('Location', 'estimate_staples_location', [
                            { label: 'Select option', value: '' },
                            { label: 'Left Side', value: 'Left Side' },
                            { label: 'Top left corner', value: 'Top left corner' }
                        ], false)}
                        <div className="w-100"></div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { formValues, handleUpdateFormValue, estimateResults, options, information } = this.props;

        let productionStyle = {};
        if (parseInt(formValues['estimate_multi_page_type']) === 1 || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 8) {
            productionStyle = { minHeight: 89 };
        }

        let productionComponent = (
            <div className="card mb-2 mr-2 pb-2">
                <div className="card-header">
                    Production Notes
                </div>
                <div className="card-body pt-2 pb-0 pl-2 pr-2">
                    <textarea name="estimate_notes" onChange={handleUpdateFormValue.bind(this, 'text', false)} style={productionStyle}>
                        {formValues['estimate_notes']}
                    </textarea>
                    {this.renderSelect('Discount Table', 'estimate_customer_discount_id', options['customerDiscounts'], false)}
                </div>
            </div>
        );

        return (
            <form>
                <div className="row no-gutters mb-2">
                    <div className="col-4" style={{ color: '#0d47a1', fontWeight: 'bold' }}>PQN - Multi-Page</div>
                </div>
                <div className="row no-gutters">
                    <div className="col">
                        {this.renderProductOptionsCard()}
                        {this.renderPressCard()}
                    </div>
                    <div className="col">
                        {this.renderCoverPaperCard()}
                        {this.renderInteriorPaperCard()}
                        {this.renderCoilOptionsCard()}
                        {this.renderStaplingOptionsCard()}
                        {this.renderBinderyOptionsCard()}
                        {this.renderHandlingOptionsCard()}
                        {parseInt(formValues['estimate_multi_page_type']) === 1 || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 8 ? productionComponent : null}
                    </div>
                    <div className="col">
                        {this.renderOutsideServices()}
                        {parseInt(formValues['estimate_multi_page_type']) === 1 || parseInt(formValues['estimate_multi_page_type']) === 3 || parseInt(formValues['estimate_multi_page_type']) === 8 ? null : productionComponent}
                        {this.renderImpositions()}
                        <div className="w-100"></div>
                    </div>
                    <div className="w-100"></div>
                    <EstimatePricing
                        formValues={formValues}
                        estimateResults={estimateResults}
                        information={information}
                        handleUpdateFormValue={handleUpdateFormValue}
                        renderSheets
                    />
                </div>
            </form>
        );
    }
}

export default EstimateMultiPage;
