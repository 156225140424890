const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/invoices`,
    data: `${API_URL}/invoice/data`,
    sizes: `${API_URL}/invoice-sizes`,
    stocks: `${API_URL}/invoice-stocks`,
    machines: `${API_URL}/invoice-machines`,
    export: `${API_URL}/invoice/export`
};

export default apiUrls;
