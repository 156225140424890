import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import { SET_ACTIVE_PAGE } from './../common/Pagination/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            invoice_size_id: '',
            invoice_stock_id: '',
            invoice_ink_id: '',
            quantity: '',
            landrys: '',
            larry: '',
            ac: 0,
            robert: 0,
            ps: '',
            pm: '',
            date: '',
            invoice_machine_id: '',
            name: '',
            description: '',
            per_unit: '',
            ac_per_unit: '',
            larry_per_unit: ''
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'id',
        type: 'desc'
    },
    filter: {
        fields: []
    },
    data: {
        sizes: [],
        stocks: [],
        machines: []
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], [])
                .setIn(['elements', 'processedElements'], [])
                .setIn(['pagination', 'totalPages'], 0)
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20))
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                invoice_size_id: '',
                invoice_stock_id: '',
                invoice_ink_id: '',
                quantity: '',
                landrys: '',
                larry: '',
                ac: 0,
                robert: 0,
                ps: '',
                pm: '',
                date: '',
                invoice_machine_id: '',
                name: '',
                description: '',
                per_unit: '',
                ac_per_unit: '',
                larry_per_unit: ''
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues'], action.payload);
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.PROCESS_ELEMENTS:
            state = state.setIn(['elements', 'processedElements'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20));
            break;

        case actionTypes.FETCH_DATA:
            state = state.setIn(['data', 'sizes'], [])
                .setIn(['data', 'stocks'], [])
                .setIn(['data', 'machines'], []);
            break;

        case actionTypes.FETCH_DATA_SUCCESS:
            state = state.setIn(['data', 'sizes'], action.payload.sizes)
                .setIn(['data', 'stocks'], action.payload.stocks)
                .setIn(['data', 'machines'], action.payload.machines);
            break;

        case actionTypes.FETCH_DATA_FAILURE:
            break;

        default:
            break;
    }

    return state.toJS();
}
