import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingForm: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        paperCategories: PropTypes.array,
        paperVendors: PropTypes.array
    };

    renderInput(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
            </div>
        );
    }

    render() {
        const { hideForm, handleSaveElement, isSavingForm, isFetchingElement, paperCategories, paperVendors } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let paperCategoriesOptions = [{ label: 'Select an option', value: '' }];
        for (let i = 0; i < paperCategories.length; i++) {
            paperCategoriesOptions.push({ label: paperCategories[i]['category_name'], value: paperCategories[i]['id'] });
        }

        let vendorsOptions = [{ label: 'Select an option', value: '' }];
        for (let i = 0; i < paperVendors.length; i++) {
            vendorsOptions.push({ label: paperVendors[i]['name'], value: paperVendors[i]['id'] });
        }

        let form = (
            <form>
                <div className="row">
                    <div className="subtitle mt-0">Paper Data</div>
                    <div className="w-100"></div>
                    {this.renderInput('Name', 'paper_name', 'text', true)}
                    {this.renderSelect('Paper Category', 'paper_category_id', paperCategoriesOptions, true)}
                    <div className="w-100"></div>
                    {this.renderSelect('Vendor', 'vendor_id', vendorsOptions, true)}
                    {this.renderInput('Item Number', 'item_number', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('Description', 'description', 'text', true)}
                    {this.renderInput('Basis Weight', 'basis_weight', 'decimal', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Width', 'width', 'decimal', true)}
                    {this.renderInput('Height', 'length', 'decimal', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Caliper', 'caliper', 'decimal', true)}
                    {this.renderInput('Color', 'color', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('Finish', 'finish', 'text', false)}
                    {this.renderInput('Grain Direction', 'grain_direction', 'text', false)}
                    <div className="w-100"></div>
                    {this.renderInput('M-Weight', 'm_weight', 'decimal', true)}
                    {this.renderInput('Price/CWT', 'price_cwt', 'decimal', true)}
                    <div className="w-100"></div>
                    {this.renderSelect('Active', 'is_active', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true)}
                    {this.renderSelect('Default', 'is_default', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true)}
                    <div className="w-100"></div>
                    {this.renderSelect('Aqueous Coating Enabled', 'aqueous_coating_enable', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true)}
                    {this.renderSelect('UV Coating Enabled', 'uv_coating_enable', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true)}
                    <div className="w-100"></div>
                    {this.renderSelect('Varnish Coating Enabled', 'varnish_coating_enable', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true, 'col-6')}
                    {this.renderSelect('Coating One Side', 'coating_one_side', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true, 'col-6')}
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
