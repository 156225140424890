import { combineReducers } from 'redux';
import * as dialog from './components/common/dialog';
import AccessoriesReducer from './components/Accessories/reducer';
import AdminReducer from './components/Admin/reducer';
import AffixElementsReducer from './components/AffixElements/reducer';
import ArtElementsReducer from './components/ArtElements/reducer';
import BandingMachinesReducer from './components/BandingMachines/reducer';
import BandingTypesReducer from './components/BandingTypes/reducer';
import CertMailElementsReducer from './components/CertMailElements/reducer';
import ChangeOrderReducer from './components/ChangeOrder/reducer';
import CoilBindingMachinesReducer from './components/CoilBindingMachines/reducer';
import CornerStapleMachinesReducer from './components/CornerStapleMachines/reducer';
import CountingReducer from './components/Counting/reducer';
import CustomerDiscountsReducer from './components/CustomerDiscounts/reducer';
import CustomersReducer from './components/Customers/reducer';
import CuttingMachinesReducer from './components/CuttingMachines/reducer';
import DashboardReducer from './components/Dashboard/reducer';
import DataProcessingElementsReducer from './components/DataProcessingElements/reducer';
import DepartmentsReducer from './components/Departments/reducer';
import DepositsReducer from './components/Deposits/reducer';
import DieCuttingMachinesReducer from './components/DieCuttingMachines/reducer';
import DigitalMachinesReducer from './components/DigitalMachines/reducer';
import DrillingMachinesReducer from './components/DrillingMachines/reducer';
import FlatHandGatheringReducer from './components/FlatHandGathering/reducer';
import FoldingMachinesReducer from './components/FoldingMachines/reducer';
import FoldTypesReducer from './components/FoldTypes/reducer';
import HandworkElementsReducer from './components/HandworkElements/reducer';
import IndexTabElementsReducer from './components/IndexTabElements/reducer';
import InkjetElementsReducer from './components/InkjetElements/reducer';
import InksReducer from './components/Inks/reducer';
import InOutRegistersReducer from './components/InOutRegisters/reducer';
import InsertingElementsReducer from './components/InsertingElements/reducer';
import InvoicesReducer from './components/Invoices/reducer';
import LabelElementsReducer from './components/LabelElements/reducer';
import LaminatesReducer from './components/Laminates/reducer';
import LargeFormatMachinesReducer from './components/LargeFormatMachines/reducer';
import LoginReducer from './components/Login/Login.reducer';
import MailingOperationsReducer from './components/MailingOperations/reducer';
import MultiPageHandGatheringReducer from './components/MultiPageHandGathering/reducer';
import NcrFormElementsReducer from './components/NcrFormElements/reducer';
import OrdersReducer from './components/Orders/reducer';
import OrderReducer from './components/Order/reducer';
import PackagesReducer from './components/Packages/reducer';
import PaddingMachinesReducer from './components/PaddingMachines/reducer';
import PaperCategoriesReducer from './components/PaperCategories/reducer';
import PapersReducer from './components/Papers/reducer';
import PaperVendorsReducer from './components/PaperVendors/reducer';
import PerfectBindingMachinesReducer from './components/PerfectBindingMachines/reducer';
import PerfectMatchElementsReducer from './components/PerfectMatchElements/reducer';
import PressesReducer from './components/Presses/reducer';
import PressureSealElementsReducer from './components/PressureSealElements/reducer';
import ProductCategoriesReducer from './components/ProductCategories/reducer';
import ProductionOrdersReducer from './components/ProductionOrders/reducer';
import ProductionOrderReducer from './components/ProductionOrder/reducer';
import ProductQuoteReducer from './components/ProductQuote/reducer';
import ProductsReducer from './components/Products/reducer';
import ProductsCatalogReducer from './components/ProductsCatalog/reducer';
import QuoteReducer from './components/Quote/reducer';
import QuotesReducer from './components/Quotes/reducer';
import RestorePasswordReducer from './components/RestorePassword/reducer';
import SaddleStitchingMachinesReducer from './components/SaddleStitchingMachines/reducer';
import ScoringMachinesReducer from './components/ScoringMachines/reducer';
import ShippingReducer from './components/Shipping/reducer';
import ShrinkWrappingCategoriesReducer from './components/ShrinkWrappingCategories/reducer';
import ShrinkWrappingMachinesReducer from './components/ShrinkWrappingMachines/reducer';
import ShrinkWrappingTypesReducer from './components/ShrinkWrappingTypes/reducer';
import SideStapleMachinesReducer from './components/SideStapleMachines/reducer';
import SpiralBindingMachinesReducer from './components/SpiralBindingMachines/reducer';
import SubstratesReducer from './components/Substrates/reducer';
import TimelineLogReducer from './components/TimelineLog/reducer';
import TurnaroundReducer from './components/Turnaround/reducer';
import UsersReducer from './components/Users/reducer';
import UwbDemoReducer from './components/UwbDemo/reducer';
import UwbRoomReducer from './components/UwbRoom/reducer';
import WaferSealElementsReducer from './components/WaferSealElements/reducer';
import WrappingMachinesReducer from './components/WrappingMachines/reducer';
import WrappingTypesReducer from './components/WrappingTypes/reducer';

const reducer = combineReducers({
    accessories: AccessoriesReducer,
    admin: AdminReducer,
    affixElements: AffixElementsReducer,
    artElements: ArtElementsReducer,
    bandingMachines: BandingMachinesReducer,
    bandingTypes: BandingTypesReducer,
    certMailElements: CertMailElementsReducer,
    changeOrder: ChangeOrderReducer,
    coilBindingMachines: CoilBindingMachinesReducer,
    cornerStapleMachines: CornerStapleMachinesReducer,
    counting: CountingReducer,
    customerDiscounts: CustomerDiscountsReducer,
    customers: CustomersReducer,
    cuttingMachines: CuttingMachinesReducer,
    dashboard: DashboardReducer,
    dataProcessingElements: DataProcessingElementsReducer,
    departments: DepartmentsReducer,
    deposits: DepositsReducer,
    dieCuttingMachines: DieCuttingMachinesReducer,
    dialog: dialog.reducer,
    digitalMachines: DigitalMachinesReducer,
    drillingMachines: DrillingMachinesReducer,
    flatHandGathering: FlatHandGatheringReducer,
    foldingMachines: FoldingMachinesReducer,
    foldTypes: FoldTypesReducer,
    handworkElements: HandworkElementsReducer,
    indexTabElements: IndexTabElementsReducer,
    inkjetElements: InkjetElementsReducer,
    inks: InksReducer,
    inOutRegisters: InOutRegistersReducer,
    insertingElements: InsertingElementsReducer,
    invoices: InvoicesReducer,
    labelElements: LabelElementsReducer,
    laminates: LaminatesReducer,
    largeFormatMachines: LargeFormatMachinesReducer,
    login: LoginReducer,
    mailingOperations: MailingOperationsReducer,
    multiPageHandGathering: MultiPageHandGatheringReducer,
    ncrFormElements: NcrFormElementsReducer,
    order: OrderReducer,
    orders: OrdersReducer,
    packages: PackagesReducer,
    paddingMachines: PaddingMachinesReducer,
    paperCategories: PaperCategoriesReducer,
    papers: PapersReducer,
    paperVendors: PaperVendorsReducer,
    perfectBindingMachines: PerfectBindingMachinesReducer,
    perfectMatchElements: PerfectMatchElementsReducer,
    presses: PressesReducer,
    pressureSealElements: PressureSealElementsReducer,
    productCategories: ProductCategoriesReducer,
    productionOrder: ProductionOrderReducer,
    productionOrders: ProductionOrdersReducer,
    productQuote: ProductQuoteReducer,
    products: ProductsReducer,
    productsCatalog: ProductsCatalogReducer,
    quote: QuoteReducer,
    quotes: QuotesReducer,
    restorePassword: RestorePasswordReducer,
    saddleStitchingMachines: SaddleStitchingMachinesReducer,
    scoringMachines: ScoringMachinesReducer,
    shipping: ShippingReducer,
    shrinkWrappingCategories: ShrinkWrappingCategoriesReducer,
    shrinkWrappingMachines: ShrinkWrappingMachinesReducer,
    shrinkWrappingTypes: ShrinkWrappingTypesReducer,
    sideStapleMachines: SideStapleMachinesReducer,
    spiralBindingMachines: SpiralBindingMachinesReducer,
    substrates: SubstratesReducer,
    timelineLog: TimelineLogReducer,
    turnaround: TurnaroundReducer,
    users: UsersReducer,
    uwbDemo: UwbDemoReducer,
    uwbRoom: UwbRoomReducer,
    waferSealElements: WaferSealElementsReducer,
    wrappingMachines: WrappingMachinesReducer,
    wrappingTypes: WrappingTypesReducer
});

export default reducer;
