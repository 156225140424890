import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import { Table, Form, Delete, AddPayor, SendEmail } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { findPermission } from './../../utils';
import Layout from './../Layout';

class Container extends Component {
    static propTypes = {
        isFetchingElements: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        formValues: PropTypes.object,
        user: PropTypes.object,
        elements: PropTypes.array,
        saveElement: PropTypes.func,
        addPayor: PropTypes.func,
        setSort: PropTypes.func,
        updateFormValue: PropTypes.func,
        fetchElements: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        save: PropTypes.func,
        addElement: PropTypes.func,
        showForm: PropTypes.func,
        hideForm: PropTypes.func,
        showAddPayor: PropTypes.func,
        hideAddPayor: PropTypes.func,
        resetForm: PropTypes.func,
        showDelete: PropTypes.func,
        hideDelete: PropTypes.func,
        setIdForDelete: PropTypes.func,
        deleteElement: PropTypes.func,
        idForDelete: PropTypes.number,
        changeAttribute: PropTypes.func,
        updateAttribute: PropTypes.func,
        formErrors: PropTypes.object,
        isFetchingElement: PropTypes.bool,
        isDeleting: PropTypes.bool,
        setActiveElement: PropTypes.func,
        setFormError: PropTypes.func,
        showInfoNotification: PropTypes.func,
        formErrorMessages: PropTypes.object,
        activeElement: PropTypes.func,
        resetState: PropTypes.func,
        changeFilter: PropTypes.func,
        processedElements: PropTypes.array,
        fetchData: PropTypes.func,
        data: PropTypes.func,
        exportExcel: PropTypes.func,
        show: PropTypes.object,
        showList: PropTypes.func,
        showEmail: PropTypes.func,
        addPm: PropTypes.func,
        setPm: PropTypes.func,
        sendEmail: PropTypes.func,
        showSendEmail: PropTypes.func,
        hideSendEmail: PropTypes.func,
        isSendingEmail: PropTypes.bool,
        setEmail: PropTypes.func,
        filterFields: PropTypes.array
    };

    componentDidMount() {
        const { fetchElements, fetchData, sortColumn, sortType, filterFields } = this.props;
        fetchElements(1, sortColumn, sortType, filterFields);
        fetchData();
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleUpdateFormValue(type, required, event) {
        const { updateFormValue, hideForm, showAddPayor } = this.props;
        let value = event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value;
        updateFormValue(event.target.name, value);

        if (event.target.name === 'payor_id' && value === -1) {
            hideForm();
            showAddPayor();
        }
    }

    handleUpdateFormDateValue(name, required, event) {
        const { updateFormValue } = this.props;
        let value = event === undefined ? '' : moment(event).format('YYYY-MM-DD');
        updateFormValue(name, value);
    }

    handleSort(column) {
        const { setSort, sortColumn, sortType, fetchElements, filterFields } = this.props;
        let type = 'asc';
        if (sortColumn === column) {
            type = sortType === 'asc' ? 'desc' : 'asc';
            setSort(column, type);
        } else {
            setSort(column, 'asc');
        }
        fetchElements(1, column, type, filterFields);
    }

    handleDayChange(index, attribute, day) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(moment(day).format('YYYY-MM-DD'), elements, index, attribute);
    }

    handleAddPayor() {
        const { addPayor, formValues, elements } = this.props;
        addPayor(formValues, elements);
    }

    handleShowForm(id) {
        const { fetchElement } = this.props;
        fetchElement(id);
    }

    handleShowDelete(id, event) {
        const { showDelete, setIdForDelete } = this.props;
        event.preventDefault();
        showDelete();
        setIdForDelete(id);
    }

    handleDeleteElement() {
        const { deleteElement, idForDelete, elements } = this.props;
        deleteElement(idForDelete, elements);
    }

    handleChangeFilter(name, event) {
        const { changeFilter, fetchElements, sortColumn, sortType, filterFields } = this.props;

        let value = null;
        let filter = filterFields;
        if (name === 'start_date') {
            value = event === undefined ? '' : moment(event).format('YYYY-MM-DD');
            changeFilter(name, value);
        } else if (name === 'end_date') {
            value = event === undefined ? '' : moment(event).format('YYYY-MM-DD');
            changeFilter(name, value);
        } else {
            value = event.target.value;
            changeFilter(name, value);
        }

        let fetch = false;
        const index = filter.findIndex(element => element['name'] === name);
        if (index === -1 && value !== '') {
            filter.push({ name: name, value: value });
            fetch = true;
        } else if (index !== -1 && value === '') {
            filter.splice(index, 1);
            fetch = true;
        } else if (index !== -1 && value !== '' && filter[index]['value'] !== value) {
            filter[index]['value'] = value;
            fetch = true;
        }

        if (fetch) {
            fetchElements(1, sortColumn, sortType, filter);
        }
    }

    handleNew() {
        const { resetForm, showForm } = this.props;
        resetForm();
        showForm();
    }

    handleSetActivePage(page) {
        const { fetchElements, setActivePage, sortColumn, sortType, filterFields } = this.props;
        setActivePage(page);
        fetchElements(page, sortColumn, sortType, filterFields);
    }

    handleShowList() {
        const { showList, sortColumn, sortType, fetchElements, setActivePage, resetFilter } = this.props;
        setActivePage(1);
        resetFilter();
        fetchElements(1, sortColumn, sortType, []);
        showList();
    }

    render() {
        const { isFetchingElements, elements, sortColumn, sortType, totalPages, selectedPage, elementsPerPage, user,
            isSavingForm, formValues, formErrors, isFetchingElement, isDeleting, hideDelete,
            formErrorMessages, processedElements, data, hideAddPayor, showAddPayor, hideSendEmail,
            exportExcel, show, addPm, saveElement, resetForm, setPm, totalItems,
            isSendingEmail, sendEmail, showSendEmail, setEmail, filterFields, amount, acct, pstg, other } = this.props;

        let permission = findPermission(user['permissions'], 'Deposits');

        let addButton = null;
        let addPayorButton = null;
        let downloadButton = null;
        let emailButton = null;
        let list = null;
        if (show['list']) {
            if (permission['Edit']) {
                addButton = <div className="upload-button" onClick={this.handleNew.bind(this)}><i className="fas fa-plus" /> Add</div>;
                emailButton = <div className="upload-button" onClick={showSendEmail.bind(this)}><i className="fas fa-envelope" /> Email</div>;
            }

            downloadButton = <div className="upload-button" onClick={exportExcel.bind(this)}><i className="fas fa-download" /> Download</div>;
            list = (
                <Table
                    elements={elements}
                    isFetchingElements={isFetchingElements}
                    totalItems={totalItems}
                    totalPages={totalPages}
                    selectedPage={selectedPage}
                    elementsPerPage={elementsPerPage}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    user={user}
                    processedElements={processedElements}
                    filterFields={filterFields}
                    amount={amount}
                    acct={acct}
                    pstg={pstg}
                    other={other}
                    handleSort={this.handleSort.bind(this)}
                    handleShowDelete={this.handleShowDelete.bind(this)}
                    handleShowForm={this.handleShowForm.bind(this)}
                    handleDayChange={this.handleDayChange.bind(this)}
                    handleChangeFilter={this.handleChangeFilter.bind(this)}
                    handleSetActivePage={this.handleSetActivePage.bind(this)}
                />
            );
        }

        let form = null;
        if (show['form']) {
            addPayorButton = <div className="upload-button" onClick={showAddPayor.bind(this)}><i className="fas fa-plus" /> Payor</div>;
            form = (
                <Form
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    isSavingForm={isSavingForm}
                    isFetchingElement={isFetchingElement}
                    data={data}
                    handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                    handleUpdateFormDateValue={this.handleUpdateFormDateValue.bind(this)}
                    saveElement={saveElement.bind(this)}
                    addPm={addPm.bind(this)}
                    handleShowList={this.handleShowList.bind(this)}
                    resetForm={resetForm.bind(this)}
                    setPm={setPm.bind(this)}
                />
            );
        }

        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid">
                    <Helmet>
                        <title>Daily Deposit Slip</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title="Daily Deposit Slip"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={user}
                            />
                            {addButton}
                            {addPayorButton}
                            {downloadButton}
                            {emailButton}
                            {list}
                            {form}
                            <AddPayor
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSavingForm={isSavingForm}
                                isFetchingElement={isFetchingElement}
                                data={data}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                hideAddPayor={hideAddPayor.bind(this)}
                                handleAddPayor={this.handleAddPayor.bind(this)}
                            />
                            <SendEmail
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSendingEmail={isSendingEmail}
                                isFetchingElement={isFetchingElement}
                                data={data}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                hideSendEmail={hideSendEmail.bind(this)}
                                sendEmail={sendEmail.bind(this)}
                                setEmail={setEmail.bind(this)}
                            />
                            <Delete
                                isDeleting={isDeleting}
                                hideDelete={hideDelete.bind(this)}
                                handleDeleteElement={this.handleDeleteElement.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    formErrorMessages: selectors.getFormErrorsMessages,
    isSavingForm: selectors.getIsSavingForm,
    elements: selectors.getElements,
    processedElements: selectors.getProcessedElements,
    isFetchingElements: selectors.getIsFetchingElements,
    isDeleting: selectors.getIsDeleting,
    idForDelete: selectors.getIdForDelete,
    totalItems: selectors.getTotalItems,
    totalPages: selectors.getTotalPages,
    selectedPage: selectors.getSelectedPage,
    elementsPerPage: selectors.getElementsPerPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    activeElement: selectors.getActiveElement,
    isSendingEmail: selectors.getIsSendingEmail,
    filterFields: selectors.getFilterFields,
    data: selectors.getData,
    show: selectors.getShow,
    amount: selectors.getAmount,
    acct: selectors.getAcct,
    pstg: selectors.getPstg,
    other: selectors.getOther,
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: (selectedPage, sortColumn, sortType, filterFields) => dispatch(actions.fetchElements(selectedPage, sortColumn, sortType, filterFields)),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setSort: (column, type) => dispatch(actions.setSort(column, type)),
        deleteElement: (elements, index) => dispatch(actions.deleteElement(elements, index)),
        showDelete: () => dispatch(dialog.actions.showDialog('delete')),
        hideDelete: () => dispatch(dialog.actions.hideDialog('delete')),
        showAddPayor: () => dispatch(dialog.actions.showDialog('add-payor')),
        hideAddPayor: () => dispatch(dialog.actions.hideDialog('add-payor')),
        showSendEmail: () => dispatch(dialog.actions.showDialog('send-email')),
        hideSendEmail: () => dispatch(dialog.actions.hideDialog('send-email')),
        setIdForDelete: (value) => dispatch(actions.setIdForDelete(value)),
        saveElement: () => dispatch(actions.saveElement()),
        addPayor: (values, elements) => dispatch(actions.addPayor(values, elements)),
        setFormError: (name, value, message) => dispatch(actions.setFormError(name, value, message)),
        resetForm: () => dispatch(actions.resetForm()),
        changeAttribute: (value, elements, index, attribute) => dispatch(actions.changeAttribute(value, elements, index, attribute)),
        updateAttribute: (id, attribute, value, elements, index) => dispatch(actions.updateAttribute(id, attribute, value, elements, index)),
        setActiveElement: (elements, id) => dispatch(actions.setActiveElement(elements, id)),
        resetState: () => dispatch(actions.resetState()),
        changeFilter: (name, value) => dispatch(actions.changeFilter(name, value)),
        fetchData: () => dispatch(actions.fetchData()),
        exportExcel: () => dispatch(actions.exportExcel()),
        showList: () => dispatch(actions.showList()),
        showForm: () => dispatch(actions.showForm()),
        showEmail: () => dispatch(actions.showEmail()),
        addPm: () => dispatch(actions.addPm()),
        setPm: (index, value) => dispatch(actions.setPm(index, value)),
        sendEmail: () => dispatch(actions.sendEmail()),
        setEmail: (email, value) => dispatch(actions.setEmail(email, value)),
        fetchElement: (id) => dispatch(actions.fetchElement(id)),
        setActivePage: (page) => dispatch(actions.setActivePage(page)),
        resetFilter: () => dispatch(actions.resetFilter())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
