import { NAME } from './constants';

export const FETCH_ELEMENTS = `${NAME}/FETCH_ELEMENTS`;
export const FETCH_ELEMENTS_SUCCESS = `${NAME}/FETCH_ELEMENTS_SUCCESS`;
export const FETCH_ELEMENTS_FAILURE = `${NAME}/FETCH_ELEMENTS_FAILURE`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const DELETE_ELEMENT = `${NAME}/DELETE_ELEMENT`;
export const DELETE_ELEMENT_SUCCESS = `${NAME}/DELETE_ELEMENT_SUCCESS`;
export const DELETE_ELEMENT_FAILURE = `${NAME}/DELETE_ELEMENT_FAILURE`;

export const SAVE_ELEMENT = `${NAME}/SAVE_ELEMENT`;
export const SAVE_ELEMENT_SUCCESS = `${NAME}/SAVE_ELEMENT_SUCCESS`;
export const SAVE_ELEMENT_FAILURE = `${NAME}/SAVE_ELEMENT_FAILURE`;

export const FETCH_QUOTE = `${NAME}/FETCH_QUOTE`;
export const FETCH_QUOTE_SUCCESS = `${NAME}/FETCH_QUOTE_SUCCESS`;
export const FETCH_QUOTE_FAILURE = `${NAME}/FETCH_QUOTE_FAILURE`;

export const FETCH_INFORMATION = `${NAME}/FETCH_INFORMATION`;
export const FETCH_INFORMATION_SUCCESS = `${NAME}/FETCH_INFORMATION_SUCCESS`;
export const FETCH_INFORMATION_FAILURE = `${NAME}/FETCH_INFORMATION_FAILURE`;

export const ESTIMATE = `${NAME}/ESTIMATE`;
export const ESTIMATE_SUCCESS = `${NAME}/ESTIMATE_SUCCESS`;
export const ESTIMATE_FAILURE = `${NAME}/ESTIMATE_FAILURE`;

export const SAVE_ESTIMATE = `${NAME}/SAVE_ESTIMATE`;
export const SAVE_ESTIMATE_SUCCESS = `${NAME}/SAVE_ESTIMATE_SUCCESS`;
export const SAVE_ESTIMATE_FAILURE = `${NAME}/SAVE_ESTIMATE_FAILURE`;

export const DELETE_ESTIMATE = `${NAME}/DELETE_ESTIMATE`;
export const DELETE_ESTIMATE_SUCCESS = `${NAME}/DELETE_ESTIMATE_SUCCESS`;
export const DELETE_ESTIMATE_FAILURE = `${NAME}/DELETE_ESTIMATE_FAILURE`;

export const CONVERT_ORDER = `${NAME}/CONVERT_ORDER`;
export const CONVERT_ORDER_SUCCESS = `${NAME}/CONVERT_ORDER_SUCCESS`;
export const CONVERT_ORDER_FAILURE = `${NAME}/CONVERT_ORDER_FAILURE`;

export const INTERSECT_PAPER_CATEGORIES = `${NAME}/INTERSECT_PAPER_CATEGORIES`;
export const INTERSECT_PAPER_CATEGORIES_SUCCESS = `${NAME}/INTERSECT_PAPER_CATEGORIES_SUCCESS`;
export const INTERSECT_PAPER_CATEGORIES_FAILURE = `${NAME}/INTERSECT_PAPER_CATEGORIES_FAILURE`;

export const INTERSECT_PAPER_WEIGHTS = `${NAME}/INTERSECT_PAPER_WEIGHTS`;
export const INTERSECT_PAPER_WEIGHTS_SUCCESS = `${NAME}/INTERSECT_PAPER_WEIGHTS_SUCCESS`;
export const INTERSECT_PAPER_WEIGHTS_FAILURE = `${NAME}/INTERSECT_PAPER_WEIGHTS_FAILURE`;

export const INTERSECT_PAPERS = `${NAME}/INTERSECT_PAPERS`;
export const INTERSECT_PAPERS_SUCCESS = `${NAME}/INTERSECT_PAPERS_SUCCESS`;
export const INTERSECT_PAPERS_FAILURE = `${NAME}/INTERSECT_PAPERS_FAILURE`;

export const FETCH_ESTIMATE = `${NAME}/FETCH_ESTIMATE`;
export const FETCH_ESTIMATE_SUCCESS = `${NAME}/FETCH_ESTIMATE_SUCCESS`;
export const FETCH_ESTIMATE_FAILURE = `${NAME}/FETCH_ESTIMATE_FAILURE`;

export const UPDATE_FORM_VALUE = `${NAME}/UPDATE_FORM_VALUE`;
export const SET_SORT = `${NAME}/SET_SORT`;
export const SET_ELEMENTS = `${NAME}/SET_ELEMENTS`;
export const CHANGE_ATTRIBUTE = `${NAME}/CHANGE_ATTRIBUTE`;
export const SET_ACTIVE_ELEMENT = `${NAME}/SET_ACTIVE_ELEMENT`;
export const SET_ID_FOR_DELETE = `${NAME}/SET_ID_FOR_DELETE`;
export const RESET_ESTIMATE_FORM = `${NAME}/RESET_ESTIMATE_FORM`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const SET_FORM_ERRORS = `${NAME}/SET_FORM_ERRORS`;
export const SET_FORM_ERROR = `${NAME}/SET_FORM_ERROR`;
export const SET_CUSTOMER_ID = `${NAME}/SET_CUSTOMER_ID`;
export const SET_QUOTE_ID = `${NAME}/SET_QUOTE_ID`;
export const SET_PRICES = `${NAME}/SET_PRICES`;
export const SET_ESTIMATES = `${NAME}/SET_ESTIMATES`;
export const SET_ITEM_QUANTITY = `${NAME}/SET_ITEM_QUANTITY`;
export const SET_EDITING_CUSTOMER = `${NAME}/SET_EDITING_CUSTOMER`;
export const CLEAR_RESULTS = `${NAME}/CLEAR_RESULTS`;
export const SET_PRODUCT = `${NAME}/SET_PRODUCT`;
export const SET_ACCESSORIES = `${NAME}/SET_ACCESSORIES`;
export const ADD_OUTSIDE_SERVICE = `${NAME}/ADD_OUTSIDE_SERVICE`;
export const DELETE_OUTSIDE_SERVICE = `${NAME}/DELETE_OUTSIDE_SERVICE`;
export const UPDATE_OUTSIDE_SERVICE = `${NAME}/UPDATE_OUTSIDE_SERVICE`;
export const SET_OPTIONS = `${NAME}/SET_OPTIONS`;
export const RESET_STATE = `${NAME}/RESET_STATE`;
export const SET_LOADING = `${NAME}/SET_LOADING`;
export const RESET_OPTIONS = `${NAME}/RESET_OPTIONS`;
export const UPDATE_STATE = `${NAME}/UPDATE_STATE`;
export const ADD_MAILING_ELEMENT = `${NAME}/ADD_MAILING_ELEMENT`;
export const UPDATE_MAILING_ELEMENT = `${NAME}/UPDATE_MAILING_ELEMENT`;
export const REMOVE_MAILING_ELEMENT = `${NAME}/REMOVE_MAILING_ELEMENT`;
export const UPDATE_MAILING_PRICES = `${NAME}/UPDATE_MAILING_PRICES`;
export const SET_DISCOUNT_CHANGED = `${NAME}/SET_DISCOUNT_CHANGED`;
export const SET_HISTORY = `${NAME}/SET_HISTORY`;
