import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import { SET_ACTIVE_PAGE } from './../common/Pagination/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            name: '',
            email: '',
            phone: '',
            company: '',
            shipping_method: '',
            notes: '',
            industry_id: '',
            discount: 0,
            reseller_id: '',
            active: 1,
            street: '',
            city: '',
            state: '',
            zip: '',
            country: 'United States',
            reseller: 0,
            po_required: 0,
            sales_rep_id: '',
            csr_id: '',
            customer_discount_id: '',
            billing_name: '',
            billing_company: '',
            billing_street: '',
            billing_city: '',
            billing_state: '',
            billing_zip: '',
            billing_country: '',
            billing_phone: '',
            shipping_name: '',
            shipping_company: '',
            shipping_street: '',
            shipping_city: '',
            shipping_state: '',
            shipping_zip: '',
            shipping_country: '',
            shipping_phone: '',
            confirm_email: '',
            use_address_for_billing: 0,
            use_address_for_shipping: 0
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'name',
        type: 'asc'
    },
    information: {
        industries: [],
        salesReps: [],
        csrs: [],
        customerDiscounts: [],
        isFetching: false
    },
    filter: {
        fields: []
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    if (window.location['pathname'] !== '/customers') {
        state = INITIAL_STATE;
        return state.toJS();
    }

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], [])
                .setIn(['elements', 'processedElements'], [])
                .setIn(['pagination', 'totalPages'], 0)
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20))
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                name: '',
                email: '',
                phone: '',
                company: '',
                shipping_method: '',
                notes: '',
                industry_id: '',
                discount: 0,
                reseller_id: '',
                active: 1,
                street: '',
                city: '',
                state: '',
                zip: '',
                country: 'United States',
                reseller: 0,
                po_required: 0,
                sales_rep_id: '',
                csr_id: '',
                customer_discount_id: '',
                billing_name: '',
                billing_company: '',
                billing_street: '',
                billing_city: '',
                billing_state: '',
                billing_zip: '',
                billing_country: '',
                billing_phone: '',
                shipping_name: '',
                shipping_company: '',
                shipping_street: '',
                shipping_city: '',
                shipping_state: '',
                shipping_zip: '',
                shipping_country: '',
                shipping_phone: '',
                confirm_email: '',
                use_address_for_billing: 0,
                use_address_for_shipping: 0
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'name'], action.payload.name)
                .setIn(['form', 'formValues', 'email'], action.payload.email)
                .setIn(['form', 'formValues', 'phone'], action.payload.phone)
                .setIn(['form', 'formValues', 'company'], action.payload.company)
                .setIn(['form', 'formValues', 'shipping_method'], action.payload.shipping_method)
                .setIn(['form', 'formValues', 'notes'], action.payload.notes)
                .setIn(['form', 'formValues', 'industry_id'], action.payload.industry_id)
                .setIn(['form', 'formValues', 'discount'], action.payload.discount)
                .setIn(['form', 'formValues', 'reseller_id'], action.payload.reseller_id)
                .setIn(['form', 'formValues', 'tax_code'], action.payload.tax_code)
                .setIn(['form', 'formValues', 'active'], action.payload.active)
                .setIn(['form', 'formValues', 'street'], action.payload.street)
                .setIn(['form', 'formValues', 'city'], action.payload.city)
                .setIn(['form', 'formValues', 'state'], action.payload.state)
                .setIn(['form', 'formValues', 'zip'], action.payload.zip)
                .setIn(['form', 'formValues', 'country'], action.payload.country)
                .setIn(['form', 'formValues', 'reseller'], action.payload.reseller)
                .setIn(['form', 'formValues', 'po_required'], action.payload.po_required)
                .setIn(['form', 'formValues', 'sales_rep_id'], action.payload.sales_rep_id)
                .setIn(['form', 'formValues', 'csr_id'], action.payload.csr_id)
                .setIn(['form', 'formValues', 'customer_discount_id'], action.payload.customer_discount_id)
                .setIn(['form', 'formValues', 'billing_name'], action.payload.billingAddress == null ? '' : action.payload.billingAddress.name)
                .setIn(['form', 'formValues', 'billing_company'], action.payload.billingAddress == null ? '' : action.payload.billingAddress.company)
                .setIn(['form', 'formValues', 'billing_street'], action.payload.billingAddress == null ? '' : action.payload.billingAddress.street)
                .setIn(['form', 'formValues', 'billing_city'], action.payload.billingAddress == null ? '' : action.payload.billingAddress.city)
                .setIn(['form', 'formValues', 'billing_state'], action.payload.billingAddress == null ? '' : action.payload.billingAddress.state)
                .setIn(['form', 'formValues', 'billing_zip'], action.payload.billingAddress == null ? '' : action.payload.billingAddress.zip)
                .setIn(['form', 'formValues', 'billing_country'], action.payload.billingAddress == null ? '' : action.payload.billingAddress.country)
                .setIn(['form', 'formValues', 'billing_phone'], action.payload.billingAddress == null ? '' : action.payload.billingAddress.phone)
                .setIn(['form', 'formValues', 'shipping_name'], action.payload.shippingAddress == null ? '' : action.payload.shippingAddress.name)
                .setIn(['form', 'formValues', 'shipping_company'], action.payload.shippingAddress == null ? '' : action.payload.shippingAddress.company)
                .setIn(['form', 'formValues', 'shipping_street'], action.payload.shippingAddress == null ? '' : action.payload.shippingAddress.street)
                .setIn(['form', 'formValues', 'shipping_city'], action.payload.shippingAddress == null ? '' : action.payload.shippingAddress.city)
                .setIn(['form', 'formValues', 'shipping_state'], action.payload.shippingAddress == null ? '' : action.payload.shippingAddress.state)
                .setIn(['form', 'formValues', 'shipping_zip'], action.payload.shippingAddress == null ? '' : action.payload.shippingAddress.zip)
                .setIn(['form', 'formValues', 'shipping_country'], action.payload.shippingAddress == null ? '' : action.payload.shippingAddress.country)
                .setIn(['form', 'formValues', 'shipping_phone'], action.payload.shippingAddress == null ? '' : action.payload.shippingAddress.phone)
                .setIn(['form', 'formValues', 'confirm_email'], action.payload.email)
                .setIn(['form', 'formValues', 'use_address_for_billing'], 0)
                .setIn(['form', 'formValues', 'use_address_for_shipping'], 0);
            break;

        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
                .setIn(['information', 'industries'], [])
                .setIn(['information', 'salesReps'], [])
                .setIn(['information', 'csrs'], [])
                .setIn(['information', 'customerDiscounts'], []);
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'industries'], action.payload.industries)
                .setIn(['information', 'salesReps'], action.payload.salesReps)
                .setIn(['information', 'csrs'], action.payload.csrs)
                .setIn(['information', 'customerDiscounts'], action.payload.customerDiscounts);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.PROCESS_ELEMENTS:
            state = state.setIn(['elements', 'processedElements'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20));
            break;

        default:
            break;
    }

    return state.toJS();
}
