import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import { needUpdate } from './../../modules/helpers/component';

export default class Header extends Component
{
    static propTypes = {
        handleClickLogout: PropTypes.func,
        user: PropTypes.object
    };

    /*shouldComponentUpdate(nextProps, nextState) {
        return needUpdate(['user', 'location'], nextProps, this.props);
    }*/

    renderHeader() {
        const { handleClickLogout, user } = this.props;
        const pathname = window.location.pathname;

        if(user.id == null || pathname.includes('/login')) {
            return null;
        }

        return (
            <div id="header" className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div id="logo"><img src={require('./../images/logo.png')} alt="Absolute Color" /></div>
                        <div className="title-agent"></div>
                        <div id="my-agent-account">
                            <i className="fas fa-user"></i>
                            Welcome&nbsp;
                            <a href="/">{user.name}</a> |&nbsp;
                            <a href="/" onClick={handleClickLogout}>Sign Out</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render(){
        return (
            <header className="main-header">
                {this.renderHeader()}
                <div className="clearfix"></div>
            </header>
        );
    }
}
