import React, { Component } from 'react';
import Form from './Form';
import { connect } from 'react-redux';
import * as actions from './Login.actions';
import * as selectors from './Login.selectors';
import PropTypes from 'prop-types';
import './Login.scss';
import { Redirect } from 'react-router-dom';
import { findPermission } from './../../utils';

class Login extends Component {
    render() {
        if(this.props.redirect) {
            this.props.setRedirect(false);

            let url = '/';
            if(parseInt(this.props.user.user_type_1) === 19) {   // Products Only
                if(this.props.user.product_windows.length > 0) {
                    url = '/products-catalog';
                } else {
                    url = '/login';
                }
            } else {
                let permissionQuotes = findPermission(this.props.user['permissions'], 'Quotes');
                let permissionOrders = findPermission(this.props.user['permissions'], 'Orders');
                let permissionProduction = findPermission(this.props.user['permissions'], 'Production');
                let permissionDeposits = findPermission(this.props.user['permissions'], 'Deposits');
                let permissionLandrys = findPermission(this.props.user['permissions'], 'Landry\'s');
                let permissionShippingReceiving = findPermission(this.props.user['permissions'], 'Shipping/Receiving');
                let permissionUsers = findPermission(this.props.user['permissions'], 'Users');
                let permissionIndustries = findPermission(this.props.user['permissions'], 'Industries');
                let permissionDepartments = findPermission(this.props.user['permissions'], 'Departments');
                let permissionTurnaround = findPermission(this.props.user['permissions'], 'Turnaround');
                let permissionPresses = findPermission(this.props.user['permissions'], 'Presses');
                let permissionInks = findPermission(this.props.user['permissions'], 'Inks');
                let permissionDigital = findPermission(this.props.user['permissions'], 'Digital');
                let permissionCutting = findPermission(this.props.user['permissions'], 'Cutting');
                let permissionDieCutting = findPermission(this.props.user['permissions'], 'Die-Cutting');
                let permissionDrilling = findPermission(this.props.user['permissions'], 'Drilling');
                let permissionScoring = findPermission(this.props.user['permissions'], 'Scoring');
                let permissionPadding = findPermission(this.props.user['permissions'], 'Padding');
                let permissionPaperCategories = findPermission(this.props.user['permissions'], 'Paper Categories');
                let permissionPaperVendors = findPermission(this.props.user['permissions'], 'Paper Vendors');
                let permissionPapers = findPermission(this.props.user['permissions'], 'Papers');
                let permissionFoldingMachines = findPermission(this.props.user['permissions'], 'Folding Machines');
                let permissionFoldingTypes = findPermission(this.props.user['permissions'], 'Folding Types');
                let permissionSaddleStitchingMachines = findPermission(this.props.user['permissions'], 'Saddle Stitching Machines');
                let permissionPerfectBindingMachines = findPermission(this.props.user['permissions'], 'Perfect Binding Machines');
                let permissionCoilBindingMachines = findPermission(this.props.user['permissions'], 'Coil Binding Machines');
                let permissionCornerStapleMachines = findPermission(this.props.user['permissions'], 'Corner Staple Machines');
                let permissionSideStapleMachines = findPermission(this.props.user['permissions'], 'Side Staple Machines');
                let permissionSpiralBindingMachines = findPermission(this.props.user['permissions'], 'Spiral Binding Machines');
                let permissionBandingTypes = findPermission(this.props.user['permissions'], 'Banding Types');
                let permissionBandingMachines = findPermission(this.props.user['permissions'], 'Banding Machines');
                let permissionWrappingTypes = findPermission(this.props.user['permissions'], 'Wrapping Types');
                let permissionWrappingMachines = findPermission(this.props.user['permissions'], 'Wrapping Machines');
                let permissionShrinkWrappingMachines = findPermission(this.props.user['permissions'], 'Shrink Wrapping Machines');
                let permissionShrinkWrappingTypes = findPermission(this.props.user['permissions'], 'Shrink Wrapping Types');
                let permissionShrinkWrappingCategories = findPermission(this.props.user['permissions'], 'Shrink Wrapping Categories');
                let permissionPackages = findPermission(this.props.user['permissions'], 'Packages');
                let permissionMailingOperations = findPermission(this.props.user['permissions'], 'Mailing Operations');
                let permissionFlatHandGathering = findPermission(this.props.user['permissions'], 'Flat Hand Gathering');
                let permissionMultiHandGathering= findPermission(this.props.user['permissions'], 'Multi-Page Hand Gathering');
                let permissionCounting = findPermission(this.props.user['permissions'], 'Counting');
                let permissionLargeFormatMachines = findPermission(this.props.user['permissions'], 'Large Format Machines');
                let permissionSubstrates = findPermission(this.props.user['permissions'], 'Substrates');
                let permissionLaminates = findPermission(this.props.user['permissions'], 'Laminates');
                let permissionAccessories = findPermission(this.props.user['permissions'], 'Accessories');
                let permissionDataProcessing = findPermission(this.props.user['permissions'], 'Data Processing');
                let permissionInkjet = findPermission(this.props.user['permissions'], 'Inkjet');
                let permissionInserting = findPermission(this.props.user['permissions'], 'Inserting');
                let permissionPerfectMatch = findPermission(this.props.user['permissions'], 'Perfect Match');
                let permissionWaferSeal = findPermission(this.props.user['permissions'], 'Wafer Seal');
                let permissionAffixLabels = findPermission(this.props.user['permissions'], 'Affix Labels');
                let permissionCertifiedMail = findPermission(this.props.user['permissions'], 'Certified Mail');
                let permissionArt = findPermission(this.props.user['permissions'], 'Art');
                let permissionHandwork = findPermission(this.props.user['permissions'], 'Handwork');
                let permissionPressureSealForms = findPermission(this.props.user['permissions'], 'Pressure Seal Forms');
                let permissionIndexTabs = findPermission(this.props.user['permissions'], 'Index Tabs');
                let permissionLabels = findPermission(this.props.user['permissions'], 'Labels');
                let permissionNcrForms = findPermission(this.props.user['permissions'], 'NCR Forms');

                if(permissionQuotes['View'] || permissionOrders['View'] || permissionProduction['View']) {
                    url = '/dashboard';
                } else if(permissionDeposits['View']) {
                    url = '/deposits';
                } else if(permissionLandrys['View']) {
                    url = '/landrys-invoices';
                } else if(permissionShippingReceiving['Warehouse'] || permissionShippingReceiving['Sales/CSR']) {
                    url = '/shipping-receiving';
                } else if(permissionUsers['View'] || permissionIndustries['View'] || permissionDepartments['View']
                    || permissionTurnaround['View'] || permissionPresses['View'] || permissionInks['View']
                    || permissionDigital['View'] || permissionCutting['View'] || permissionDieCutting['View']
                    || permissionDrilling['View'] || permissionScoring['View'] || permissionPadding['View']
                    || permissionPaperCategories['View'] || permissionPaperVendors['View'] || permissionPapers['View']
                    || permissionFoldingMachines['View'] || permissionFoldingTypes['View'] || permissionSaddleStitchingMachines['View']
                    || permissionPerfectBindingMachines['View'] || permissionCoilBindingMachines['View'] || permissionCornerStapleMachines['View']
                    || permissionSideStapleMachines['View'] || permissionSpiralBindingMachines['View'] || permissionBandingTypes['View']
                    || permissionBandingMachines['View'] || permissionWrappingTypes['View'] || permissionWrappingMachines['View']
                    || permissionShrinkWrappingMachines['View'] || permissionShrinkWrappingTypes['View'] || permissionShrinkWrappingCategories['View']
                    || permissionPackages['View'] || permissionMailingOperations['View'] || permissionFlatHandGathering['View']
                    || permissionMultiHandGathering['View'] || permissionCounting['View'] || permissionLargeFormatMachines['View']
                    || permissionSubstrates['View'] || permissionLaminates['View'] || permissionAccessories['View']
                    || permissionDataProcessing['View'] || permissionInkjet['View'] || permissionInserting['View']
                    || permissionPerfectMatch['View'] || permissionWaferSeal['View'] || permissionAffixLabels['View']
                    || permissionCertifiedMail['View'] || permissionArt['View'] || permissionHandwork['View']
                    || permissionPressureSealForms['View'] || permissionIndexTabs['View'] || permissionLabels['View']
                    || permissionNcrForms['View']
                ) {
                    url = '/admin';
                } else {
                    url = '/dashboard';
                }
            }

            return <Redirect to={url} />;
        }
        
        return (
            <div className="login-container">
                <Form
                    form={this.props.form}
                    login={this.props.login}
                    setField={this.props.setField}
                    isLogging={this.props.isLogging}
                    restorePassword={this.props.restorePassword}
                />
            </div>
        );
    }
}

Login.propTypes = {
    isLogging: PropTypes.bool.isRequired,
    setField: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    redirect: PropTypes.bool,
    setRedirect: PropTypes.func.isRequired,
    restorePassword: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    isLogging: selectors.getIsLogging(state),
    form: selectors.getForm(state),
    redirect: selectors.getRedirect(state),
    user: selectors.getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
    login: () => dispatch(actions.login()),
    setField: (name, value) => dispatch(actions.setField(name, value)),
    setRedirect: () => dispatch(actions.setRedirect()),
    restorePassword: () => dispatch(actions.restorePassword())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
