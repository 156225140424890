import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import { SET_ACTIVE_PAGE } from './../common/Pagination/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            name: '',
            labor_rate: '',
            labor_markup_rate: '',
            min_paper_width: '',
            min_paper_length: '',
            max_paper_width: '',
            max_paper_length: '',
            setup_time: '',
            waste: '',
            is_active: 1,
            is_default: 0,
            cost_click_black: '',
            cost_click_color: '',
            printable_area_width: '',
            printable_area_length: '',
            paper_category_id: '',
            paper_weight: '',
            active_paper_ids: [],
            discount_table: []
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        activePapers: [],
        isSavingActivePapers: false,
        speedTable: [],
        isSavingSpeedTable: false,
        isFetching: false,
        discountTable: [],
        isSavingDiscountTable: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'name',
        type: 'asc'
    },
    information: {
        paperCategories: [],
        paperWeights: [],
        papers: [],
        isFetching: false
    },
    options: {
        activePaperCategories: [{ label: 'Select option', value: '' }],
        activePaperWeights: [{ label: 'Select option', value: '' }],
        activePapers: [{ label: 'Select option', value: '' }]
    },
    fetching: {
        activePaperCategories: false,
        activePaperWeights: false,
        activePapers: false
    },
    filter: {
        fields: []
    }
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    if (window.location['pathname'] !== '/digital-machines') {
        state = INITIAL_STATE;
        return state.toJS();
    }

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], [])
                .setIn(['elements', 'processedElements'], [])
                .setIn(['pagination', 'totalPages'], 0)
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20))
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                name: '',
                labor_rate: '',
                labor_markup_rate: '',
                min_paper_width: '',
                min_paper_length: '',
                max_paper_width: '',
                max_paper_length: '',
                setup_time: '',
                waste: '',
                is_active: 1,
                is_default: 0,
                cost_click_black: '',
                cost_click_color: '',
                printable_area_width: '',
                printable_area_length: '',
                active_paper_ids: [],
                discount_table: []
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['activeElement', 'activePapers'], action.payload.activePapers)
                .setIn(['activeElement', 'speedTable'], action.payload.speeds)
                .setIn(['activeElement', 'discountTable'], action.payload.discounts)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'name'], action.payload.name)
                .setIn(['form', 'formValues', 'labor_rate'], action.payload.labor_rate)
                .setIn(['form', 'formValues', 'labor_markup_rate'], action.payload.labor_markup_rate)
                .setIn(['form', 'formValues', 'min_paper_width'], action.payload.min_paper_width)
                .setIn(['form', 'formValues', 'min_paper_length'], action.payload.min_paper_length)
                .setIn(['form', 'formValues', 'max_paper_width'], action.payload.max_paper_width)
                .setIn(['form', 'formValues', 'max_paper_length'], action.payload.max_paper_length)
                .setIn(['form', 'formValues', 'setup_time'], action.payload.setup_time)
                .setIn(['form', 'formValues', 'waste'], action.payload.waste)
                .setIn(['form', 'formValues', 'is_active'], action.payload.is_active)
                .setIn(['form', 'formValues', 'is_default'], action.payload.is_default)
                .setIn(['form', 'formValues', 'cost_click_black'], action.payload.cost_click_black)
                .setIn(['form', 'formValues', 'cost_click_color'], action.payload.cost_click_color)
                .setIn(['form', 'formValues', 'printable_area_width'], action.payload.printable_area_width)
                .setIn(['form', 'formValues', 'printable_area_length'], action.payload.printable_area_length)
                .setIn(['form', 'formValues', 'active_paper_ids'], [])
                .setIn(['form', 'formValues', 'discount_table'], []);
            break;

        case actionTypes.SAVE_ACTIVE_PAPERS:
            state = state.setIn(['activeElement', 'isSavingActivePapers'], true);
            break;

        case actionTypes.SAVE_ACTIVE_PAPERS_SUCCESS:
            state = state.setIn(['activeElement', 'isSavingActivePapers'], false);
            break;

        case actionTypes.SAVE_ACTIVE_PAPERS_FAILURE:
            state = state.setIn(['activeElement', 'isSavingActivePapers'], false);
            break;

        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
                .setIn(['information', 'paperCategories'], [])
                .setIn(['information', 'paperWeights'], [])
                .setIn(['information', 'papers'], []);
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'paperCategories'], action.payload.paperCategories)
                .setIn(['information', 'paperWeights'], action.payload.paperWeights)
                .setIn(['information', 'papers'], action.payload.papers);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        case actionTypes.SET_SPEED_TABLE:
            state = state.setIn(['activeElement', 'speedTable'], action.payload);
            break;

        case actionTypes.SAVE_SPEED_TABLE:
            state = state.setIn(['activeElement', 'isSavingSpeedTable'], true);
            break;

        case actionTypes.SAVE_SPEED_TABLE_SUCCESS:
            state = state.setIn(['activeElement', 'isSavingSpeedTable'], false);
            break;

        case actionTypes.SAVE_SPEED_TABLE_FAILURE:
            state = state.setIn(['activeElement', 'isSavingSpeedTable'], false);
            break;

        case actionTypes.ADD_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.push(action.payload.value));
            break;

        case actionTypes.DELETE_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.delete(action.payload.value));
            break;

        case actionTypes.CONCAT_ACTIVE_DATA:
            state = state.updateIn(['activeElement', action.payload.name], (data) => data.concat(action.payload.data));
            break;

        case actionTypes.ADD_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload.name], (data) => data.push(action.payload.value));
            break;

        case actionTypes.CLEAR_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload], (data) => data.clear());
            break;

        case actionTypes.CONCAT_MULTIPLE_VALUE:
            state = state.updateIn(['form', 'formValues', action.payload.name], (data) => data.concat(action.payload.data));
            break;

        case actionTypes.SET_OPTIONS:
            state = state.updateIn(['options', action.payload.name], (data) => Immutable.List(action.payload.data));
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.PROCESS_ELEMENTS:
            state = state.setIn(['elements', 'processedElements'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20));
            break;

        case actionTypes.SET_LOADING:
            state = state.setIn(['fetching', action.payload.name], action.payload.value);
            break;

        case actionTypes.RESET_OPTIONS:
            state = state.setIn(['options', action.payload], []);
            break;

        case actionTypes.FETCH_PAPER_CATEGORIES:
            state = state.setIn(['fetching', 'activePaperCategories'], true);
            break;

        case actionTypes.FETCH_PAPER_CATEGORIES_SUCCESS:
            state = state.setIn(['fetching', 'activePaperCategories'], false);
            break;

        case actionTypes.FETCH_PAPER_CATEGORIES_FAILURE:
            state = state.setIn(['fetching', 'activePaperCategories'], false);
            break;

        case actionTypes.FETCH_PAPER_WEIGHTS:
            state = state.setIn(['fetching', 'activePaperWeights'], true);
            break;

        case actionTypes.FETCH_PAPER_WEIGHTS_SUCCESS:
            state = state.setIn(['fetching', 'activePaperWeights'], false);
            break;

        case actionTypes.FETCH_PAPER_WEIGHTS_FAILURE:
            state = state.setIn(['fetching', 'activePaperWeights'], false);
            break;

        case actionTypes.FETCH_PAPERS:
            state = state.setIn(['fetching', 'activePapers'], true)
                .setIn(['information', 'papers'], []);
            break;

        case actionTypes.FETCH_PAPERS_SUCCESS:
            state = state.setIn(['fetching', 'activePapers'], false)
                .setIn(['information', 'papers'], action.payload);
            break;

        case actionTypes.FETCH_PAPERS_FAILURE:
            state = state.setIn(['fetching', 'activePapers'], false);
            break;

        case actionTypes.UPDATE_STATE:
            if (action.payload.formValues) {
                state = state.updateIn(['form', 'formValues'], (formValues) => {
                    for (let [element, value] of Object.entries(action.payload.formValues)) {
                        formValues = formValues.set(element, value);
                    }
                    return formValues;
                });
            }
            if (action.payload.options) {
                state = state.updateIn(['options'], (options) => {
                    for (let [element, value] of Object.entries(action.payload.options)) {
                        options = options.set(element, value);
                    }
                    return options;
                });
            }
            if (action.payload.fetching) {
                state = state.updateIn(['fetching'], (fetching) => {
                    for (let [element, value] of Object.entries(action.payload.fetching)) {
                        fetching = fetching.set(element, value);
                    }
                    return fetching;
                });
            }
            break;

        case actionTypes.SAVE_DISCOUNT_TABLE:
            state = state.setIn(['activeElement', 'isSavingDiscountTable'], true);
            break;

        case actionTypes.SAVE_DISCOUNT_TABLE_SUCCESS:
            state = state.setIn(['activeElement', 'isSavingDiscountTable'], false);
            break;

        case actionTypes.SAVE_DISCOUNT_TABLE_FAILURE:
            state = state.setIn(['activeElement', 'isSavingDiscountTable'], false);
            break;

        case actionTypes.ADD_DISCOUNT:
            state = state.updateIn(['activeElement', 'discountTable'], (discountTable) => discountTable.push({
                id: '',
                start_quantity: '',
                end_quantity: '',
                discount: ''
            }));
            break;

        case actionTypes.DELETE_DISCOUNT:
            state = state.updateIn(['activeElement', 'discountTable'], (discountTable) => discountTable.delete(action.payload));
            break;

        case actionTypes.UPDATE_DISCOUNT:
            state = state.updateIn(['activeElement', 'discountTable'], (discountTable) => {
                const discount = discountTable.get(action.payload.index);
                const newDiscount = discount.setIn([action.payload.field], action.payload.value);
                return discountTable.set(action.payload.index, newDiscount);
            });
            break;

        default:
            break;
    }

    return state.toJS();
}
