import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getModel = state => state[NAME];

/** activeElement **/
export const getActiveElementModel = createSelector(
    getModel,
    (model) => model.activeElement
);

/** activeElement **/
export const getActiveElement = createSelector(
    getActiveElementModel,
    (activeElement) => activeElement.data
);
export const getActiveProduct = createSelector(
    getActiveElementModel,
    (activeElement) => activeElement.product
);
export const getIsFetchingElement = createSelector(
    getActiveElementModel,
    (activeElement) => activeElement.isFetching
);

/** form **/
export const getForm = createSelector(
    getModel,
    (model) => model.form
);
export const getFormValues = createSelector(
    getForm,
    (form) => form.formValues
);
export const getIsSavingForm = createSelector(
    getForm,
    (form) => form.isSaving
);
export const getFormErrors = createSelector(
    getForm,
    (form) => form.formErrors
);
export const getFormErrorsMessages = createSelector(
    getForm,
    (form) => form.formErrorMessages
);
export const getEstimateAccessories = createSelector(
    getForm,
    (form) => form.formValues.estimate_accessories
);

/** elements **/
export const getElements = createSelector(
    getModel,
    (model) => model.elements.data
);
export const getIsFetchingElements = createSelector(
    getModel,
    (model) => model.elements.isFetching
);

/** delete **/
export const getIsDeleting = createSelector(
    getModel,
    (model) => model.delete.isDeleting
);
export const getIdForDelete = createSelector(
    getModel,
    (model) => model.delete.id
);

/** pagination **/
export const getTotalPages = createSelector(
    getModel,
    (model) => model.pagination.totalPages
);
export const getSelectedPage = createSelector(
    getModel,
    (model) => model.pagination.selectedPage
);
export const getElementsPerPage = createSelector(
    getModel,
    (model) => model.pagination.elementsPerPage
);

/** sort **/
export const getSortColumn = createSelector(
    getModel,
    (model) => model.sort.column
);
export const getSortType = createSelector(
    getModel,
    (model) => model.sort.type
);

/** customer **/
export const getCustomerId = createSelector(
    getModel,
    (model) => model.customer.id
);
export const getEditingCustomer = createSelector(
    getModel,
    (model) => model.customer.editing
);

/** order **/
export const getOrderId = createSelector(
    getModel,
    (model) => model.order.id
);
export const getOrder = createSelector(
    getModel,
    (model) => model.order.data
);
export const getIsFetchingOrder = createSelector(
    getModel,
    (model) => model.order.isFetching
);
export const getIsSendingToProduction = createSelector(
    getModel,
    (model) => model.order.isSendingToProduction
);

/** information **/
export const getIsFetchingInformation = createSelector(
    getModel,
    (model) => model.information.isFetching
);
export const getInformation = createSelector(
    getModel,
    (model) => model.information
);

/** estimate **/
export const getIsLoadingEstimate = createSelector(
    getModel,
    (model) => model.estimate.isLoading
);
export const getEstimateResults = createSelector(
    getModel,
    (model) => model.estimate.results
);
export const getIsSavingEstimate = createSelector(
    getModel,
    (model) => model.estimate.isSaving
);

/** file **/
export const getFiles = createSelector(
    getModel,
    (model) => model.uploadFile.files
);
export const getIsUploadingFile = createSelector(
    getModel,
    (model) => model.uploadFile.isUploading
);
export const getFilename = createSelector(
    getModel,
    (model) => model.uploadFile.filename
);
export const getUploadingProgress = createSelector(
    getModel,
    (model) => model.uploadFile.progress
);
export const getFileEstimateId = createSelector(
    getModel,
    (model) => model.uploadFile.id
);
export const getIsDeletingFile = createSelector(
    getModel,
    (model) => model.uploadFile.isDeleting
);
export const getFileIdToDelete = createSelector(
    getModel,
    (model) => model.uploadFile.idToDelete
);

/** options **/
export const getOptions = createSelector(
    getModel,
    (model) => model.options
);

/** fetching **/
export const getFetching = createSelector(
    getModel,
    (model) => model.fetching
);

/** history **/
export const getHistory = createSelector(
    getModel,
    (model) => model.history
);
