import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import { Table, Form, Delete } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import moment from 'moment';
import numeral from 'numeral';
import { Helmet } from 'react-helmet';
import { showNotification, findPermission } from './../../utils';
import Layout from './../Layout';

class Container extends Component {
    static propTypes = {
        isFetchingElements: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        formValues: PropTypes.object,
        user: PropTypes.object,
        elements: PropTypes.array,
        saveElement: PropTypes.func,
        addPayor: PropTypes.func,
        setSort: PropTypes.func,
        updateFormValue: PropTypes.func,
        fetchElements: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        save: PropTypes.func,
        addElement: PropTypes.func,
        showForm: PropTypes.func,
        hideForm: PropTypes.func,
        resetForm: PropTypes.func,
        showDelete: PropTypes.func,
        hideDelete: PropTypes.func,
        setIdForDelete: PropTypes.func,
        deleteElement: PropTypes.func,
        idForDelete: PropTypes.number,
        changeAttribute: PropTypes.func,
        updateAttribute: PropTypes.func,
        formErrors: PropTypes.object,
        isFetchingElement: PropTypes.bool,
        isDeleting: PropTypes.bool,
        setActiveElement: PropTypes.func,
        setFormError: PropTypes.func,
        showInfoNotification: PropTypes.func,
        formErrorMessages: PropTypes.object,
        activeElement: PropTypes.func,
        resetState: PropTypes.func,
        changeFilter: PropTypes.func,
        processedElements: PropTypes.array,
        processElements: PropTypes.func,
        fetchData: PropTypes.func,
        data: PropTypes.func,
        exportExcel: PropTypes.func,
        setCost: PropTypes.func,
        show: PropTypes.object,
        showList: PropTypes.func,
        filterFields: PropTypes.array,
        addNotification: PropTypes.func,
        removeNotification: PropTypes.func,
        costs: PropTypes.object
    };

    componentDidMount() {
        const { fetchElements, fetchData } = this.props;
        fetchElements();
        fetchData();
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleUpdateFormValue(type, required, event) {
        const { updateFormValue, hideForm } = this.props;
        let value = event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value;
        updateFormValue(event.target.name, value);

        if (event.target.name === 'payor_id' && value === -1) {
            hideForm();
        }
    }

    handleUpdateFormDateValue(name, required, event) {
        const { updateFormValue } = this.props;
        let value = event === undefined ? '' : moment(event).format('YYYY-MM-DD');
        updateFormValue(name, value);
    }

    handleSort(column) {
        const { setSort, sortColumn, sortType, processElements } = this.props;
        if (sortColumn === column) {
            setSort(column, sortType === 'asc' ? 'desc' : 'asc');
        } else {
            setSort(column, 'asc');
        }
        processElements();
    }

    handleInlineChange(index, attribute, value) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(value, elements, index, attribute);
    }

    handleDayChange(index, attribute, day) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(moment(day).format('YYYY-MM-DD'), elements, index, attribute);
    }

    handleShowForm(id) {
        const { showForm, setActiveElement, elements } = this.props;
        setActiveElement(elements, id);
        showForm();
    }

    handleUpdateDayValue(name, day) {
        const { updateFormValue, setFormError } = this.props;
        updateFormValue(name, day);
        setFormError(name, day === '');
    }

    handleShowDelete(id, event) {
        const { showDelete, setIdForDelete } = this.props;
        event.preventDefault();
        showDelete();
        setIdForDelete(id);
    }

    handleDeleteElement() {
        const { deleteElement, idForDelete, elements } = this.props;
        deleteElement(idForDelete, elements);
    }

    handleChangeAttribute(index, attribute, type, value) {
        const { processedElements, changeAttribute, updateAttribute } = this.props;

        let valueToSave = value;
        if (type === 'money') {
            valueToSave = numeral(value).format('0.00');
            value = numeral(value).format('$0,0.00');
        } else if (type === 'number') {
            if (parseInt(value).toString().match(/^[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        } else if (type === 'date') {
            valueToSave = moment(value).format('YYYY-MM-DD');
            value = valueToSave;
        } else if (type === 'decimal') {
            if (parseInt(value).toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        }

        changeAttribute(value, processedElements, index, attribute);
        updateAttribute(processedElements[index].id, attribute, valueToSave, processedElements, index);
    }

    handleChangeFilter(name, event) {
        const { changeFilter, processElements } = this.props;

        if (name === 'start_date') {
            let value = event === undefined ? '' : moment(event).format('YYYY-MM-DD');
            changeFilter(name, value);
        } else if (name === 'end_date') {
            let value = event === undefined ? '' : moment(event).format('YYYY-MM-DD');
            changeFilter(name, value);
        } else {
            changeFilter(name, event.target.value);
        }

        processElements();
    }

    handleNotificationClick(id, event) {
        const { addNotification, removeNotification } = this.props;

        if (event.target.checked) {
            addNotification(id);
        } else {
            removeNotification(id);
        }
    }

    handleNew() {
        const { resetForm, showForm } = this.props;
        resetForm();
        showForm();
    }

    render() {
        const { isFetchingElements, elements, sortColumn, sortType, totalPages, selectedPage, elementsPerPage, user,
            isSavingForm, formValues, formErrors, isFetchingElement, isDeleting, hideDelete,
            formErrorMessages, processedElements, data, show, showList, saveElement,
            resetForm, filterFields, setCost, costs } = this.props;

        let permission = findPermission(user['permissions'], 'Change Order');

        let addButton = null;
        let list = null;
        if (show['list']) {
            if (permission['Create'] || permission['Enter Costs']) {
                addButton = <div className="upload-button" onClick={this.handleNew.bind(this)}><i className="fas fa-plus" /> Add</div>;
            }

            list = (
                <Table
                    elements={elements}
                    isFetchingElements={isFetchingElements}
                    totalPages={totalPages}
                    selectedPage={selectedPage}
                    elementsPerPage={elementsPerPage}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    user={user}
                    processedElements={processedElements}
                    filterFields={filterFields}
                    handleSort={this.handleSort.bind(this)}
                    handleChangeAttribute={this.handleChangeAttribute.bind(this)}
                    handleInlineChange={this.handleInlineChange.bind(this)}
                    handleShowDelete={this.handleShowDelete.bind(this)}
                    handleShowForm={this.handleShowForm.bind(this)}
                    handleDayChange={this.handleDayChange.bind(this)}
                    handleChangeFilter={this.handleChangeFilter.bind(this)}
                />
            );
        }

        let form = null;
        if (show['form']) {
            form = (
                <Form
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    isSavingForm={isSavingForm}
                    isFetchingElement={isFetchingElement}
                    data={data}
                    permission={permission}
                    costs={costs}
                    handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                    handleUpdateDayValue={this.handleUpdateDayValue.bind(this)}
                    handleUpdateFormDateValue={this.handleUpdateFormDateValue.bind(this)}
                    handleNotificationClick={this.handleNotificationClick.bind(this)}
                    saveElement={saveElement.bind(this)}
                    showList={showList.bind(this)}
                    resetForm={resetForm.bind(this)}
                    setCost={setCost.bind(this)}
                />
            );
        }

        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid">
                    <Helmet>
                        <title>Change Order</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title="Change Order"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={user}
                            />
                            {addButton}
                            {list}
                            {form}
                            <Delete
                                isDeleting={isDeleting}
                                hideDelete={hideDelete.bind(this)}
                                handleDeleteElement={this.handleDeleteElement.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    formErrorMessages: selectors.getFormErrorsMessages,
    isSavingForm: selectors.getIsSavingForm,
    elements: selectors.getElements,
    processedElements: selectors.getProcessedElements,
    isFetchingElements: selectors.getIsFetchingElements,
    isDeleting: selectors.getIsDeleting,
    idForDelete: selectors.getIdForDelete,
    totalPages: selectors.getTotalPages,
    selectedPage: selectors.getSelectedPage,
    elementsPerPage: selectors.getElementsPerPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    activeElement: selectors.getActiveElement,
    filterFields: selectors.getFilterFields,
    data: selectors.getData,
    show: selectors.getShow,
    costs: selectors.getCosts,
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: () => dispatch(actions.fetchElements()),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setSort: (column, type) => dispatch(actions.setSort(column, type)),
        deleteElement: (elements, index) => dispatch(actions.deleteElement(elements, index)),
        showDelete: () => dispatch(dialog.actions.showDialog('delete')),
        hideDelete: () => dispatch(dialog.actions.hideDialog('delete')),
        setIdForDelete: (value) => dispatch(actions.setIdForDelete(value)),
        saveElement: () => dispatch(actions.saveElement()),
        setFormError: (name, value, message) => dispatch(actions.setFormError(name, value, message)),
        resetForm: () => dispatch(actions.resetForm()),
        changeAttribute: (value, elements, index, attribute) => dispatch(actions.changeAttribute(value, elements, index, attribute)),
        updateAttribute: (id, attribute, value, elements, index) => dispatch(actions.updateAttribute(id, attribute, value, elements, index)),
        setActiveElement: (elements, id) => dispatch(actions.setActiveElement(elements, id)),
        resetState: () => dispatch(actions.resetState()),
        changeFilter: (name, value) => dispatch(actions.changeFilter(name, value)),
        processElements: () => dispatch(actions.processElements()),
        fetchData: () => dispatch(actions.fetchData()),
        showList: () => dispatch(actions.showList()),
        showForm: () => dispatch(actions.showForm()),
        addNotification: (element) => dispatch(actions.addNotification(element)),
        removeNotification: (element) => dispatch(actions.removeNotification(element)),
        setCost: (name, type, value) => dispatch(actions.setCost(name, type, value))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
