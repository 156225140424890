import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import { Quote, Form, Delete, QuoteInformation } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Helmet } from 'react-helmet';
import cloneDeep from 'lodash/cloneDeep';
import { showNotification } from './../../utils';
import Layout from './../Layout';
import './styles.scss';

class Container extends Component {
    static propTypes = {
        isFetchingElements: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        formValues: PropTypes.object,
        user: PropTypes.object,
        elements: PropTypes.array,
        setSort: PropTypes.func,
        updateFormValue: PropTypes.func,
        fetchElements: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        save: PropTypes.func,
        addElement: PropTypes.func,
        showForm: PropTypes.func,
        hideForm: PropTypes.func,
        resetForm: PropTypes.func,
        resetEstimateForm: PropTypes.func,
        showDelete: PropTypes.func,
        hideDelete: PropTypes.func,
        setIdForDelete: PropTypes.func,
        deleteElement: PropTypes.func,
        idForDelete: PropTypes.number,
        formErrors: PropTypes.object,
        isFetchingElement: PropTypes.bool,
        isDeleting: PropTypes.bool,
        setFormError: PropTypes.func,
        showInfoNotification: PropTypes.func,
        formErrorMessages: PropTypes.object,
        quote: PropTypes.object,
        estimate: PropTypes.func,
        isLoadingEstimate: PropTypes.bool,
        isSavingEstimate: PropTypes.bool,
        estimateResults: PropTypes.array,
        estimates: PropTypes.array,
        saveEstimate: PropTypes.func,
        deleteEstimate: PropTypes.func,
        clearResults: PropTypes.func,
        customerId: PropTypes.number,
        isFetchingQuote: PropTypes.bool,
        isFetchingInformation: PropTypes.bool,
        quoteId: PropTypes.number,
        isSavingOrder: PropTypes.bool,
        convertToQuote: PropTypes.func,
        setCustomerId: PropTypes.func,
        setItemQuantity: PropTypes.func,
        fetchInformation: PropTypes.func,
        editingCustomer: PropTypes.bool,
        setEditingCustomer: PropTypes.bool,
        activeProduct: PropTypes.object,
        setAccessories: PropTypes.func,
        estimateAccessories: PropTypes.array,
        information: PropTypes.object,
        options: PropTypes.object,
        intersectPaperCategories: PropTypes.func,
        intersectPresses: PropTypes.func,
        resetState: PropTypes.func,
        fetching: PropTypes.object,
        setLoading: PropTypes.func,
        intersectPaperWeights: PropTypes.func,
        intersectPapers: PropTypes.func,
        resetOptions: PropTypes.func,
        updateState: PropTypes.func,
        fetchEstimate: PropTypes.func
    };

    componentWillMount() {
        this.props.resetState();
        this.props.setProductWindowId(this.props.match.params.productWindowId);
        this.props.fetchProductWindow(this.props.match.params.productWindowId);

        if(this.props.match.params.customerId) {
            this.props.fetchInformation(this.props.match.params.customerId);
            this.props.setProductCustomerId(this.props.match.params.customerId);
        } else {
            this.props.fetchInformation(null);
        }
    }
    
    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleUpdateFormValue(type, required, event) {
        const { setFormError, clearResults, intersectPaperCategories, intersectPaperWeights,
            intersectPapers, formValues, updateState } = this.props;

        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        let values = {};
        values[event.target.name] = value;
        let newValues = cloneDeep(formValues);
        newValues[event.target.name] = value;

        let error = false;
        let errorMessage = '';
        if (required && value === '') {
            error = true;
            errorMessage = 'This field is required';
        } else {
            if (value !== '' && type === 'number' && value.toString().match(/^[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            } else if (value !== '' && type === 'decimal' && value.toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            }
        }

        let fetching = {};
        let options = {};
        if (event.target.name === 'estimate_paper_category_id') {
            intersectPaperWeights(newValues);
            fetching.paperWeights = true;
            options.paperWeights = [];
            options.papers = [{ label: 'Select option', value: '' }];
            values.estimate_paper_weight = '';
            values.estimate_paper_id = '';
        } else if (event.target.name === 'estimate_paper_weight') {
            intersectPapers(newValues);
            fetching.papers = true;
            options.papers = [];
            values.estimate_paper_id = '';
        } else if (event.target.name === 'estimate_cover_press_id') {
            intersectPaperCategories(newValues, true);
            fetching.coverPaperCategories = true;
            options.coverPaperCategories = [];
            options.coverPaperWeights = [{ label: 'Select option', value: '' }];
            options.coverPapers = [{ label: 'Select option', value: '' }];
            values.estimate_cover_paper_category_id = '';
            values.estimate_cover_paper_weight = '';
            values.estimate_cover_paper_id = '';
            values.estimate_cover_running_style = '';
        } else if (event.target.name === 'estimate_cover_paper_category_id') {
            intersectPaperWeights(newValues, true);
            fetching.coverPaperWeights = true;
            options.coverPaperWeights = [];
            options.coverPapers = [{ label: 'Select option', value: '' }];
            values.estimate_cover_paper_weight = '';
            values.estimate_cover_paper_id = '';
        } else if (event.target.name === 'estimate_cover_paper_weight') {
            intersectPapers(newValues, true);
            fetching.coverPapers = true;
            options.coverPapers = [];
            values.estimate_cover_paper_id = '';
        } else if (event.target.name === 'estimate_type') {
            clearResults(value);
            intersectPaperCategories(newValues);
            fetching.paperCategories = true;
            options.paperCategories = [];
            options.paperWeights = [{ label: 'Select option', value: '' }];
            options.papers = [{ label: 'Select option', value: '' }];
            values.estimate_paper_category_id = '';
            values.estimate_paper_weight = '';
            values.estimate_paper_id = '';
            values.estimate_front_coating = '';
            values.estimate_back_coating = '';
            values.estimate_interior_coating = '';

            if ([2, 3, 6, 7].includes(formValues['estimate_multi_page_type'])) {
                intersectPaperCategories(newValues, true);
                fetching.coverPaperCategories = true;
                options.coverPaperCategories = [];
                options.coverPaperWeights = [{ label: 'Select option', value: '' }];
                options.coverPapers = [{ label: 'Select option', value: '' }];
                values.estimate_cover_paper_category_id = '';
                values.estimate_cover_paper_weight = '';
                values.estimate_cover_paper_id = '';
            }
        } else if (event.target.name === 'estimate_press_id') {
            intersectPaperCategories(newValues);
            fetching.paperCategories = true;
            options.paperCategories = [];
            options.paperWeights = [{ label: 'Select option', value: '' }];
            options.papers = [{ label: 'Select option', value: '' }];
            values.estimate_paper_category_id = '';
            values.estimate_paper_weight = '';
            values.estimate_paper_id = '';
            values.estimate_running_style = '';
        } else if (event.target.name === 'estimate_second_press_id') {
            intersectPaperCategories(newValues);
            fetching.paperCategories = true;
            options.paperCategories = [];
            options.paperWeights = [{ label: 'Select option', value: '' }];
            options.papers = [{ label: 'Select option', value: '' }];
            values.estimate_paper_category_id = '';
            values.estimate_paper_weight = '';
            values.estimate_paper_id = '';
            values.estimate_running_style = '';
        } else if (event.target.name === 'estimate_digital_machine_id') {
            intersectPaperCategories(newValues);
            fetching.paperCategories = true;
            options.paperCategories = [];
            options.paperWeights = [{ label: 'Select option', value: '' }];
            options.papers = [{ label: 'Select option', value: '' }];
            values.estimate_paper_category_id = '';
            values.estimate_paper_weight = '';
            values.estimate_paper_id = '';
        } else if (event.target.name === 'estimate_multi_page_type') {
            clearResults(value);
        }

        let newState = { formValues: values };

        let keys = Object.keys(fetching);
        if (keys.length > 0) {
            newState.fetching = fetching;
        }

        keys = Object.keys(options);
        if (keys.length > 0) {
            newState.options = options;
        }

        updateState(newState);
        setFormError(event.target.name, error, errorMessage);
    }

    handleSort(column) {
        const { setSort, sortColumn, sortType } = this.props;
        if (sortColumn === column) {
            setSort(column, sortType === 'asc' ? 'desc' : 'asc');
        } else {
            setSort(column, 'asc');
        }
    }

    handleShowForm(id) {
        const { showForm, resetEstimateForm, intersectPresses, intersectPaperCategories,
            formValues, updateState, fetchEstimate, intersectPaperWeights, intersectPapers } = this.props;

        let fetching = {};
        let options = {};
        let values = {};
        if (id === null) {
            resetEstimateForm(id);
            intersectPresses();
            intersectPaperCategories(formValues);
            intersectPaperWeights(formValues);
            intersectPapers(formValues);
            fetching.paperCategories = true;
            fetching.paperWeights = true;
            fetching.papers = true;
            options.paperCategories = [];
            options.paperWeights = [{ label: 'Select option', value: '' }];
            options.papers = [{ label: 'Select option', value: '' }];

            if ([2, 3, 6, 7].includes(parseInt(formValues['estimate_multi_page_type']))) {
                intersectPaperCategories(formValues, true);
                intersectPaperWeights(formValues, true);
                intersectPapers(formValues, true);
                fetching.coverPaperCategories = true;
                fetching.coverPaperWeights = true;
                fetching.coverPapers = true;
                options.coverPaperCategories = [];
                options.coverPaperWeights = [{ label: 'Select option', value: '' }];
                options.coverPapers = [{ label: 'Select option', value: '' }];
            }

            if (parseInt(formValues['estimate_type']) === 1 || parseInt(formValues['estimate_type']) === 2) {
                values.estimate_quantity_1 = 500;
                values.estimate_quantity_2 = 1000;
                values.estimate_quantity_3 = 2000;
            } else if (parseInt(formValues['estimate_type']) === 3) {
                values.estimate_quantity_1 = 250;
                values.estimate_quantity_2 = 500;
                values.estimate_quantity_3 = 1000;
            } else if (parseInt(formValues['estimate_type']) === 4) {
                values.estimate_quantity_1 = 1;
                values.estimate_quantity_2 = 2;
                values.estimate_quantity_3 = 5;
            }
        } else {
            fetchEstimate(id);
        }

        let newState = { formValues: values };

        let keys = Object.keys(fetching);
        if (keys.length > 0) {
            newState.fetching = fetching;
        }

        keys = Object.keys(options);
        if (keys.length > 0) {
            newState.options = options;
        }

        updateState(newState);
        showForm();
    }

    handleUpdateDayValue(name, day) {
        const { updateFormValue, setFormError } = this.props;
        updateFormValue(name, day);
        setFormError(name, day === '');
    }

    handleShowDelete(id, event) {
        const { showDelete, setIdForDelete } = this.props;
        event.preventDefault();
        showDelete();
        setIdForDelete(id);
    }

    handleDeleteElement() {
        const { deleteEstimate, idForDelete, information } = this.props;
        deleteEstimate(idForDelete, information['estimates']);
    }

    handleEstimate() {
        const { estimate, formValues } = this.props;
        estimate(formValues);
    }

    handleSaveEstimate() {
        const { saveEstimate, formValues, estimateResults, information, quoteId } = this.props;
        saveEstimate(formValues, estimateResults, information['estimates'], quoteId);
    }

    handleSetItemQuantity(index, quantity) {
        const { setItemQuantity, information } = this.props;
        setItemQuantity(information['estimates'], index, quantity);
    }

    handleSetCustomerId(event) {
        const { setCustomerId, setEditingCustomer } = this.props;
        setCustomerId(event.target.value);
        setEditingCustomer(0);
    }

    handleEditingCustomer(editing) {
        const { setEditingCustomer } = this.props;
        setEditingCustomer(editing);
    }

    handleAccessoryClick(id, event) {
        const { setAccessories, estimateAccessories } = this.props;
        setAccessories(estimateAccessories, id, event.target.checked);
    }

    handleShowQuoteInformation() {
        const { showQuoteInformation, information } = this.props;

        if (information['estimates'].length === 0) {
            showNotification('No Items', 'There are no items to convert into an order', 'info');
            return;
        }

        for (let i = 0; i < information['estimates'].length; i++) {
            if (!information['estimates'][i]['selected_quantity']) {
                showNotification('Select Quantities', 'Select all of the item quantities', 'info');
                return;
            }
        }

        showQuoteInformation();
    }

    productsDashboardClickHandler() {
        this.props.history.push('/products-catalog');
    }

    render() {
        const { isFetchingElements, elements, sortColumn, sortType, totalPages, selectedPage, elementsPerPage, user,
            isSavingForm, formValues, formErrors, isFetchingElement, hideForm, isDeleting, hideDelete, formErrorMessages,
            quote, customerId, isLoadingEstimate, estimateResults, isSavingEstimate, isFetchingQuote, quoteId,
            isFetchingInformation, isSavingOrder, editingCustomer, activeProduct, estimateAccessories, information,
            options, fetching, hideQuoteInformation, isSending, convertToQuote, configuration, checkAccessory, productWindowName,
            customerName } = this.props;
        return (
            <Layout>
                <div id="quote-container" className="container-fluid">
                    <Helmet>
                        <title>Dashboard</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title={productWindowName}
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={user}
                            />
                            <Quote
                                elements={elements}
                                isFetchingElements={isFetchingElements}
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                                elementsPerPage={elementsPerPage}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                user={user}
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSavingForm={isSavingForm}
                                isFetchingElement={isFetchingElement}
                                information={information}
                                options={options}
                                quote={quote}
                                isFetchingQuote={isFetchingQuote}
                                isFetchingInformation={isFetchingInformation}
                                isSavingOrder={isSavingOrder}
                                customerId={customerId}
                                editingCustomer={editingCustomer}
                                quoteId={quoteId}
                                customerName={customerName}
                                handleShowQuoteInformation={this.handleShowQuoteInformation.bind(this)}
                                handleSetCustomerId={this.handleSetCustomerId.bind(this)}
                                handleSort={this.handleSort.bind(this)}
                                handleShowDelete={this.handleShowDelete.bind(this)}
                                handleShowForm={this.handleShowForm.bind(this)}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleSetItemQuantity={this.handleSetItemQuantity.bind(this)}
                                handleEditingCustomer={this.handleEditingCustomer.bind(this)}
                                productsDashboardClickHandler={this.productsDashboardClickHandler.bind(this)}
                            />
                            <Form
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSavingForm={isSavingForm}
                                isFetchingElement={isFetchingElement}
                                isLoadingEstimate={isLoadingEstimate}
                                estimateResults={estimateResults}
                                isSavingEstimate={isSavingEstimate}
                                activeProduct={activeProduct}
                                estimateAccessories={estimateAccessories}
                                information={information}
                                options={options}
                                fetching={fetching}
                                configuration={configuration}
                                checkAccessory={checkAccessory.bind(this)}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleUpdateDayValue={this.handleUpdateDayValue.bind(this)}
                                hideForm={hideForm.bind(this)}
                                handleEstimate={this.handleEstimate.bind(this)}
                                handleSaveEstimate={this.handleSaveEstimate.bind(this)}
                                handleAccessoryClick={this.handleAccessoryClick.bind(this)}
                            />
                            <Delete
                                isDeleting={isDeleting}
                                hideDelete={hideDelete.bind(this)}
                                handleDeleteElement={this.handleDeleteElement.bind(this)}
                            />
                            <QuoteInformation
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSending={isSending}
                                isSavingOrder={isSavingOrder}
                                hideQuoteInformation={hideQuoteInformation.bind(this)}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                convertToQuote={convertToQuote.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    formErrorMessages: selectors.getFormErrorsMessages,
    isSavingForm: selectors.getIsSavingForm,
    elements: selectors.getElements,
    isFetchingElements: selectors.getIsFetchingElements,
    isDeleting: selectors.getIsDeleting,
    idForDelete: selectors.getIdForDelete,
    totalPages: selectors.getTotalPages,
    selectedPage: selectors.getSelectedPage,
    elementsPerPage: selectors.getElementsPerPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    customerId: selectors.getCustomerId,
    quoteId: selectors.getQuoteId,
    quote: selectors.getQuote,
    isFetchingQuote: selectors.getIsFetchingQuote,
    isFetchingInformation: selectors.getIsFetchingInformation,
    isLoadingEstimate: selectors.getIsLoadingEstimate,
    estimateResults: selectors.getEstimateResults,
    isSavingEstimate: selectors.getIsSavingEstimate,
    isSavingOrder: selectors.getIsSavingOrder,
    editingCustomer: selectors.getEditingCustomer,
    activeProduct: selectors.getActiveProduct,
    estimateAccessories: selectors.getEstimateAccessories,
    information: selectors.getInformation,
    options: selectors.getOptions,
    fetching: selectors.getFetching,
    isSending: selectors.getIsSending,
    configuration: selectors.getConfiguration,
    productCustomerId: selectors.getProductCustomerId,
    productWindowName: selectors.getProductWindowName,
    customerName: selectors.getProductCustomerName,
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: () => dispatch(actions.fetchElements()),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setSort: (column, type) => dispatch(actions.setSort(column, type)),
        deleteElement: (id, elements) => dispatch(actions.deleteElement(id, elements)),
        deleteEstimate: (id, estimates) => dispatch(actions.deleteEstimate(id, estimates)),
        showDelete: () => dispatch(dialog.actions.showDialog('delete')),
        hideDelete: () => dispatch(dialog.actions.hideDialog('delete')),
        showForm: () => dispatch(dialog.actions.showDialog('form')),
        hideForm: () => dispatch(dialog.actions.hideDialog('form')),
        showQuoteInformation: () => dispatch(dialog.actions.showDialog('quote-information')),
        hideQuoteInformation: () => dispatch(dialog.actions.hideDialog('quote-information')),
        setIdForDelete: (value) => dispatch(actions.setIdForDelete(value)),
        setFormError: (name, value, message) => dispatch(actions.setFormError(name, value, message)),
        resetForm: () => dispatch(actions.resetForm()),
        resetEstimateForm: () => dispatch(actions.resetEstimateForm()),
        estimate: (values) => dispatch(actions.estimate(values)),
        saveEstimate: (values, estimateResults, estimates, quoteId) => dispatch(actions.saveEstimate(values, estimateResults, estimates, quoteId)),
        convertToQuote: () => dispatch(actions.convertToQuote()),
        setItemQuantity: (estimates, index, quantity) => dispatch(actions.setItemQuantity(estimates, index, quantity)),
        setCustomerId: (id) => dispatch(actions.setCustomerId(id)),
        setEditingCustomer: (editing) => dispatch(actions.setEditingCustomer(editing)),
        clearResults: (value) => dispatch(actions.clearResults(value)),
        setAccessories: (accessories, id, checked) => dispatch(actions.setAccessories(accessories, id, checked)),
        fetchInformation: (customerId) => dispatch(actions.fetchInformation(customerId)),
        intersectPresses: () => dispatch(actions.intersectPresses()),
        intersectPaperCategories: (formValues, isCover) => dispatch(actions.intersectPaperCategories(formValues, isCover)),
        intersectPaperWeights: (formValues, isCover) => dispatch(actions.intersectPaperWeights(formValues, isCover)),
        intersectPapers: (formValues, isCover) => dispatch(actions.intersectPapers(formValues, isCover)),
        resetState: () => dispatch(actions.resetState()),
        setLoading: (name, value) => dispatch(actions.setLoading(name, value)),
        resetOptions: (name) => dispatch(actions.resetOptions(name)),
        updateState: (values) => dispatch(actions.updateState(values)),
        fetchEstimate: (id) => dispatch(actions.fetchEstimate(id)),
        checkAccessory: (id) => dispatch(actions.checkAccessory(id)),
        setProductWindowId: (id) => dispatch(actions.setProductWindowId(id)),
        fetchProductWindow: (id) => dispatch(actions.fetchProductWindow(id)),
        setProductCustomerId: (id) => dispatch(actions.setProductCustomerId(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
