import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class EstimatePricing extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingEstimate: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        handleEstimate: PropTypes.func,
        isLoadingEstimate: PropTypes.bool,
        estimateResults: PropTypes.array,
        handleSaveEstimate: PropTypes.array,
        activeProduct: PropTypes.object,
        handleAccessoryClick: PropTypes.func,
        estimateAccessories: PropTypes.array,
        handleUpdateOutsideService: PropTypes.func,
        handleAddOutsideService: PropTypes.func,
        handleDeleteOutsideService: PropTypes.func,
        information: PropTypes.object,
        options: PropTypes.object,
        fetching: PropTypes.object,
        renderSheets: PropTypes.bool
    };

    renderSheets() {
        const { estimateResults, formValues } = this.props;

        let sheets = null;
        let time = null;
        if (estimateResults.length === 1 && estimateResults[0]['results'].length > 0) {
            let result = estimateResults[0]['results'][0];
            if (parseInt(formValues['estimate_type']) === 2) {
                let impositionsSheets = 0;
                for (let i = 0; i < result['impositions_information'].length; i++) {
                    impositionsSheets += result['impositions_information'][i]['paper_information']['total_pars'];
                }
                sheets = numeral(impositionsSheets).format('0,0');
                time = numeral(result['totals']['total_time']).format('0.[00]');
            } else {
                sheets = numeral(result['paper_information']['total_pars']).format('0,0');
                time = numeral(result['totals']['total_time']).format('0.[00]');
            }
        }

        return (
            <tr>
                <td></td>
                <td className="text-right" style={{ fontWeight: 500 }}>Press Sheets:</td>
                <td colSpan="2" className="text-right cost-td">{sheets} ({time} hrs)</td>
            </tr>
        );
    }

    loadPrice(name) {
        const { formValues } = this.props;

        let price = [];
        if (formValues[name] !== '' && formValues[name] != null) {
            price = parseFloat(formValues[name]);
        } else {
            price = 0;
        }

        return price;
    }

    renderBinding(estimateResults, result) {
        const { handleUpdateFormValue, formValues } = this.props;

        if (parseInt(formValues['estimate_type']) !== 2) {
            return null;
        }

        let bindingName = result['binding_information']['name'];
        let bindingCost = result['binding_information']['total_cost'];

        return (
            <tr key="binding">
                <td>Binding</td>
                <td>{bindingName}</td>
                <td className="text-right cost-td">{this.renderBindingTooltip(bindingCost, estimateResults[0])}</td>
                <td className="price-td">
                    $<input type="text" name="estimate_binding_price" value={formValues['estimate_binding_price']}
                        onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                </td>
            </tr>
        );
    }

    renderFolding(estimateResults, result) {
        const { handleUpdateFormValue, formValues } = this.props;

        if (parseInt(formValues['estimate_type']) === 4) {
            return null;
        }

        let foldingName = null;
        let foldingCost = 0;
        if (parseInt(formValues['estimate_type']) === 1 && result['folding_information']['id'] != null) {
            foldingName = <div>{result['folding_information']['name']}</div>;
            foldingCost = result['folding_information']['total_cost'];
        } else if (parseInt(formValues['estimate_type']) === 2) {
            foldingName = <div>{result['impositions_information'][0]['folding_information']['name']}</div>;

            for (let i = 0; i < result['impositions_information'].length; i++) {
                foldingCost += result['impositions_information'][i]['folding_information']['total_cost'];
            }

            if (this.hasCover()) {
                foldingCost += estimateResults[0]['cover_results'][0]['folding_information']['total_cost'];
            }
        } else if (parseInt(formValues['estimate_type']) === 3 && result['folding_information']['id'] != null) {
            foldingName = <div>{result['folding_information']['name']}</div>;
            foldingCost = result['folding_information']['total_cost'];
        }

        if (foldingName == null) {
            return null;
        }

        return (
            <tr key="folding">
                <td>Folding</td>
                <td>{foldingName}</td>
                <td className="text-right cost-td">{this.renderFoldingTooltip(foldingCost, estimateResults[0])}</td>
                <td className="price-td">
                    $<input type="text" name="estimate_folding_price" value={formValues['estimate_folding_price']}
                        onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                </td>
            </tr>
        );
    }

    renderPressTooltip(pressCost, estimateResult) {
        const { formValues } = this.props;

        let tooltipHtml = null;
        if (parseInt(formValues['estimate_type']) === 1) {
            let pressInformation = estimateResult['results'][0]['press_information'];
            let paperInformation = estimateResult['results'][0]['paper_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Name: {pressInformation['name']}</div>
                    <div>BHR: {numeral(pressInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(pressInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Ink Units: {pressInformation['ink_units']}</div>
                    <div>Max. Paper Size: {pressInformation['max_size_sheet']['width']}x{pressInformation['max_size_sheet']['length']}</div>
                    <div>Total Impressions: {numeral(paperInformation['total_impressions']).format('0,0')}</div>
                    <div>Speed: {numeral(pressInformation['speed']).format('0,0')} pss/hour</div>
                    <div>Total Forms: {numeral(paperInformation['total_forms']).format('0,0')}</div>
                    <div>Total Plates: {pressInformation['total_plates']}</div>
                    <div>Total Plates Cost: {numeral(pressInformation['total_plates_cost']).format('$0,0.00')}</div>
                    <div>Total Running Cost: {numeral(pressInformation['total_running_cost']).format('$0,0.00')}</div>
                    <div>Total Setup Cost: {numeral(pressInformation['total_setup_cost']).format('$0,0.00')}</div>
                    <div>Total Cost: {numeral(pressInformation['total_cost']).format('$0,0.00')}</div>
                    <div>Total Time: {numeral(pressInformation['total_time']).format('0.[00]')} hrs</div>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let coverInformation = null;
            let interior2Information = null;
            let pressInformation = null;
            let paperInformation = null;

            if (this.hasCover()) {
                pressInformation = estimateResult['cover_results'][0]['press_information'];
                paperInformation = estimateResult['cover_results'][0]['paper_information'];
                coverInformation = (
                    <div className="text-left tooltip-column">
                        <div><b>Cover</b></div>
                        <div>Name: {pressInformation['name']}</div>
                        <div>BHR: {numeral(pressInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(pressInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Ink Units: {pressInformation['ink_units']}</div>
                        <div>Max. Paper Size: {pressInformation['max_size_sheet']['width']}x{pressInformation['max_size_sheet']['length']}</div>
                        <div>Total Impressions: {numeral(paperInformation['total_impressions']).format('0,0')}</div>
                        <div>Speed: {numeral(pressInformation['speed']).format('0,0')} pss/hour</div>
                        <div>Total Forms: {numeral(paperInformation['total_forms']).format('0,0')}</div>
                        <div>Total Plates: {pressInformation['total_plates']}</div>
                        <div>Total Plates Cost: {numeral(pressInformation['total_plates_cost']).format('$0,0.00')}</div>
                        <div>Total Running Cost: {numeral(pressInformation['total_running_cost']).format('$0,0.00')}</div>
                        <div>Total Setup Cost: {numeral(pressInformation['total_setup_cost']).format('$0,0.00')}</div>
                        <div>Total Cost: {numeral(pressInformation['total_cost']).format('$0,0.00')}</div>
                        <div>Total Time: {numeral(pressInformation['total_time']).format('0.[00]')} hrs</div>
                    </div>
                );
            }

            pressInformation = estimateResult['results'][0]['impositions_information'][0]['press_information'];
            paperInformation = estimateResult['results'][0]['impositions_information'][0]['paper_information'];
            let interior1Information = (
                <div className="text-left tooltip-column">
                    <div><b>Interior 1</b></div>
                    <div>Name: {pressInformation['name']}</div>
                    <div>BHR: {numeral(pressInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(pressInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Ink Units: {pressInformation['ink_units']}</div>
                    <div>Max. Paper Size: {pressInformation['max_size_sheet']['width']}x{pressInformation['max_size_sheet']['length']}</div>
                    <div>Total Impressions: {numeral(paperInformation['total_impressions']).format('0,0')}</div>
                    <div>Speed: {numeral(pressInformation['speed']).format('0,0')} pss/hour</div>
                    <div>Total Forms: {numeral(paperInformation['total_forms']).format('0,0')}</div>
                    <div>Total Plates: {pressInformation['total_plates']}</div>
                    <div>Total Plates Cost: {numeral(pressInformation['total_plates_cost']).format('$0,0.00')}</div>
                    <div>Total Running Cost: {numeral(pressInformation['total_running_cost']).format('$0,0.00')}</div>
                    <div>Total Setup Cost: {numeral(pressInformation['total_setup_cost']).format('$0,0.00')}</div>
                    <div>Total Cost: {numeral(pressInformation['total_cost']).format('$0,0.00')}</div>
                    <div>Total Time: {numeral(pressInformation['total_time']).format('0.[00]')} hrs</div>
                </div>
            );

            if (estimateResult['results'][0]['impositions_information'].length === 2) {
                pressInformation = estimateResult['results'][0]['impositions_information'][1]['press_information'];
                paperInformation = estimateResult['results'][0]['impositions_information'][1]['paper_information'];
                interior2Information = (
                    <div className="text-left tooltip-column">
                        <div><b>Interior 2 (leftover)</b></div>
                        <div>Name: {pressInformation['name']}</div>
                        <div>BHR: {numeral(pressInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(pressInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Ink Units: {pressInformation['ink_units']}</div>
                        <div>Max. Paper Size: {pressInformation['max_size_sheet']['width']}x{pressInformation['max_size_sheet']['length']}</div>
                        <div>Total Impressions: {numeral(paperInformation['total_impressions']).format('0,0')}</div>
                        <div>Speed: {numeral(pressInformation['speed']).format('0,0')} pss/hour</div>
                        <div>Total Forms: {numeral(paperInformation['total_forms']).format('0,0')}</div>
                        <div>Total Plates: {pressInformation['total_plates']}</div>
                        <div>Total Plates Cost: {numeral(pressInformation['total_plates_cost']).format('$0,0.00')}</div>
                        <div>Total Running Cost: {numeral(pressInformation['total_running_cost']).format('$0,0.00')}</div>
                        <div>Total Setup Cost: {numeral(pressInformation['total_setup_cost']).format('$0,0.00')}</div>
                        <div>Total Cost: {numeral(pressInformation['total_cost']).format('$0,0.00')}</div>
                        <div>Total Time: {numeral(pressInformation['total_time']).format('0.[00]')} hrs</div>
                    </div>
                );
            }

            tooltipHtml = (
                <div className="text-left">
                    <table border="1">
                        <tr>
                            <td>{coverInformation}</td>
                            <td>{interior1Information}</td>
                            <td>{interior2Information}</td>
                        </tr>
                    </table>
                </div>
            );
        }

        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(pressCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderDigitalMachineTooltip(digitalMachineCost, estimateResult) {
        const digitalInformation = estimateResult['results'][0]['digital_machine_information'];
        const tooltipHtml = (
            <div className="text-left">
                <div>Name: {digitalInformation['name']}</div>
                <div>BHR: {numeral(digitalInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(digitalInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Cost/Click: {numeral(digitalInformation['cost_per_click']).format('$0,0.00')}</div>
                <div>Max. Paper Size: {digitalInformation['max_size_sheet']['width']}x{digitalInformation['max_size_sheet']['length']}</div>
                <div>Total Clicks: {numeral(digitalInformation['impressions']).format('0,0')}</div>
                <div>Discount: {numeral(digitalInformation['discount']).format('0.[00]%')}</div>
                <div>Speed: {numeral(digitalInformation['speed']).format('0,0')} sheets/hour</div>
                <div>Total Cost: {numeral(digitalInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(digitalInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(digitalMachineCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderPaperTooltip(paperCost, estimateResult) {
        const { formValues } = this.props;

        let tooltipHtml = null;
        if (parseInt(formValues['estimate_type']) === 1) {
            let paperInformation = estimateResult['results'][0]['paper_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Name: {paperInformation['basis_weight']}# {paperInformation['name']} {paperInformation['pars']['width']}x{paperInformation['pars']['length']}</div>
                    <div>Price/CWT: {numeral(paperInformation['price_cwt']).format('$0,0.00')}</div>
                    <div>Price/M-Sheets: {numeral(paperInformation['price_m_sheets']).format('$0,0.00')}</div>
                    <div>Number-up: {paperInformation['pss']['up']}</div>
                    <div>Press Sheets (pss): {numeral(paperInformation['pss']['number_pss']).format('0,0')}</div>
                    <div>Press Make Ready (pss): {numeral(paperInformation['press_make_ready']).format('0,0')}</div>
                    <div>Waste (pss): {numeral(paperInformation['pss']['paper_waste']).format('0,0')}</div>
                    <div>Bindery Make Ready (pss): {numeral(paperInformation['pss']['bindery_make_ready']).format('0,0')}</div>
                    <div>Total Pss: {numeral(paperInformation['pss']['total_pss']).format('0,0')}</div>
                    <div>Number-Out: {paperInformation['pss']['out']}</div>
                    <div>Total Pars: {numeral(paperInformation['total_pars']).format('0,0')}</div>
                    <div>Total Poundage: {numeral(paperInformation['total_poundage']).format('0,0.[00]')} lbs</div>
                    <div>Total Cost: {numeral(paperInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let coverInformation = null;
            let interior2Information = null;
            let paperInformation = null;

            if (this.hasCover()) {
                paperInformation = estimateResult['cover_results'][0]['paper_information'];
                coverInformation = (
                    <div className="text-left tooltip-column">
                        <div><b>Cover</b></div>
                        <div>Name: {paperInformation['basis_weight']}# {paperInformation['name']} {paperInformation['pars']['width']}x{paperInformation['pars']['length']}</div>
                        <div>Price/CWT: {numeral(paperInformation['price_cwt']).format('$0,0.00')}</div>
                        <div>Price/M-Sheets: {numeral(paperInformation['price_m_sheets']).format('$0,0.00')}</div>
                        <div>Number-up: {paperInformation['pss']['up']}</div>
                        <div>Press Sheets (pss): {numeral(paperInformation['pss']['number_pss']).format('0,0')}</div>
                        <div>Press Make Ready (pss): {numeral(paperInformation['press_make_ready']).format('0,0')}</div>
                        <div>Waste (pss): {numeral(paperInformation['pss']['paper_waste']).format('0,0')}</div>
                        <div>Bindery Make Ready (pss): {numeral(paperInformation['pss']['bindery_make_ready']).format('0,0')}</div>
                        <div>Total Pss: {numeral(paperInformation['pss']['total_pss']).format('0,0')}</div>
                        <div>Number-Out: {paperInformation['pss']['out']}</div>
                        <div>Total Pars: {numeral(paperInformation['total_pars']).format('0,0')}</div>
                        <div>Total Poundage: {numeral(paperInformation['total_poundage']).format('0,0.[00]')} lbs</div>
                        <div>Total Cost: {numeral(paperInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            paperInformation = estimateResult['results'][0]['impositions_information'][0]['paper_information'];
            let interior1Information = (
                <div className="text-left tooltip-column">
                    <div><b>Interior 1</b></div>
                    <div>Name: {paperInformation['basis_weight']}# {paperInformation['name']} {paperInformation['pars']['width']}x{paperInformation['pars']['length']}</div>
                    <div>Price/CWT: {numeral(paperInformation['price_cwt']).format('$0,0.00')}</div>
                    <div>Price/M-Sheets: {numeral(paperInformation['price_m_sheets']).format('$0,0.00')}</div>
                    <div>Number-up: {paperInformation['pss']['up']}</div>
                    <div>Press Sheets (pss): {numeral(paperInformation['pss']['number_pss']).format('0,0')}</div>
                    <div>Press Make Ready (pss): {numeral(paperInformation['press_make_ready']).format('0,0')}</div>
                    <div>Waste (pss): {numeral(paperInformation['pss']['paper_waste']).format('0,0')}</div>
                    <div>Bindery Make Ready (pss): {numeral(paperInformation['pss']['bindery_make_ready']).format('0,0')}</div>
                    <div>Total Pss: {numeral(paperInformation['pss']['total_pss']).format('0,0')}</div>
                    <div>Number-Out: {paperInformation['pss']['out']}</div>
                    <div>Total Pars: {numeral(paperInformation['total_pars']).format('0,0')}</div>
                    <div>Total Poundage: {numeral(paperInformation['total_poundage']).format('0,0.[00]')} lbs</div>
                    <div>Total Cost: {numeral(paperInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );

            if (estimateResult['results'][0]['impositions_information'].length === 2) {
                paperInformation = estimateResult['results'][0]['impositions_information'][1]['paper_information'];
                interior2Information = (
                    <div className="text-left tooltip-column">
                        <div><b>Interior 2 (leftover)</b></div>
                        <div>Name: {paperInformation['basis_weight']}# {paperInformation['name']} {paperInformation['pars']['width']}x{paperInformation['pars']['length']}</div>
                        <div>Price/CWT: {numeral(paperInformation['price_cwt']).format('$0,0.00')}</div>
                        <div>Price/M-Sheets: {numeral(paperInformation['price_m_sheets']).format('$0,0.00')}</div>
                        <div>Number-up: {paperInformation['pss']['up']}</div>
                        <div>Press Sheets (pss): {numeral(paperInformation['pss']['number_pss']).format('0,0')}</div>
                        <div>Press Make Ready (pss): {numeral(paperInformation['press_make_ready']).format('0,0')}</div>
                        <div>Waste (pss): {numeral(paperInformation['pss']['paper_waste']).format('0,0')}</div>
                        <div>Bindery Make Ready (pss): {numeral(paperInformation['pss']['bindery_make_ready']).format('0,0')}</div>
                        <div>Total Pss: {numeral(paperInformation['pss']['total_pss']).format('0,0')}</div>
                        <div>Number-Out: {paperInformation['pss']['out']}</div>
                        <div>Total Pars: {numeral(paperInformation['total_pars']).format('0,0')}</div>
                        <div>Total Poundage: {numeral(paperInformation['total_poundage']).format('0,0.[00]')} lbs</div>
                        <div>Total Cost: {numeral(paperInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            tooltipHtml = (
                <div className="text-left">
                    <table border="1">
                        <tr>
                            <td>{coverInformation}</td>
                            <td>{interior1Information}</td>
                            <td>{interior2Information}</td>
                        </tr>
                    </table>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 3) {
            let paperInformation = estimateResult['results'][0]['paper_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Name: {paperInformation['basis_weight']}# {paperInformation['name']} {paperInformation['pars']['width']}x{paperInformation['pars']['length']}</div>
                    <div>Price/CWT: {numeral(paperInformation['price_cwt']).format('$0,0.00')}</div>
                    <div>Price/M-Sheets: {numeral(paperInformation['price_m_sheets']).format('$0,0.00')}</div>
                    <div>Number-up: {paperInformation['pss']['up']}</div>
                    <div>Press Sheets (pss): {numeral(paperInformation['pss']['number_pss']).format('0,0')}</div>
                    <div>Waste (pss): {numeral(paperInformation['pss']['paper_waste']).format('0,0')}</div>
                    <div>Bindery Make Ready (pss): {numeral(paperInformation['pss']['bindery_make_ready']).format('0,0')}</div>
                    <div>Total Pss: {numeral(paperInformation['pss']['total_pss']).format('0,0')}</div>
                    <div>Number-Out: {paperInformation['pss']['out']}</div>
                    <div>Total Pars: {numeral(paperInformation['total_pars']).format('0,0')}</div>
                    <div>Total Poundage: {numeral(paperInformation['total_poundage']).format('0,0.[00]')} lbs</div>
                    <div>Total Cost: {numeral(paperInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        }

        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(paperCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderInkTooltip(inkCost, estimateResult) {
        const { formValues } = this.props;

        let tooltipHtml = null;
        if (parseInt(formValues['estimate_type']) === 1) {
            let inkInformation = estimateResult['results'][0]['ink_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Inks: {inkInformation['total_front_colors']}/{inkInformation['total_back_colors']}</div>
                    <div>Total Cost: {numeral(inkInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let coverInformation = null;
            let interior2Information = null;
            let inkInformation = null;

            if (this.hasCover()) {
                inkInformation = estimateResult['cover_results'][0]['ink_information'];
                coverInformation = (
                    <div className="text-left tooltip-column">
                        <div><b>Cover</b></div>
                        <div>Inks: {inkInformation['total_front_colors']}/{inkInformation['total_back_colors']}</div>
                        <div>Total Cost: {numeral(inkInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            inkInformation = estimateResult['results'][0]['impositions_information'][0]['ink_information'];
            let interior1Information = (
                <div className="text-left tooltip-column">
                    <div><b>Interior 1</b></div>
                    <div>Inks: {inkInformation['total_front_colors']}/{inkInformation['total_back_colors']}</div>
                    <div>Total Cost: {numeral(inkInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );

            if (estimateResult['results'][0]['impositions_information'].length === 2) {
                inkInformation = estimateResult['results'][0]['impositions_information'][1]['ink_information'];
                interior2Information = (
                    <div className="text-left tooltip-column">
                        <div><b>Interior 2 (leftover)</b></div>
                        <div>Inks: {inkInformation['total_front_colors']}/{inkInformation['total_back_colors']}</div>
                        <div>Total Cost: {numeral(inkInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            tooltipHtml = (
                <div className="text-left">
                    <table border="1">
                        <tr>
                            <td>{coverInformation}</td>
                            <td>{interior1Information}</td>
                            <td>{interior2Information}</td>
                        </tr>
                    </table>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 3) {
            let inkInformation = estimateResult['results'][0]['ink_information'];

            let inkName = null;
            if (estimateResult['results'][0]['ink_information']['front_colors'].length > 0 && estimateResult['results'][0]['ink_information']['back_colors'].length > 0) {
                inkName = 'Coating both sides';
            } else if (estimateResult['results'][0]['ink_information']['front_colors'].length > 0 || estimateResult['results'][0]['ink_information']['back_colors'].length > 0) {
                inkName = 'Coating one side';
            } else {
                inkName = null;
            }

            tooltipHtml = (
                <div className="text-left">
                    <div>Inks: {inkName}</div>
                    <div>Total Cost: {numeral(inkInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        }

        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(inkCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderCuttingTooltip(cuttingCost, estimateResult) {
        const { formValues } = this.props;

        let tooltipHtml = null;
        if (parseInt(formValues['estimate_type']) === 1 || parseInt(formValues['estimate_type']) === 3) {
            let cuttingInformation = estimateResult['results'][0]['cutting_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Name: {cuttingInformation['name']}</div>
                    <div>BHR: {numeral(cuttingInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(cuttingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Cutting Lift: {cuttingInformation['lift']}</div>
                    <div>Time/Cut: {cuttingInformation['time_per_cut']} hrs</div>
                    <div>Make Ready Sheets: {numeral(cuttingInformation['make_ready']).format('0,0')}</div>
                    <div>Sheets/Load: {numeral(cuttingInformation['sheets_per_load']).format('0,0')}</div>
                    <div>Pre-Press Loads: {numeral(cuttingInformation['pre_press_loads']).format('0,0')}</div>
                    <div>Pre-Press Cuts: {numeral(cuttingInformation['pre_press_cuts']).format('0,0')}</div>
                    <div>Pre-Press Time: {numeral(cuttingInformation['pre_press_time']).format('0,0.[00]')} hrs</div>
                    <div>Pre-Press Cost: {numeral(cuttingInformation['pre_press_cost']).format('$0,0.00')}</div>
                    <div>Post-Press Loads: {numeral(cuttingInformation['post_press_loads']).format('0,0')}</div>
                    <div>Post-Press Cuts: {numeral(cuttingInformation['post_press_cuts']).format('0,0')}</div>
                    <div>Post-Press Time: {numeral(cuttingInformation['post_press_time']).format('0,0.[00]')} hrs</div>
                    <div>Post-Press Cost: {numeral(cuttingInformation['post_press_cost']).format('$0,0.00')}</div>
                    <div>Total Cost: {numeral(cuttingInformation['total_time']).format('0,0.[00]')} hrs</div>
                    <div>Total Cost: {numeral(cuttingInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let coverInformation = null;
            let interior2Information = null;
            let cuttingInformation = null;

            if (this.hasCover()) {
                cuttingInformation = estimateResult['cover_results'][0]['cutting_information'];
                coverInformation = (
                    <div className="text-left tooltip-column">
                        <div><b>Cover</b></div>
                        <div>Name: {cuttingInformation['name']}</div>
                        <div>BHR: {numeral(cuttingInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(cuttingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Cutting Lift: {cuttingInformation['lift']}</div>
                        <div>Time/Cut: {cuttingInformation['time_per_cut']} hrs</div>
                        <div>Make Ready Sheets: {numeral(cuttingInformation['make_ready']).format('0,0')}</div>
                        <div>Sheets/Load: {numeral(cuttingInformation['sheets_per_load']).format('0,0')}</div>
                        <div>Pre-Press Loads: {numeral(cuttingInformation['pre_press_loads']).format('0,0')}</div>
                        <div>Pre-Press Cuts: {numeral(cuttingInformation['pre_press_cuts']).format('0,0')}</div>
                        <div>Pre-Press Time: {numeral(cuttingInformation['pre_press_time']).format('0,0.[00]')} hrs</div>
                        <div>Pre-Press Cost: {numeral(cuttingInformation['pre_press_cost']).format('$0,0.00')}</div>
                        <div>Post-Press Loads: {numeral(cuttingInformation['post_press_loads']).format('0,0')}</div>
                        <div>Post-Press Cuts: {numeral(cuttingInformation['post_press_cuts']).format('0,0')}</div>
                        <div>Post-Press Time: {numeral(cuttingInformation['post_press_time']).format('0,0.[00]')} hrs</div>
                        <div>Post-Press Cost: {numeral(cuttingInformation['post_press_cost']).format('$0,0.00')}</div>
                        <div>Total Cost: {numeral(cuttingInformation['total_time']).format('0,0.[00]')} hrs</div>
                        <div>Total Cost: {numeral(cuttingInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            cuttingInformation = estimateResult['results'][0]['impositions_information'][0]['cutting_information'];
            let interior1Information = (
                <div className="text-left tooltip-column">
                    <div><b>Interior 1</b></div>
                    <div>Name: {cuttingInformation['name']}</div>
                    <div>BHR: {numeral(cuttingInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(cuttingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Cutting Lift: {cuttingInformation['lift']}</div>
                    <div>Time/Cut: {cuttingInformation['time_per_cut']} hrs</div>
                    <div>Make Ready Sheets: {numeral(cuttingInformation['make_ready']).format('0,0')}</div>
                    <div>Sheets/Load: {numeral(cuttingInformation['sheets_per_load']).format('0,0')}</div>
                    <div>Pre-Press Loads: {numeral(cuttingInformation['pre_press_loads']).format('0,0')}</div>
                    <div>Pre-Press Cuts: {numeral(cuttingInformation['pre_press_cuts']).format('0,0')}</div>
                    <div>Pre-Press Time: {numeral(cuttingInformation['pre_press_time']).format('0,0.[00]')} hrs</div>
                    <div>Pre-Press Cost: {numeral(cuttingInformation['pre_press_cost']).format('$0,0.00')}</div>
                    <div>Post-Press Loads: {numeral(cuttingInformation['post_press_loads']).format('0,0')}</div>
                    <div>Post-Press Cuts: {numeral(cuttingInformation['post_press_cuts']).format('0,0')}</div>
                    <div>Post-Press Time: {numeral(cuttingInformation['post_press_time']).format('0,0.[00]')} hrs</div>
                    <div>Post-Press Cost: {numeral(cuttingInformation['post_press_cost']).format('$0,0.00')}</div>
                    <div>Total Cost: {numeral(cuttingInformation['total_time']).format('0,0.[00]')} hrs</div>
                    <div>Total Cost: {numeral(cuttingInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );

            if (estimateResult['results'][0]['impositions_information'].length === 2) {
                cuttingInformation = estimateResult['results'][0]['impositions_information'][1]['cutting_information'];
                interior2Information = (
                    <div className="text-left tooltip-column">
                        <div><b>Interior 2 (leftover)</b></div>
                        <div>Name: {cuttingInformation['name']}</div>
                        <div>BHR: {numeral(cuttingInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(cuttingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Cutting Lift: {cuttingInformation['lift']}</div>
                        <div>Time/Cut: {cuttingInformation['time_per_cut']} hrs</div>
                        <div>Make Ready Sheets: {numeral(cuttingInformation['make_ready']).format('0,0')}</div>
                        <div>Sheets/Load: {numeral(cuttingInformation['sheets_per_load']).format('0,0')}</div>
                        <div>Pre-Press Loads: {numeral(cuttingInformation['pre_press_loads']).format('0,0')}</div>
                        <div>Pre-Press Cuts: {numeral(cuttingInformation['pre_press_cuts']).format('0,0')}</div>
                        <div>Pre-Press Time: {numeral(cuttingInformation['pre_press_time']).format('0,0.[00]')} hrs</div>
                        <div>Pre-Press Cost: {numeral(cuttingInformation['pre_press_cost']).format('$0,0.00')}</div>
                        <div>Post-Press Loads: {numeral(cuttingInformation['post_press_loads']).format('0,0')}</div>
                        <div>Post-Press Cuts: {numeral(cuttingInformation['post_press_cuts']).format('0,0')}</div>
                        <div>Post-Press Time: {numeral(cuttingInformation['post_press_time']).format('0,0.[00]')} hrs</div>
                        <div>Post-Press Cost: {numeral(cuttingInformation['post_press_cost']).format('$0,0.00')}</div>
                        <div>Total Cost: {numeral(cuttingInformation['total_time']).format('0,0.[00]')} hrs</div>
                        <div>Total Cost: {numeral(cuttingInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            tooltipHtml = (
                <div className="text-left">
                    <table border="1">
                        <tr>
                            <td>{coverInformation}</td>
                            <td>{interior1Information}</td>
                            <td>{interior2Information}</td>
                        </tr>
                    </table>
                </div>
            );
        }

        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(cuttingCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderFoldingTooltip(foldingCost, estimateResult) {
        const { formValues } = this.props;

        let tooltipHtml = null;
        if (parseInt(formValues['estimate_type']) === 1 || parseInt(formValues['estimate_type']) === 3) {
            let foldingInformation = estimateResult['results'][0]['folding_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Name: {foldingInformation['name']}</div>
                    <div>BHR: {numeral(foldingInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(foldingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Make Ready Sheets: {numeral(foldingInformation['make_ready']).format('0,0')}</div>
                    <div>Total Sheets: {numeral(foldingInformation['quantity']).format('0,0')}</div>
                    <div>Setup Time: {numeral(foldingInformation['setup_time']).format('0,0.[00]')} hrs</div>
                    <div>Speed: {numeral(foldingInformation['speed']).format('0,0')} sheets/hour</div>
                    <div>Total Cost: {numeral(foldingInformation['total_time']).format('0,0.[00]')} hrs</div>
                    <div>Total Cost: {numeral(foldingInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let coverInformation = null;
            let interior2Information = null;
            let foldingInformation = null;

            if (this.hasCover()) {
                foldingInformation = estimateResult['cover_results'][0]['folding_information'];
                coverInformation = (
                    <div className="text-left tooltip-column">
                        <div><b>Cover</b></div>
                        <div>Name: {foldingInformation['name']}</div>
                        <div>BHR: {numeral(foldingInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(foldingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Make Ready Sheets: {numeral(foldingInformation['make_ready']).format('0,0')}</div>
                        <div>Total Sheets: {numeral(foldingInformation['quantity']).format('0,0')}</div>
                        <div>Setup Time: {numeral(foldingInformation['setup_time']).format('0,0.[00]')} hrs</div>
                        <div>Speed: {numeral(foldingInformation['speed']).format('0,0')} sheets/hour</div>
                        <div>Total Cost: {numeral(foldingInformation['total_time']).format('0,0.[00]')} hrs</div>
                        <div>Total Cost: {numeral(foldingInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            foldingInformation = estimateResult['results'][0]['impositions_information'][0]['folding_information'];
            let interior1Information = (
                <div className="text-left tooltip-column">
                    <div><b>Interior 1</b></div>
                    <div>Name: {foldingInformation['name']}</div>
                    <div>BHR: {numeral(foldingInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(foldingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Make Ready Sheets: {numeral(foldingInformation['make_ready']).format('0,0')}</div>
                    <div>Total Sheets: {numeral(foldingInformation['quantity']).format('0,0')}</div>
                    <div>Setup Time: {numeral(foldingInformation['setup_time']).format('0,0.[00]')} hrs</div>
                    <div>Speed: {numeral(foldingInformation['speed']).format('0,0')} sheets/hour</div>
                    <div>Total Cost: {numeral(foldingInformation['total_time']).format('0,0.[00]')} hrs</div>
                    <div>Total Cost: {numeral(foldingInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );

            if (estimateResult['results'][0]['impositions_information'].length === 2) {
                foldingInformation = estimateResult['results'][0]['impositions_information'][1]['folding_information'];
                interior2Information = (
                    <div className="text-left tooltip-column">
                        <div><b>Interior 2 (leftover)</b></div>
                        <div>Name: {foldingInformation['name']}</div>
                        <div>BHR: {numeral(foldingInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(foldingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Make Ready Sheets: {numeral(foldingInformation['make_ready']).format('0,0')}</div>
                        <div>Total Sheets: {numeral(foldingInformation['quantity']).format('0,0')}</div>
                        <div>Setup Time: {numeral(foldingInformation['setup_time']).format('0,0.[00]')} hrs</div>
                        <div>Speed: {numeral(foldingInformation['speed']).format('0,0')} sheets/hour</div>
                        <div>Total Cost: {numeral(foldingInformation['total_time']).format('0,0.[00]')} hrs</div>
                        <div>Total Cost: {numeral(foldingInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            tooltipHtml = (
                <div className="text-left">
                    <table border="1">
                        <tr>
                            <td>{coverInformation}</td>
                            <td>{interior1Information}</td>
                            <td>{interior2Information}</td>
                        </tr>
                    </table>
                </div>
            );
        }

        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(foldingCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderBindingTooltip(bindingCost, estimateResult) {
        let bindingInformation = estimateResult['results'][0]['binding_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {bindingInformation['name']}</div>
                <div>BHR: {numeral(bindingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(bindingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Make Ready: {numeral(bindingInformation['make_ready']).format('0,0')}</div>
                <div>Pieces/Hour: {numeral(bindingInformation['quantity_per_hour']).format('0,0')}</div>
                <div>Total Pieces: {numeral(bindingInformation['books']).format('0,0')}</div>
                <div>Setup Time: {numeral(bindingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(bindingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(bindingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(bindingCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderLargeFormatMachineTooltip(largeFormatMachineCost, estimateResult) {
        let largeFormatInformation = estimateResult['results'][0]['large_format_machine_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {largeFormatInformation['name']}</div>
                <div>BHR: {numeral(largeFormatInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(largeFormatInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Setup Cost: {numeral(largeFormatInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(largeFormatInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(largeFormatInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(largeFormatMachineCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderSubstrateTooltip(substrateCost, estimateResult) {
        let substrateInformation = estimateResult['results'][0]['substrate_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {substrateInformation['name']}</div>
                <div>Type: {parseInt(substrateInformation['type']) === 1 ? 'Roll' : 'Sheet'}</div>
                <div>Cost/{parseInt(substrateInformation['type']) === 1 ? 'Roll' : 'Sheet'}: {numeral(substrateInformation['cost_each']).format('$0,0.00')}</div>
                <div>Cost/{parseInt(substrateInformation['type']) === 1 ? 'Linear Foot' : 'Square Foot'}: {numeral(substrateInformation['cost']).format('$0,0.00')}</div>
                <div>Markup: {numeral(substrateInformation['markup']).format('0.[00]%')}</div>
                <div>Square Feet/Unit: {numeral(substrateInformation['area']).format('0,0.[00]')}</div>
                <div>Total Square Feet: {numeral(substrateInformation['total_area']).format('0,0.[00]')}</div>
                {substrateInformation['discount_percentage'] !== 0 && <div>Cost: {numeral(substrateInformation['cost']).format('$0,0.00')}</div>}
                {substrateInformation['discount_percentage'] !== 0 && <div>Discount Percentage: {numeral(substrateInformation['discount_percentage']).format('0.[00]%')}</div>}
                {substrateInformation['discount_percentage'] !== 0 && <div>Discounted Cost: {numeral(substrateInformation['discounted_cost']).format('$0,0.00')}</div>}
                <div>Total Cost: {numeral(substrateInformation['total_cost']).format('$0,0.00')}</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(substrateCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderLaminateTooltip(laminateCost, estimateResult) {
        let laminateInformation = estimateResult['results'][0]['laminate_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {laminateInformation['name']}</div>
                <div>Type: {parseInt(laminateInformation['type']) === 1 ? 'Roll' : 'Sheet'}</div>
                <div>Cost/Roll: {numeral(laminateInformation['cost_each']).format('$0,0.00')}</div>
                <div>Cost/Linear Foot: {numeral(laminateInformation['cost_linear_foot']).format('$0,0.00')}</div>
                <div>Markup: {numeral(laminateInformation['markup']).format('0.[00]%')}</div>
                <div>Square Feet/Unit: {numeral(laminateInformation['area']).format('0,0.[00]')}</div>
                <div>Total Square Feet: {numeral(laminateInformation['total_area']).format('0,0.[00]')}</div>
                {laminateInformation['discount_percentage'] !== 0 && <div>Cost: {numeral(laminateInformation['cost']).format('$0,0.00')}</div>}
                {laminateInformation['discount_percentage'] !== 0 && <div>Discount Percentage: {numeral(laminateInformation['discount_percentage']).format('0.[00]%')}</div>}
                {laminateInformation['discount_percentage'] !== 0 && <div>Discounted Cost: {numeral(laminateInformation['discounted_cost']).format('$0,0.00')}</div>}
                <div>Total Cost: {numeral(laminateInformation['total_cost']).format('$0,0.00')}</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(laminateCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderAccessoriesTooltip(accessoriesCost, estimateResult) {
        let accessoriesInformation = estimateResult['results'][0]['accessories_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Total Accessories: {accessoriesInformation['accessories'].length}</div>
                {accessoriesInformation['accessories'].map((accessory, index) => {
                    return [
                        <div key={'accessory-name-' + index}>{accessory['name']} cost: {numeral(accessory['total_cost']).format('$0,0.00')}</div>,
                        <div key={'accessory-markup-' + index}>{accessory['name']} markup: {numeral(accessory['markup']).format('0.[00]%')}</div>
                    ];
                })}
                <div>Total Cost: {numeral(accessoriesInformation['total_cost']).format('$0,0.00')}</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(accessoriesCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderScoringTooltip(scoringCost, estimateResult) {
        let scoringInformation = estimateResult['results'][0]['scoring_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {scoringInformation['name']}</div>
                <div>BHR: {numeral(scoringInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(scoringInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Make Ready: {numeral(scoringInformation['make_ready']).format('0,0')}</div>
                <div>Total Pieces: {numeral(scoringInformation['total_pieces']).format('0,0')}</div>
                <div>Speed: {numeral(scoringInformation['speed']).format('0,0')}</div>
                <div>Setup Time: {numeral(scoringInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(scoringInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(scoringInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(scoringCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderDieCuttingTooltip(dieCuttingCost, estimateResult) {
        let dieCuttingInformation = estimateResult['results'][0]['die_cutter_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {dieCuttingInformation['name']}</div>
                <div>BHR: {numeral(dieCuttingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(dieCuttingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Make Ready: {numeral(dieCuttingInformation['make_ready']).format('0,0')}</div>
                <div>Total Pieces: {numeral(dieCuttingInformation['total_pieces']).format('0,0')}</div>
                <div>Speed: {numeral(dieCuttingInformation['speed']).format('0,0')} Pieces/Hour</div>
                <div>Setup Time: {numeral(dieCuttingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(dieCuttingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(dieCuttingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(dieCuttingCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderDrillingTooltip(drillingCost, estimateResult) {
        let drillingInformation = estimateResult['results'][0]['drilling_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {drillingInformation['name']}</div>
                <div>BHR: {numeral(drillingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(drillingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Make Ready: {numeral(drillingInformation['make_ready']).format('0,0')}</div>
                <div>Total Pieces: {numeral(drillingInformation['total_pieces']).format('0,0')}</div>
                <div>Time Per Hole/M-Sheets: {numeral(drillingInformation['time_per_hole']).format('0.[00]')}</div>
                <div>Time Per Additional Hole/M-Sheets: {numeral(drillingInformation['time_per_additional_hole']).format('0.[00]')}</div>
                <div>Setup Time: {numeral(drillingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(drillingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(drillingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(drillingCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderPaddingTooltip(paddingCost, estimateResult) {
        let paddingInformation = estimateResult['results'][0]['padding_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {paddingInformation['name']}</div>
                <div>BHR: {numeral(paddingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(paddingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Make Ready: {numeral(paddingInformation['make_ready']).format('0,0')}</div>
                <div>Quantity: {numeral(paddingInformation['quantity']).format('0,0')}</div>
                <div>Time/M-Sheets: {numeral(paddingInformation['time_per_m_sheets']).format('0.[00]')}</div>
                <div>Cost per Chipboard: {numeral(paddingInformation['chipboard_cost']).format('$0,0.00')}</div>
                <div>Total Chipboard Cost: {numeral(paddingInformation['total_chipboard_cost']).format('$0,0.00')}</div>
                <div>Setup Time: {numeral(paddingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(paddingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(paddingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(paddingCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderBandingTooltip(bandingCost, estimateResult) {
        let bandingInformation = estimateResult['results'][0]['banding_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {bandingInformation['type_name']}</div>
                <div>BHR: {numeral(bandingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(bandingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Time/M-Packages: {numeral(bandingInformation['time_per_m_packages']).format('0.[00]')}</div>
                <div>Total Packages: {numeral(bandingInformation['total_packages']).format('0,0')}</div>
                <div>Setup Time: {numeral(bandingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(bandingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(bandingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(bandingCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderWrappingTooltip(wrappingCost, estimateResult) {
        let wrappingInformation = estimateResult['results'][0]['wrapping_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {wrappingInformation['type_name']}</div>
                <div>BHR: {numeral(wrappingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(wrappingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Time/Package: {numeral(wrappingInformation['time_per_package']).format('0.[00]')}</div>
                <div>Total Packages: {numeral(wrappingInformation['total_packages']).format('0,0')}</div>
                <div>Setup Time: {numeral(wrappingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(wrappingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(wrappingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(wrappingCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderShrinkWrappingTooltip(shrinkWrappingCost, estimateResult) {
        let shrinkWrappingInformation = estimateResult['results'][0]['shrink_wrapping_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {shrinkWrappingInformation['type_name']}</div>
                <div>BHR: {numeral(shrinkWrappingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(shrinkWrappingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Time/M-Packages: {numeral(shrinkWrappingInformation['time_per_m_packages']).format('0.[00]')}</div>
                <div>Total Packages: {numeral(shrinkWrappingInformation['total_packages']).format('0,0')}</div>
                <div>Setup Time: {numeral(shrinkWrappingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(shrinkWrappingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(shrinkWrappingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(shrinkWrappingCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderPackageTooltip(packageCost, estimateResult) {
        let packageInformation = estimateResult['results'][0]['package_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {packageInformation['width']}x{packageInformation['length']}x{packageInformation['height']}</div>
                <div>Cost: {numeral(packageInformation['cost']).format('$0,0.00')}</div>
                <div>TotalPieces: {numeral(packageInformation['total_pieces']).format('0,0')}</div>
                <div>Pieces/Package: {numeral(packageInformation['pieces_per_package']).format('0,0')}</div>
                <div>Total Packages: {numeral(packageInformation['total_packages']).format('0,0')}</div>
                <div>Total Time: {numeral(packageInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(packageCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderHandGatheringTooltip(handGatheringCost, estimateResult) {
        let handGatheringInformation = estimateResult['results'][0]['hand_gathering_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {handGatheringInformation['name']}</div>
                <div>BHR: {numeral(handGatheringInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(handGatheringInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Sheet Sets/Hour: {numeral(handGatheringInformation['sheets_sets_per_hour']).format('0.[00]')}</div>
                <div>Total Pieces: {numeral(handGatheringInformation['total_pieces']).format('0,0')}</div>
                <div>Setup Time: {numeral(handGatheringInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(handGatheringInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(handGatheringInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(handGatheringCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderCountingTooltip(countingCost, estimateResult) {
        let countingInformation = estimateResult['results'][0]['counting_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {countingInformation['name']}</div>
                <div>BHR: {numeral(countingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(countingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Time/M-Pieces: {numeral(countingInformation['time_per_m_pieces']).format('0.[00]')}</div>
                <div>Total Pieces: {numeral(countingInformation['total_pieces']).format('0,0')}</div>
                <div>Setup Time: {numeral(countingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(countingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(countingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(countingCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderOutsideServicesTooltip(outsideServicesCost, estimateResult) {
        let outsideServicesInformation = estimateResult['results'][0]['outside_services_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Total Services: {outsideServicesInformation['services'].length}</div>
                {outsideServicesInformation['services'].map((service, index) => {
                    return <div key={index}>{service['description']} cost: {numeral(service['total_cost']).format('$0,0.00')}</div>;
                })}
                <div>Total Cost: {numeral(outsideServicesInformation['total_cost']).format('$0,0.00')}</div>
            </div>
        );
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(outsideServicesCost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderResults() {
        const { estimateResults, formValues, handleUpdateFormValue } = this.props;

        if (estimateResults.length === 0) {
            return (
                <tr>
                    <td colSpan="8">No results</td>
                </tr>
            );
        }

        let result = estimateResults[0]['results'].length === 0 ? null : estimateResults[0]['results'][0];
        if (result == null) {
            return (
                <tr>
                    <td colSpan="8">No results</td>
                </tr>
            );
        }

        let weight = 0;
        if (parseInt(formValues['estimate_type']) === 1 || parseInt(formValues['estimate_type']) === 3) {
            weight = estimateResults[0]['results'][0]['paper_information']['total_poundage'];
        } else if (parseInt(formValues['estimate_type']) === 2) {
            for (let j = 0; j < estimateResults[0]['results'][0]['impositions_information'].length; j++) {
                weight += estimateResults[0]['results'][0]['impositions_information'][j]['paper_information']['total_poundage'];
            }
        }

        let pressPrice = this.loadPrice('estimate_press_price');
        let digitalMachinePrice = this.loadPrice('estimate_digital_machine_price');
        let paperPrice = this.loadPrice('estimate_paper_price');
        let inkPrice = this.loadPrice('estimate_ink_price');
        let cuttingPrice = this.loadPrice('estimate_cutting_price');
        let foldingPrice = this.loadPrice('estimate_folding_price');
        let bindingPrice = this.loadPrice('estimate_binding_price');
        let largeFormatMachinePrice = this.loadPrice('estimate_large_format_machine_price');
        let substratePrice = this.loadPrice('estimate_substrate_price');
        let laminatePrice = this.loadPrice('estimate_laminate_price');
        let accessoriesPrice = this.loadPrice('estimate_accessories_price');
        let outsideServicesPrice = this.loadPrice('estimate_outside_services_price');
        let scoringPrice = this.loadPrice('estimate_scoring_price');
        let dieCuttingPrice = this.loadPrice('estimate_die_cutting_price');
        let drillingPrice = this.loadPrice('estimate_drilling_price');
        let paddingPrice = this.loadPrice('estimate_padding_price');
        let bandingPrice = this.loadPrice('estimate_banding_price');
        let wrappingPrice = this.loadPrice('estimate_wrapping_price');
        let shrinkWrappingPrice = this.loadPrice('estimate_shrink_wrapping_price');
        let packagePrice = this.loadPrice('estimate_package_price');
        let handGatheringPrice = this.loadPrice('estimate_hand_gathering_price');
        let countingPrice = this.loadPrice('estimate_counting_price');

        let totalPrice = pressPrice + paperPrice + inkPrice + cuttingPrice + bindingPrice + foldingPrice
            + digitalMachinePrice + largeFormatMachinePrice + substratePrice + laminatePrice + accessoriesPrice
            + outsideServicesPrice + scoringPrice + dieCuttingPrice + drillingPrice + paddingPrice + bandingPrice
            + wrappingPrice + shrinkWrappingPrice + packagePrice + handGatheringPrice + countingPrice;

        let pressName = null;
        let digitalMachineName = null;
        let paperName = null;
        let inkName = null;
        let cuttingName = null;
        let largeFormatMachineName = null;
        let substrateName = null;
        let laminateName = null;
        let accessoriesNames = [];
        let outsideServicesNames = [];
        let scoringName = null;
        let dieCuttingName = null;
        let drillingName = null;
        let paddingName = null;
        let bandingName = null;
        let wrappingName = null;
        let shrinkWrappingName = null;
        let packageName = null;
        let handGatheringName = null;
        let countingName = null;
        let digitalMachineCost = 0;
        let largeFormatMachineCost = 0;
        let pressCost = 0;
        let paperCost = 0;
        let inkCost = 0;
        let cuttingCost = 0;
        let substrateCost = 0;
        let laminateCost = 0;
        let accessoriesCost = 0;
        let outsideServicesCost = 0;
        let scoringCost = 0;
        let dieCuttingCost = 0;
        let drillingCost = 0;
        let paddingCost = 0;
        let bandingCost = 0;
        let wrappingCost = 0;
        let shrinkWrappingCost = 0;
        let packageCost = 0;
        let handGatheringCost = 0;
        let countingCost = 0;
        let totalCost = 0;
        let discount = 0;

        if (parseInt(formValues['estimate_type']) === 1) {
            pressName = result['press_information']['name'];
            paperName = result['paper_information']['basis_weight'] + '# ' + result['paper_information']['name']
                + ' ' + result['paper_information']['pars']['width'] + 'x' + result['paper_information']['pars']['length'];
            inkName = result['ink_information']['front_colors'].length + '/' + result['ink_information']['back_colors'].length;
            cuttingName = result['cutting_information']['name'];

            pressCost = result['press_information']['total_cost'];
            paperCost = result['paper_information']['total_cost'];
            inkCost = result['ink_information']['total_cost'];
            cuttingCost = result['cutting_information']['total_cost'];
            discount = result['totals']['discount'];
            totalCost = result['totals']['total_cost'];
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let paperNameString = null;
            let inkNameString = null;
            pressName = [];
            paperName = [];
            inkName = [];

            if (this.hasCover()) {
                pressName.push(<div><b>Cover:</b> {estimateResults[0]['cover_results'][0]['press_information']['name']}</div>);

                paperNameString = estimateResults[0]['cover_results'][0]['paper_information']['basis_weight'] + '# '
                    + estimateResults[0]['cover_results'][0]['paper_information']['name'] + ' '
                    + estimateResults[0]['cover_results'][0]['paper_information']['pars']['width'] + 'x'
                    + estimateResults[0]['cover_results'][0]['paper_information']['pars']['length'];
                paperName.push(<div><b>Cover:</b> {paperNameString}</div>);

                inkNameString = estimateResults[0]['cover_results'][0]['ink_information']['front_colors'].length + '/'
                    + estimateResults[0]['cover_results'][0]['ink_information']['back_colors'].length;
                inkName.push(<div><b>Cover:</b> {inkNameString}</div>);
            }

            for (let j = 0; j < result['impositions_information'].length; j++) {
                pressName.push(<div><b>Interior {j + 1}:</b> {result['impositions_information'][j]['press_information']['name']}</div>);
            }

            paperNameString = result['impositions_information'][0]['paper_information']['basis_weight'] + '# '
                + result['impositions_information'][0]['paper_information']['name'] + ' '
                + result['impositions_information'][0]['paper_information']['pars']['width'] + 'x'
                + result['impositions_information'][0]['paper_information']['pars']['length'];
            paperName.push(<div><b>Interior:</b> {paperNameString}</div>);

            inkNameString = result['impositions_information'][0]['ink_information']['front_colors'].length + '/'
                + result['impositions_information'][0]['ink_information']['back_colors'].length;
            inkName.push(<div><b>Interior:</b> {inkNameString}</div>);

            cuttingName = result['impositions_information'][0]['cutting_information']['name'];

            for (let j = 0; j < result['impositions_information'].length; j++) {
                pressCost += result['impositions_information'][j]['press_information']['total_cost'];
                paperCost += result['impositions_information'][j]['paper_information']['total_cost'];
                inkCost += result['impositions_information'][j]['ink_information']['total_cost'];
                cuttingCost += result['impositions_information'][j]['cutting_information']['total_cost'];
            }

            if (this.hasCover()) {
                pressCost += estimateResults[0]['cover_results'][0]['press_information']['total_cost'];
                paperCost += estimateResults[0]['cover_results'][0]['paper_information']['total_cost'];
                inkCost += estimateResults[0]['cover_results'][0]['ink_information']['total_cost'];
                cuttingCost += estimateResults[0]['cover_results'][0]['cutting_information']['total_cost'];
            }

            discount = result['totals']['discount'];
            totalCost = result['totals']['total_cost'];
        } else if (parseInt(formValues['estimate_type']) === 3) {
            digitalMachineName = result['digital_machine_information']['name'];
            paperName = result['paper_information']['basis_weight'] + '# ' + result['paper_information']['name']
                + ' ' + result['paper_information']['pars']['width'] + 'x' + result['paper_information']['pars']['length'];

            if (result['ink_information']['front_colors'].length > 0 && result['ink_information']['back_colors'].length > 0) {
                inkName = 'Coating both sides';
            } else if (result['ink_information']['front_colors'].length > 0 || result['ink_information']['back_colors'].length > 0) {
                inkName = 'Coating one side';
            } else {
                inkName = null;
            }

            cuttingName = result['cutting_information']['name'];

            digitalMachineCost = result['digital_machine_information']['total_cost'];
            paperCost = result['paper_information']['total_cost'];
            inkCost = result['ink_information']['total_cost'];
            cuttingCost = result['cutting_information']['total_cost'];
            discount = result['totals']['discount'];
            totalCost = result['totals']['total_cost'];
        } else if (parseInt(formValues['estimate_type']) === 4) {
            largeFormatMachineName = result['large_format_machine_information']['name'];
            substrateName = result['substrate_information']['name'];

            if (result['laminate_information'] != null) {
                laminateName = result['laminate_information']['name'];
            }

            if (result['accessories_information']['accessories'].length > 0) {
                for (let i = 0; i < result['accessories_information']['accessories'].length; i++) {
                    accessoriesNames.push(result['accessories_information']['accessories'][i]['name']);
                }
            }

            largeFormatMachineCost = result['large_format_machine_information']['total_cost'];
            substrateCost = result['substrate_information']['total_cost'];

            if (result['laminate_information'] != null) {
                laminateCost = result['laminate_information']['total_cost'];
            }

            if (result['accessories_information']['accessories'].length > 0) {
                accessoriesCost = result['accessories_information']['total_cost'];
            }

            discount = result['totals']['discount'];
            totalCost = result['totals']['total_cost'];
        }

        if (parseInt(formValues['estimate_type']) === 1 || parseInt(formValues['estimate_type']) === 2 || parseInt(formValues['estimate_type']) === 3) {
            if (result['scoring_information'] && result['scoring_information']['id'] != null) {
                scoringName = result['scoring_information']['name'];
                scoringCost = result['scoring_information']['total_cost'];
            }

            if (result['die_cutter_information'] && result['die_cutter_information']['id'] != null) {
                dieCuttingName = result['die_cutter_information']['name'];
                dieCuttingCost = result['die_cutter_information']['total_cost'];
            }

            if (result['drilling_information'] && result['drilling_information']['id'] != null) {
                drillingName = result['drilling_information']['name'];
                drillingCost = result['drilling_information']['total_cost'];
            }

            if (result['padding_information'] && result['padding_information']['id'] != null) {
                paddingName = result['padding_information']['name'];
                paddingCost = result['padding_information']['total_cost'];
            }

            if (result['banding_information'] && result['banding_information']['id'] != null) {
                bandingName = result['banding_information']['type_name'];
                bandingCost = result['banding_information']['total_cost'];
            }

            if (result['wrapping_information'] && result['wrapping_information']['id'] != null) {
                wrappingName = result['wrapping_information']['type_name'];
                wrappingCost = result['wrapping_information']['total_cost'];
            }

            if (result['shrink_wrapping_information'] && result['shrink_wrapping_information']['id'] != null) {
                shrinkWrappingName = result['shrink_wrapping_information']['type_name'];
                shrinkWrappingCost = result['shrink_wrapping_information']['total_cost'];
            }

            if (result['package_information'] && result['package_information']['id'] != null) {
                packageName = result['package_information']['width'] + 'x'
                    + result['package_information']['length'] + 'x'
                    + result['package_information']['height'];
                packageCost = result['package_information']['total_cost'];
            }

            if (result['hand_gathering_information'] && result['hand_gathering_information']['id'] != null) {
                handGatheringName = result['hand_gathering_information']['name'];
                handGatheringCost = result['hand_gathering_information']['total_cost'];
            }

            if (result['counting_information'] && result['counting_information']['id'] != null) {
                countingName = result['counting_information']['name'];
                countingCost = result['counting_information']['total_cost'];
            }

            if (result['outside_services_information'] && result['outside_services_information']['services'].length > 0) {
                for (let i = 0; i < result['outside_services_information']['services'].length; i++) {
                    outsideServicesNames.push(result['outside_services_information']['services'][i]['description']);
                }

                outsideServicesCost = result['outside_services_information']['total_cost'];
            }
        }

        let pressRow = null;
        if (pressName != null) {
            pressRow = (
                <tr key="press">
                    <td>Press</td>
                    <td>{pressName}</td>
                    <td className="text-right cost-td">{this.renderPressTooltip(pressCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_press_price" value={formValues['estimate_press_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let digitalMachineRow = null;
        if (digitalMachineName != null) {
            digitalMachineRow = (
                <tr key="digital-machine">
                    <td>Digital Machine</td>
                    <td>{digitalMachineName}</td>
                    <td className="text-right cost-td">{this.renderDigitalMachineTooltip(digitalMachineCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_digital_machine_price" value={formValues['estimate_digital_machine_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let inkRow = null;
        if (inkName != null) {
            inkRow = (
                <tr key="ink">
                    <td>Ink</td>
                    <td>{inkName}</td>
                    <td className="text-right cost-td">{this.renderInkTooltip(inkCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_ink_price" value={formValues['estimate_ink_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let paperRow = null;
        if (paperName != null) {
            paperRow = (
                <tr key="paper">
                    <td>Paper</td>
                    <td>{paperName}</td>
                    <td className="text-right cost-td">{this.renderPaperTooltip(paperCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_paper_price" value={formValues['estimate_paper_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let cuttingRow = null;
        if (cuttingName != null) {
            cuttingRow = (
                <tr key="cutting">
                    <td>Cutting</td>
                    <td>{cuttingName}</td>
                    <td className="text-right cost-td">{this.renderCuttingTooltip(cuttingCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_cutting_price" value={formValues['estimate_cutting_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let largeFormatMachineRow = null;
        if (largeFormatMachineName != null) {
            largeFormatMachineRow = (
                <tr key="large-format-machine">
                    <td>Large Format Machine</td>
                    <td>{largeFormatMachineName}</td>
                    <td className="text-right cost-td">{this.renderLargeFormatMachineTooltip(largeFormatMachineCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_large_format_machine_price" value={formValues['estimate_large_format_machine_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let substrateRow = null;
        if (substrateName != null) {
            substrateRow = (
                <tr key="substrate">
                    <td>Substrate</td>
                    <td>{substrateName}</td>
                    <td className="text-right cost-td">{this.renderSubstrateTooltip(substrateCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_substrate_price" value={formValues['estimate_substrate_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let laminateRow = null;
        if (laminateName != null) {
            laminateRow = (
                <tr key="laminate">
                    <td>Laminate</td>
                    <td>{laminateName}</td>
                    <td className="text-right cost-td">{this.renderLaminateTooltip(laminateCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_laminate_price" value={formValues['estimate_laminate_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let accessoriesRow = null;
        if (accessoriesNames.length > 0) {
            accessoriesRow = (
                <tr key="accessories">
                    <td>Accessories</td>
                    <td>{accessoriesNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderAccessoriesTooltip(accessoriesCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_accessories_price" value={formValues['estimate_accessories_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let scoringRow = null;
        if (scoringName != null) {
            scoringRow = (
                <tr key="scoring">
                    <td>Scoring</td>
                    <td>{scoringName}</td>
                    <td className="text-right cost-td">{this.renderScoringTooltip(scoringCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_scoring_price" value={formValues['estimate_scoring_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let dieCuttingRow = null;
        if (dieCuttingName != null) {
            dieCuttingRow = (
                <tr key="die-cutting">
                    <td>Die-Cutting</td>
                    <td>{dieCuttingName}</td>
                    <td className="text-right cost-td">{this.renderDieCuttingTooltip(dieCuttingCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_die_cutting_price" value={formValues['estimate_die_cutting_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let drillingRow = null;
        if (drillingName != null) {
            drillingRow = (
                <tr key="drilling">
                    <td>Drilling</td>
                    <td>{drillingName}</td>
                    <td className="text-right cost-td">{this.renderDrillingTooltip(drillingCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_drilling_price" value={formValues['estimate_drilling_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let paddingRow = null;
        if (paddingName != null) {
            paddingRow = (
                <tr key="padding">
                    <td>Padding</td>
                    <td>{paddingName}</td>
                    <td className="text-right cost-td">{this.renderPaddingTooltip(paddingCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_padding_price" value={formValues['estimate_padding_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let bandingRow = null;
        if (bandingName != null) {
            bandingRow = (
                <tr key="banding">
                    <td>Banding</td>
                    <td>{bandingName}</td>
                    <td className="text-right cost-td">{this.renderBandingTooltip(bandingCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_banding_price" value={formValues['estimate_banding_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let wrappingRow = null;
        if (wrappingName != null) {
            wrappingRow = (
                <tr key="wrapping">
                    <td>Wrapping</td>
                    <td>{wrappingName}</td>
                    <td className="text-right cost-td">{this.renderWrappingTooltip(wrappingCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_wrapping_price" value={formValues['estimate_wrapping_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let shrinkWrappingRow = null;
        if (shrinkWrappingName != null) {
            shrinkWrappingRow = (
                <tr key="shrink-wrapping">
                    <td>Shrink Wrapping</td>
                    <td>{shrinkWrappingName}</td>
                    <td className="text-right cost-td">{this.renderShrinkWrappingTooltip(shrinkWrappingCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_shrink_wrapping_price" value={formValues['estimate_shrink_wrapping_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let packageRow = null;
        if (packageName != null) {
            packageRow = (
                <tr key="package">
                    <td>Package</td>
                    <td>{packageName}</td>
                    <td className="text-right cost-td">{this.renderPackageTooltip(packageCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_package_price" value={formValues['estimate_package_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let handGatheringRow = null;
        if (handGatheringName != null) {
            handGatheringRow = (
                <tr key="hand-gathering">
                    <td>Hand Gathering</td>
                    <td></td>
                    <td className="text-right cost-td">{this.renderHandGatheringTooltip(handGatheringCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_hand_gathering_price" value={formValues['estimate_hand_gathering_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let countingRow = null;
        if (countingName != null) {
            countingRow = (
                <tr key="counting">
                    <td>Counting</td>
                    <td></td>
                    <td className="text-right cost-td">{this.renderCountingTooltip(countingCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_counting_price" value={formValues['estimate_counting_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let outsideServicesRow = null;
        if (outsideServicesNames.length > 0) {
            outsideServicesRow = (
                <tr key="outside-services">
                    <td>Outside Services</td>
                    <td>{outsideServicesNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderOutsideServicesTooltip(outsideServicesCost, estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_outside_services_price" value={formValues['estimate_outside_services_price']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let weightRow = null;
        if (parseInt(formValues['estimate_type']) !== 4) {
            weightRow = (
                <tr key="weight">
                    <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>Weight:</td>
                    <td className="text-right cost-td" colSpan="2">{numeral(weight).format('0,0.[00]')} lbs</td>
                </tr>
            );
        }

        let discountRow = null;
        if (discount !== undefined && discount !== 0) {
            let discountType = 'Markup';
            if (discount < 0) {
                discountType = 'Discount';
            }

            let discountPrice = 0;
            if (result['totals']['discount_type'] === 1) {
                discountPrice = totalPrice * -result['totals']['discount_value'];
            } else {
                discountPrice = totalPrice * result['totals']['discount_value'];
            }
            totalPrice += discountPrice;

            discountRow = (
                <tr key="discount">
                    <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>{discountType}:</td>
                    <td className="text-right cost-td">{numeral(discount).format('$0,0.00')}</td>
                    <td className="text-right price-td">{numeral(discountPrice).format('$0,0.00')}</td>
                </tr>
            );
        }

        return [
            pressRow,
            digitalMachineRow,
            largeFormatMachineRow,
            paperRow,
            inkRow,
            cuttingRow,
            this.renderFolding(estimateResults, result),
            this.renderBinding(estimateResults, result),
            substrateRow,
            laminateRow,
            accessoriesRow,
            scoringRow,
            dieCuttingRow,
            drillingRow,
            paddingRow,
            packageRow,
            bandingRow,
            wrappingRow,
            shrinkWrappingRow,
            handGatheringRow,
            countingRow,
            outsideServicesRow,
            discountRow,
            <tr key="total">
                <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>Total:</td>
                <td className="text-right cost-td">{numeral(totalCost).format('$0,0.00')}</td>
                <td className="text-right price-td">{numeral(totalPrice).format('$0,0.00')}</td>
            </tr>,
            weightRow
        ];
    }

    hasCover() {
        const { formValues } = this.props;
        return [2, 3, 6, 7].includes(parseInt(formValues['estimate_multi_page_type']));
    }

    render() {
        const { formValues, handleUpdateFormValue, renderSheets } = this.props;
        return (
            <div className="col">
                <div className="card mb-2 mr-1 pb-2">
                    <div className="card-header">
                        Pricing
                    </div>
                    <div className="card-body p-1 pb-0">
                        <table id="estimate-pricing-table" className="table table-sm table-striped">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="text-right" style={{ fontWeight: 500 }}>Quantity:</td>
                                    <td></td>
                                    <td className="quantity-td">
                                        <input type="text" value={formValues['estimate_quantity']} id="estimate_quantity" name="estimate_quantity"
                                            onChange={handleUpdateFormValue.bind(this, 'number', true)} className="quantity-field text-right" />
                                    </td>
                                </tr>
                                {renderSheets ? this.renderSheets() : null}
                                <tr>
                                    <th>Department</th>
                                    <th>Name</th>
                                    <th className="cost-td">Cost</th>
                                    <th className="price-td">Price</th>
                                </tr>
                                {this.renderResults()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default EstimatePricing;
