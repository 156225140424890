import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingForm: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        industries: PropTypes.array,
        salesReps: PropTypes.array,
        csrs: PropTypes.array
    };

    renderInput(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
            </div>
        );
    }

    render() {
        const { hideForm, handleSaveElement, isSavingForm, isFetchingElement, customerDiscounts,
            industries, salesReps, csrs, formValues, handleUpdateFormValue } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let industriesOptions = [{ label: 'Select Option', value: '' }];
        for (let i = 0; i < industries.length; i++) {
            industriesOptions.push({ label: industries[i]['name'], value: industries[i]['id'] });
        }

        let salesRepsOptions = [{ label: 'Select Option', value: '' }];
        for (let i = 0; i < salesReps.length; i++) {
            salesRepsOptions.push({ label: salesReps[i]['name'], value: salesReps[i]['id'] });
        }

        let csrOptions = [{ label: 'Select Option', value: '' }];
        for (let i = 0; i < csrs.length; i++) {
            csrOptions.push({ label: csrs[i]['name'], value: csrs[i]['id'] });
        }

        let customerDiscountsOptions = [{ label: 'None', value: '' }];
        for (let i = 0; i < customerDiscounts.length; i++) {
            customerDiscountsOptions.push({ label: customerDiscounts[i]['name'], value: customerDiscounts[i]['id'] });
        }

        let statesOptions = [{ label: 'Select Option', value: '' }];
        let states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas',
            'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia',
            'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana',
            'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
            'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska',
            'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
            'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio',
            'Oklahoma', 'Oregon', 'Other Links', 'Pennsylvania', 'Puerto Rico',
            'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas',
            'Tribal Governments', 'U.S. Virgin Islands', 'Utah', 'Vermont', 'Virginia',
            'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
        for (let i = 0; i < states.length; i++) {
            statesOptions.push({ label: states[i], value: states[i] });
        }

        let form = (
            <form>
                <div className="row">
                    <div className="subtitle">Customer Data</div>
                    <div className="w-100"></div>
                    {this.renderInput('Company', 'company', 'text', true)}
                    {this.renderInput('Name', 'name', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Email', 'email', 'text', true)}
                    {this.renderInput('Confirm Email', 'confirm_email', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Street', 'street', 'text', true)}
                    {this.renderInput('City', 'city', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderSelect('State', 'state', statesOptions, true)}
                    {this.renderInput('ZIP', 'zip', 'text', true)}
                    <div className="w-100"></div>
                    {this.renderInput('Phone', 'phone', 'text', true)}
                    {this.renderSelect('Shipping Method', 'shipping_method', [
                        { label: 'Select Option', value: '' },
                        { label: 'FedEx', value: 'FedEx' },
                        { label: 'UPS', value: 'UPS' },
                        { label: 'USPO', value: 'USPO' },
                        { label: 'DHL', value: 'DHL' },
                        { label: 'Customer Pickup', value: 'Customer Pickup' },
                        { label: 'Other', value: 'Other' }
                    ], true)}
                    <div className="w-100"></div>
                    {this.renderSelect('Industry Type', 'industry_id', industriesOptions, true)}
                    {this.renderSelect('Customer Discount', 'customer_discount_id', customerDiscountsOptions, false, 'col-6')}
                    <div className="w-100"></div>
                    {this.renderSelect('PO Required?', 'po_required', [
                        { label: 'No', value: 0 },
                        { label: 'Yes', value: 1 }
                    ], true)}
                    {this.renderSelect('Salesperson', 'sales_rep_id', salesRepsOptions, true, 'col-6')}
                    <div className="w-100"></div>
                    {this.renderSelect('CSR', 'csr_id', csrOptions, false, 'col-6')}
                    {this.renderSelect('Reseller?', 'reseller', [
                        { label: 'No', value: 0 },
                        { label: 'Yes', value: 1 }
                    ], true, 'col-6')}
                    <div className="w-100"></div>
                    {formValues['reseller'] === 0 ? null : this.renderInput('Reseller ID', 'reseller_id', 'text', true, 'col-6')}
                    {this.renderInput('Notes', 'notes', 'text', false, 'col-6')}
                    <div className="w-100"></div>
                    <div className="subtitle">
                        Billing Information
                        <span className="use-above-address">
                            <input type="checkbox" name="use_address_for_billing" checked={formValues['use_address_for_billing']}
                                onClick={handleUpdateFormValue.bind(this, 'number', true)} /> Use above address information
                        </span>
                    </div>
                    <div className="w-100"></div>
                    {formValues['use_address_for_billing'] === 1 ? null : this.renderInput('Name', 'billing_name', 'text', true)}
                    {formValues['use_address_for_billing'] === 1 ? null : this.renderInput('Company', 'billing_company', 'text', true)}
                    <div className="w-100"></div>
                    {formValues['use_address_for_billing'] === 1 ? null : this.renderInput('Street', 'billing_street', 'text', true)}
                    {formValues['use_address_for_billing'] === 1 ? null : this.renderInput('City', 'billing_city', 'text', true)}
                    <div className="w-100"></div>
                    {formValues['use_address_for_billing'] === 1 ? null : this.renderSelect('State', 'billing_state', statesOptions, true)}
                    {formValues['use_address_for_billing'] === 1 ? null : this.renderInput('ZIP', 'billing_zip', 'text', true)}
                    <div className="w-100"></div>
                    {formValues['use_address_for_billing'] === 1 ? null : this.renderInput('Phone', 'billing_phone', 'text', true, 'col-6')}
                    <div className="w-100"></div>
                    <div className="subtitle">
                        Shipping Information
                        <span className="use-above-address">
                            <input type="checkbox" name="use_address_for_shipping" checked={formValues['use_address_for_shipping']}
                                onClick={handleUpdateFormValue.bind(this, 'number', true)} /> Use above address information
                        </span>
                    </div>
                    <div className="w-100"></div>
                    {formValues['use_address_for_shipping'] === 1 ? null : this.renderInput('Name', 'shipping_name', 'text', true)}
                    {formValues['use_address_for_shipping'] === 1 ? null : this.renderInput('Company', 'shipping_company', 'text', true)}
                    <div className="w-100"></div>
                    {formValues['use_address_for_shipping'] === 1 ? null : this.renderInput('Street', 'shipping_street', 'text', true)}
                    {formValues['use_address_for_shipping'] === 1 ? null : this.renderInput('City', 'shipping_city', 'text', true)}
                    <div className="w-100"></div>
                    {formValues['use_address_for_shipping'] === 1 ? null : this.renderSelect('State', 'shipping_state', statesOptions, true)}
                    {formValues['use_address_for_shipping'] === 1 ? null : this.renderInput('ZIP', 'shipping_zip', 'text', true)}
                    <div className="w-100"></div>
                    {formValues['use_address_for_shipping'] === 1 ? null : this.renderInput('Phone', 'shipping_phone', 'text', true, 'col-6')}
                    <div className="w-100"></div>
                </div>
            </form>
        );
        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
