import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

class EstimatePricing extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingEstimate: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        handleEstimate: PropTypes.func,
        isLoadingEstimate: PropTypes.bool,
        estimateResults: PropTypes.array,
        handleSaveEstimate: PropTypes.array,
        activeProduct: PropTypes.object,
        handleAccessoryClick: PropTypes.func,
        estimateAccessories: PropTypes.array,
        information: PropTypes.object,
        options: PropTypes.object,
        fetching: PropTypes.object,
        renderSheets: PropTypes.bool,
        isMailing: PropTypes.bool
    };

    loadPrices(name, discountPercentage, discountType) {
        const { formValues } = this.props;

        let prices = [];
        for (let i = 0; i < 3; i++) {
            if (formValues[name + (i + 1)] !== '' && formValues[name + (i + 1)] !== null) {
                let price = parseFloat(formValues[name + (i + 1)]);
                if (discountPercentage !== undefined && discountPercentage !== 0) {
                    if (parseInt(discountType) === 1) {
                        price += price * -discountPercentage;
                    } else {
                        price += price * discountPercentage;
                    }
                }

                prices.push(price);
            } else {
                prices.push(0);
            }
        }

        return prices;
    }

    renderBinding(estimateResults, results) {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) !== 2) {
            return null;
        }

        let bindingName = results[0]['binding_information']['name'];
        let bindingCosts = [0, 0, 0];

        for (let i = 0; i < 3; i++) {
            bindingCosts[i] += results[i]['binding_information']['total_cost'];
        }

        return (
            <tr key="binding">
                <td>Binding</td>
                <td>{bindingName}</td>
                <td className="price-td">{numeral(formValues['estimate_binding_price_1']).format('$0,0.00')}</td>
                <td className="price-td">{numeral(formValues['estimate_binding_price_2']).format('$0,0.00')}</td>
                <td className="price-td">{numeral(formValues['estimate_binding_price_3']).format('$0,0.00')}</td>
            </tr>
        );
    }

    renderFolding(estimateResults, results) {
        const { formValues } = this.props;

        if (parseInt(formValues['estimate_type']) === 4) {
            return null;
        }

        let foldingName = [];
        if (parseInt(formValues['estimate_type']) === 1 && results[0]['folding_information']['id'] != null) {
            foldingName.push(<div>{results[0]['folding_information']['name']}</div>);
        } else if (parseInt(formValues['estimate_type']) === 2) {
            foldingName.push(<div>{results[0]['impositions_information'][0]['folding_information']['name']}</div>);
        } else if (parseInt(formValues['estimate_type']) === 3 && results[0]['folding_information']['id'] != null) {
            foldingName.push(<div>{results[0]['folding_information']['name']}</div>);
        }

        if (foldingName.length === 0) {
            return null;
        }

        return (
            <tr key="folding">
                <td>Folding</td>
                <td>{foldingName}</td>
                <td className="price-td">{numeral(formValues['estimate_folding_price_1']).format('$0,0.00')}</td>
                <td className="price-td">{numeral(formValues['estimate_folding_price_2']).format('$0,0.00')}</td>
                <td className="price-td">{numeral(formValues['estimate_folding_price_3']).format('$0,0.00')}</td>
            </tr>
        );
    }

    renderResults() {
        const { estimateResults, formValues } = this.props;

        if (estimateResults.length === 0) {
            return (
                <tr>
                    <td colSpan="5">No results</td>
                </tr>
            );
        }

        for (let i = 0; i < 3; i++) {
            if (estimateResults[i]['results'].length === 0) {
                return (
                    <tr>
                        <td colSpan="5">No results</td>
                    </tr>
                );
            }
        }

        let results = [];
        for (let i = 0; i < 3; i++) {
            results.push(estimateResults[i]['results'].length === 0 ? null : estimateResults[i]['results'][0]);
        }

        let weights = [];
        if (parseInt(formValues['estimate_type']) === 1 || parseInt(formValues['estimate_type']) === 3) {
            for (let i = 0; i < 3; i++) {
                weights.push(estimateResults[i]['results'][0]['paper_information']['total_poundage']);
            }
        } else if (parseInt(formValues['estimate_type']) === 2) {
            for (let i = 0; i < 3; i++) {
                let totalWeight = 0;
                for (let j = 0; j < estimateResults[i]['results'][0]['impositions_information'].length; j++) {
                    totalWeight += estimateResults[i]['results'][0]['impositions_information'][j]['paper_information']['total_poundage'];
                }
                weights.push(totalWeight);
            }
        }

        if (results[0] === null || results[1] === null || results[2] === null) {
            return (
                <tr>
                    <td colSpan="5">No results</td>
                </tr>
            );
        }

        let pressPrices = this.loadPrices('estimate_press_price_');
        let digitalMachinePrices = this.loadPrices('estimate_digital_machine_price_');
        let paperPrices = this.loadPrices('estimate_paper_price_');
        let inkPrices = this.loadPrices('estimate_ink_price_');
        let cuttingPrices = this.loadPrices('estimate_cutting_price_');
        let foldingPrices = this.loadPrices('estimate_folding_price_');
        let bindingPrices = this.loadPrices('estimate_binding_price_');
        let largeFormatMachinePrices = this.loadPrices('estimate_large_format_machine_price_');
        let substratePrices = this.loadPrices('estimate_substrate_price_');
        let laminatePrices = this.loadPrices('estimate_laminate_price_');
        let accessoriesPrices = this.loadPrices('estimate_accessories_price_');
        let scoringPrices = this.loadPrices('estimate_scoring_price_');
        let dieCuttingPrices = this.loadPrices('estimate_die_cutting_price_');
        let drillingPrices = this.loadPrices('estimate_drilling_price_');
        let paddingPrices = this.loadPrices('estimate_padding_price_');
        let bandingPrices = this.loadPrices('estimate_banding_price_');
        let wrappingPrices = this.loadPrices('estimate_wrapping_price_');
        let shrinkWrappingPrices = this.loadPrices('estimate_shrink_wrapping_price_');
        let packagePrices = this.loadPrices('estimate_package_price_');
        let handGatheringPrices = this.loadPrices('estimate_hand_gathering_price_');
        let countingPrices = this.loadPrices('estimate_counting_price_');

        let totalPrices = [];
        for (let i = 0; i < 3; i++) {
            totalPrices.push(pressPrices[i] + paperPrices[i] + inkPrices[i] + cuttingPrices[i] + bindingPrices[i]
                + foldingPrices[i] + digitalMachinePrices[i] + largeFormatMachinePrices[i] + substratePrices[i]
                + laminatePrices[i] + accessoriesPrices[i] + scoringPrices[i]
                + dieCuttingPrices[i] + drillingPrices[i] + paddingPrices[i] + bandingPrices[i] + wrappingPrices[i]
                + shrinkWrappingPrices[i] + packagePrices[i] + handGatheringPrices[i] + countingPrices[i]);
        }

        let pressName = null;
        let digitalMachineName = null;
        let paperName = null;
        let inkName = null;
        let cuttingName = null;
        let largeFormatMachineName = null;
        let substrateName = null;
        let laminateName = null;
        let accessoriesNames = [];
        let scoringName = null;
        let dieCuttingName = null;
        let drillingName = null;
        let paddingName = null;
        let bandingName = null;
        let wrappingName = null;
        let shrinkWrappingName = null;
        let packageName = null;
        let handGatheringName = null;
        let countingName = null;

        if (parseInt(formValues['estimate_type']) === 1) {
            pressName = results[0]['press_information']['name'];
            paperName = results[0]['paper_information']['basis_weight'] + '# ' + results[0]['paper_information']['name']
                + ' ' + results[0]['paper_information']['pars']['width'] + 'x' + results[0]['paper_information']['pars']['length'];
            inkName = results[0]['ink_information']['front_colors'].length + '/' + results[0]['ink_information']['back_colors'].length;
            cuttingName = results[0]['cutting_information']['name'];
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let paperNameString = null;
            let inkNameString = null;
            pressName = [];
            paperName = [];
            inkName = [];

            if (this.hasCover()) {
                pressName.push(<div><b>Cover:</b> {estimateResults[0]['cover_results'][0]['press_information']['name']}</div>);

                paperNameString = estimateResults[0]['cover_results'][0]['paper_information']['basis_weight'] + '# '
                    + estimateResults[0]['cover_results'][0]['paper_information']['name'] + ' '
                    + estimateResults[0]['cover_results'][0]['paper_information']['pars']['width'] + 'x'
                    + estimateResults[0]['cover_results'][0]['paper_information']['pars']['length'];
                paperName.push(<div><b>Cover:</b> {paperNameString}</div>);

                inkNameString = estimateResults[0]['cover_results'][0]['ink_information']['front_colors'].length + '/'
                    + estimateResults[0]['cover_results'][0]['ink_information']['back_colors'].length;
                inkName.push(<div><b>Cover:</b> {inkNameString}</div>);
            }

            for (let j = 0; j < results[0]['impositions_information'].length; j++) {
                pressName.push(<div><b>Interior {j + 1}:</b> {results[0]['impositions_information'][j]['press_information']['name']}</div>);
            }

            paperNameString = results[0]['impositions_information'][0]['paper_information']['basis_weight'] + '# '
                + results[0]['impositions_information'][0]['paper_information']['name'] + ' '
                + results[0]['impositions_information'][0]['paper_information']['pars']['width'] + 'x'
                + results[0]['impositions_information'][0]['paper_information']['pars']['length'];
            paperName.push(<div><b>Interior:</b> {paperNameString}</div>);

            inkNameString = results[0]['impositions_information'][0]['ink_information']['front_colors'].length + '/'
                + results[0]['impositions_information'][0]['ink_information']['back_colors'].length;
            inkName.push(<div><b>Interior:</b> {inkNameString}</div>);

            cuttingName = results[0]['impositions_information'][0]['cutting_information']['name'];
        } else if (parseInt(formValues['estimate_type']) === 3) {
            digitalMachineName = results[0]['digital_machine_information']['name'];
            paperName = results[0]['paper_information']['basis_weight'] + '# ' + results[0]['paper_information']['name']
                + ' ' + results[0]['paper_information']['pars']['width'] + 'x' + results[0]['paper_information']['pars']['length'];

            if (results[0]['ink_information']['front_colors'].length > 0 && results[0]['ink_information']['back_colors'].length > 0) {
                inkName = 'Coating both sides';
            } else if (results[0]['ink_information']['front_colors'].length > 0 || results[0]['ink_information']['back_colors'].length > 0) {
                inkName = 'Coating one side';
            } else {
                inkName = null;
            }

            cuttingName = results[0]['cutting_information']['name'];
        } else if (parseInt(formValues['estimate_type']) === 4) {
            largeFormatMachineName = results[0]['large_format_machine_information']['name'];
            substrateName = results[0]['substrate_information']['name'];

            if (results[0]['laminate_information'] != null) {
                laminateName = results[0]['laminate_information']['name'];
            }

            if (results[0]['accessories_information'] != null) {
                for (let i = 0; i < results[0]['accessories_information']['accessories'].length; i++) {
                    accessoriesNames.push(results[0]['accessories_information']['accessories'][i]['name']);
                }
            }
        }

        if (parseInt(formValues['estimate_type']) === 1 || parseInt(formValues['estimate_type']) === 2 || parseInt(formValues['estimate_type']) === 3) {
            if (results[0]['scoring_information'] && results[0]['scoring_information']['id'] != null) {
                scoringName = results[0]['scoring_information']['name'];
            }

            if (results[0]['die_cutter_information'] && results[0]['die_cutter_information']['id'] != null) {
                dieCuttingName = results[0]['die_cutter_information']['name'];
            }

            if (results[0]['drilling_information'] && results[0]['drilling_information']['id'] != null) {
                drillingName = results[0]['drilling_information']['name'];
            }

            if (results[0]['padding_information'] && results[0]['padding_information']['id'] != null) {
                paddingName = results[0]['padding_information']['name'];
            }

            if (results[0]['banding_information'] && results[0]['banding_information']['id'] != null) {
                bandingName = results[0]['banding_information']['type_name'];
            }

            if (results[0]['wrapping_information'] && results[0]['wrapping_information']['id'] != null) {
                wrappingName = results[0]['wrapping_information']['type_name'];
            }

            if (results[0]['shrink_wrapping_information'] && results[0]['shrink_wrapping_information']['id'] != null) {
                shrinkWrappingName = results[0]['shrink_wrapping_information']['type_name'];
            }

            if (results[0]['package_information'] && results[0]['package_information']['id'] != null) {
                packageName = results[0]['package_information']['width'] + 'x'
                    + results[0]['package_information']['length'] + 'x'
                    + results[0]['package_information']['height'];
            }

            if (results[0]['hand_gathering_information'] && results[0]['hand_gathering_information']['id'] != null) {
                handGatheringName = results[0]['hand_gathering_information']['name'];
            }

            if (results[0]['counting_information'] && results[0]['counting_information']['id'] != null) {
                countingName = results[0]['counting_information']['name'];
            }
        }

        let discounts = [];
        for (let i = 0; i < 3; i++) {
            discounts.push(results[i]['totals']['discount']);
        }

        const discountValue = results[0]['totals']['discount_value'];
        const discountType = results[0]['totals']['discount_type'];
        if (discounts[0] !== undefined && discounts[0] !== 0) {
            let discountPrices = [0, 0, 0];
            if (parseInt(results[0]['totals']['discount_type']) === 1) {
                discountPrices[0] = totalPrices[0] * -results[0]['totals']['discount_value'];
                discountPrices[1] = totalPrices[1] * -results[1]['totals']['discount_value'];
                discountPrices[2] = totalPrices[2] * -results[2]['totals']['discount_value'];
            } else {
                discountPrices[0] = totalPrices[0] * results[0]['totals']['discount_value'];
                discountPrices[1] = totalPrices[1] * results[1]['totals']['discount_value'];
                discountPrices[2] = totalPrices[2] * results[2]['totals']['discount_value'];
            }

            totalPrices[0] += discountPrices[0];
            totalPrices[1] += discountPrices[1];
            totalPrices[2] += discountPrices[2];
        }

        let pressRow = null;
        if (pressName != null) {
            pressRow = (
                <tr key="press">
                    <td>Press</td>
                    <td>{pressName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_press_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_press_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_press_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let digitalMachineRow = null;
        if (digitalMachineName != null) {
            digitalMachineRow = (
                <tr key="digital-machine">
                    <td>Digital Machine</td>
                    <td>{digitalMachineName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_digital_machine_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_digital_machine_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_digital_machine_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let inkRow = null;
        if (inkName != null) {
            inkRow = (
                <tr key="ink">
                    <td>Ink</td>
                    <td>{inkName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_ink_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_ink_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_ink_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let paperRow = null;
        if (paperName != null) {
            paperRow = (
                <tr key="paper">
                    <td>Paper</td>
                    <td>{paperName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_paper_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_paper_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_paper_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let cuttingRow = null;
        if (cuttingName != null) {
            cuttingRow = (
                <tr key="cutting">
                    <td>Cutting</td>
                    <td>{cuttingName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_cutting_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_cutting_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_cutting_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let largeFormatMachineRow = null;
        if (largeFormatMachineName != null) {
            largeFormatMachineRow = (
                <tr key="large-format-machine">
                    <td>Large Format Machine</td>
                    <td>{largeFormatMachineName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_large_format_machine_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_large_format_machine_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_large_format_machine_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let substrateRow = null;
        if (substrateName != null) {
            substrateRow = (
                <tr key="substrate">
                    <td>Substrate</td>
                    <td>{substrateName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_substrate_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_substrate_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_substrate_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let laminateRow = null;
        if (laminateName != null) {
            laminateRow = (
                <tr key="laminate">
                    <td>Laminate</td>
                    <td>{laminateName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_laminate_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_laminate_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_laminate_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let accessoriesRow = null;
        if (accessoriesNames.length > 0) {
            accessoriesRow = (
                <tr key="accessories">
                    <td>Accessories</td>
                    <td>{accessoriesNames.join(', ')}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_accessories_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_accessories_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_accessories_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let scoringRow = null;
        if (scoringName != null) {
            scoringRow = (
                <tr key="scoring">
                    <td>Scoring</td>
                    <td>{scoringName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_scoring_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_scoring_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_scoring_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let dieCuttingRow = null;
        if (dieCuttingName != null) {
            dieCuttingRow = (
                <tr key="die-cutting">
                    <td>Die-Cutting</td>
                    <td>{dieCuttingName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_die_cutting_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_die_cutting_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_die_cutting_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let drillingRow = null;
        if (drillingName != null) {
            drillingRow = (
                <tr key="drilling">
                    <td>Drilling</td>
                    <td>{drillingName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_drilling_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_drilling_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_drilling_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let paddingRow = null;
        if (paddingName != null) {
            paddingRow = (
                <tr key="padding">
                    <td>Padding</td>
                    <td>{paddingName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_padding_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_padding_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_padding_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let bandingRow = null;
        if (bandingName != null) {
            bandingRow = (
                <tr key="banding">
                    <td>Banding</td>
                    <td>{bandingName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_banding_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_banding_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_banding_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let wrappingRow = null;
        if (wrappingName != null) {
            wrappingRow = (
                <tr key="wrapping">
                    <td>Wrapping</td>
                    <td>{wrappingName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_wrapping_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_wrapping_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_wrapping_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let shrinkWrappingRow = null;
        if (shrinkWrappingName != null) {
            shrinkWrappingRow = (
                <tr key="shrink-wrapping">
                    <td>Shrink Wrapping</td>
                    <td>{shrinkWrappingName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_shrink_wrapping_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_shrink_wrapping_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_shrink_wrapping_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let packageRow = null;
        if (packageName != null) {
            packageRow = (
                <tr key="package">
                    <td>Package</td>
                    <td>{packageName}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_package_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_package_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_package_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let handGatheringRow = null;
        if (handGatheringName != null) {
            handGatheringRow = (
                <tr key="hand-gathering">
                    <td>Hand Gathering</td>
                    <td></td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_hand_gathering_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_hand_gathering_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_hand_gathering_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let countingRow = null;
        if (countingName != null) {
            countingRow = (
                <tr key="counting">
                    <td>Counting</td>
                    <td></td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_counting_price_1'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_counting_price_2'], discountValue, discountType)}</td>
                    <td className="price-td">{this.applyDiscountToPrice(formValues['estimate_counting_price_3'], discountValue, discountType)}</td>
                </tr>
            );
        }

        let weightRow = null;
        if (parseInt(formValues['estimate_type']) !== 4) {
            weightRow = (
                <tr key="weight">
                    <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>Weight:</td>
                    <td className="text-right cost-td">{numeral(weights[0]).format('0,0.[00]')} lbs</td>
                    <td className="text-right cost-td">{numeral(weights[1]).format('0,0.[00]')} lbs</td>
                    <td className="text-right cost-td">{numeral(weights[2]).format('0,0.[00]')} lbs</td>
                </tr>
            );
        }

        return [
            pressRow,
            digitalMachineRow,
            largeFormatMachineRow,
            paperRow,
            inkRow,
            cuttingRow,
            this.renderFolding(estimateResults, results),
            this.renderBinding(estimateResults, results),
            substrateRow,
            laminateRow,
            accessoriesRow,
            scoringRow,
            dieCuttingRow,
            drillingRow,
            paddingRow,
            packageRow,
            bandingRow,
            wrappingRow,
            shrinkWrappingRow,
            handGatheringRow,
            countingRow,
            <tr key="total">
                <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>Total:</td>
                <td className="text-right price-td">{numeral(totalPrices[0]).format('$0,0.00')}</td>
                <td className="text-right price-td">{numeral(totalPrices[1]).format('$0,0.00')}</td>
                <td className="text-right price-td">{numeral(totalPrices[2]).format('$0,0.00')}</td>
            </tr>,
            weightRow
        ];
    }

    applyDiscountToPrice(price, discountValue, discountType) {
        price = parseFloat(price);
        if (discountValue !== undefined && discountValue !== 0) {
            if (parseInt(discountType) === 1) {
                price += price * -discountValue;
            } else {
                price += price * discountValue;
            }
        }

        return numeral(price).format('$0,0.00');
    }

    hasCover() {
        const { formValues } = this.props;
        return [2, 3, 6, 7].includes(parseInt(formValues['estimate_multi_page_type']));
    }

    render() {
        const { formValues, handleUpdateFormValue } = this.props;
        return (
            <div className="col">
                <div className="card mb-2 mr-1 pb-2">
                    <div className="card-header">
                        Pricing
                    </div>
                    <div className="card-body p-1 pb-0">
                        <table id="estimate-pricing-table" className="table table-sm table-striped">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="text-right" style={{ fontWeight: 500 }}>Quantity:</td>
                                    <td className="quantity-td">
                                        <input type="text" value={formValues['estimate_quantity_1']} id="estimate_quantity_1" name="estimate_quantity_1"
                                            onChange={handleUpdateFormValue.bind(this, 'number', true)} className="quantity-field text-right" />
                                    </td>
                                    <td className="quantity-td">
                                        <input type="text" value={formValues['estimate_quantity_2']} id="estimate_quantity_2" name="estimate_quantity_2"
                                            onChange={handleUpdateFormValue.bind(this, 'number', true)} className="quantity-field text-right" />
                                    </td>
                                    <td className="quantity-td">
                                        <input type="text" value={formValues['estimate_quantity_3']} id="estimate_quantity_3" name="estimate_quantity_3"
                                            onChange={handleUpdateFormValue.bind(this, 'number', true)} className="quantity-field text-right" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Department</th>
                                    <th>Name</th>
                                    <th className="price-td">Price</th>
                                    <th className="price-td">Price</th>
                                    <th className="price-td">Price</th>
                                </tr>
                                {this.renderResults()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default EstimatePricing;
