import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class Tabs extends Component
{
    static propTypes = {
        user: PropTypes.object
    };

    hasPermission(name) {
        const { user } = this.props;
        for (let i = 0; i < user['permissions'].length; i++) {
            if(user['permissions'][i]['name'] === name) {
                for (let j = 0; j < user['permissions'][i]['subnames'].length; j++) {
                    if(user['permissions'][i]['subnames'][j]['selected']) {
                        return true;
                    }
                }
            }
        }
    }

    render() {
        const { user } = this.props;
        const pathname = window.location.pathname;

        let productWindowsTabs = [];
        let dashboardTab = null;
        let quotesTab = null;
        let ordersTab = null;
        let productionTab = null;
        let shippingTab = null;
        let customersTab = null;
        let productsTab = null;
        let adminTab = null;
        let changeOrderTab = null;
        let depositsTab = null;
        let landrysTab = null;
        let shippingReceivingTab = null;
        let timelineTab = null;
        let productsCatalogTab = null;
        
        if(user['user_type_1'] === 19) {     // Products Only
            return null;
        } else {
            if(this.hasPermission('Dashboard') || user['type'] === 1) {
                dashboardTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('/dashboard') || pathname === '/' ? ' active' : '')}
                              to={'/dashboard'}>Dashboard</Link>
                    </li>
                );
            }
    
            if(this.hasPermission('Quotes') || user['type'] === 1) {
                quotesTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('/quotes') ? ' active' : '')}
                              to={'/quotes'}>Quotes</Link>
                    </li>
                );
            }
    
            if(this.hasPermission('Orders') || user['type'] === 1) {
                ordersTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('/orders') ? ' active' : '')}
                              to={'/orders'}>Orders</Link>
                    </li>
                );
            }
    
            if(this.hasPermission('Production') || user['type'] === 1) {
                productionTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('/production') ? ' active' : '')}
                              to={'/production-orders'}>Production</Link>
                    </li>
                );
            }
    
            if(this.hasPermission('Shipping') || user['type'] === 1) {
                shippingTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname === '/shipping' ? ' active' : '')}
                              to={'/shipping'}>Shipping</Link>
                    </li>
                );
            }
    
            if(this.hasPermission('Customers') || user['type'] === 1) {
                customersTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname === '/customers' ? ' active' : '')}
                              to={'/customers'}>Customers</Link>
                    </li>
                );
            }
    
            if(this.hasPermission('Products') || user['type'] === 1) {
                /*productsTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('/products') ? ' active' : '')}
                              to={'/products'}>Products</Link>
                    </li>
                );*/
            }
    
            if(this.hasPermission('Users') || this.hasPermission('Industries') || this.hasPermission('Departments')
                || this.hasPermission('Turnaround') || this.hasPermission('Presses') || this.hasPermission('Inks')
                || this.hasPermission('Digital') || this.hasPermission('Cutting') || this.hasPermission('Die-Cutting')
                || this.hasPermission('Drilling') || this.hasPermission('Scoring') || this.hasPermission('Padding')
                || this.hasPermission('Paper Categories') || this.hasPermission('Paper Vendors') || this.hasPermission('Papers')
                || this.hasPermission('Folding Machines') || this.hasPermission('Folding Types') || this.hasPermission('Saddle Stitching Machines')
                || this.hasPermission('Perfect Binding Machines') || this.hasPermission('Coil Binding Machines') || this.hasPermission('Corner Staple Machines')
                || this.hasPermission('Side Staple Machines') || this.hasPermission('Spiral Binding Machines') || this.hasPermission('Banding Types')
                || this.hasPermission('Banding Machines') || this.hasPermission('Wrapping Types') || this.hasPermission('Wrapping Machines')
                || this.hasPermission('Shrink Wrapping Machines') || this.hasPermission('Shrink Wrapping Types') || this.hasPermission('Shrink Wrapping Categories')
                || this.hasPermission('Packages') || this.hasPermission('Mailing Operations') || this.hasPermission('Flat Hand Gathering')
                || this.hasPermission('Multi-Page Hand Gathering') || this.hasPermission('Counting') || this.hasPermission('Large Format Machines')
                || this.hasPermission('Substrates') || this.hasPermission('Laminates') || this.hasPermission('Accessories') || user['type'] === 1
                || this.hasPermission('Data Processing') || this.hasPermission('Inkjet') || this.hasPermission('Inserting')
                || this.hasPermission('Perfect Match') || this.hasPermission('Wafer Seal') || this.hasPermission('Affix Labels')
                || this.hasPermission('Certified Mail') || this.hasPermission('Art') || this.hasPermission('Handwork')
                || this.hasPermission('Pressure Seal Forms') || this.hasPermission('Index Tabs') || this.hasPermission('Labels')
                || this.hasPermission('NCR Forms')
            ) {
                adminTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('/admin') ? ' active' : '')}
                              to={'/admin'}>Admin</Link>
                    </li>
                );
            }
    
            if(this.hasPermission('Change Order') || user['type'] === 1) {
                changeOrderTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('/change-order') ? ' active' : '')}
                              to={'/change-order'}>Change Order</Link>
                    </li>
                );
            }
    
            if(this.hasPermission('Deposits') || user['type'] === 1) {
                depositsTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('/deposits') ? ' active' : '')}
                              to={'/deposits'}>Deposits</Link>
                    </li>
                );
            }
    
            if(this.hasPermission('Landry\'s') || user['type'] === 1) {
                landrysTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('/landrys-invoices') ? ' active' : '')}
                              to={'/landrys-invoices'}>Landry's Invoices</Link>
                    </li>
                );
            }
    
            if(this.hasPermission('Shipping/Receiving') || user['type'] === 1) {
                shippingReceivingTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('/shipping-receiving') ? ' active' : '')}
                              to={'/shipping-receiving'}>Shipping/Receiving</Link>
                    </li>
                );
            }

            if(this.hasPermission('Products Catalog') || user['type'] === 1) {
                productsCatalogTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('products-catalog') ? ' active' : '')}
                              to={'products-catalog'}>Products Catalog</Link>
                    </li>
                );
            }

            if(user['id'] === 61 || user['id'] === 64) {
                timelineTab = (
                    <li className="nav-item">
                        <Link className={'nav-link' + (pathname.includes('/timeline-log') ? ' active' : '')}
                              to={'/timeline-log'}>Timeline Log</Link>
                    </li>
                );
            }
        }

        return (
            <ul className="nav nav-tabs">
                {dashboardTab}
                {quotesTab}
                {ordersTab}
                {productionTab}
                {shippingTab}
                {customersTab}
                {productsTab}
                {changeOrderTab}
                {depositsTab}
                {landrysTab}
                {shippingReceivingTab}
                {timelineTab}
                {productsCatalogTab}
                {adminTab}
                {productWindowsTabs}
                {/*<li className="nav-item">
                    <Link className={'nav-link' + (pathname.includes('/reports') ? ' active' : '')}
                          to={'/reports'}>Reports</Link>
                </li>*/}
            </ul>
        );
    }
}

export default Tabs;
