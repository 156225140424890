import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import { findPermission } from './../../../utils';
import numeral from 'numeral';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class Table extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        isFetchingElements: PropTypes.bool,
        handleShowForm: PropTypes.func,
        handleShowDelete: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleChangeAttribute: PropTypes.func,
        handleInlineChange: PropTypes.func,
        handleViewProperty: PropTypes.func,
        handleChangeFilter: PropTypes.func,
        processedElements: PropTypes.array,
        user: PropTypes.object,
        filterFields: PropTypes.object
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn === name) {
            if (sortType === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name)}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { processedElements, isFetchingElements, handleShowForm, handleShowDelete, selectedPage, elementsPerPage,
            user } = this.props;

        if (isFetchingElements) {
            return (
                <tr>
                    <td colSpan="17"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (processedElements.length === 0) {
            return (
                <tr>
                    <td colSpan="17">No Results</td>
                </tr>
            );
        }

        let permission = findPermission(user['permissions'], 'Landry\'s');

        let data = [];
        let startIndex = (selectedPage - 1) * elementsPerPage;
        for (let i = startIndex; i < processedElements.length && i < startIndex + elementsPerPage; i++) {
            let editAction = null;
            if (permission['Edit']) {
                editAction = (
                    <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-edit" onClick={handleShowForm.bind(this, processedElements[i].id, null)} />
                    </Tooltip>
                );
            }

            let deleteAction = null;
            if (permission['Delete']) {
                deleteAction = (
                    <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash" onClick={handleShowDelete.bind(this, processedElements[i].id)} />
                    </Tooltip>
                );
            }

            data.push(
                <tr key={i}>
                    <td>{moment(processedElements[i]['date']).format('MM/DD/YYYY')}</td>
                    <td>{processedElements[i]['description']}</td>
                    <td>{processedElements[i]['invoice_size_name']}</td>
                    <td>{processedElements[i]['invoice_stock_name']}</td>
                    <td>{processedElements[i]['ink_name']}</td>
                    <td>{processedElements[i]['invoice_machine_name']}</td>
                    <td>{numeral(processedElements[i]['quantity']).format('0,0')}</td>
                    <td>{numeral(processedElements[i]['landrys']).format('$0,0.00')}</td>
                    <td>{numeral(processedElements[i]['per_unit']).format('$0,0.00')}</td>
                    <td>{numeral(processedElements[i]['larry']).format('$0,0.00')}</td>
                    <td>{numeral(processedElements[i]['larry_per_unit']).format('$0,0.00')}</td>
                    <td>{numeral(processedElements[i]['ac']).format('$0,0.00')}</td>
                    <td>{numeral(processedElements[i]['ac_per_unit']).format('$0,0.00')}</td>
                    <td>{numeral(processedElements[i]['robert']).format('$0,0.00')}</td>
                    <td>{processedElements[i]['ps']}</td>
                    <td>{processedElements[i]['pm']}</td>
                    <td>
                        <div className="actions">
                            {editAction}
                            {deleteAction}
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { processedElements, totalPages, selectedPage, elementsPerPage,
            handleChangeFilter } = this.props;

        let landrysTotal = 0;
        let larryTotal = 0;
        let acTotal = 0;
        let robertTotal = 0;
        for (let i = 0; i < processedElements.length; i++) {
            landrysTotal += processedElements[i]['landrys'];
            larryTotal += processedElements[i]['larry'];
            acTotal += processedElements[i]['ac'];
            robertTotal += processedElements[i]['robert'];
        }

        return (
            <div id="tables-container" className="production-container">
                <div className="table-container table-hover">
                    <table id="titles-table" className="table table-sm table-hover">
                        <thead>
                            <tr>
                                {this.renderTableHeader('Date', 'date', { width: 162 })}
                                {this.renderTableHeader('Description', 'description')}
                                {this.renderTableHeader('Size', 'invoice_size_name', { width: 100 })}
                                {this.renderTableHeader('Stock', 'invoice_stock_name')}
                                {this.renderTableHeader('Ink', 'ink_name', { width: 90 })}
                                {this.renderTableHeader('Machine', 'invoice_machine_name')}
                                {this.renderTableHeader('Quantity', 'quantity', { width: 90 })}
                                {this.renderTableHeader('Landry\'s', 'landrys', { width: 90 })}
                                {this.renderTableHeader('Landry\'s/Unit', 'per_unit', { width: 110 })}
                                {this.renderTableHeader('Larry', 'larry', { width: 90 })}
                                {this.renderTableHeader('Larry/Unit', 'larry_per_unit', { width: 110 })}
                                {this.renderTableHeader('AC', 'ac', { width: 90 })}
                                {this.renderTableHeader('AC/Unit', 'ac_per_unit', { width: 110 })}
                                {this.renderTableHeader('Robert', 'robert', { width: 90 })}
                                {this.renderTableHeader('PS #', 'ps', { width: 90 })}
                                {this.renderTableHeader('PM #', 'pm', { width: 90 })}
                                <th style={{ width: 80 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="search-th multiple-date">
                                    <DayPickerInput
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        placeholder=""
                                        format="MM/DD/YY"
                                        onDayChange={handleChangeFilter.bind(this, 'start_date')}
                                    />
                                    <div className="float-left ml-1 mr-1">to</div>
                                    <DayPickerInput
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        placeholder=""
                                        format="MM/DD/YY"
                                        onDayChange={handleChangeFilter.bind(this, 'end_date')}
                                    />
                                </th>
                                {this.renderSearchField('description')}
                                {this.renderSearchField('invoice_size_name')}
                                {this.renderSearchField('invoice_stock_name')}
                                {this.renderSearchSelect('ink_name', [
                                    { label: '', value: '' },
                                    { label: '4/0', value: '4/0' },
                                    { label: '4/1', value: '4/1' },
                                    { label: '4/4', value: '4/4' },
                                    { label: '1/1', value: '1/1' },
                                    { label: '1/0', value: '1/0' },
                                    { label: '2/0', value: '2/0' },
                                    { label: '5/5', value: '5/5' }
                                ])}
                                {this.renderSearchField('invoice_machine_name')}
                                <th></th>
                                <th style={{ fontWeight: 'bold', color: '#388e3c' }}>{numeral(landrysTotal).format('$0,0.00')}</th>
                                <th></th>
                                <th style={{ fontWeight: 'bold', color: '#388e3c' }}>{numeral(larryTotal).format('$0,0.00')}</th>
                                <th></th>
                                <th style={{ fontWeight: 'bold', color: '#388e3c' }}>{numeral(acTotal).format('$0,0.00')}</th>
                                <th></th>
                                <th style={{ fontWeight: 'bold', color: '#388e3c' }}>{numeral(robertTotal).format('$0,0.00')}</th>
                                {this.renderSearchField('ps')}
                                {this.renderSearchField('pm')}
                                <th className="search-th"></th>
                            </tr>
                            {this.renderData()}
                        </tbody>
                    </table>
                    <Pagination.Container
                        totalItems={processedElements.length}
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        elementsPerPage={elementsPerPage}
                    />
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Table;
