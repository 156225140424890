import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import Input from './../../common/components/Input';
import Select from './../../common/components/Select';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingForm: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        permissions: PropTypes.array,
        checkPermission: PropTypes.func,
        checkMainPermission: PropTypes.func,
        checkAllPermissions: PropTypes.func
    };

    renderInput(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderPassword(label, name, type, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Input
                    name={name}
                    required={required}
                    formValues={formValues}
                    formErrors={formErrors}
                    type="password"
                    formErrorMessages={formErrorMessages}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                />
            </div>
        );
    }

    renderSelect(label, name, options, required, colClass = 'col') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div className={colClass} key={name}>
                <label>{label} {required ? <span className="required">*</span> : null}</label>
                <Select
                    name={name}
                    required={required}
                    options={options}
                    formValues={formValues}
                    formErrors={formErrors}
                    handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                />
            </div>
        );
    }

    renderPermissionsList() {
        const { permissions, checkPermission, checkMainPermission, formValues } = this.props;
        return permissions.map((permission, index) => {
            if (permission['name'] === 'Timeline Log' && formValues.id !== 61 && formValues.id !== 64) {
                return null;
            }

            let mainSelected = true;
            let someSelected = false;
            for (let i = 0; i < permission['subnames'].length; i++) {
                if (!permission['subnames'][i]['selected']) {
                    mainSelected = false;
                } else {
                    someSelected = true;
                }
            }

            let style = null;
            if (someSelected) {
                style = { color: '#43a047' };
            }

            return (
                <div className="permission-element" key={'permission-element' + index}>
                    <input type="checkbox" onClick={(event) => checkMainPermission(permission['name'], event.target.checked ? 1 : 0)}
                        checked={mainSelected} /> <b style={style}>{permission['name']}</b>
                    {permission['subnames'].map((subname, index) => {
                        return (
                            <div key={'permission-subelement-' + index}>
                                <input type="checkbox" onClick={(event) => checkPermission(permission['name'], subname['name'], event.target.checked ? 1 : 0)}
                                    checked={subname['selected']} /> {subname['name']}
                            </div>
                        );
                    })}
                </div>
            );
        });
    }

    renderProductWindowssList() {
        const { information, selectedProductWindows, toggleProductWindow } = this.props;
        return information['productWindows'].map((productWindow, index) => {
            let checked = false;
            for (let i = 0; i < selectedProductWindows.length; i++) {
                if (parseInt(selectedProductWindows[i]) === parseInt(productWindow['id'])) {
                    checked = true;
                    break;
                }
            }

            return (
                <div className="permission-element" key={'permission-element' + index}>
                    <input type="checkbox" checked={checked} onClick={toggleProductWindow.bind(this, productWindow['id'])} /> {productWindow['name']}
                </div>
            );
        });
    }

    render() {
        const { hideForm, handleSaveElement, isSavingForm, isFetchingElement, formValues,
            checkAllPermissions } = this.props;

        let saveButtonLabel = 'Save';
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let userTypesOptions = [
            { label: 'Select option', value: '' },
            { label: 'Accounting', value: 1 },
            { label: 'CSR', value: 2 },
            { label: 'Data', value: 3 },
            { label: 'Sales', value: 4 },
            { label: 'Purchasing', value: 5 },
            { label: 'Prepress', value: 6 },
            { label: 'Graphics', value: 7 },
            { label: 'Large Format', value: 8 },
            { label: 'Operation', value: 9 },
            { label: 'Production', value: 10 },
            { label: 'Lettershop', value: 11 },
            { label: 'Management', value: 12 },
            { label: 'Warehouse', value: 13 },
            { label: 'CEO', value: 14 },
            { label: 'President', value: 15 },
            { label: 'Marketing', value: 16 },
            { label: 'CFO', value: 17 },
            { label: 'Laser', value: 18 },
            { label: 'Products Only', value: 19 }
        ];

        let form = null;
        if (parseInt(formValues['user_type_1']) === 19) {
            form = (
                <form>
                    <div className="row">
                        <div className="subtitle">User Data</div>
                        <div className="w-100"></div>
                        {this.renderInput('Name', 'name', 'text', true)}
                        {this.renderInput('Email', 'email', 'text', true)}
                        <div className="w-100"></div>
                        {this.renderInput('Username', 'username', 'text', true)}
                        {this.renderPassword('Password', 'password', 'text', formValues['id'] === '')}
                        <div className="w-100"></div>
                        {this.renderInput('Phone', 'phone', 'text', false)}
                        {this.renderSelect('Main Role', 'user_type_1', userTypesOptions, true)}
                        <div className="w-100"></div>
                        {this.renderSelect('Active', 'is_active', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true, 'col-6')}
                        <div className="w-100"></div>
                        <div className="subtitle">
                            Products
                        </div>
                        <div className="w-100"></div>
                        <div className="col">
                            {this.renderProductWindowssList()}
                        </div>
                    </div>
                </form>
            );
        } else {
            form = (
                <form>
                    <div className="row">
                        <div className="subtitle">User Data</div>
                        <div className="w-100"></div>
                        {this.renderInput('Name', 'name', 'text', true)}
                        {this.renderInput('Email', 'email', 'text', true)}
                        <div className="w-100"></div>
                        {this.renderInput('Username', 'username', 'text', true)}
                        {this.renderPassword('Password', 'password', 'text', formValues['id'] === '')}
                        <div className="w-100"></div>
                        {this.renderInput('Phone', 'phone', 'text', false)}
                        {this.renderSelect('Main Role', 'user_type_1', userTypesOptions, true)}
                        <div className="w-100"></div>
                        {this.renderSelect('Second Role', 'user_type_2', userTypesOptions, false)}
                        {this.renderSelect('Third Role', 'user_type_3', userTypesOptions, false)}
                        <div className="w-100"></div>
                        {this.renderSelect('Active', 'is_active', [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }], true, 'col-6')}
                        <div className="w-100"></div>
                        <div className="subtitle">
                            Permissions <div id="check-all"><input type="checkbox" onClick={(event) => checkAllPermissions(event.target.checked ? 1 : 0)} /> Check All</div>
                        </div>
                        <div className="w-100"></div>
                        <div className="col">
                            {this.renderPermissionsList()}
                        </div>
                    </div>
                </form>
            );
        }

        if (isFetchingElement) {
            form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveElement} disabled={isSavingForm}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 1000, maxWidth: 1000 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
