import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class ActivePapers extends Component {
    static propTypes = {
        activePapers: PropTypes.array,
        hideActivePapers: PropTypes.func,
        isSavingActivePapers: PropTypes.bool,
        handleSaveActivePapers: PropTypes.func,
        handleAddActivePaper: PropTypes.func,
        handleDeleteActivePaper: PropTypes.func,
        isFetchingInformation: PropTypes.bool,
        formValues: PropTypes.object,
        handleUpdateFormValue: PropTypes.object,
        handleMultipleChange: PropTypes.func,
        options: PropTypes.object,
        fetching: PropTypes.object
    };

    renderActivePapers() {
        const { activePapers, handleDeleteActivePaper } = this.props;

        let papers = [];
        for (let i = 0; i < activePapers.length; i++) {
            papers.push({
                id: activePapers[i]['id'], name: activePapers[i]['basis_weight'] + '# '
                    + activePapers[i]['paper_name'] + ' ' + activePapers[i]['width'] + 'x' + activePapers[i]['length']
            });
        }

        papers.sort(function (a, b) {
            return a['name'] < b['name'] ? -1 : 1;
        });

        return papers.map((paper, index) => {
            return (
                <div key={index} className="float-left mr-2 mt-1">
                    <span className="badge badge-info bold">
                        {paper['name']} <i className="fas fa-times" onClick={handleDeleteActivePaper.bind(this, paper['id'])}></i>
                    </span>
                </div>
            );
        });
    }

    isActivePaper(id) {
        const { activePapers } = this.props;

        for (let i = 0; i < activePapers.length; i++) {
            if (id === activePapers[i]['id']) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { hideActivePapers, handleSaveActivePapers, isSavingActivePapers, handleAddActivePaper,
            isFetchingInformation, formValues, handleUpdateFormValue, handleMultipleChange, options,
            fetching } = this.props;

        let form = null;
        if (fetching['element']) {
            form = (
                <div className="loading">
                    <i className="fas fa-circle-notch fa-spin" /> Loading...
                </div>
            );
        } else {
            let paperCategoriesOptions = [];
            for (let i = 0; i < options['activePaperCategories'].length; i++) {
                paperCategoriesOptions.push(<option value={options['activePaperCategories'][i]['value']}>{options['activePaperCategories'][i]['label']}</option>);
            }

            let paperWeightsOptions = [];
            for (let i = 0; i < options['activePaperWeights'].length; i++) {
                paperWeightsOptions.push(<option value={options['activePaperWeights'][i]['value']}>{options['activePaperWeights'][i]['label']}</option>);
            }

            let papersOptions = [];
            for (let i = 0; i < options['activePapers'].length; i++) {
                if (!this.isActivePaper(options['activePapers'][i]['value'])) {
                    papersOptions.push(<option value={options['activePapers'][i]['value']}>{options['activePapers'][i]['label']}</option>);
                }
            }

            let paperCategoriesLoadingIcon = null;
            if (fetching['activePaperCategories']) {
                paperCategoriesLoadingIcon = <div className="top-loading-icon"><i className="fas fa-circle-notch fa-spin" /></div>;
            }

            let paperWeightsLoadingIcon = null;
            if (fetching['activePaperWeights']) {
                paperWeightsLoadingIcon = <div className="top-loading-icon"><i className="fas fa-circle-notch fa-spin" /></div>;
            }

            let papersLoadingIcon = null;
            if (fetching['activePapers']) {
                papersLoadingIcon = <div className="top-loading-icon"><i className="fas fa-circle-notch fa-spin" /></div>;
            }

            form = (
                <div className="row">
                    <div className="col-4">
                        Paper Categories:
                        {paperCategoriesLoadingIcon}
                        <select name="paper_category_id" value={formValues['paper_category_id']} onChange={handleUpdateFormValue.bind(this, 'text', true)}>
                            {paperCategoriesOptions}
                        </select>
                    </div>
                    <div className="col-3">
                        Paper Weights:
                        {paperWeightsLoadingIcon}
                        <select name="paper_weight" value={formValues['paper_weight']} onChange={handleUpdateFormValue.bind(this, 'text', true)}>
                            {paperWeightsOptions}
                        </select>
                    </div>
                    <div className="col-5">
                        Papers:
                        {papersLoadingIcon}
                        <select name="active_paper_ids" multiple size={5} value={formValues['active_paper_ids']}
                            onChange={handleMultipleChange.bind(this, 'active_paper_ids')}>
                            {papersOptions}
                        </select>
                    </div>
                    <div className="w-100"></div>
                    <div className="col">
                        <div className="add-button mt-2" onClick={handleAddActivePaper} style={{ width: 100, fontSize: 13 }}><i className="fas fa-plus" /> Add</div>
                    </div>
                    <div className="w-100"></div>
                    <div className="col">
                        <div className="active-papers">
                            {this.renderActivePapers()}
                        </div>
                    </div>
                </div>
            );

            if (isFetchingInformation) {
                form = null;
            }
        }

        let saveButtonLabel = 'Save';
        if (isSavingActivePapers) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveActivePapers} disabled={isSavingActivePapers}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideActivePapers}>Close</button>
        ];
        return (
            <Dialog
                name="active-papers"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{form}</div>
            </Dialog>
        );
    }
}

export default ActivePapers;
