import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import numeral from 'numeral';
import moment from 'moment';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class Accepted extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        handleShowAccept: PropTypes.func,
        fetching: PropTypes.bool,
        handleShowForm: PropTypes.func,
        handleShowDelete: PropTypes.func,
        selectedPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleChangeAttribute: PropTypes.func,
        handleInlineChange: PropTypes.func,
        handleViewProperty: PropTypes.func,
        handleChangeFilter: PropTypes.func,
        user: PropTypes.object
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn['accept']['column'] === name) {
            if (sortType['accept']['type'] === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name, 'accept')}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { elements, fetching, handleShowForm, handleShowDelete, handleShowAccept } = this.props;

        if (fetching['accept']) {
            return (
                <tr>
                    <td colSpan="13"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (elements['accept'].length === 0) {
            return (
                <tr>
                    <td colSpan="13">No Results</td>
                </tr>
            );
        }

        let data = [];
        for (let i = 0; i < elements['accept'].length; i++) {
            let check = null;
            if (elements['accept'][i]['status'] === 2) {
                check = (
                    <Tooltip title="Accept" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-clipboard-check" style={{ fontSize: 20 }} onClick={handleShowAccept.bind(this, elements['accept'][i]['id'], 'accept')} />
                    </Tooltip>
                );
            } else if (elements['accept'][i]['status'] === 3 || elements['accept'][i]['status'] === 5) {
                check = (
                    <Tooltip title="Accepted" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-clipboard-check" style={{ fontSize: 20, color: '#4caf50' }} />
                    </Tooltip>
                );
            }

            data.push(
                <tr key={i}>
                    <td>{elements['accept'][i]['customer']}</td>
                    <td>{elements['accept'][i]['description']}</td>
                    <td>{numeral(elements['accept'][i]['quantity']).format('0,0')}</td>
                    <td>{elements['accept'][i]['ps_number']}</td>
                    <td>{elements['accept'][i]['pm_number']}</td>
                    <td>{elements['accept'][i]['received_at'] == null ? null : moment(elements['accept'][i]['received_at']).subtract(4, 'hours').format('MM/DD/YYYY h:mm a')}</td>
                    <td>{elements['accept'][i]['created_by_name']}</td>
                    <td>{elements['accept'][i]['received_by_name']}</td>
                    <td>{elements['accept'][i]['accepted_by_name']}</td>
                    <td>{elements['accept'][i]['notification_names']}</td>
                    <td>{elements['accept'][i]['notes']}</td>
                    <td>
                        <div className="actions">
                            {check}
                            <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-edit" onClick={handleShowForm.bind(this, elements['accept'][i].id, null, 'accept')} />
                            </Tooltip>
                            <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                                <i className="fas fa-trash" onClick={handleShowDelete.bind(this, elements['accept'][i].id)} />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    handleKey(name, event) {
        const { handleChangeFilter } = this.props;
        if (event.key === 'Enter') {
            handleChangeFilter(name, 'accept', event);
        }
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onBlur={handleChangeFilter.bind(this, name, 'accept')} onKeyPress={this.handleKey.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name, 'accept')} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { handleSetActivePage, pagination } = this.props;
        return (
            <div className="table-container table-hover" style={{ borderTop: '1px solid #cfd3d7' }}>
                <div className="inout-table-title">Need to Accept</div>
                <table id="titles-table" className="table table-sm table-hover">
                    <thead>
                        <tr>
                            {this.renderTableHeader('Customer', 'customer')}
                            {this.renderTableHeader('Description', 'description')}
                            {this.renderTableHeader('Quantity', 'quantity', { width: 90 })}
                            {this.renderTableHeader('PS #', 'ps_number', { width: 90 })}
                            {this.renderTableHeader('PM #', 'pm_number', { width: 90 })}
                            {this.renderTableHeader('Received At', 'received_at', { width: 160 })}
                            {this.renderTableHeader('Created By', 'created_by_name')}
                            {this.renderTableHeader('Received By', 'received_by_name')}
                            {this.renderTableHeader('Accepted By', 'accepted_by_name')}
                            {this.renderTableHeader('Notify', 'notification_names')}
                            {this.renderTableHeader('Notes', 'notes')}
                            <th style={{ width: 110 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {this.renderSearchField('customer')}
                            {this.renderSearchField('description')}
                            {this.renderSearchField('quantity')}
                            {this.renderSearchField('ps_number')}
                            {this.renderSearchField('pm_number')}
                            {this.renderSearchField('received_at')}
                            {this.renderSearchField('created_by_name')}
                            {this.renderSearchField('received_by_name')}
                            {this.renderSearchField('accepted_by_name')}
                            {this.renderSearchField('notification_names')}
                            {this.renderSearchField('notes')}
                            <th className="search-th"></th>
                        </tr>
                        {this.renderData()}
                    </tbody>
                </table>
                <Pagination.Container
                    totalItems={pagination.accept.totalItems}
                    totalPages={pagination.accept.totalPages}
                    selectedPage={pagination.accept.selectedPage}
                    elementsPerPage={10}
                    handleSetActivePage={handleSetActivePage.bind(this, 'accept')}
                />
                <div className="clearfix"></div>
            </div>
        );
    }
}

export default Accepted;
