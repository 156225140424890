import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import numeral from 'numeral';
import moment from 'moment';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class Unclaimed extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleShowAccept: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        fetching: PropTypes.bool,
        handleShowForm: PropTypes.func,
        handleShowDelete: PropTypes.func,
        selectedPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleChangeAttribute: PropTypes.func,
        handleInlineChange: PropTypes.func,
        handleViewProperty: PropTypes.func,
        handleChangeFilter: PropTypes.func,
        processedElements: PropTypes.array,
        user: PropTypes.object
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn['unclaim']['column'] === name) {
            if (sortType['unclaim']['type'] === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name, 'unclaim')}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { elements, fetching, handleShowForm, handleShowDelete, user, handleShowAccept } = this.props;

        if (fetching['unclaim']) {
            return (
                <tr>
                    <td colSpan="10"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (elements['unclaim'].length === 0) {
            return (
                <tr>
                    <td colSpan="10">No Results</td>
                </tr>
            );
        }

        let data = [];
        for (let i = 0; i < elements['unclaim'].length; i++) {
            let actions = null;
            if (user['is_in_out_warehouse'] && elements['unclaim'][i]['status'] === 4 && elements['unclaim'][i]['created_by'] === user['id']) {
                actions = (
                    <div className="actions">
                        <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-edit" onClick={handleShowForm.bind(this, elements['unclaim'][i]['id'], null, 'unclaim')} />
                        </Tooltip>
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={handleShowDelete.bind(this, elements['unclaim'][i]['id'])} />
                        </Tooltip>
                    </div>
                );
            } else if (user['is_in_out_sales'] && elements['unclaim'][i]['status'] === 4) {
                actions = (
                    <div className="actions">
                        <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-edit" onClick={handleShowForm.bind(this, elements['unclaim'][i]['id'], null, 'unclaim')} />
                        </Tooltip>
                        <Tooltip title="Accept" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-clipboard-check" style={{ fontSize: 20 }} onClick={handleShowAccept.bind(this, elements['unclaim'][i]['id'], 'unclaim')} />
                        </Tooltip>
                    </div>
                );
            }

            data.push(
                <tr key={i}>
                    <td>{elements['unclaim'][i]['customer']}</td>
                    <td>{elements['unclaim'][i]['description']}</td>
                    <td>{numeral(elements['unclaim'][i]['quantity']).format('0,0')}</td>
                    <td>{elements['unclaim'][i]['ps_number']}</td>
                    <td>{elements['unclaim'][i]['pm_number']}</td>
                    <td>{moment(elements['unclaim'][i]['created_at']).subtract(4, 'hours').format('MM/DD/YYYY h:mm a')}</td>
                    <td>{elements['unclaim'][i]['created_by_name']}</td>
                    <td>{elements['unclaim'][i]['accepted_by_name']}</td>
                    <td>{elements['unclaim'][i]['notes']}</td>
                    <td>{actions}</td>
                </tr>
            );
        }

        return data;
    }

    handleKey(name, event) {
        const { handleChangeFilter } = this.props;
        if (event.key === 'Enter') {
            handleChangeFilter(name, 'unclaim', event);
        }
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onBlur={handleChangeFilter.bind(this, name, 'unclaim')} onKeyPress={this.handleKey.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name, 'unclaim')} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { pagination, handleSetActivePage } = this.props;
        return (
            <div className="table-container table-hover" style={{ borderTop: '1px solid #cfd3d7' }}>
                <div className="inout-table-title">Unclaimed Delivery</div>
                <table id="titles-table" className="table table-sm table-hover">
                    <thead>
                        <tr>
                            {this.renderTableHeader('Customer', 'customer')}
                            {this.renderTableHeader('Description', 'description')}
                            {this.renderTableHeader('Quantity', 'quantity', { width: 90 })}
                            {this.renderTableHeader('PS #', 'ps_number', { width: 90 })}
                            {this.renderTableHeader('PM #', 'pm_number', { width: 90 })}
                            {this.renderTableHeader('Created At', 'created_at', { width: 160 })}
                            {this.renderTableHeader('Created By', 'created_by_name')}
                            {this.renderTableHeader('Accepted By', 'accepted_by_name')}
                            {this.renderTableHeader('Notes', 'notes')}
                            <th style={{ width: 110 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {this.renderSearchField('customer')}
                            {this.renderSearchField('description')}
                            {this.renderSearchField('quantity')}
                            {this.renderSearchField('ps_number')}
                            {this.renderSearchField('pm_number')}
                            {this.renderSearchField('created_at')}
                            {this.renderSearchField('created_by_name')}
                            {this.renderSearchField('accepted_by_name')}
                            {this.renderSearchField('notes')}
                            <th className="search-th"></th>
                        </tr>
                        {this.renderData()}
                    </tbody>
                </table>
                <Pagination.Container
                    totalItems={pagination.unclaim.totalItems}
                    totalPages={pagination.unclaim.totalPages}
                    selectedPage={pagination.unclaim.selectedPage}
                    elementsPerPage={10}
                    handleSetActivePage={handleSetActivePage.bind(this, 'unclaim')}
                />
                <div className="clearfix"></div>
            </div>
        );
    }
}

export default Unclaimed;
