import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import { SET_ACTIVE_PAGE } from './../common/Pagination/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            name: '',
            email: '',
            username: '',
            password: '',
            is_active: 1,
            type: '',
            phone: '',
            user_type_1: '',
            user_type_2: '',
            user_type_3: '',
            user_discount_id: ''
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        selectedProductWindows: [],
        isFetching: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'name',
        type: 'asc'
    },
    filter: {
        fields: []
    },
    information: {
        productWindows: [],
        isFetching: false
    },
    permissions: [
        {
            name: 'Dashboard', subnames: [
                { name: 'View', selected: 0 }
            ]
        },
        {
            name: 'Quotes', subnames: [
                { name: 'View All', selected: 0 },
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Orders', subnames: [
                { name: 'View All', selected: 0 },
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Production', subnames: [
                { name: 'View All', selected: 0 },
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Shipping', subnames: [
                { name: 'View All', selected: 0 },
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Customers', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Vendors', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Products', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Product Categories', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Product Dashboards', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Change Order', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Create', selected: 0 },
                { name: 'Enter Costs', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Deposits', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Landry\'s', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Shipping/Receiving', subnames: [
                { name: 'Warehouse', selected: 0 },
                { name: 'Sales/CSR', selected: 0 }
            ]
        },
        {
            name: 'Users', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Customer Discounts', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Indutries', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Departments', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Turnaround', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Presses', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Inks', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Digital', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Cutting', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Die-Cutting', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Drilling', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Scoring', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Padding', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Paper Categories', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Paper Vendors', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Papers', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Folding Machines', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Folding Types', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Saddle Stitching Machines', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Perfect Binding Machines', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Coil Binding Machines', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Corner Staple Machines', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Side Staple Machines', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Spiral Binding Machines', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Banding Types', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Banding Machines', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Wrapping Types', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Wrapping Machines', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Shrink Wrapping Machines', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Shrink Wrapping Types', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Shrink Wrapping Categories', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Packages', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Mailing Operations', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Flat Hand Gathering', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Multi-Page Hand Gathering', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Counting', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Large Format Machines', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Substrates', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Laminates', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Accessories', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Data Processing', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Inkjet', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Inserting', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Perfect Match', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Wafer Seal', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Affix Labels', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Certified Mail', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Art', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Handwork', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Pressure Seal Forms', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Index Tabs', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Labels', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'NCR Forms', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Timeline Log', subnames: [
                { name: 'View', selected: 0 },
                { name: 'Edit', selected: 0 },
                { name: 'Delete', selected: 0 }
            ]
        },
        {
            name: 'Products Catalog', subnames: [
                { name: 'View', selected: 0 }
            ]
        }
    ]
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    if (window.location['pathname'] !== '/users') {
        state = INITIAL_STATE;
        return state.toJS();
    }

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], [])
                .setIn(['elements', 'processedElements'], [])
                .setIn(['pagination', 'totalPages'], 0)
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20))
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.CHANGE_ATTRIBUTE:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                name: '',
                email: '',
                username: '',
                password: '',
                is_active: 1,
                type: '',
                phone: '',
                user_type_1: '',
                user_type_2: '',
                user_type_3: '',
                user_discount_id: ''
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .updateIn(['activeElement', 'selectedProductWindows'], (selectedProductWindows) => {
                    let ids = action.payload.product_windows.map((productWindow) => productWindow['id']);
                    return Immutable.List(ids);
                })
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'name'], action.payload.name)
                .setIn(['form', 'formValues', 'email'], action.payload.email)
                .setIn(['form', 'formValues', 'username'], action.payload.username)
                .setIn(['form', 'formValues', 'password'], '')
                .setIn(['form', 'formValues', 'is_active'], action.payload.is_active)
                .setIn(['form', 'formValues', 'is_active'], action.payload.is_active)
                .setIn(['form', 'formValues', 'type'], action.payload.type)
                .setIn(['form', 'formValues', 'phone'], action.payload.phone)
                .setIn(['form', 'formValues', 'user_type'], action.payload.user_type == null ? '' : action.payload.user_type)
                .setIn(['form', 'formValues', 'user_type_1'], action.payload.user_type_1 == null ? '' : action.payload.user_type_1)
                .setIn(['form', 'formValues', 'user_type_2'], action.payload.user_type_2 == null ? '' : action.payload.user_type_2)
                .setIn(['form', 'formValues', 'user_type_3'], action.payload.user_type_3 == null ? '' : action.payload.user_type_3);
            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.PROCESS_ELEMENTS:
            state = state.setIn(['elements', 'processedElements'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20));
            break;

        case actionTypes.CHECK_PERMISSION:
            state = state.updateIn(['permissions'], (permissions) => {
                let index1 = permissions.findIndex(permission => permission.get('name') === action.payload.name);
                let permission = permissions.get(index1);
                let index2 = permission.get('subnames').findIndex(element => element.get('name') === action.payload.subname);
                return permissions.setIn([index1, 'subnames', index2, 'selected'], action.payload.value);
            });
            break;

        case actionTypes.CHECK_MAIN_PERMISSION:
            state = state.updateIn(['permissions'], (permissions) => {
                let index1 = permissions.findIndex(permission => permission.get('name') === action.payload.name);
                let subnames = permissions.get(index1).get('subnames').toJS();

                for (let i = 0; i < subnames.length; i++) {
                    subnames[i]['selected'] = action.payload.value;
                }

                return permissions.setIn([index1, 'subnames'], subnames);
            });
            break;

        case actionTypes.CHECK_ALL_PERMISSIONS:
            state = state.updateIn(['permissions'], (permissions) => {
                let permissionsArray = permissions.toJS();
                for (let i = 0; i < permissionsArray.length; i++) {
                    for (let j = 0; j < permissionsArray[i]['subnames'].length; j++) {
                        permissionsArray[i]['subnames'][j]['selected'] = action.payload.value;
                    }
                }
                return permissionsArray;
            });
            break;

        case actionTypes.SETUP_PERMISSIONS:
            state = state.setIn(['permissions'], action.payload);
            break;

        case actionTypes.FETCH_ELEMENT:
            state = state.setIn(['activeElement', 'isFetching'], true);
            break;

        case actionTypes.FETCH_ELEMENT_SUCCESS:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.FETCH_ELEMENT_FAILURE:
            state = state.setIn(['activeElement', 'isFetching'], false);
            break;

        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
                .setIn(['information', 'productWindows'], []);
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'productWindows'], action.payload.productWindows);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        case actionTypes.TOGGLE_PRODUCT_WINDOW:
            state = state.updateIn(['activeElement', 'selectedProductWindows'], (selectedProductWindows) => {
                const element = selectedProductWindows.find((productWindow) => parseInt(productWindow) === parseInt(action.payload));
                if (element === undefined) {
                    return selectedProductWindows.push(action.payload);
                } else {
                    const index = selectedProductWindows.indexOf(element);
                    return selectedProductWindows.delete(index);
                }
            });
            break;

        default:
            break;
    }

    return state.toJS();
}
