import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class EstimatePricing extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingEstimate: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        handleEstimate: PropTypes.func,
        isLoadingEstimate: PropTypes.bool,
        estimateResults: PropTypes.array,
        handleSaveEstimate: PropTypes.array,
        activeProduct: PropTypes.object,
        handleAccessoryClick: PropTypes.func,
        estimateAccessories: PropTypes.array,
        handleUpdateOutsideService: PropTypes.func,
        handleAddOutsideService: PropTypes.func,
        handleDeleteOutsideService: PropTypes.func,
        information: PropTypes.object,
        options: PropTypes.object,
        fetching: PropTypes.object,
        renderSheets: PropTypes.bool,
        isMailing: PropTypes.bool
    };

    renderSheets() {
        const { estimateResults, formValues } = this.props;

        let sheets = [null, null, null];
        let times = [null, null, null];
        if (estimateResults.length === 3) {
            for (let i = 0; i < 3; i++) {
                if (estimateResults[i]['results'].length > 0) {
                    let result = estimateResults[i]['results'][0];
                    if (parseInt(formValues['estimate_type']) === 2) {
                        let impositionsSheets = 0;
                        for (let j = 0; j < result['impositions_information'].length; j++) {
                            impositionsSheets += result['impositions_information'][j]['paper_information']['total_pars'];
                        }
                        sheets[i] = numeral(impositionsSheets).format('0,0');
                        times[i] = numeral(result['totals']['total_time']).format('0.[00]');
                    } else {
                        sheets[i] = numeral(result['paper_information']['total_pars']).format('0,0');
                        times[i] = numeral(result['totals']['total_time']).format('0.[00]');
                    }
                }
            }
        }

        return (
            <tr>
                <td></td>
                <td className="text-right" style={{ fontWeight: 500 }}>Press Sheets:</td>
                <td colSpan="2" className="text-right cost-td">{sheets[0]} ({times[0]} hrs)</td>
                <td colSpan="2" className="text-right cost-td">{sheets[1]} ({times[1]} hrs)</td>
                <td colSpan="2" className="text-right cost-td">{sheets[2]} ({times[2]} hrs)</td>
            </tr>
        );
    }

    loadPrices(name) {
        const { formValues } = this.props;

        let prices = [];
        for (let i = 0; i < 3; i++) {
            if (formValues[name + (i + 1)] !== '' && formValues[name + (i + 1)] !== null) {
                prices.push(parseFloat(formValues[name + (i + 1)]));
            } else {
                prices.push(0);
            }
        }

        return prices;
    }

    renderBinding(estimateResults, results) {
        const { handleUpdateFormValue, formValues } = this.props;

        if (parseInt(formValues['estimate_type']) !== 2) {
            return null;
        }

        let bindingName = results[0]['binding_information']['name'];
        let bindingCosts = [0, 0, 0];

        for (let i = 0; i < 3; i++) {
            bindingCosts[i] += results[i]['binding_information']['total_cost'];
        }

        return (
            <tr key="binding">
                <td>Binding</td>
                <td>{bindingName}</td>
                <td className="text-right cost-td">{this.renderBindingTooltip(bindingCosts[0], estimateResults[0])}</td>
                <td className="price-td">
                    $<input type="text" name="estimate_binding_price_1" value={formValues['estimate_binding_price_1']}
                        onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                </td>
                <td className="text-right cost-td">{this.renderBindingTooltip(bindingCosts[1], estimateResults[1])}</td>
                <td className="price-td">
                    $<input type="text" name="estimate_binding_price_2" value={formValues['estimate_binding_price_2']}
                        onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                </td>
                <td className="text-right cost-td">{this.renderBindingTooltip(bindingCosts[2], estimateResults[2])}</td>
                <td className="price-td">
                    $<input type="text" name="estimate_binding_price_3" value={formValues['estimate_binding_price_3']}
                        onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                </td>
            </tr>
        );
    }

    renderFolding(estimateResults, results) {
        const { handleUpdateFormValue, formValues } = this.props;

        if (parseInt(formValues['estimate_type']) === 4) {
            return null;
        }

        let foldingName = [];
        let foldingCosts = [0, 0, 0];
        if (parseInt(formValues['estimate_type']) === 1 && results[0]['folding_information']['id'] != null) {
            foldingName.push(<div>{results[0]['folding_information']['name']}</div>);

            for (let i = 0; i < 3; i++) {
                foldingCosts[i] = results[i]['folding_information']['total_cost'];
            }
        } else if (parseInt(formValues['estimate_type']) === 2) {
            foldingName.push(<div>{results[0]['impositions_information'][0]['folding_information']['name']}</div>);

            for (let i = 0; i < 3; i++) {
                let foldingCost = 0;
                for (let j = 0; j < results[i]['impositions_information'].length; j++) {
                    foldingCost += results[i]['impositions_information'][j]['folding_information']['total_cost'];
                }

                if (this.hasCover()) {
                    foldingCost += estimateResults[i]['cover_results'][0]['folding_information']['total_cost'];
                }

                foldingCosts[i] = foldingCost;
            }
        } else if (parseInt(formValues['estimate_type']) === 3 && results[0]['folding_information']['id'] != null) {
            foldingName.push(<div>{results[0]['folding_information']['name']}</div>);

            for (let i = 0; i < 3; i++) {
                foldingCosts[i] = results[i]['folding_information']['total_cost'];
            }
        }

        if (foldingName.length === 0) {
            return null;
        }

        return (
            <tr key="folding">
                <td>Folding</td>
                <td>{foldingName}</td>
                <td className="text-right cost-td">{this.renderFoldingTooltip(foldingCosts[0], estimateResults[0])}</td>
                <td className="price-td">
                    $<input type="text" name="estimate_folding_price_1" value={formValues['estimate_folding_price_1']}
                        onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                </td>
                <td className="text-right cost-td">{this.renderFoldingTooltip(foldingCosts[1], estimateResults[1])}</td>
                <td className="price-td">
                    $<input type="text" name="estimate_folding_price_2" value={formValues['estimate_folding_price_2']}
                        onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                </td>
                <td className="text-right cost-td">{this.renderFoldingTooltip(foldingCosts[2], estimateResults[2])}</td>
                <td className="price-td">
                    $<input type="text" name="estimate_folding_price_3" value={formValues['estimate_folding_price_3']}
                        onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                </td>
            </tr>
        );
    }

    renderTooltip(cost, tooltipHtml) {
        return (
            <Tooltip html={tooltipHtml} position="top" arrow size="small " className="action-tooltip">
                {numeral(cost).format('$0,0.00')}
            </Tooltip>
        );
    }

    renderPressTooltip(pressCost, estimateResult) {
        const { formValues } = this.props;

        let tooltipHtml = null;
        if (parseInt(formValues['estimate_type']) === 1) {
            let pressInformation = estimateResult['results'][0]['press_information'];
            let paperInformation = estimateResult['results'][0]['paper_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Name: {pressInformation['name']}</div>
                    <div>BHR: {numeral(pressInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(pressInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Ink Units: {pressInformation['ink_units']}</div>
                    <div>Max. Paper Size: {pressInformation['max_size_sheet']['width']}x{pressInformation['max_size_sheet']['length']}</div>
                    <div>Total Impressions: {numeral(paperInformation['total_impressions']).format('0,0')}</div>
                    <div>Speed: {numeral(pressInformation['speed']).format('0,0')} pss/hour</div>
                    <div>Total Forms: {numeral(paperInformation['total_forms']).format('0,0')}</div>
                    <div>Total Plates: {pressInformation['total_plates']}</div>
                    <div>Total Plates Cost: {numeral(pressInformation['total_plates_cost']).format('$0,0.00')}</div>
                    <div>Total Running Cost: {numeral(pressInformation['total_running_cost']).format('$0,0.00')}</div>
                    <div>Total Setup Cost: {numeral(pressInformation['total_setup_cost']).format('$0,0.00')}</div>
                    <div>Total Cost: {numeral(pressInformation['total_cost']).format('$0,0.00')}</div>
                    <div>Total Time: {numeral(pressInformation['total_time']).format('0.[00]')} hrs</div>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let coverInformation = null;
            let interior2Information = null;
            let pressInformation = null;
            let paperInformation = null;

            if (this.hasCover()) {
                pressInformation = estimateResult['cover_results'][0]['press_information'];
                paperInformation = estimateResult['cover_results'][0]['paper_information'];
                coverInformation = (
                    <div className="text-left tooltip-column">
                        <div><b>Cover</b></div>
                        <div>Name: {pressInformation['name']}</div>
                        <div>BHR: {numeral(pressInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(pressInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Ink Units: {pressInformation['ink_units']}</div>
                        <div>Max. Paper Size: {pressInformation['max_size_sheet']['width']}x{pressInformation['max_size_sheet']['length']}</div>
                        <div>Total Impressions: {numeral(paperInformation['total_impressions']).format('0,0')}</div>
                        <div>Speed: {numeral(pressInformation['speed']).format('0,0')} pss/hour</div>
                        <div>Total Forms: {numeral(paperInformation['total_forms']).format('0,0')}</div>
                        <div>Total Plates: {pressInformation['total_plates']}</div>
                        <div>Total Plates Cost: {numeral(pressInformation['total_plates_cost']).format('$0,0.00')}</div>
                        <div>Total Running Cost: {numeral(pressInformation['total_running_cost']).format('$0,0.00')}</div>
                        <div>Total Setup Cost: {numeral(pressInformation['total_setup_cost']).format('$0,0.00')}</div>
                        <div>Total Cost: {numeral(pressInformation['total_cost']).format('$0,0.00')}</div>
                        <div>Total Time: {numeral(pressInformation['total_time']).format('0.[00]')} hrs</div>
                    </div>
                );
            }

            pressInformation = estimateResult['results'][0]['impositions_information'][0]['press_information'];
            paperInformation = estimateResult['results'][0]['impositions_information'][0]['paper_information'];
            let interior1Information = (
                <div className="text-left tooltip-column">
                    <div><b>Interior 1</b></div>
                    <div>Name: {pressInformation['name']}</div>
                    <div>BHR: {numeral(pressInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(pressInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Ink Units: {pressInformation['ink_units']}</div>
                    <div>Max. Paper Size: {pressInformation['max_size_sheet']['width']}x{pressInformation['max_size_sheet']['length']}</div>
                    <div>Total Impressions: {numeral(paperInformation['total_impressions']).format('0,0')}</div>
                    <div>Speed: {numeral(pressInformation['speed']).format('0,0')} pss/hour</div>
                    <div>Total Forms: {numeral(paperInformation['total_forms']).format('0,0')}</div>
                    <div>Total Plates: {pressInformation['total_plates']}</div>
                    <div>Total Plates Cost: {numeral(pressInformation['total_plates_cost']).format('$0,0.00')}</div>
                    <div>Total Running Cost: {numeral(pressInformation['total_running_cost']).format('$0,0.00')}</div>
                    <div>Total Setup Cost: {numeral(pressInformation['total_setup_cost']).format('$0,0.00')}</div>
                    <div>Total Cost: {numeral(pressInformation['total_cost']).format('$0,0.00')}</div>
                    <div>Total Time: {numeral(pressInformation['total_time']).format('0.[00]')} hrs</div>
                </div>
            );

            if (estimateResult['results'][0]['impositions_information'].length === 2) {
                pressInformation = estimateResult['results'][0]['impositions_information'][1]['press_information'];
                paperInformation = estimateResult['results'][0]['impositions_information'][1]['paper_information'];
                interior2Information = (
                    <div className="text-left tooltip-column">
                        <div><b>Interior 2 (leftover)</b></div>
                        <div>Name: {pressInformation['name']}</div>
                        <div>BHR: {numeral(pressInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(pressInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Ink Units: {pressInformation['ink_units']}</div>
                        <div>Max. Paper Size: {pressInformation['max_size_sheet']['width']}x{pressInformation['max_size_sheet']['length']}</div>
                        <div>Total Impressions: {numeral(paperInformation['total_impressions']).format('0,0')}</div>
                        <div>Speed: {numeral(pressInformation['speed']).format('0,0')} pss/hour</div>
                        <div>Total Forms: {numeral(paperInformation['total_forms']).format('0,0')}</div>
                        <div>Total Plates: {pressInformation['total_plates']}</div>
                        <div>Total Plates Cost: {numeral(pressInformation['total_plates_cost']).format('$0,0.00')}</div>
                        <div>Total Running Cost: {numeral(pressInformation['total_running_cost']).format('$0,0.00')}</div>
                        <div>Total Setup Cost: {numeral(pressInformation['total_setup_cost']).format('$0,0.00')}</div>
                        <div>Total Cost: {numeral(pressInformation['total_cost']).format('$0,0.00')}</div>
                        <div>Total Time: {numeral(pressInformation['total_time']).format('0.[00]')} hrs</div>
                    </div>
                );
            }

            tooltipHtml = (
                <div className="text-left">
                    <table border="1">
                        <tr>
                            <td>{coverInformation}</td>
                            <td>{interior1Information}</td>
                            <td>{interior2Information}</td>
                        </tr>
                    </table>
                </div>
            );
        }

        return this.renderTooltip(pressCost, tooltipHtml);
    }

    renderDigitalMachineTooltip(digitalMachineCost, estimateResult) {
        const digitalInformation = estimateResult['results'][0]['digital_machine_information'];
        const tooltipHtml = (
            <div className="text-left">
                <div>Name: {digitalInformation['name']}</div>
                <div>BHR: {numeral(digitalInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(digitalInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Cost/Click: {numeral(digitalInformation['cost_per_click']).format('$0,0.00')}</div>
                <div>Max. Paper Size: {digitalInformation['max_size_sheet']['width']}x{digitalInformation['max_size_sheet']['length']}</div>
                <div>Total Clicks: {numeral(digitalInformation['impressions']).format('0,0')}</div>
                <div>Discount: {numeral(digitalInformation['discount']).format('0.[00]%')}</div>
                <div>Speed: {numeral(digitalInformation['speed']).format('0,0')} sheets/hour</div>
                <div>Total Cost: {numeral(digitalInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(digitalInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(digitalMachineCost, tooltipHtml);
    }

    renderPaperTooltip(paperCost, estimateResult) {
        const { formValues } = this.props;

        let tooltipHtml = null;
        if (parseInt(formValues['estimate_type']) === 1) {
            let paperInformation = estimateResult['results'][0]['paper_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Name: {paperInformation['basis_weight']}# {paperInformation['name']} {paperInformation['pars']['width']}x{paperInformation['pars']['length']}</div>
                    <div>Price/CWT: {numeral(paperInformation['price_cwt']).format('$0,0.00')}</div>
                    <div>Price/M-Sheets: {numeral(paperInformation['price_m_sheets']).format('$0,0.00')}</div>
                    <div>Number-up: {paperInformation['pss']['up']}</div>
                    <div>Press Sheets (pss): {numeral(paperInformation['pss']['number_pss']).format('0,0')}</div>
                    <div>Press Make Ready (pss): {numeral(paperInformation['press_make_ready']).format('0,0')}</div>
                    <div>Waste (pss): {numeral(paperInformation['pss']['paper_waste']).format('0,0')}</div>
                    <div>Bindery Make Ready (pss): {numeral(paperInformation['pss']['bindery_make_ready']).format('0,0')}</div>
                    <div>Total Pss: {numeral(paperInformation['pss']['total_pss']).format('0,0')}</div>
                    <div>Number-Out: {paperInformation['pss']['out']}</div>
                    <div>Total Pars: {numeral(paperInformation['total_pars']).format('0,0')}</div>
                    <div>Total Poundage: {numeral(paperInformation['total_poundage']).format('0,0.[00]')} lbs</div>
                    <div>Total Cost: {numeral(paperInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let coverInformation = null;
            let interior2Information = null;
            let paperInformation = null;

            if (this.hasCover()) {
                paperInformation = estimateResult['cover_results'][0]['paper_information'];
                coverInformation = (
                    <div className="text-left tooltip-column">
                        <div><b>Cover</b></div>
                        <div>Name: {paperInformation['basis_weight']}# {paperInformation['name']} {paperInformation['pars']['width']}x{paperInformation['pars']['length']}</div>
                        <div>Price/CWT: {numeral(paperInformation['price_cwt']).format('$0,0.00')}</div>
                        <div>Price/M-Sheets: {numeral(paperInformation['price_m_sheets']).format('$0,0.00')}</div>
                        <div>Number-up: {paperInformation['pss']['up']}</div>
                        <div>Press Sheets (pss): {numeral(paperInformation['pss']['number_pss']).format('0,0')}</div>
                        <div>Press Make Ready (pss): {numeral(paperInformation['press_make_ready']).format('0,0')}</div>
                        <div>Waste (pss): {numeral(paperInformation['pss']['paper_waste']).format('0,0')}</div>
                        <div>Bindery Make Ready (pss): {numeral(paperInformation['pss']['bindery_make_ready']).format('0,0')}</div>
                        <div>Total Pss: {numeral(paperInformation['pss']['total_pss']).format('0,0')}</div>
                        <div>Number-Out: {paperInformation['pss']['out']}</div>
                        <div>Total Pars: {numeral(paperInformation['total_pars']).format('0,0')}</div>
                        <div>Total Poundage: {numeral(paperInformation['total_poundage']).format('0,0.[00]')} lbs</div>
                        <div>Total Cost: {numeral(paperInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            paperInformation = estimateResult['results'][0]['impositions_information'][0]['paper_information'];
            let interior1Information = (
                <div className="text-left tooltip-column">
                    <div><b>Interior 1</b></div>
                    <div>Name: {paperInformation['basis_weight']}# {paperInformation['name']} {paperInformation['pars']['width']}x{paperInformation['pars']['length']}</div>
                    <div>Price/CWT: {numeral(paperInformation['price_cwt']).format('$0,0.00')}</div>
                    <div>Price/M-Sheets: {numeral(paperInformation['price_m_sheets']).format('$0,0.00')}</div>
                    <div>Number-up: {paperInformation['pss']['up']}</div>
                    <div>Press Sheets (pss): {numeral(paperInformation['pss']['number_pss']).format('0,0')}</div>
                    <div>Press Make Ready (pss): {numeral(paperInformation['press_make_ready']).format('0,0')}</div>
                    <div>Waste (pss): {numeral(paperInformation['pss']['paper_waste']).format('0,0')}</div>
                    <div>Bindery Make Ready (pss): {numeral(paperInformation['pss']['bindery_make_ready']).format('0,0')}</div>
                    <div>Total Pss: {numeral(paperInformation['pss']['total_pss']).format('0,0')}</div>
                    <div>Number-Out: {paperInformation['pss']['out']}</div>
                    <div>Total Pars: {numeral(paperInformation['total_pars']).format('0,0')}</div>
                    <div>Total Poundage: {numeral(paperInformation['total_poundage']).format('0,0.[00]')} lbs</div>
                    <div>Total Cost: {numeral(paperInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );

            if (estimateResult['results'][0]['impositions_information'].length === 2) {
                paperInformation = estimateResult['results'][0]['impositions_information'][1]['paper_information'];
                interior2Information = (
                    <div className="text-left tooltip-column">
                        <div><b>Interior 2 (leftover)</b></div>
                        <div>Name: {paperInformation['basis_weight']}# {paperInformation['name']} {paperInformation['pars']['width']}x{paperInformation['pars']['length']}</div>
                        <div>Price/CWT: {numeral(paperInformation['price_cwt']).format('$0,0.00')}</div>
                        <div>Price/M-Sheets: {numeral(paperInformation['price_m_sheets']).format('$0,0.00')}</div>
                        <div>Number-up: {paperInformation['pss']['up']}</div>
                        <div>Press Sheets (pss): {numeral(paperInformation['pss']['number_pss']).format('0,0')}</div>
                        <div>Press Make Ready (pss): {numeral(paperInformation['press_make_ready']).format('0,0')}</div>
                        <div>Waste (pss): {numeral(paperInformation['pss']['paper_waste']).format('0,0')}</div>
                        <div>Bindery Make Ready (pss): {numeral(paperInformation['pss']['bindery_make_ready']).format('0,0')}</div>
                        <div>Total Pss: {numeral(paperInformation['pss']['total_pss']).format('0,0')}</div>
                        <div>Number-Out: {paperInformation['pss']['out']}</div>
                        <div>Total Pars: {numeral(paperInformation['total_pars']).format('0,0')}</div>
                        <div>Total Poundage: {numeral(paperInformation['total_poundage']).format('0,0.[00]')} lbs</div>
                        <div>Total Cost: {numeral(paperInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            tooltipHtml = (
                <div className="text-left">
                    <table border="1">
                        <tr>
                            <td>{coverInformation}</td>
                            <td>{interior1Information}</td>
                            <td>{interior2Information}</td>
                        </tr>
                    </table>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 3) {
            let paperInformation = estimateResult['results'][0]['paper_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Name: {paperInformation['basis_weight']}# {paperInformation['name']} {paperInformation['pars']['width']}x{paperInformation['pars']['length']}</div>
                    <div>Price/CWT: {numeral(paperInformation['price_cwt']).format('$0,0.00')}</div>
                    <div>Price/M-Sheets: {numeral(paperInformation['price_m_sheets']).format('$0,0.00')}</div>
                    <div>Number-up: {paperInformation['pss']['up']}</div>
                    <div>Press Sheets (pss): {numeral(paperInformation['pss']['number_pss']).format('0,0')}</div>
                    <div>Waste (pss): {numeral(paperInformation['pss']['paper_waste']).format('0,0')}</div>
                    <div>Bindery Make Ready (pss): {numeral(paperInformation['pss']['bindery_make_ready']).format('0,0')}</div>
                    <div>Total Pss: {numeral(paperInformation['pss']['total_pss']).format('0,0')}</div>
                    <div>Number-Out: {paperInformation['pss']['out']}</div>
                    <div>Total Pars: {numeral(paperInformation['total_pars']).format('0,0')}</div>
                    <div>Total Poundage: {numeral(paperInformation['total_poundage']).format('0,0.[00]')} lbs</div>
                    <div>Total Cost: {numeral(paperInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        }

        return this.renderTooltip(paperCost, tooltipHtml);
    }

    renderInkTooltip(inkCost, estimateResult) {
        const { formValues } = this.props;

        let tooltipHtml = null;
        if (parseInt(formValues['estimate_type']) === 1) {
            let inkInformation = estimateResult['results'][0]['ink_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Inks: {inkInformation['total_front_colors']}/{inkInformation['total_back_colors']}</div>
                    <div>Total Cost: {numeral(inkInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let coverInformation = null;
            let interior2Information = null;
            let inkInformation = null;

            if (this.hasCover()) {
                inkInformation = estimateResult['cover_results'][0]['ink_information'];
                coverInformation = (
                    <div className="text-left tooltip-column">
                        <div><b>Cover</b></div>
                        <div>Inks: {inkInformation['total_front_colors']}/{inkInformation['total_back_colors']}</div>
                        <div>Total Cost: {numeral(inkInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            inkInformation = estimateResult['results'][0]['impositions_information'][0]['ink_information'];
            let interior1Information = (
                <div className="text-left tooltip-column">
                    <div><b>Interior 1</b></div>
                    <div>Inks: {inkInformation['total_front_colors']}/{inkInformation['total_back_colors']}</div>
                    <div>Total Cost: {numeral(inkInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );

            if (estimateResult['results'][0]['impositions_information'].length === 2) {
                inkInformation = estimateResult['results'][0]['impositions_information'][1]['ink_information'];
                interior2Information = (
                    <div className="text-left tooltip-column">
                        <div><b>Interior 2 (leftover)</b></div>
                        <div>Inks: {inkInformation['total_front_colors']}/{inkInformation['total_back_colors']}</div>
                        <div>Total Cost: {numeral(inkInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            tooltipHtml = (
                <div className="text-left">
                    <table border="1">
                        <tr>
                            <td>{coverInformation}</td>
                            <td>{interior1Information}</td>
                            <td>{interior2Information}</td>
                        </tr>
                    </table>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 3) {
            let inkInformation = estimateResult['results'][0]['ink_information'];

            let inkName = null;
            if (estimateResult['results'][0]['ink_information']['front_colors'].length > 0 && estimateResult['results'][0]['ink_information']['back_colors'].length > 0) {
                inkName = 'Coating both sides';
            } else if (estimateResult['results'][0]['ink_information']['front_colors'].length > 0 || estimateResult['results'][0]['ink_information']['back_colors'].length > 0) {
                inkName = 'Coating one side';
            } else {
                inkName = null;
            }

            tooltipHtml = (
                <div className="text-left">
                    <div>Inks: {inkName}</div>
                    <div>Total Cost: {numeral(inkInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        }

        return this.renderTooltip(inkCost, tooltipHtml);
    }

    renderCuttingTooltip(cuttingCost, estimateResult) {
        const { formValues } = this.props;

        let tooltipHtml = null;
        if (parseInt(formValues['estimate_type']) === 1 || parseInt(formValues['estimate_type']) === 3) {
            let cuttingInformation = estimateResult['results'][0]['cutting_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Name: {cuttingInformation['name']}</div>
                    <div>BHR: {numeral(cuttingInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(cuttingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Cutting Lift: {cuttingInformation['lift']}</div>
                    <div>Time/Cut: {cuttingInformation['time_per_cut']} hrs</div>
                    <div>Make Ready Sheets: {numeral(cuttingInformation['make_ready']).format('0,0')}</div>
                    <div>Sheets/Load: {numeral(cuttingInformation['sheets_per_load']).format('0,0')}</div>
                    <div>Pre-Press Loads: {numeral(cuttingInformation['pre_press_loads']).format('0,0')}</div>
                    <div>Pre-Press Cuts: {numeral(cuttingInformation['pre_press_cuts']).format('0,0')}</div>
                    <div>Pre-Press Time: {numeral(cuttingInformation['pre_press_time']).format('0,0.[00]')} hrs</div>
                    <div>Pre-Press Cost: {numeral(cuttingInformation['pre_press_cost']).format('$0,0.00')}</div>
                    <div>Post-Press Loads: {numeral(cuttingInformation['post_press_loads']).format('0,0')}</div>
                    <div>Post-Press Cuts: {numeral(cuttingInformation['post_press_cuts']).format('0,0')}</div>
                    <div>Post-Press Time: {numeral(cuttingInformation['post_press_time']).format('0,0.[00]')} hrs</div>
                    <div>Post-Press Cost: {numeral(cuttingInformation['post_press_cost']).format('$0,0.00')}</div>
                    <div>Total Cost: {numeral(cuttingInformation['total_time']).format('0,0.[00]')} hrs</div>
                    <div>Total Cost: {numeral(cuttingInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let coverInformation = null;
            let interior2Information = null;
            let cuttingInformation = null;

            if (this.hasCover()) {
                cuttingInformation = estimateResult['cover_results'][0]['cutting_information'];
                coverInformation = (
                    <div className="text-left tooltip-column">
                        <div><b>Cover</b></div>
                        <div>Name: {cuttingInformation['name']}</div>
                        <div>BHR: {numeral(cuttingInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(cuttingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Cutting Lift: {cuttingInformation['lift']}</div>
                        <div>Time/Cut: {cuttingInformation['time_per_cut']} hrs</div>
                        <div>Make Ready Sheets: {numeral(cuttingInformation['make_ready']).format('0,0')}</div>
                        <div>Sheets/Load: {numeral(cuttingInformation['sheets_per_load']).format('0,0')}</div>
                        <div>Pre-Press Loads: {numeral(cuttingInformation['pre_press_loads']).format('0,0')}</div>
                        <div>Pre-Press Cuts: {numeral(cuttingInformation['pre_press_cuts']).format('0,0')}</div>
                        <div>Pre-Press Time: {numeral(cuttingInformation['pre_press_time']).format('0,0.[00]')} hrs</div>
                        <div>Pre-Press Cost: {numeral(cuttingInformation['pre_press_cost']).format('$0,0.00')}</div>
                        <div>Post-Press Loads: {numeral(cuttingInformation['post_press_loads']).format('0,0')}</div>
                        <div>Post-Press Cuts: {numeral(cuttingInformation['post_press_cuts']).format('0,0')}</div>
                        <div>Post-Press Time: {numeral(cuttingInformation['post_press_time']).format('0,0.[00]')} hrs</div>
                        <div>Post-Press Cost: {numeral(cuttingInformation['post_press_cost']).format('$0,0.00')}</div>
                        <div>Total Cost: {numeral(cuttingInformation['total_time']).format('0,0.[00]')} hrs</div>
                        <div>Total Cost: {numeral(cuttingInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            cuttingInformation = estimateResult['results'][0]['impositions_information'][0]['cutting_information'];
            let interior1Information = (
                <div className="text-left tooltip-column">
                    <div><b>Interior 1</b></div>
                    <div>Name: {cuttingInformation['name']}</div>
                    <div>BHR: {numeral(cuttingInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(cuttingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Cutting Lift: {cuttingInformation['lift']}</div>
                    <div>Time/Cut: {cuttingInformation['time_per_cut']} hrs</div>
                    <div>Make Ready Sheets: {numeral(cuttingInformation['make_ready']).format('0,0')}</div>
                    <div>Sheets/Load: {numeral(cuttingInformation['sheets_per_load']).format('0,0')}</div>
                    <div>Pre-Press Loads: {numeral(cuttingInformation['pre_press_loads']).format('0,0')}</div>
                    <div>Pre-Press Cuts: {numeral(cuttingInformation['pre_press_cuts']).format('0,0')}</div>
                    <div>Pre-Press Time: {numeral(cuttingInformation['pre_press_time']).format('0,0.[00]')} hrs</div>
                    <div>Pre-Press Cost: {numeral(cuttingInformation['pre_press_cost']).format('$0,0.00')}</div>
                    <div>Post-Press Loads: {numeral(cuttingInformation['post_press_loads']).format('0,0')}</div>
                    <div>Post-Press Cuts: {numeral(cuttingInformation['post_press_cuts']).format('0,0')}</div>
                    <div>Post-Press Time: {numeral(cuttingInformation['post_press_time']).format('0,0.[00]')} hrs</div>
                    <div>Post-Press Cost: {numeral(cuttingInformation['post_press_cost']).format('$0,0.00')}</div>
                    <div>Total Cost: {numeral(cuttingInformation['total_time']).format('0,0.[00]')} hrs</div>
                    <div>Total Cost: {numeral(cuttingInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );

            if (estimateResult['results'][0]['impositions_information'].length === 2) {
                cuttingInformation = estimateResult['results'][0]['impositions_information'][1]['cutting_information'];
                interior2Information = (
                    <div className="text-left tooltip-column">
                        <div><b>Interior 2 (leftover)</b></div>
                        <div>Name: {cuttingInformation['name']}</div>
                        <div>BHR: {numeral(cuttingInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(cuttingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Cutting Lift: {cuttingInformation['lift']}</div>
                        <div>Time/Cut: {cuttingInformation['time_per_cut']} hrs</div>
                        <div>Make Ready Sheets: {numeral(cuttingInformation['make_ready']).format('0,0')}</div>
                        <div>Sheets/Load: {numeral(cuttingInformation['sheets_per_load']).format('0,0')}</div>
                        <div>Pre-Press Loads: {numeral(cuttingInformation['pre_press_loads']).format('0,0')}</div>
                        <div>Pre-Press Cuts: {numeral(cuttingInformation['pre_press_cuts']).format('0,0')}</div>
                        <div>Pre-Press Time: {numeral(cuttingInformation['pre_press_time']).format('0,0.[00]')} hrs</div>
                        <div>Pre-Press Cost: {numeral(cuttingInformation['pre_press_cost']).format('$0,0.00')}</div>
                        <div>Post-Press Loads: {numeral(cuttingInformation['post_press_loads']).format('0,0')}</div>
                        <div>Post-Press Cuts: {numeral(cuttingInformation['post_press_cuts']).format('0,0')}</div>
                        <div>Post-Press Time: {numeral(cuttingInformation['post_press_time']).format('0,0.[00]')} hrs</div>
                        <div>Post-Press Cost: {numeral(cuttingInformation['post_press_cost']).format('$0,0.00')}</div>
                        <div>Total Cost: {numeral(cuttingInformation['total_time']).format('0,0.[00]')} hrs</div>
                        <div>Total Cost: {numeral(cuttingInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            tooltipHtml = (
                <div className="text-left">
                    <table border="1">
                        <tr>
                            <td>{coverInformation}</td>
                            <td>{interior1Information}</td>
                            <td>{interior2Information}</td>
                        </tr>
                    </table>
                </div>
            );
        }

        return this.renderTooltip(cuttingCost, tooltipHtml);
    }

    renderFoldingTooltip(foldingCost, estimateResult) {
        const { formValues } = this.props;

        let tooltipHtml = null;
        if (parseInt(formValues['estimate_type']) === 1 || parseInt(formValues['estimate_type']) === 3) {
            let foldingInformation = estimateResult['results'][0]['folding_information'];
            tooltipHtml = (
                <div className="text-left">
                    <div>Name: {foldingInformation['name']}</div>
                    <div>BHR: {numeral(foldingInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(foldingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Make Ready Sheets: {numeral(foldingInformation['make_ready']).format('0,0')}</div>
                    <div>Total Sheets: {numeral(foldingInformation['quantity']).format('0,0')}</div>
                    <div>Setup Time: {numeral(foldingInformation['setup_time']).format('0,0.[00]')} hrs</div>
                    <div>Speed: {numeral(foldingInformation['speed']).format('0,0')} sheets/hour</div>
                    <div>Total Cost: {numeral(foldingInformation['total_time']).format('0,0.[00]')} hrs</div>
                    <div>Total Cost: {numeral(foldingInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let coverInformation = null;
            let interior2Information = null;
            let foldingInformation = null;

            if (this.hasCover()) {
                foldingInformation = estimateResult['cover_results'][0]['folding_information'];
                coverInformation = (
                    <div className="text-left tooltip-column">
                        <div><b>Cover</b></div>
                        <div>Name: {foldingInformation['name']}</div>
                        <div>BHR: {numeral(foldingInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(foldingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Make Ready Sheets: {numeral(foldingInformation['make_ready']).format('0,0')}</div>
                        <div>Total Sheets: {numeral(foldingInformation['quantity']).format('0,0')}</div>
                        <div>Setup Time: {numeral(foldingInformation['setup_time']).format('0,0.[00]')} hrs</div>
                        <div>Speed: {numeral(foldingInformation['speed']).format('0,0')} sheets/hour</div>
                        <div>Total Cost: {numeral(foldingInformation['total_time']).format('0,0.[00]')} hrs</div>
                        <div>Total Cost: {numeral(foldingInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            foldingInformation = estimateResult['results'][0]['impositions_information'][0]['folding_information'];
            let interior1Information = (
                <div className="text-left tooltip-column">
                    <div><b>Interior 1</b></div>
                    <div>Name: {foldingInformation['name']}</div>
                    <div>BHR: {numeral(foldingInformation['labor_rate']).format('$0,0.00')}</div>
                    <div>BHR Markup: {numeral(foldingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                    <div>Make Ready Sheets: {numeral(foldingInformation['make_ready']).format('0,0')}</div>
                    <div>Total Sheets: {numeral(foldingInformation['quantity']).format('0,0')}</div>
                    <div>Setup Time: {numeral(foldingInformation['setup_time']).format('0,0.[00]')} hrs</div>
                    <div>Speed: {numeral(foldingInformation['speed']).format('0,0')} sheets/hour</div>
                    <div>Total Cost: {numeral(foldingInformation['total_time']).format('0,0.[00]')} hrs</div>
                    <div>Total Cost: {numeral(foldingInformation['total_cost']).format('$0,0.00')}</div>
                </div>
            );

            if (estimateResult['results'][0]['impositions_information'].length === 2) {
                foldingInformation = estimateResult['results'][0]['impositions_information'][1]['folding_information'];
                interior2Information = (
                    <div className="text-left tooltip-column">
                        <div><b>Interior 2 (leftover)</b></div>
                        <div>Name: {foldingInformation['name']}</div>
                        <div>BHR: {numeral(foldingInformation['labor_rate']).format('$0,0.00')}</div>
                        <div>BHR Markup: {numeral(foldingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                        <div>Make Ready Sheets: {numeral(foldingInformation['make_ready']).format('0,0')}</div>
                        <div>Total Sheets: {numeral(foldingInformation['quantity']).format('0,0')}</div>
                        <div>Setup Time: {numeral(foldingInformation['setup_time']).format('0,0.[00]')} hrs</div>
                        <div>Speed: {numeral(foldingInformation['speed']).format('0,0')} sheets/hour</div>
                        <div>Total Cost: {numeral(foldingInformation['total_time']).format('0,0.[00]')} hrs</div>
                        <div>Total Cost: {numeral(foldingInformation['total_cost']).format('$0,0.00')}</div>
                    </div>
                );
            }

            tooltipHtml = (
                <div className="text-left">
                    <table border="1">
                        <tr>
                            <td>{coverInformation}</td>
                            <td>{interior1Information}</td>
                            <td>{interior2Information}</td>
                        </tr>
                    </table>
                </div>
            );
        }

        return this.renderTooltip(foldingCost, tooltipHtml);
    }

    renderBindingTooltip(bindingCost, estimateResult) {
        let bindingInformation = estimateResult['results'][0]['binding_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {bindingInformation['name']}</div>
                <div>BHR: {numeral(bindingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(bindingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Make Ready: {numeral(bindingInformation['make_ready']).format('0,0')}</div>
                <div>Pieces/Hour: {numeral(bindingInformation['quantity_per_hour']).format('0,0')}</div>
                <div>Total Pieces: {numeral(bindingInformation['books']).format('0,0')}</div>
                <div>Setup Time: {numeral(bindingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(bindingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(bindingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(bindingCost, tooltipHtml);
    }

    renderLargeFormatMachineTooltip(largeFormatMachineCost, estimateResult) {
        let largeFormatInformation = estimateResult['results'][0]['large_format_machine_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {largeFormatInformation['name']}</div>
                <div>BHR: {numeral(largeFormatInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(largeFormatInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Setup Cost: {numeral(largeFormatInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(largeFormatInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(largeFormatInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(largeFormatMachineCost, tooltipHtml);
    }

    renderSubstrateTooltip(substrateCost, estimateResult) {
        let substrateInformation = estimateResult['results'][0]['substrate_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {substrateInformation['name']}</div>
                <div>Type: {parseInt(substrateInformation['type']) === 1 ? 'Roll' : 'Sheet'}</div>
                <div>Cost/{parseInt(substrateInformation['type']) === 1 ? 'Roll' : 'Sheet'}: {numeral(substrateInformation['cost_each']).format('$0,0.00')}</div>
                <div>Cost/{parseInt(substrateInformation['type']) === 1 ? 'Linear Foot' : 'Square Foot'}: {numeral(substrateInformation['cost']).format('$0,0.00')}</div>
                <div>Markup: {numeral(substrateInformation['markup']).format('0.[00]%')}</div>
                <div>Square Feet/Unit: {numeral(substrateInformation['area']).format('0,0.[00]')}</div>
                <div>Total Square Feet: {numeral(substrateInformation['total_area']).format('0,0.[00]')}</div>
                {substrateInformation['discount_percentage'] !== 0 && <div>Cost: {numeral(substrateInformation['cost']).format('$0,0.00')}</div>}
                {substrateInformation['discount_percentage'] !== 0 && <div>Discount Percentage: {numeral(substrateInformation['discount_percentage']).format('0.[00]%')}</div>}
                {substrateInformation['discount_percentage'] !== 0 && <div>Discounted Cost: {numeral(substrateInformation['discounted_cost']).format('$0,0.00')}</div>}
                <div>Total Cost: {numeral(substrateInformation['total_cost']).format('$0,0.00')}</div>
            </div>
        );
        return this.renderTooltip(substrateCost, tooltipHtml);
    }

    renderLaminateTooltip(laminateCost, estimateResult) {
        let laminateInformation = estimateResult['results'][0]['laminate_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {laminateInformation['name']}</div>
                <div>Type: {parseInt(laminateInformation['type']) === 1 ? 'Roll' : 'Sheet'}</div>
                <div>Cost/Roll: {numeral(laminateInformation['cost_each']).format('$0,0.00')}</div>
                <div>Cost/Linear Foot: {numeral(laminateInformation['cost_linear_foot']).format('$0,0.00')}</div>
                <div>Markup: {numeral(laminateInformation['markup']).format('0.[00]%')}</div>
                <div>Square Feet/Unit: {numeral(laminateInformation['area']).format('0,0.[00]')}</div>
                <div>Total Square Feet: {numeral(laminateInformation['total_area']).format('0,0.[00]')}</div>
                {laminateInformation['discount_percentage'] !== 0 && <div>Cost: {numeral(laminateInformation['cost']).format('$0,0.00')}</div>}
                {laminateInformation['discount_percentage'] !== 0 && <div>Discount Percentage: {numeral(laminateInformation['discount_percentage']).format('0.[00]%')}</div>}
                {laminateInformation['discount_percentage'] !== 0 && <div>Discounted Cost: {numeral(laminateInformation['discounted_cost']).format('$0,0.00')}</div>}
                <div>Total Cost: {numeral(laminateInformation['total_cost']).format('$0,0.00')}</div>
            </div>
        );
        return this.renderTooltip(laminateCost, tooltipHtml);
    }

    renderAccessoriesTooltip(accessoriesCost, estimateResult) {
        let accessoriesInformation = estimateResult['results'][0]['accessories_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Total Accessories: {accessoriesInformation['accessories'].length}</div>
                {accessoriesInformation['accessories'].map((accessory, index) => {
                    return [
                        <div key={'accessory-name-' + index}>{accessory['name']} cost: {numeral(accessory['total_cost']).format('$0,0.00')}</div>,
                        <div key={'accessory-markup-' + index}>{accessory['name']} markup: {numeral(accessory['markup']).format('0.[00]%')}</div>
                    ];
                })}
                <div>Total Cost: {numeral(accessoriesInformation['total_cost']).format('$0,0.00')}</div>
            </div>
        );
        return this.renderTooltip(accessoriesCost, tooltipHtml);
    }

    renderScoringTooltip(scoringCost, estimateResult) {
        let scoringInformation = estimateResult['results'][0]['scoring_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {scoringInformation['name']}</div>
                <div>BHR: {numeral(scoringInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(scoringInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Make Ready: {numeral(scoringInformation['make_ready']).format('0,0')}</div>
                <div>Total Pieces: {numeral(scoringInformation['total_pieces']).format('0,0')}</div>
                <div>Speed: {numeral(scoringInformation['speed']).format('0,0')}</div>
                <div>Setup Time: {numeral(scoringInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(scoringInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(scoringInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(scoringCost, tooltipHtml);
    }

    renderDieCuttingTooltip(dieCuttingCost, estimateResult) {
        let dieCuttingInformation = estimateResult['results'][0]['die_cutter_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {dieCuttingInformation['name']}</div>
                <div>BHR: {numeral(dieCuttingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(dieCuttingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Make Ready: {numeral(dieCuttingInformation['make_ready']).format('0,0')}</div>
                <div>Total Pieces: {numeral(dieCuttingInformation['total_pieces']).format('0,0')}</div>
                <div>Speed: {numeral(dieCuttingInformation['speed']).format('0,0')} Pieces/Hour</div>
                <div>Setup Time: {numeral(dieCuttingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(dieCuttingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(dieCuttingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(dieCuttingCost, tooltipHtml);
    }

    renderDrillingTooltip(drillingCost, estimateResult) {
        let drillingInformation = estimateResult['results'][0]['drilling_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {drillingInformation['name']}</div>
                <div>BHR: {numeral(drillingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(drillingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Make Ready: {numeral(drillingInformation['make_ready']).format('0,0')}</div>
                <div>Total Pieces: {numeral(drillingInformation['total_pieces']).format('0,0')}</div>
                <div>Time Per Hole/M-Sheets: {numeral(drillingInformation['time_per_hole']).format('0.[00]')}</div>
                <div>Time Per Additional Hole/M-Sheets: {numeral(drillingInformation['time_per_additional_hole']).format('0.[00]')}</div>
                <div>Setup Time: {numeral(drillingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(drillingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(drillingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(drillingCost, tooltipHtml);
    }

    renderPaddingTooltip(paddingCost, estimateResult) {
        let paddingInformation = estimateResult['results'][0]['padding_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {paddingInformation['name']}</div>
                <div>BHR: {numeral(paddingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(paddingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Make Ready: {numeral(paddingInformation['make_ready']).format('0,0')}</div>
                <div>Quantity: {numeral(paddingInformation['quantity']).format('0,0')}</div>
                <div>Time/M-Sheets: {numeral(paddingInformation['time_per_m_sheets']).format('0.[00]')}</div>
                <div>Cost per Chipboard: {numeral(paddingInformation['chipboard_cost']).format('$0,0.00')}</div>
                <div>Total Chipboard Cost: {numeral(paddingInformation['total_chipboard_cost']).format('$0,0.00')}</div>
                <div>Setup Time: {numeral(paddingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(paddingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(paddingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(paddingCost, tooltipHtml);
    }

    renderBandingTooltip(bandingCost, estimateResult) {
        let bandingInformation = estimateResult['results'][0]['banding_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {bandingInformation['type_name']}</div>
                <div>BHR: {numeral(bandingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(bandingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Time/M-Packages: {numeral(bandingInformation['time_per_m_packages']).format('0.[00]')}</div>
                <div>Total Packages: {numeral(bandingInformation['total_packages']).format('0,0')}</div>
                <div>Setup Time: {numeral(bandingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(bandingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(bandingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(bandingCost, tooltipHtml);
    }

    renderWrappingTooltip(wrappingCost, estimateResult) {
        let wrappingInformation = estimateResult['results'][0]['wrapping_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {wrappingInformation['type_name']}</div>
                <div>BHR: {numeral(wrappingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(wrappingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Time/Package: {numeral(wrappingInformation['time_per_package']).format('0.[00]')}</div>
                <div>Total Packages: {numeral(wrappingInformation['total_packages']).format('0,0')}</div>
                <div>Setup Time: {numeral(wrappingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(wrappingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(wrappingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(wrappingCost, tooltipHtml);
    }

    renderShrinkWrappingTooltip(shrinkWrappingCost, estimateResult) {
        let shrinkWrappingInformation = estimateResult['results'][0]['shrink_wrapping_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {shrinkWrappingInformation['type_name']}</div>
                <div>BHR: {numeral(shrinkWrappingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(shrinkWrappingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Time/M-Packages: {numeral(shrinkWrappingInformation['time_per_m_packages']).format('0.[00]')}</div>
                <div>Total Packages: {numeral(shrinkWrappingInformation['total_packages']).format('0,0')}</div>
                <div>Setup Time: {numeral(shrinkWrappingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(shrinkWrappingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(shrinkWrappingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(shrinkWrappingCost, tooltipHtml);
    }

    renderPackageTooltip(packageCost, estimateResult) {
        let packageInformation = estimateResult['results'][0]['package_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {packageInformation['width']}x{packageInformation['length']}x{packageInformation['height']}</div>
                <div>Cost: {numeral(packageInformation['cost']).format('$0,0.00')}</div>
                <div>TotalPieces: {numeral(packageInformation['total_pieces']).format('0,0')}</div>
                <div>Pieces/Package: {numeral(packageInformation['pieces_per_package']).format('0,0')}</div>
                <div>Total Packages: {numeral(packageInformation['total_packages']).format('0,0')}</div>
                <div>Total Time: {numeral(packageInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(packageCost, tooltipHtml);
    }

    renderHandGatheringTooltip(handGatheringCost, estimateResult) {
        let handGatheringInformation = estimateResult['results'][0]['hand_gathering_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {handGatheringInformation['name']}</div>
                <div>BHR: {numeral(handGatheringInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(handGatheringInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Sheet Sets/Hour: {numeral(handGatheringInformation['sheets_sets_per_hour']).format('0.[00]')}</div>
                <div>Total Pieces: {numeral(handGatheringInformation['total_pieces']).format('0,0')}</div>
                <div>Setup Time: {numeral(handGatheringInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(handGatheringInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(handGatheringInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(handGatheringCost, tooltipHtml);
    }

    renderCountingTooltip(countingCost, estimateResult) {
        let countingInformation = estimateResult['results'][0]['counting_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Name: {countingInformation['name']}</div>
                <div>BHR: {numeral(countingInformation['labor_rate']).format('$0,0.00')}</div>
                <div>BHR Markup: {numeral(countingInformation['labor_markup_rate']).format('0.[00]%')}</div>
                <div>Time/M-Pieces: {numeral(countingInformation['time_per_m_pieces']).format('0.[00]')}</div>
                <div>Total Pieces: {numeral(countingInformation['total_pieces']).format('0,0')}</div>
                <div>Setup Time: {numeral(countingInformation['setup_time']).format('0.[00]')} hrs</div>
                <div>Total Cost: {numeral(countingInformation['total_cost']).format('$0,0.00')}</div>
                <div>Total Time: {numeral(countingInformation['total_time']).format('0.[00]')} hrs</div>
            </div>
        );
        return this.renderTooltip(countingCost, tooltipHtml);
    }

    renderOutsideServicesTooltip(outsideServicesCost, estimateResult) {
        let outsideServicesInformation = estimateResult['results'][0]['outside_services_information'];
        let tooltipHtml = (
            <div className="text-left">
                <div>Total Services: {outsideServicesInformation['services'].length}</div>
                {outsideServicesInformation['services'].map((service, index) => {
                    return <div key={index}>{service['description']} cost: {numeral(service['total_cost']).format('$0,0.00')}</div>;
                })}
                <div>Total Cost: {numeral(outsideServicesInformation['total_cost']).format('$0,0.00')}</div>
            </div>
        );
        return this.renderTooltip(outsideServicesCost, tooltipHtml);
    }

    renderResults() {
        const { estimateResults, formValues, handleUpdateFormValue, isMailing } = this.props;

        if (estimateResults.length === 0) {
            return (
                <tr>
                    <td colSpan="8">No results</td>
                </tr>
            );
        }

        for (let i = 0; i < 3; i++) {
            if (estimateResults[i]['results'].length === 0) {
                return (
                    <tr>
                        <td colSpan="8">No results</td>
                    </tr>
                );
            }
        }

        let results = [];
        for (let i = 0; i < 3; i++) {
            results.push(estimateResults[i]['results'].length === 0 ? null : estimateResults[i]['results'][0]);
        }

        let weights = [];
        if (parseInt(formValues['estimate_type']) === 1 || parseInt(formValues['estimate_type']) === 3) {
            for (let i = 0; i < 3; i++) {
                weights.push(estimateResults[i]['results'][0]['paper_information']['total_poundage']);
            }
        } else if (parseInt(formValues['estimate_type']) === 2) {
            for (let i = 0; i < 3; i++) {
                let totalWeight = 0;
                for (let j = 0; j < estimateResults[i]['results'][0]['impositions_information'].length; j++) {
                    totalWeight += estimateResults[i]['results'][0]['impositions_information'][j]['paper_information']['total_poundage'];
                }
                weights.push(totalWeight);
            }
        }

        if (results[0] == null || results[1] == null || results[2] == null) {
            return (
                <tr>
                    <td colSpan="8">No results</td>
                </tr>
            );
        }

        let pressPrices = this.loadPrices('estimate_press_price_');
        let digitalMachinePrices = this.loadPrices('estimate_digital_machine_price_');
        let paperPrices = this.loadPrices('estimate_paper_price_');
        let inkPrices = this.loadPrices('estimate_ink_price_');
        let cuttingPrices = this.loadPrices('estimate_cutting_price_');
        let foldingPrices = this.loadPrices('estimate_folding_price_');
        let bindingPrices = this.loadPrices('estimate_binding_price_');
        let largeFormatMachinePrices = this.loadPrices('estimate_large_format_machine_price_');
        let substratePrices = this.loadPrices('estimate_substrate_price_');
        let laminatePrices = this.loadPrices('estimate_laminate_price_');
        let accessoriesPrices = this.loadPrices('estimate_accessories_price_');
        let outsideServicesPrices = this.loadPrices('estimate_outside_services_price_');
        let scoringPrices = this.loadPrices('estimate_scoring_price_');
        let dieCuttingPrices = this.loadPrices('estimate_die_cutting_price_');
        let drillingPrices = this.loadPrices('estimate_drilling_price_');
        let paddingPrices = this.loadPrices('estimate_padding_price_');
        let bandingPrices = this.loadPrices('estimate_banding_price_');
        let wrappingPrices = this.loadPrices('estimate_wrapping_price_');
        let shrinkWrappingPrices = this.loadPrices('estimate_shrink_wrapping_price_');
        let packagePrices = this.loadPrices('estimate_package_price_');
        let handGatheringPrices = this.loadPrices('estimate_hand_gathering_price_');
        let countingPrices = this.loadPrices('estimate_counting_price_');

        let totalPrices = [];
        for (let i = 0; i < 3; i++) {
            totalPrices.push(pressPrices[i] + paperPrices[i] + inkPrices[i] + cuttingPrices[i] + bindingPrices[i]
                + foldingPrices[i] + digitalMachinePrices[i] + largeFormatMachinePrices[i] + substratePrices[i]
                + laminatePrices[i] + accessoriesPrices[i] + outsideServicesPrices[i] + scoringPrices[i]
                + dieCuttingPrices[i] + drillingPrices[i] + paddingPrices[i] + bandingPrices[i] + wrappingPrices[i]
                + shrinkWrappingPrices[i] + packagePrices[i] + handGatheringPrices[i] + countingPrices[i]);
        }

        let pressName = null;
        let digitalMachineName = null;
        let paperName = null;
        let inkName = null;
        let cuttingName = null;
        let largeFormatMachineName = null;
        let substrateName = null;
        let laminateName = null;
        let accessoriesNames = [];
        let outsideServicesNames = [];
        let scoringName = null;
        let dieCuttingName = null;
        let drillingName = null;
        let paddingName = null;
        let bandingName = null;
        let wrappingName = null;
        let shrinkWrappingName = null;
        let packageName = null;
        let handGatheringName = null;
        let countingName = null;
        let digitalMachineCosts = [];
        let largeFormatMachineCosts = [];
        let pressCosts = [];
        let paperCosts = [];
        let inkCosts = [];
        let cuttingCosts = [];
        let substrateCosts = [];
        let laminateCosts = [];
        let accessoriesCosts = [];
        let outsideServicesCosts = [];
        let scoringCosts = [];
        let dieCuttingCosts = [];
        let drillingCosts = [];
        let paddingCosts = [];
        let bandingCosts = [];
        let wrappingCosts = [];
        let shrinkWrappingCosts = [];
        let packageCosts = [];
        let handGatheringCosts = [];
        let countingCosts = [];
        let totalCosts = [];
        let discounts = [];

        if (parseInt(formValues['estimate_type']) === 1) {
            pressName = results[0]['press_information']['name'];
            paperName = results[0]['paper_information']['basis_weight'] + '# ' + results[0]['paper_information']['name']
                + ' ' + results[0]['paper_information']['pars']['width'] + 'x' + results[0]['paper_information']['pars']['length'];
            inkName = results[0]['ink_information']['front_colors'].length + '/' + results[0]['ink_information']['back_colors'].length;
            cuttingName = results[0]['cutting_information']['name'];

            for (let i = 0; i < 3; i++) {
                pressCosts.push(results[i]['press_information']['total_cost']);
                paperCosts.push(results[i]['paper_information']['total_cost']);
                inkCosts.push(results[i]['ink_information']['total_cost']);
                cuttingCosts.push(results[i]['cutting_information']['total_cost']);
                discounts.push(results[i]['totals']['discount']);
                totalCosts.push(results[i]['totals']['total_cost']);
            }
        } else if (parseInt(formValues['estimate_type']) === 2) {
            let paperNameString = null;
            let inkNameString = null;
            pressName = [];
            paperName = [];
            inkName = [];

            if (this.hasCover()) {
                pressName.push(<div><b>Cover:</b> {estimateResults[0]['cover_results'][0]['press_information']['name']}</div>);

                paperNameString = estimateResults[0]['cover_results'][0]['paper_information']['basis_weight'] + '# '
                    + estimateResults[0]['cover_results'][0]['paper_information']['name'] + ' '
                    + estimateResults[0]['cover_results'][0]['paper_information']['pars']['width'] + 'x'
                    + estimateResults[0]['cover_results'][0]['paper_information']['pars']['length'];
                paperName.push(<div><b>Cover:</b> {paperNameString}</div>);

                inkNameString = estimateResults[0]['cover_results'][0]['ink_information']['front_colors'].length + '/'
                    + estimateResults[0]['cover_results'][0]['ink_information']['back_colors'].length;
                inkName.push(<div><b>Cover:</b> {inkNameString}</div>);
            }

            for (let j = 0; j < results[0]['impositions_information'].length; j++) {
                pressName.push(<div><b>Interior {j + 1}:</b> {results[0]['impositions_information'][j]['press_information']['name']}</div>);
            }

            paperNameString = results[0]['impositions_information'][0]['paper_information']['basis_weight'] + '# '
                + results[0]['impositions_information'][0]['paper_information']['name'] + ' '
                + results[0]['impositions_information'][0]['paper_information']['pars']['width'] + 'x'
                + results[0]['impositions_information'][0]['paper_information']['pars']['length'];
            paperName.push(<div><b>Interior:</b> {paperNameString}</div>);

            inkNameString = results[0]['impositions_information'][0]['ink_information']['front_colors'].length + '/'
                + results[0]['impositions_information'][0]['ink_information']['back_colors'].length;
            inkName.push(<div><b>Interior:</b> {inkNameString}</div>);

            cuttingName = results[0]['impositions_information'][0]['cutting_information']['name'];

            for (let i = 0; i < 3; i++) {
                let pressCost = 0;
                let paperCost = 0;
                let inkCost = 0;
                let cuttingCost = 0;
                for (let j = 0; j < results[i]['impositions_information'].length; j++) {
                    pressCost += results[i]['impositions_information'][j]['press_information']['total_cost'];
                    paperCost += results[i]['impositions_information'][j]['paper_information']['total_cost'];
                    inkCost += results[i]['impositions_information'][j]['ink_information']['total_cost'];
                    cuttingCost += results[i]['impositions_information'][j]['cutting_information']['total_cost'];
                }

                if (this.hasCover()) {
                    pressCost += estimateResults[i]['cover_results'][0]['press_information']['total_cost'];
                    paperCost += estimateResults[i]['cover_results'][0]['paper_information']['total_cost'];
                    inkCost += estimateResults[i]['cover_results'][0]['ink_information']['total_cost'];
                    cuttingCost += estimateResults[i]['cover_results'][0]['cutting_information']['total_cost'];
                }

                pressCosts.push(pressCost);
                paperCosts.push(paperCost);
                inkCosts.push(inkCost);
                cuttingCosts.push(cuttingCost);
                discounts.push(results[i]['totals']['discount']);
                totalCosts.push(results[i]['totals']['total_cost']);
            }
        } else if (parseInt(formValues['estimate_type']) === 3) {
            digitalMachineName = results[0]['digital_machine_information']['name'];
            paperName = results[0]['paper_information']['basis_weight'] + '# ' + results[0]['paper_information']['name']
                + ' ' + results[0]['paper_information']['pars']['width'] + 'x' + results[0]['paper_information']['pars']['length'];

            if (results[0]['ink_information']['front_colors'].length > 0 && results[0]['ink_information']['back_colors'].length > 0) {
                inkName = 'Coating both sides';
            } else if (results[0]['ink_information']['front_colors'].length > 0 || results[0]['ink_information']['back_colors'].length > 0) {
                inkName = 'Coating one side';
            } else {
                inkName = null;
            }

            cuttingName = results[0]['cutting_information']['name'];

            for (let i = 0; i < 3; i++) {
                digitalMachineCosts.push(results[i]['digital_machine_information']['total_cost']);
                paperCosts.push(results[i]['paper_information']['total_cost']);
                inkCosts.push(results[i]['ink_information']['total_cost']);
                cuttingCosts.push(results[i]['cutting_information']['total_cost']);
                discounts.push(results[i]['totals']['discount']);
                totalCosts.push(results[i]['totals']['total_cost']);
            }
        } else if (parseInt(formValues['estimate_type']) === 4) {
            largeFormatMachineName = results[0]['large_format_machine_information']['name'];
            substrateName = results[0]['substrate_information']['name'];

            if (results[0]['laminate_information'] != null) {
                laminateName = results[0]['laminate_information']['name'];
            }

            if (results[0]['accessories_information'] != null) {
                for (let i = 0; i < results[0]['accessories_information']['accessories'].length; i++) {
                    accessoriesNames.push(results[0]['accessories_information']['accessories'][i]['name']);
                }
            }

            for (let i = 0; i < 3; i++) {
                largeFormatMachineCosts.push(results[i]['large_format_machine_information']['total_cost']);
                substrateCosts.push(results[i]['substrate_information']['total_cost']);

                if (results[i]['laminate_information'] != null) {
                    laminateCosts.push(results[i]['laminate_information']['total_cost']);
                }

                if (results[i]['accessories_information'] != null) {
                    accessoriesCosts.push(results[i]['accessories_information']['total_cost']);
                }

                discounts.push(results[i]['totals']['discount']);
                totalCosts.push(results[i]['totals']['total_cost']);
            }
        }

        if (parseInt(formValues['estimate_type']) === 1 || parseInt(formValues['estimate_type']) === 2 || parseInt(formValues['estimate_type']) === 3) {
            if (results[0]['scoring_information'] && results[0]['scoring_information']['id'] != null) {
                scoringName = results[0]['scoring_information']['name'];
                for (let i = 0; i < 3; i++) {
                    scoringCosts.push(results[i]['scoring_information']['total_cost']);
                }
            }

            if (results[0]['die_cutter_information'] && results[0]['die_cutter_information']['id'] != null) {
                dieCuttingName = results[0]['die_cutter_information']['name'];
                for (let i = 0; i < 3; i++) {
                    dieCuttingCosts.push(results[i]['die_cutter_information']['total_cost']);
                }
            }

            if (results[0]['drilling_information'] && results[0]['drilling_information']['id'] != null) {
                drillingName = results[0]['drilling_information']['name'];
                for (let i = 0; i < 3; i++) {
                    drillingCosts.push(results[i]['drilling_information']['total_cost']);
                }
            }

            if (results[0]['padding_information'] && results[0]['padding_information']['id'] != null) {
                paddingName = results[0]['padding_information']['name'];
                for (let i = 0; i < 3; i++) {
                    paddingCosts.push(results[i]['padding_information']['total_cost']);
                }
            }

            if (results[0]['banding_information'] && results[0]['banding_information']['id'] != null) {
                bandingName = results[0]['banding_information']['type_name'];
                for (let i = 0; i < 3; i++) {
                    bandingCosts.push(results[i]['banding_information']['total_cost']);
                }
            }

            if (results[0]['wrapping_information'] && results[0]['wrapping_information']['id'] != null) {
                wrappingName = results[0]['wrapping_information']['type_name'];
                for (let i = 0; i < 3; i++) {
                    wrappingCosts.push(results[i]['wrapping_information']['total_cost']);
                }
            }

            if (results[0]['shrink_wrapping_information'] && results[0]['shrink_wrapping_information']['id'] != null) {
                shrinkWrappingName = results[0]['shrink_wrapping_information']['type_name'];
                for (let i = 0; i < 3; i++) {
                    shrinkWrappingCosts.push(results[i]['shrink_wrapping_information']['total_cost']);
                }
            }

            if (results[0]['package_information'] && results[0]['package_information']['id'] != null) {
                packageName = results[0]['package_information']['width'] + 'x'
                    + results[0]['package_information']['length'] + 'x'
                    + results[0]['package_information']['height'];
                for (let i = 0; i < 3; i++) {
                    packageCosts.push(results[i]['package_information']['total_cost']);
                }
            }

            if (results[0]['hand_gathering_information'] && results[0]['hand_gathering_information']['id'] != null) {
                handGatheringName = results[0]['hand_gathering_information']['name'];
                for (let i = 0; i < 3; i++) {
                    handGatheringCosts.push(results[i]['hand_gathering_information']['total_cost']);
                }
            }

            if (results[0]['counting_information'] && results[0]['counting_information']['id'] != null) {
                countingName = results[0]['counting_information']['name'];
                for (let i = 0; i < 3; i++) {
                    countingCosts.push(results[i]['counting_information']['total_cost']);
                }
            }

            if (results[0]['outside_services_information'] && results[0]['outside_services_information']['services'].length > 0) {
                for (let i = 0; i < results[0]['outside_services_information']['services'].length; i++) {
                    outsideServicesNames.push(results[0]['outside_services_information']['services'][i]['description']);
                }

                for (let i = 0; i < 3; i++) {
                    outsideServicesCosts.push(results[i]['outside_services_information']['total_cost']);
                }
            }
        }

        let pressRow = null;
        if (pressName != null) {
            pressRow = (
                <tr key="press">
                    <td>Press</td>
                    <td>{pressName}</td>
                    <td className="text-right cost-td">{this.renderPressTooltip(pressCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_press_price_1" value={formValues['estimate_press_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderPressTooltip(pressCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_press_price_2" value={formValues['estimate_press_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderPressTooltip(pressCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_press_price_3" value={formValues['estimate_press_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let digitalMachineRow = null;
        if (digitalMachineName != null) {
            digitalMachineRow = (
                <tr key="digital-machine">
                    <td>Digital Machine</td>
                    <td>{digitalMachineName}</td>
                    <td className="text-right cost-td">{this.renderDigitalMachineTooltip(digitalMachineCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_digital_machine_price_1" value={formValues['estimate_digital_machine_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderDigitalMachineTooltip(digitalMachineCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_digital_machine_price_2" value={formValues['estimate_digital_machine_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderDigitalMachineTooltip(digitalMachineCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_digital_machine_price_3" value={formValues['estimate_digital_machine_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let inkRow = null;
        if (inkName != null) {
            inkRow = (
                <tr key="ink">
                    <td>Ink</td>
                    <td>{inkName}</td>
                    <td className="text-right cost-td">{this.renderInkTooltip(inkCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_ink_price_1" value={formValues['estimate_ink_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderInkTooltip(inkCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_ink_price_2" value={formValues['estimate_ink_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderInkTooltip(inkCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_ink_price_3" value={formValues['estimate_ink_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let paperRow = null;
        if (paperName != null) {
            paperRow = (
                <tr key="paper">
                    <td>Paper</td>
                    <td>{paperName}</td>
                    <td className="text-right cost-td">{this.renderPaperTooltip(paperCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_paper_price_1" value={formValues['estimate_paper_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderPaperTooltip(paperCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_paper_price_2" value={formValues['estimate_paper_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderPaperTooltip(paperCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_paper_price_3" value={formValues['estimate_paper_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let cuttingRow = null;
        if (cuttingName != null) {
            cuttingRow = (
                <tr key="cutting">
                    <td>Cutting</td>
                    <td>{cuttingName}</td>
                    <td className="text-right cost-td">{this.renderCuttingTooltip(cuttingCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_cutting_price_1" value={formValues['estimate_cutting_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderCuttingTooltip(cuttingCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_cutting_price_2" value={formValues['estimate_cutting_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderCuttingTooltip(cuttingCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_cutting_price_3" value={formValues['estimate_cutting_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let largeFormatMachineRow = null;
        if (largeFormatMachineName != null) {
            largeFormatMachineRow = (
                <tr key="large-format-machine">
                    <td>Large Format Machine</td>
                    <td>{largeFormatMachineName}</td>
                    <td className="text-right cost-td">{this.renderLargeFormatMachineTooltip(largeFormatMachineCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_large_format_machine_price_1" value={formValues['estimate_large_format_machine_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderLargeFormatMachineTooltip(largeFormatMachineCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_large_format_machine_price_2" value={formValues['estimate_large_format_machine_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderLargeFormatMachineTooltip(largeFormatMachineCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_large_format_machine_price_3" value={formValues['estimate_large_format_machine_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let substrateRow = null;
        if (substrateName != null) {
            substrateRow = (
                <tr key="substrate">
                    <td>Substrate</td>
                    <td>{substrateName}</td>
                    <td className="text-right cost-td">{this.renderSubstrateTooltip(substrateCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_substrate_price_1" value={formValues['estimate_substrate_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderSubstrateTooltip(substrateCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_substrate_price_2" value={formValues['estimate_substrate_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderSubstrateTooltip(substrateCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_substrate_price_3" value={formValues['estimate_substrate_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let laminateRow = null;
        if (laminateName != null) {
            laminateRow = (
                <tr key="laminate">
                    <td>Laminate</td>
                    <td>{laminateName}</td>
                    <td className="text-right cost-td">{this.renderLaminateTooltip(laminateCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_laminate_price_1" value={formValues['estimate_laminate_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderLaminateTooltip(laminateCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_laminate_price_2" value={formValues['estimate_laminate_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderLaminateTooltip(laminateCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_laminate_price_3" value={formValues['estimate_laminate_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let accessoriesRow = null;
        if (accessoriesNames.length > 0) {
            accessoriesRow = (
                <tr key="accessories">
                    <td>Accessories</td>
                    <td>{accessoriesNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderAccessoriesTooltip(accessoriesCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_accessories_price_1" value={formValues['estimate_accessories_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderAccessoriesTooltip(accessoriesCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_accessories_price_2" value={formValues['estimate_accessories_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderAccessoriesTooltip(accessoriesCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_accessories_price_3" value={formValues['estimate_accessories_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let scoringRow = null;
        if (scoringName != null) {
            scoringRow = (
                <tr key="scoring">
                    <td>Scoring</td>
                    <td>{scoringName}</td>
                    <td className="text-right cost-td">{this.renderScoringTooltip(scoringCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_scoring_price_1" value={formValues['estimate_scoring_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderScoringTooltip(scoringCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_scoring_price_2" value={formValues['estimate_scoring_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderScoringTooltip(scoringCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_scoring_price_3" value={formValues['estimate_scoring_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let dieCuttingRow = null;
        if (dieCuttingName != null) {
            dieCuttingRow = (
                <tr key="die-cutting">
                    <td>Die-Cutting</td>
                    <td>{dieCuttingName}</td>
                    <td className="text-right cost-td">{this.renderDieCuttingTooltip(dieCuttingCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_die_cutting_price_1" value={formValues['estimate_die_cutting_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderDieCuttingTooltip(dieCuttingCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_die_cutting_price_2" value={formValues['estimate_die_cutting_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderDieCuttingTooltip(dieCuttingCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_die_cutting_price_3" value={formValues['estimate_die_cutting_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let drillingRow = null;
        if (drillingName != null) {
            drillingRow = (
                <tr key="drilling">
                    <td>Drilling</td>
                    <td>{drillingName}</td>
                    <td className="text-right cost-td">{this.renderDrillingTooltip(drillingCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_drilling_price_1" value={formValues['estimate_drilling_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderDrillingTooltip(drillingCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_drilling_price_2" value={formValues['estimate_drilling_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderDrillingTooltip(drillingCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_drilling_price_3" value={formValues['estimate_drilling_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let paddingRow = null;
        if (paddingName != null) {
            paddingRow = (
                <tr key="padding">
                    <td>Padding</td>
                    <td>{paddingName}</td>
                    <td className="text-right cost-td">{this.renderPaddingTooltip(paddingCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_padding_price_1" value={formValues['estimate_padding_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderPaddingTooltip(paddingCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_padding_price_2" value={formValues['estimate_padding_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderPaddingTooltip(paddingCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_padding_price_3" value={formValues['estimate_padding_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let bandingRow = null;
        if (bandingName != null) {
            bandingRow = (
                <tr key="banding">
                    <td>Banding</td>
                    <td>{bandingName}</td>
                    <td className="text-right cost-td">{this.renderBandingTooltip(bandingCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_banding_price_1" value={formValues['estimate_banding_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderBandingTooltip(bandingCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_banding_price_2" value={formValues['estimate_banding_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderBandingTooltip(bandingCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_banding_price_3" value={formValues['estimate_banding_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let wrappingRow = null;
        if (wrappingName != null) {
            wrappingRow = (
                <tr key="wrapping">
                    <td>Wrapping</td>
                    <td>{wrappingName}</td>
                    <td className="text-right cost-td">{this.renderWrappingTooltip(wrappingCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_wrapping_price_1" value={formValues['estimate_wrapping_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderWrappingTooltip(wrappingCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_wrapping_price_2" value={formValues['estimate_wrapping_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderWrappingTooltip(wrappingCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_wrapping_price_3" value={formValues['estimate_wrapping_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let shrinkWrappingRow = null;
        if (shrinkWrappingName != null) {
            shrinkWrappingRow = (
                <tr key="shrink-wrapping">
                    <td>Shrink Wrapping</td>
                    <td>{shrinkWrappingName}</td>
                    <td className="text-right cost-td">{this.renderShrinkWrappingTooltip(shrinkWrappingCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_shrink_wrapping_price_1" value={formValues['estimate_shrink_wrapping_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderShrinkWrappingTooltip(shrinkWrappingCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_shrink_wrapping_price_2" value={formValues['estimate_shrink_wrapping_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderShrinkWrappingTooltip(shrinkWrappingCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_shrink_wrapping_price_3" value={formValues['estimate_shrink_wrapping_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let packageRow = null;
        if (packageName != null) {
            packageRow = (
                <tr key="package">
                    <td>Package</td>
                    <td>{packageName}</td>
                    <td className="text-right cost-td">{this.renderPackageTooltip(packageCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_package_price_1" value={formValues['estimate_package_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderPackageTooltip(packageCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_package_price_2" value={formValues['estimate_package_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderPackageTooltip(packageCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_package_price_3" value={formValues['estimate_package_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let handGatheringRow = null;
        if (handGatheringName != null) {
            handGatheringRow = (
                <tr key="hand-gathering">
                    <td>Hand Gathering</td>
                    <td></td>
                    <td className="text-right cost-td">{this.renderHandGatheringTooltip(handGatheringCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_hand_gathering_price_1" value={formValues['estimate_hand_gathering_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderHandGatheringTooltip(handGatheringCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_hand_gathering_price_2" value={formValues['estimate_hand_gathering_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderHandGatheringTooltip(handGatheringCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_hand_gathering_price_3" value={formValues['estimate_hand_gathering_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let countingRow = null;
        if (countingName != null) {
            countingRow = (
                <tr key="counting">
                    <td>Counting</td>
                    <td></td>
                    <td className="text-right cost-td">{this.renderCountingTooltip(countingCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_counting_price_1" value={formValues['estimate_counting_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderCountingTooltip(countingCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_counting_price_2" value={formValues['estimate_counting_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderCountingTooltip(countingCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_counting_price_3" value={formValues['estimate_counting_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let outsideServicesRow = null;
        if (outsideServicesNames.length > 0) {
            outsideServicesRow = (
                <tr key="outside-services">
                    <td>Outside Services</td>
                    <td>{outsideServicesNames.join(', ')}</td>
                    <td className="text-right cost-td">{this.renderOutsideServicesTooltip(outsideServicesCosts[0], estimateResults[0])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_outside_services_price_1" value={formValues['estimate_outside_services_price_1']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderOutsideServicesTooltip(outsideServicesCosts[1], estimateResults[1])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_outside_services_price_2" value={formValues['estimate_outside_services_price_2']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                    <td className="text-right cost-td">{this.renderOutsideServicesTooltip(outsideServicesCosts[2], estimateResults[2])}</td>
                    <td className="price-td">
                        $<input type="text" name="estimate_outside_services_price_3" value={formValues['estimate_outside_services_price_3']}
                            onChange={handleUpdateFormValue.bind(this, 'decimal', true)} />
                    </td>
                </tr>
            );
        }

        let weightRow = null;
        if (parseInt(formValues['estimate_type']) !== 4) {
            weightRow = (
                <tr key="weight">
                    <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>Weight:</td>
                    <td className="text-right cost-td" colSpan="2">{numeral(weights[0]).format('0,0.[00]')} lbs</td>
                    <td className="text-right cost-td" colSpan="2">{numeral(weights[1]).format('0,0.[00]')} lbs</td>
                    <td className="text-right cost-td" colSpan="2">{numeral(weights[2]).format('0,0.[00]')} lbs</td>
                </tr>
            );
        }

        if (isMailing) {
            return [
                outsideServicesRow,
                <tr key="total">
                    <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>Total:</td>
                    <td className="text-right">{numeral(totalPrices[0]).format('$0,0.00')}</td>
                    <td className="text-right">{numeral(totalCosts[0]).format('$0,0.00')}</td>
                </tr>,
                weightRow
            ];
        }

        let discountRow = null;
        if (discounts[0] !== undefined && discounts[0] !== 0) {
            let discountType = 'Markup';
            if (discounts[0] < 0) {
                discountType = 'Discount';
            }

            let discountPrices = [0, 0, 0];
            if (parseInt(results[0]['totals']['discount_type']) === 1) {
                discountPrices[0] = totalPrices[0] * -results[0]['totals']['discount_value'];
                discountPrices[1] = totalPrices[1] * -results[1]['totals']['discount_value'];
                discountPrices[2] = totalPrices[2] * -results[2]['totals']['discount_value'];
            } else {
                discountPrices[0] = totalPrices[0] * results[0]['totals']['discount_value'];
                discountPrices[1] = totalPrices[1] * results[1]['totals']['discount_value'];
                discountPrices[2] = totalPrices[2] * results[2]['totals']['discount_value'];
            }
            totalPrices[0] += discountPrices[0];
            totalPrices[1] += discountPrices[1];
            totalPrices[2] += discountPrices[2];

            discountRow = (
                <tr key="discount">
                    <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>{discountType}:</td>
                    <td className="text-right cost-td">{numeral(discounts[0]).format('$0,0.00')}</td>
                    <td className="text-right price-td">{numeral(discountPrices[0]).format('$0,0.00')}</td>
                    <td className="text-right cost-td">{numeral(discounts[1]).format('$0,0.00')}</td>
                    <td className="text-right price-td">{numeral(discountPrices[1]).format('$0,0.00')}</td>
                    <td className="text-right cost-td">{numeral(discounts[2]).format('$0,0.00')}</td>
                    <td className="text-right price-td">{numeral(discountPrices[2]).format('$0,0.00')}</td>
                </tr>
            );
        }

        return [
            pressRow,
            digitalMachineRow,
            largeFormatMachineRow,
            paperRow,
            inkRow,
            cuttingRow,
            this.renderFolding(estimateResults, results),
            this.renderBinding(estimateResults, results),
            substrateRow,
            laminateRow,
            accessoriesRow,
            scoringRow,
            dieCuttingRow,
            drillingRow,
            paddingRow,
            packageRow,
            bandingRow,
            wrappingRow,
            shrinkWrappingRow,
            handGatheringRow,
            countingRow,
            outsideServicesRow,
            discountRow,
            <tr key="total">
                <td colSpan="2" className="text-right" style={{ fontWeight: 500 }}>Total:</td>
                <td className="text-right cost-td">{numeral(totalCosts[0]).format('$0,0.00')}</td>
                <td className="text-right price-td">{numeral(totalPrices[0]).format('$0,0.00')}</td>
                <td className="text-right cost-td">{numeral(totalCosts[1]).format('$0,0.00')}</td>
                <td className="text-right price-td">{numeral(totalPrices[1]).format('$0,0.00')}</td>
                <td className="text-right cost-td">{numeral(totalCosts[2]).format('$0,0.00')}</td>
                <td className="text-right price-td">{numeral(totalPrices[2]).format('$0,0.00')}</td>
            </tr>,
            weightRow
        ];
    }

    hasCover() {
        const { formValues } = this.props;
        return [2, 3, 6, 7].includes(parseInt(formValues['estimate_multi_page_type']));
    }

    render() {
        const { formValues, handleUpdateFormValue, renderSheets, isMailing } = this.props;

        if (isMailing) {
            return (
                <div className="col">
                    <div className="card mb-2 mr-1 pb-2">
                        <div className="card-header">
                            Pricing
                        </div>
                        <div className="card-body p-1 pb-0">
                            <table id="estimate-pricing-table" className="table table-sm table-striped">
                                <tbody>
                                    <tr>
                                        <th>Department</th>
                                        <th>Name</th>
                                        <th className="cost-td">Cost</th>
                                        <th className="price-td">Price</th>
                                    </tr>
                                    {this.renderResults()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="col">
                <div className="card mb-2 mr-1 pb-2">
                    <div className="card-header">
                        Pricing
                    </div>
                    <div className="card-body p-1 pb-0">
                        <table id="estimate-pricing-table" className="table table-sm table-striped">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="text-right" style={{ fontWeight: 500 }}>Quantity:</td>
                                    <td></td>
                                    <td className="quantity-td">
                                        <input type="text" value={formValues['estimate_quantity_1']} id="estimate_quantity_1" name="estimate_quantity_1"
                                            onChange={handleUpdateFormValue.bind(this, 'number', true)} className="quantity-field text-right" />
                                    </td>
                                    <td></td>
                                    <td className="quantity-td">
                                        <input type="text" value={formValues['estimate_quantity_2']} id="estimate_quantity_2" name="estimate_quantity_2"
                                            onChange={handleUpdateFormValue.bind(this, 'number', true)} className="quantity-field text-right" />
                                    </td>
                                    <td></td>
                                    <td className="quantity-td">
                                        <input type="text" value={formValues['estimate_quantity_3']} id="estimate_quantity_3" name="estimate_quantity_3"
                                            onChange={handleUpdateFormValue.bind(this, 'number', true)} className="quantity-field text-right" />
                                    </td>
                                </tr>
                                {renderSheets ? this.renderSheets() : null}
                                <tr>
                                    <th>Department</th>
                                    <th>Name</th>
                                    <th className="cost-td">Cost</th>
                                    <th className="price-td">Price</th>
                                    <th className="cost-td">Cost</th>
                                    <th className="price-td">Price</th>
                                    <th className="cost-td">Cost</th>
                                    <th className="price-td">Price</th>
                                </tr>
                                {this.renderResults()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default EstimatePricing;
