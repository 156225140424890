import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import numeral from 'numeral';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { findPermission } from './../../../utils';

class Table extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        isFetchingElements: PropTypes.bool,
        handleShowForm: PropTypes.func,
        handleShowDelete: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleViewProperty: PropTypes.func,
        handleChangeFilter: PropTypes.func,
        user: PropTypes.object,
        filterFields: PropTypes.object
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn === name) {
            if (sortType === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name)}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { elements, isFetchingElements, handleShowForm, handleShowDelete, user } = this.props;

        if (isFetchingElements) {
            return (
                <tr>
                    <td colSpan="12"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (elements.length === 0) {
            return (
                <tr>
                    <td colSpan="12">No Results</td>
                </tr>
            );
        }

        let permission = findPermission(user['permissions'], 'Deposits');

        let data = [];
        for (let i = 0; i < elements.length; i++) {
            let type = null;
            if (elements[i]['type'] === 1) {
                type = 'ACH';
            } else if (elements[i]['type'] === 2) {
                type = 'Cash';
            } else if (elements[i]['type'] === 3) {
                type = 'Check';
            } else if (elements[i]['type'] === 4) {
                type = 'Paypal';
            } else if (elements[i]['type'] === 5) {
                type = 'Wire-Transfer';
            } else if (elements[i]['type'] === 6) {
                type = 'Visa/Master';
            } else if (elements[i]['type'] === 7) {
                type = 'AMEX';
            }

            let pms = [];
            if (elements[i]['pm_invoices'] !== null && elements[i]['pm_invoices'] !== '') {
                pms = JSON.parse(elements[i]['pm_invoices']);
            }

            let className = '';
            if (elements[i]['edited']) {
                className = 'edited';
            } else if (elements[i]['notes'] !== null && elements[i]['notes'] !== '') {
                className = 'noticed';
            }

            let editAction = null;
            if (permission['Edit']) {
                editAction = (
                    <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-edit" onClick={handleShowForm.bind(this, elements[i].id, null)} />
                    </Tooltip>
                );
            }

            let deleteAction = null;
            if (permission['Delete']) {
                deleteAction = (
                    <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="fas fa-trash" onClick={handleShowDelete.bind(this, elements[i].id)} />
                    </Tooltip>
                );
            }

            data.push(
                <tr key={i} className={className}>
                    <td>{moment(elements[i]['date']).format('MM/DD/YYYY')}</td>
                    <td>{type}</td>
                    <td>{elements[i]['check']}</td>
                    <td>{elements[i]['approval_code']}</td>
                    <td>{elements[i]['convenience_fee']}</td>
                    <td>{elements[i]['ac_bank']}</td>
                    <td>{elements[i]['payor_name']}</td>
                    <td>{elements[i]['sales_name']}</td>
                    <td>{elements[i]['has_postage'] ? 'Postage' : ''}</td>
                    <td>{pms.join(', ')}</td>
                    <td style={{ textAlign: 'right' }}>{numeral(elements[i]['amount']).format('$0,0.00')}</td>
                    <td style={{ textAlign: 'right' }}>{numeral(elements[i]['acct_rec']).format('$0,0.00')}</td>
                    <td style={{ textAlign: 'right' }}>{numeral(elements[i]['pstg']).format('$0,0.00')}</td>
                    <td style={{ textAlign: 'right' }}>{numeral(elements[i]['other']).format('$0,0.00')}</td>
                    <td>{elements[i]['notes']}</td>
                    <td>
                        <div className="actions">
                            {editAction}
                            {deleteAction}
                        </div>
                    </td>
                </tr>
            );
        }

        return data;
    }

    handleKey(name, event) {
        const { handleChangeFilter } = this.props;
        if (event.key === 'Enter') {
            handleChangeFilter(name, event);
        }
    }

    renderSearchField(name) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <input type="text" name={name} className="search-input" onBlur={handleChangeFilter.bind(this, name)} onKeyPress={this.handleKey.bind(this, name)} />
            </th>
        );
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { totalPages, selectedPage, elementsPerPage, totalItems, handleChangeFilter, handleSetActivePage,
            amount, acct, pstg, other } = this.props;
        return (
            <div id="tables-container" className="production-container">
                <div className="table-container table-hover">
                    <table id="titles-table" className="table table-sm table-hover">
                        <thead>
                            <tr>
                                {this.renderTableHeader('Deposit Date', 'date', { width: 162 })}
                                {this.renderTableHeader('Type', 'type')}
                                {this.renderTableHeader('Check #', 'check')}
                                {this.renderTableHeader('Approval Code', 'approval_code')}
                                {this.renderTableHeader('Convenience Fee', 'convenience_fee')}
                                {this.renderTableHeader('AC or ACM bank', 'ac_bank')}
                                {this.renderTableHeader('Payor', 'payor_name')}
                                {this.renderTableHeader('Sales', 'sales_name')}
                                {this.renderTableHeader('Postage', 'has_postage')}
                                {this.renderTableHeader('PM#', 'pm_invoices')}
                                {this.renderTableHeader('Amount', 'amount', { width: 110, textAlign: 'right' })}
                                {this.renderTableHeader('ACCT. REC.', 'acct_rec', { width: 110, textAlign: 'right' })}
                                {this.renderTableHeader('ADVANCED PSTG.', 'pstg', { width: 110, textAlign: 'right' })}
                                {this.renderTableHeader('Other', 'other', { width: 110, textAlign: 'right' })}
                                {this.renderTableHeader('Comments', 'notes')}
                                <th style={{ width: 80 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="search-th multiple-date">
                                    <DayPickerInput
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        placeholder=""
                                        format="MM/DD/YY"
                                        onDayChange={handleChangeFilter.bind(this, 'start_date')}
                                    />
                                    <div className="float-left ml-1 mr-1">to</div>
                                    <DayPickerInput
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        placeholder=""
                                        format="MM/DD/YY"
                                        onDayChange={handleChangeFilter.bind(this, 'end_date')}
                                    />
                                </th>
                                {this.renderSearchSelect('type', [
                                    { label: '', value: '' },
                                    { label: 'ACH', value: 1 },
                                    { label: 'Cash', value: 2 },
                                    { label: 'Check', value: 3 },
                                    { label: 'PayPal', value: 4 },
                                    { label: 'Wire-Transfer', value: 5 },
                                    { label: 'Visa/Master', value: 6 },
                                    { label: 'AMEX', value: 7 }
                                ])}
                                {this.renderSearchField('check')}
                                {this.renderSearchField('approval_code')}
                                {this.renderSearchField('convenience_fee')}
                                {this.renderSearchField('ac_bank')}
                                {this.renderSearchField('payor_name')}
                                {this.renderSearchField('sales_name')}
                                {this.renderSearchSelect('has_postage', [
                                    { label: '', value: '' },
                                    { label: 'Yes', value: 1 },
                                    { label: 'No', value: 0 }
                                ])}
                                {this.renderSearchField('pm_invoices')}
                                <th style={{ fontWeight: 'bold', color: '#388e3c', textAlign: 'right' }}>{numeral(amount).format('$0,0.00')}</th>
                                <th style={{ fontWeight: 'bold', color: '#388e3c', textAlign: 'right' }}>{numeral(acct).format('$0,0.00')}</th>
                                <th style={{ fontWeight: 'bold', color: '#388e3c', textAlign: 'right' }}>{numeral(pstg).format('$0,0.00')}</th>
                                <th style={{ fontWeight: 'bold', color: '#388e3c', textAlign: 'right' }}>{numeral(other).format('$0,0.00')}</th>
                                {this.renderSearchField('notes')}
                                <th className="search-th"></th>
                            </tr>
                            {this.renderData()}
                        </tbody>
                    </table>
                    <Pagination.Container
                        totalItems={totalItems}
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        elementsPerPage={elementsPerPage}
                        handleSetActivePage={handleSetActivePage}
                    />
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Table;
