import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import Select from './../../common/components/Select';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import Input from './../../common/components/Input';
import TextArea from './../../common/components/TextArea';

class Quote extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        isFetchingElements: PropTypes.bool,
        handleShowDelete: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleShowForm: PropTypes.func,
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        quote: PropTypes.object,
        customerId: PropTypes.number,
        isFetchingQuote: PropTypes.bool,
        isFetchingInformation: PropTypes.bool,
        isSavingOrder: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        setCustomerId: PropTypes.func,
        handleShowQuoteInformation: PropTypes.func,
        handleSetItemQuantity: PropTypes.func,
        quoteId: PropTypes.number,
        information: PropTypes.object,
        options: PropTypes.object
    };

    renderInput(label, name, type, required, extraMessage = '', col = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div key={'quote-information-' + name} className={col}>
                <div className="labelColumn bold" style={{ width: 160 }}><label>{required ? <span className="required">*</span> : null} {label}: </label></div>
                <div className="inputColumn">
                    <Input
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                    />
                    &nbsp;
                    {extraMessage}
                </div>
            </div>
        );
    }

    renderTextArea(label, name, required) {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div key={'quote-information-' + name} className="col">
                <div className="labelColumn bold" style={{ width: 160 }}><label>{required ? <span className="required">*</span> : null} {label}: </label></div>
                <div className="inputColumn">
                    <TextArea
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                </div>
            </div>
        );
    }

    renderSelect(label, name, options, required, extraMessage = '') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div key={'quote-information-' + name} className="col">
                <div className="labelColumn bold" style={{ width: 160 }}><label>{required ? <span className="required">*</span> : null} {label}: </label></div>
                <div className="inputColumn">
                    <Select
                        name={name}
                        required={required}
                        options={options}
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                    &nbsp;
                    {extraMessage}
                </div>
            </div>
        );
    }

    getMailingNames(estimate, name, optionsName) {
        const { options } = this.props;

        if (estimate[name] == null) {
            return null;
        }

        let names = [];
        let elements = JSON.parse(estimate[name]);
        for (let i = 0; i < elements.length; i++) {
            let selectedElement = null;
            for (let j = 0; j < options[optionsName].length; j++) {
                let element = options[optionsName][j]['element'];
                if (element != null && parseInt(element['id']) === parseInt(elements[i]['element_id'])) {
                    selectedElement = element;
                    break;
                }
            }

            if (selectedElement != null) {
                names.push(selectedElement['name']);
            }
        }
        return names;
    }

    renderEstimates() {
        const { information, handleShowDelete, handleShowForm, handleSetItemQuantity } = this.props;
        return information['estimates'].map((estimate, index) => {
            let pressNames = [];
            let digitalMachineName = null;
            let largeFormatMachineName = null;
            let paperNames = [];
            let inkNames = [];
            let foldingNames = [];
            let bindingName = null;
            let substrateName = null;
            let laminateName = null;
            let digitalSides = null;
            let accessoriesNames = [];
            let quantities = [null, null, null];
            let totals = [null, null, null];

            let estimateResult1 = estimate['estimateResults'][0];
            let estimateResult2 = estimate['estimateResults'][1];
            let estimateResult3 = estimate['estimateResults'][2];

            totals = [
                estimateResult1['subtotal'] + estimateResult1['discount'] - estimateResult1['customer_discount'],
                estimateResult2['subtotal'] + estimateResult2['discount'] - estimateResult2['customer_discount'],
                estimateResult3['subtotal'] + estimateResult3['discount'] - estimateResult3['customer_discount']
            ];

            if (parseInt(estimate['type']) === 1) {
                pressNames = estimate['presses_names'];
                paperNames = estimate['paper_names'];
                inkNames.push(estimate['front_inks'] + '/' + estimate['back_inks']);
                foldingNames = estimate['folding_machine_name'];

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else if (parseInt(estimate['type']) === 2) {
                pressNames = estimate['presses_names'];
                paperNames = estimate['paper_names'];
                foldingNames = estimate['folding_machine_name'];
                bindingName = estimate['binding_machine_name'];

                let auxInkName = estimate['interior_inks'] + '/' + estimate['interior_inks'];
                if (!inkNames.includes(auxInkName)) {
                    inkNames.push(auxInkName);
                }

                if ([2, 3, 6, 7].includes(parseInt(estimate['multi_page_type']))) {
                    auxInkName = estimate['front_inks'] + '/' + estimate['back_inks'];
                    if (!inkNames.includes(auxInkName)) {
                        inkNames.push(auxInkName);
                    }
                }

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else if (parseInt(estimate['type']) === 3) {
                digitalMachineName = estimate['digital_machine_name'];
                digitalSides = estimate['digital_sides'];
                paperNames = estimate['paper_names'];
                foldingNames = estimate['folding_machine_name'];

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else if (parseInt(estimate['type']) === 4) {
                largeFormatMachineName = estimate['large_format_machine_name'];
                substrateName = estimate['substrate_name'];
                laminateName = estimate['laminate_name'];
                accessoriesNames = estimate['accessories_names'];

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            }

            let pressRow = null;
            if (pressNames != null && pressNames.length > 0) {
                pressRow = <div>Press: {pressNames.join(', ')}</div>;
            }

            let digitalMachineRow = null;
            if (digitalMachineName != null) {
                digitalMachineRow = <div>Digital Machine: {digitalMachineName}</div>;
            }

            let inkRow = null;
            if (inkNames != null && inkNames.length > 0) {
                inkRow = <div>Inks: {inkNames.join(', ')}</div>;
            }

            let paperRow = null;
            if (paperNames != null && paperNames.length > 0) {
                paperRow = <div>Paper: {paperNames.join(', ')}</div>;
            }

            let foldingRow = null;
            if (foldingNames != null && foldingNames.length > 0) {
                foldingRow = <div>Folding: {foldingNames.join(', ')}</div>;
            }

            let bindingRow = null;
            if (bindingName != null) {
                bindingRow = <div>Binding: {bindingName}</div>;
            }

            let largeFormatMachineRow = null;
            if (largeFormatMachineName != null) {
                largeFormatMachineRow = <div>Large Format Machine: {largeFormatMachineName}</div>;
            }

            let substrateRow = null;
            if (substrateName != null) {
                substrateRow = <div>Substrate: {substrateName}</div>;
            }

            let laminateRow = null;
            if (laminateName != null) {
                laminateRow = <div>Laminate: {laminateName}</div>;
            }

            let digitalSidesRow = null;
            if (digitalSides != null) {
                digitalSidesRow = <div>Sides: {digitalSides}</div>;
            }

            let accessoriesRow = null;
            if (accessoriesNames != null && accessoriesNames.length > 0) {
                accessoriesRow = <div>Accessories: {accessoriesNames.join(', ')}</div>;
            }

            return (
                <tr key={index}>
                    <td>
                        <div className="bold"><b>{estimate['product_category_name']}</b></div>
                        <div className="bold">{estimate['product_description']}</div>
                        {pressRow}
                        {digitalMachineRow}
                        {largeFormatMachineRow}
                        {paperRow}
                        {digitalSidesRow}
                        {inkRow}
                        {foldingRow}
                        {bindingRow}
                        {substrateRow}
                        {laminateRow}
                        {accessoriesRow}
                    </td>
                    <td className="price">
                        <div>
                            <input type="radio" className="mr-1" checked={parseInt(quantities[0]) === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, quantities[0])} />
                            {numeral(quantities[0]).format('0,0')}
                        </div>
                        <div>{numeral(totals[0]).format('$0,0.00')}</div>
                    </td>
                    <td className="price">
                        <div>
                            <input type="radio" className="mr-1" checked={parseInt(quantities[1]) === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, quantities[1])} />
                            {numeral(quantities[1]).format('0,0')}
                        </div>
                        <div>{numeral(totals[1]).format('$0,0.00')}</div>
                    </td>
                    <td className="price">
                        <div>
                            <input type="radio" className="mr-1" checked={parseInt(quantities[2]) === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, quantities[2])} />
                            {numeral(quantities[2]).format('0,0')}
                        </div>
                        <div>{numeral(totals[2]).format('$0,0.00')}</div>
                    </td>
                    <td className="actions">
                        <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-edit" onClick={handleShowForm.bind(this, estimate['id'])} />
                        </Tooltip>
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={handleShowDelete.bind(this, estimate['id'])} />
                        </Tooltip>
                    </td>
                </tr>
            );
        });
    }

    render() {
        const { information, handleShowForm, productsDashboardClickHandler, handleShowQuoteInformation, customerName } = this.props;

        let items = null;
        let totals = null;
        if (information['estimates'].length > 0) {
            let totalPrices = [0, 0, 0];
            for (let i = 0; i < information['estimates'].length; i++) {
                totalPrices[0] += information['estimates'][i]['estimateResults'][0]['total'];
                totalPrices[1] += information['estimates'][i]['estimateResults'][1]['total'];
                totalPrices[2] += information['estimates'][i]['estimateResults'][2]['total'];
            }

            totals = (
                <tr>
                    <td className="bold text-right">
                        Total <br />
                    </td>
                    <td className="text-right">
                        {numeral(totalPrices[0]).format('$0,0.00')} <br />
                    </td>
                    <td className="text-right">
                        {numeral(totalPrices[1]).format('$0,0.00')} <br />
                    </td>
                    <td className="text-right">
                        {numeral(totalPrices[2]).format('$0,0.00')} <br />
                    </td>
                    <td></td>
                </tr>
            );
        }

        let orderButton = (
            <div className="add-button float-right mb-3 mr-2" onClick={handleShowQuoteInformation} style={{ width: 120 }}>
                <div><i className="fas fa-mail-bulk" /> Send</div>
            </div>
        );

        if (information['estimates'].length === 0) {
            orderButton = null;
        }

        items = (
            <div className="col">
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="float-left mt-1">Items</div>
                        <div className="add-button float-right mr-0 mb-0" onClick={handleShowForm.bind(this, null)}><i className="fas fa-plus" /> Quote</div>
                        <div className="add-button float-right mr-3 mb-0" onClick={productsDashboardClickHandler}><i className="fas fa-undo" /> Return to Products Catalog</div>
                    </div>
                    <div className="card-body p-0">
                        <table id="estimates-table" className="table">
                            <tbody>
                                {this.renderEstimates()}
                                {totals}
                            </tbody>
                        </table>
                        {orderButton}
                    </div>
                </div>
            </div>
        );

        let customerRow = null;
        if (customerName !== '') {
            customerRow = (
                <div className="col" style={{ fontSize: 15, marginTop: -10, marginBottom: 5, textAlign: 'right' }}><b>Customer:</b> {customerName}</div>
            );
        }

        return (
            <div id="quote-container" className="production-container">
                <div className="table-container mt-3">
                    <div className="row">
                        {customerRow}
                        <div className="w-100"></div>
                        {items}
                        <div className="w-100"></div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Quote;
