import React from 'react';
import PropTypes from 'prop-types';

const Form = (props) => {
    const setFieldHandler = event => {
        props.setField(event.target.name, event.target.value);
    }

    const keyPressHandler = event => {
        if (event.key === 'Enter') {
            props.login();
        }
    }

    const forgotPasswordHandler = event => {
        event.preventDefault();
        props.restorePassword();
    }

    return (
        <div className="form-container">
            <div className="logo"><img src={require('./../images/logo.png')} alt="Viteci" /></div>
            <div className="form">
                <h2>Login</h2>
                <div className="form-field">
                    <label htmlFor="">Email <span className="required">*</span></label>
                    <input type="text" name="email" value={props.form.email} onChange={setFieldHandler} onKeyPress={keyPressHandler} />
                </div>
                <div className="form-field">
                    <label htmlFor="">Password <span className="required">*</span></label>
                    <input type="password" name="password" value={props.form.password} onChange={setFieldHandler} onKeyPress={keyPressHandler} />
                </div>
                <div className="text-center">
                    <div className="forgot-password">
                        <a href="/" onClick={forgotPasswordHandler}>Forgot your password</a>
                    </div>
                    <button onClick={props.login}>{props.isLogging ? <i className="fas fa-circle-notch fa-spin"></i> : 'Login'}</button>
                </div>
            </div>
            <div className="clearfix"></div>
        </div>
    );
}

Form.propTypes = {
    isLogging: PropTypes.bool.isRequired,
    setField: PropTypes.func.isRequired
}

export default Form;
