import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';
import EstimateFlat from './EstimateFlat';
import EstimateMultiPage from './EstimateMultiPage';
import EstimateDigital from './EstimateDigital';
import EstimateLargeFormat from './EstimateLargeFormat';
import EstimateDataProcessing from './EstimateDataProcessing';
import EstimateInkjet from './EstimateInkjet';
import EstimateInserting from './EstimateInserting';
import EstimateCertMail from './EstimateCertMail';
import EstimateArt from './EstimateArt';
import EstimateHandwork from './EstimateHandwork';
import EstimatePressureSeal from './EstimatePressureSeal';
import EstimateIndexTab from './EstimateIndexTab';
import EstimateLabel from './EstimateLabel';
import EstimateNcrForm from './EstimateNcrForm';

class Form extends Component {
    static propTypes = {
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        handleUpdateFormValue: PropTypes.func,
        handleSaveElement: PropTypes.func,
        handleUpdateDayValue: PropTypes.func,
        handleCheckProperty: PropTypes.func,
        hideForm: PropTypes.func,
        isSavingEstimate: PropTypes.bool,
        isFetchingElement: PropTypes.bool,
        handleEstimate: PropTypes.func,
        isLoadingEstimate: PropTypes.bool,
        estimateResults: PropTypes.array,
        handleAccessoryClick: PropTypes.array,
        estimateAccessories: PropTypes.array,
        activeProduct: PropTypes.object,
        handleSaveEstimate: PropTypes.func,
        handleUpdateOutsideService: PropTypes.func,
        handleAddOutsideService: PropTypes.func,
        handleDeleteOutsideService: PropTypes.func,
        information: PropTypes.func,
        options: PropTypes.func,
        fetching: PropTypes.object,
        addMailingElement: PropTypes.func,
        handleUpdateMailingElement: PropTypes.func,
        handleRemoveMailingElement: PropTypes.func
    };

    render() {
        const { formValues, handleSaveEstimate, handleEstimate, hideForm, isSavingEstimate, isLoadingEstimate,
            isFetchingElement, fetching, formErrors, handleUpdateFormValue, formErrorMessages, estimateResults,
            options, information, estimateAccessories, handleAccessoryClick, addMailingElement, handleUpdateMailingElement,
            handleRemoveMailingElement, handleAddOutsideService, handleDeleteOutsideService, handleUpdateOutsideService } = this.props;

        let form = null;
        if (fetching['estimate']) {
            form = (
                <div className="loading">
                    <i className="fas fa-circle-notch fa-spin" /> Loading...
                </div>
            );
        } else {
            if (parseInt(formValues['estimate_type']) === 1) {
                form = (
                    <EstimateFlat
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 2) {
                form = (
                    <EstimateMultiPage
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 3) {
                form = (
                    <EstimateDigital
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 4) {
                form = (
                    <EstimateLargeFormat
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        estimateAccessories={estimateAccessories}
                        handleAccessoryClick={handleAccessoryClick}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 5) {
                form = (
                    <EstimateDataProcessing
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                        addMailingElement={addMailingElement}
                        handleUpdateMailingElement={handleUpdateMailingElement}
                        handleRemoveMailingElement={handleRemoveMailingElement}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 6) {
                form = (
                    <EstimateInkjet
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                        addMailingElement={addMailingElement}
                        handleUpdateMailingElement={handleUpdateMailingElement}
                        handleRemoveMailingElement={handleRemoveMailingElement}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 7) {
                form = (
                    <EstimateInserting
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                        addMailingElement={addMailingElement}
                        handleUpdateMailingElement={handleUpdateMailingElement}
                        handleRemoveMailingElement={handleRemoveMailingElement}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 8) {
                form = (
                    <EstimateCertMail
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                        addMailingElement={addMailingElement}
                        handleUpdateMailingElement={handleUpdateMailingElement}
                        handleRemoveMailingElement={handleRemoveMailingElement}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 9) {
                form = (
                    <EstimateArt
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                        addMailingElement={addMailingElement}
                        handleUpdateMailingElement={handleUpdateMailingElement}
                        handleRemoveMailingElement={handleRemoveMailingElement}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 10) {
                form = (
                    <EstimateHandwork
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                        addMailingElement={addMailingElement}
                        handleUpdateMailingElement={handleUpdateMailingElement}
                        handleRemoveMailingElement={handleRemoveMailingElement}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 11) {
                form = (
                    <EstimatePressureSeal
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                        addMailingElement={addMailingElement}
                        handleUpdateMailingElement={handleUpdateMailingElement}
                        handleRemoveMailingElement={handleRemoveMailingElement}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 12) {
                form = (
                    <EstimateIndexTab
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                        addMailingElement={addMailingElement}
                        handleUpdateMailingElement={handleUpdateMailingElement}
                        handleRemoveMailingElement={handleRemoveMailingElement}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 13) {
                form = (
                    <EstimateLabel
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                        addMailingElement={addMailingElement}
                        handleUpdateMailingElement={handleUpdateMailingElement}
                        handleRemoveMailingElement={handleRemoveMailingElement}
                    />
                );
            } else if (parseInt(formValues['estimate_type']) === 14) {
                form = (
                    <EstimateNcrForm
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue}
                        formErrorMessages={formErrorMessages}
                        estimateResults={estimateResults}
                        options={options}
                        information={information}
                        fetching={fetching}
                        handleAddOutsideService={handleAddOutsideService}
                        handleDeleteOutsideService={handleDeleteOutsideService}
                        handleUpdateOutsideService={handleUpdateOutsideService}
                        addMailingElement={addMailingElement}
                        handleUpdateMailingElement={handleUpdateMailingElement}
                        handleRemoveMailingElement={handleRemoveMailingElement}
                    />
                );
            }

            if (isFetchingElement) {
                form = <div className="loading-data"><i className="fas fa-circle-notch fa-spin" /> Loading data...</div>;
            }
        }

        let saveButtonLabel = <div><i className="fas fa-save" /> Save</div>;
        if (isSavingEstimate) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let estimateLabel = <div><i className="fas fa-calculator" /> Estimate</div>;
        if (isLoadingEstimate) {
            estimateLabel = <div><i className="fas fa-circle-notch fa-spin" /></div>;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button" onClick={handleSaveEstimate} disabled={isSavingEstimate}>{saveButtonLabel}</button>,
            [5, 6, 7, 8, 9, 10, 11, 12, 13, 14].includes(parseInt(formValues['estimate_type'])) ? null : <button key="estimate-button" className="btn btn-light btn-sm action-button" onClick={handleEstimate} disabled={isLoadingEstimate}>{estimateLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideForm}>Close</button>
        ];
        return (
            <Dialog
                name="form"
                modal={false}
                actions={actions}
                contentStyle={{ width: 1100, maxWidth: 1100 }}
                bodyStyle={{ overflow: 'initial' }}
                className="large-dialog"
            >
                <div className="form-container estimate-container">{form}</div>
            </Dialog>
        );
    }
}

export default Form;
