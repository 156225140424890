import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from './../../common/Pagination';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

class Table extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        isFetchingElements: PropTypes.bool,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        user: PropTypes.object
    };

    renderTableHeader(label, name, style, sortable = true) {
        const { handleSort, sortColumn, sortType } = this.props;

        let icon = <i className="fas fa-sort" />;
        if (sortColumn === name) {
            if (sortType === 'asc') {
                icon = <i className="fas fa-sort-up" />;
            } else {
                icon = <i className="fas fa-sort-down" />;
            }
        }

        if (sortable) {
            return <th style={style}><span onClick={handleSort.bind(this, name)}>{label} {icon}</span></th>;
        }

        return <th style={style}>{label}</th>;
    }

    renderData() {
        const { elements, isFetchingElements, quoteClickHandler, isFetchingData } = this.props;

        if (isFetchingElements || isFetchingData) {
            return (
                <tr>
                    <td colSpan="7"><i className="fas fa-circle-notch fa-spin" /> Loading data...</td>
                </tr>
            );
        }

        if (elements.length === 0) {
            return (
                <tr>
                    <td colSpan="7">No Results</td>
                </tr>
            );
        }

        let data = [];
        for (let i = 0; i < elements.length; i++) {
            if (parseInt(elements[i].product_type) === 1 || parseInt(elements[i].product_type) === 3) {    // Flat or Digital
                data.push(
                    <tr key={i}>
                        <td>{elements[i].name}</td>
                        <td>{elements[i].size}</td>
                        <td>{elements[i].paper_category_name}</td>
                        <td>{elements[i].weight}</td>
                        <td>{elements[i].paper_name}</td>
                        <td>{elements[i].production_notes}</td>
                        <td>
                            <div className="actions" onClick={quoteClickHandler.bind(this, elements[i].id)}>
                                <Tooltip title="Quote" position="bottom" arrow size="small" className="action-tooltip" style={{ cursor: 'pointer' }} >
                                    <i className="fas fa-share"></i> Quote
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                );
            } else if (parseInt(elements[i].product_type) === 2) {    // Multi-Page
                data.push(
                    <tr key={i}>
                        <td>{elements[i].name}</td>
                        <td>{elements[i].multi_page_type}</td>
                        <td>{elements[i].size}</td>
                        <td>{elements[i].pages}</td>
                        <td>{elements[i].cover}</td>
                        <td>{elements[i].paper_category_name}</td>
                        <td>{elements[i].weight}</td>
                        <td>{elements[i].paper_name}</td>
                        <td>{elements[i].production_notes}</td>
                        <td>
                            <div className="actions" onClick={quoteClickHandler.bind(this, elements[i].id)}>
                                <Tooltip title="Quote" position="bottom" arrow size="small" className="action-tooltip" style={{ cursor: 'pointer' }}>
                                    <i className="fas fa-share"></i> Quote
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                );
            } else if (parseInt(elements[i].product_type) === 4) {    // Large Format
                data.push(
                    <tr key={i}>
                        <td>{elements[i].name}</td>
                        <td>{elements[i].size}</td>
                        <td>{elements[i].substrate_name}</td>
                        <td>{elements[i].laminate_name}</td>
                        <td>{elements[i].accesories_names}</td>
                        <td>{elements[i].production_notes}</td>
                        <td>
                            <div className="actions" onClick={quoteClickHandler.bind(this, elements[i].id)}>
                                <Tooltip title="Quote" position="bottom" arrow size="small" className="action-tooltip" style={{ cursor: 'pointer' }}>
                                    <i className="fas fa-share"></i> Quote
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                );
            }
        }

        return data;
    }

    renderSearchSelect(name, options) {
        const { handleChangeFilter } = this.props;
        return (
            <th className="search-th">
                <select type="text" name={name} className="search-input" onChange={handleChangeFilter.bind(this, name)} >
                    {options.map((option, index) => {
                        return (
                            <option key={index} value={option['value']}>{option['label']}</option>
                        );
                    })}
                </select>
            </th>
        );
    }

    render() {
        const { totalPages, selectedPage, elementsPerPage, totalItems, handleSetActivePage, productCategories, productCategoryId, 
            productType, changeProductTypeHandler, user, customers, customerId, setCustomerId } = this.props;

        let options = [<option value="">Select option</option>];
        for (let i = 0; i < productCategories.length; i++) {
            options.push(<option value={productCategories[i].id + '-' + productCategories[i].type}>{productCategories[i].name}</option>);
        }

        let headers = null;
        if (parseInt(productType) === 1 || parseInt(productType) === 3) {     // Flat or Digital
            headers = (
                <tr>
                    {this.renderTableHeader('Description', 'description')}
                    {this.renderTableHeader('Size', 'size')}
                    {this.renderTableHeader('Paper Type', 'paper_type')}
                    {this.renderTableHeader('Weight', 'paper_weight')}
                    {this.renderTableHeader('Paper Name', 'paper_name')}
                    {this.renderTableHeader('Production Note', 'production_note')}
                    <th style={{ width: 80 }}></th>
                </tr>
            );
        } else if(parseInt(productType) === 2) {   // Multi-Page
            headers = (
                <tr>
                    {this.renderTableHeader('Description', 'description')}
                    {this.renderTableHeader('Multi-Page Type', 'multi_page_type')}
                    {this.renderTableHeader('Size', 'size')}
                    {this.renderTableHeader('Pages', 'pages')}
                    {this.renderTableHeader('Cover', 'cover')}
                    {this.renderTableHeader('Paper Type', 'paper_type')}
                    {this.renderTableHeader('Weight', 'paper_weight')}
                    {this.renderTableHeader('Paper Name', 'paper_name')}
                    {this.renderTableHeader('Production Note', 'production_note')}
                    <th style={{ width: 80 }}></th>
                </tr>
            );
        } else if(parseInt(productType) === 4) {   // Large Format
            headers = (
                <tr>
                    {this.renderTableHeader('Description', 'description')}
                    {this.renderTableHeader('Size', 'size')}
                    {this.renderTableHeader('Substrate', 'substrate_name')}
                    {this.renderTableHeader('Laminate', 'laminate_name')}
                    {this.renderTableHeader('Accessories', 'accesories_names')}
                    {this.renderTableHeader('Production Note', 'production_note')}
                    <th style={{ width: 80 }}></th>
                </tr>
            );
        }

        let customerSelect = null;
        if(parseInt(user['user_type_1']) !== 19) {     // Products Only
            let customersOptions = [<option value="">Select option</option>];
            for (let i = 0; i < customers.length; i++) {
                customersOptions.push(<option value={customers[i].id}>{customers[i].name}</option>);
            }

            customerSelect = (
                <div className="customer-container mb-2 float-left ml-5">
                    Customer:
                    <select className="ml-1" style={{ height: 25 }} value={customerId} onChange={(event) => setCustomerId(event.target.value)}>
                        {customersOptions}
                    </select>
                </div>
            );
        }

        return (
            <div id="products-dashboard-table" className="production-container products-dashboard-table">
                <div className="table-container table-hover" style={{ border: '1px solid #cfd3d7' }}>
                    <div className="product-type-container mb-2 float-left">
                        Product Type:
                        <select className="ml-1" style={{ height: 25 }} value={productCategoryId + '-' + productType} onChange={changeProductTypeHandler}>
                            {options}
                        </select>
                    </div>
                    {customerSelect}
                    <div className="clearfix"></div>
                    <table id="titles-table" className="table table-sm table-hover">
                        <thead>{headers}</thead>
                        <tbody>
                            {this.renderData()}
                        </tbody>
                    </table>
                    <Pagination.Container
                        totalItems={totalItems}
                        totalPages={totalPages}
                        selectedPage={selectedPage}
                        elementsPerPage={elementsPerPage}
                        handleSetActivePage={handleSetActivePage}
                    />
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Table;
