import { createSelector } from 'reselect';
import { NAME } from './constants';

export const getModel = state => state[NAME];

/** activeElement **/
export const getActiveElementModel = createSelector(
    getModel,
    (model) => model.activeElement
);

/** activeElement **/
export const getActiveElement = createSelector(
    getActiveElementModel,
    (activeElement) => activeElement.data
);
export const getIsFetchingElement = createSelector(
    getActiveElementModel,
    (activeElement) => activeElement.isFetching
);

/** form **/
export const getForm = createSelector(
    getModel,
    (model) => model.form
);
export const getFormValues = createSelector(
    getForm,
    (form) => form.formValues
);
export const getIsSavingForm = createSelector(
    getForm,
    (form) => form.isSaving
);
export const getFormErrors = createSelector(
    getForm,
    (form) => form.formErrors
);
export const getFormErrorsMessages = createSelector(
    getForm,
    (form) => form.formErrorMessages
);

/** elements **/
export const getElements = createSelector(
    getModel,
    (model) => model.elements.data
);
export const getProcessedElements = createSelector(
    getModel,
    (model) => model.elements.processedElements
);
export const getIsFetchingElements = createSelector(
    getModel,
    (model) => model.elements.isFetching
);

/** delete **/
export const getIsDeleting = createSelector(
    getModel,
    (model) => model.delete.isDeleting
);
export const getIdForDelete = createSelector(
    getModel,
    (model) => model.delete.id
);

/** pagination **/
export const getTotalPages = createSelector(
    getModel,
    (model) => model.pagination.totalPages
);
export const getSelectedPage = createSelector(
    getModel,
    (model) => model.pagination.selectedPage
);
export const getElementsPerPage = createSelector(
    getModel,
    (model) => model.pagination.elementsPerPage
);

/** sort **/
export const getSortColumn = createSelector(
    getModel,
    (model) => model.sort.column
);
export const getSortType = createSelector(
    getModel,
    (model) => model.sort.type
);

/** filter **/
export const getFilterFields = createSelector(
    getModel,
    (model) => model.filter.fields
);

/** data **/
export const getData = createSelector(
    getModel,
    (model) => model.data
);
