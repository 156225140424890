import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import Select from './../../common/components/Select';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import Input from './../../common/components/Input';
import TextArea from './../../common/components/TextArea';
import apiUrls from './../apiUrls';

class Quote extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        isFetchingElements: PropTypes.bool,
        handleShowDelete: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleChangeAttribute: PropTypes.func,
        handleInlineChange: PropTypes.func,
        handleDayChange: PropTypes.func,
        handleViewProperty: PropTypes.func,
        handleShowForm: PropTypes.func,
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        quote: PropTypes.object,
        customerId: PropTypes.number,
        handleSaveElement: PropTypes.func,
        isFetchingQuote: PropTypes.bool,
        isFetchingInformation: PropTypes.bool,
        isSavingOrder: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        setCustomerId: PropTypes.func,
        handleConvertToOrder: PropTypes.func,
        handleSetItemQuantity: PropTypes.func,
        handleSetCustomerId: PropTypes.func,
        handleEditingCustomer: PropTypes.func,
        editingCustomer: PropTypes.bool,
        quoteId: PropTypes.number,
        information: PropTypes.object,
        options: PropTypes.object
    };

    renderInput(label, name, type, required, extraMessage = '', col = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div key={'quote-information-' + name} className={col}>
                <div className="labelColumn bold" style={{ width: 160 }}><label>{required ? <span className="required">*</span> : null} {label}: </label></div>
                <div className="inputColumn">
                    <Input
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                    />
                    &nbsp;
                    {extraMessage}
                </div>
            </div>
        );
    }

    renderTextArea(label, name, required) {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div key={'quote-information-' + name} className="col">
                <div className="labelColumn bold" style={{ width: 160 }}><label>{required ? <span className="required">*</span> : null} {label}: </label></div>
                <div className="inputColumn">
                    <TextArea
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                </div>
            </div>
        );
    }

    renderSelect(label, name, options, required, extraMessage = '') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div key={'quote-information-' + name} className="col">
                <div className="labelColumn bold" style={{ width: 160 }}><label>{required ? <span className="required">*</span> : null} {label}: </label></div>
                <div className="inputColumn">
                    <Select
                        name={name}
                        required={required}
                        options={options}
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                    &nbsp;
                    {extraMessage}
                </div>
            </div>
        );
    }

    getMailingNames(estimate, name, optionsName) {
        const { options } = this.props;

        if (estimate[name] == null) {
            return null;
        }

        let names = [];
        let elements = JSON.parse(estimate[name]);
        for (let i = 0; i < elements.length; i++) {
            let selectedElement = null;
            for (let j = 0; j < options[optionsName].length; j++) {
                let element = options[optionsName][j]['element'];
                if (element != null && parseInt(element['id']) === parseInt(elements[i]['element_id'])) {
                    selectedElement = element;
                    break;
                }
            }

            if (selectedElement != null) {
                names.push(selectedElement['name']);
            }
        }
        return names;
    }

    renderEstimates() {
        const { information, handleShowDelete, handleShowForm, handleSetItemQuantity } = this.props;
        return information['estimates'].map((estimate, index) => {
            let pressNames = [];
            let digitalMachineName = null;
            let largeFormatMachineName = null;
            let paperNames = [];
            let inkNames = [];
            let foldingNames = [];
            let bindingName = null;
            let substrateName = null;
            let laminateName = null;
            let digitalSides = null;
            let accessoriesNames = [];
            let dataProcessingNames = [];
            let inkjetNames = [];
            let insertingNames = [];
            let certMailNames = [];
            let artNames = [];
            let handworkNames = [];
            let pressureSealNames = [];
            let indexTabNames = [];
            let labelNames = [];
            let ncrFormNames = [];
            let quantities = [null, null, null];
            let totals = [null, null, null];

            let estimateResult1 = estimate['estimateResults'][0];
            let estimateResult2 = estimate['estimateResults'][1];
            let estimateResult3 = estimate['estimateResults'][2];

            totals = [
                estimateResult1['subtotal'] + estimateResult1['discount'],
                estimateResult2['subtotal'] + estimateResult2['discount'],
                estimateResult3['subtotal'] + estimateResult3['discount']
            ];

            if (parseInt(estimate['type']) === 1) {
                pressNames = estimate['presses_names'];
                paperNames = estimate['paper_names'];
                inkNames.push(estimate['front_inks'] + '/' + estimate['back_inks']);
                foldingNames = estimate['folding_machine_name'];

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else if (parseInt(estimate['type']) === 2) {
                pressNames = estimate['presses_names'];
                paperNames = estimate['paper_names'];
                foldingNames = estimate['folding_machine_name'];
                bindingName = estimate['binding_machine_name'];

                let auxInkName = estimate['interior_inks'] + '/' + estimate['interior_inks'];
                if (!inkNames.includes(auxInkName)) {
                    inkNames.push(auxInkName);
                }

                if ([2, 3, 6, 7].includes(parseInt(estimate['multi_page_type']))) {
                    auxInkName = estimate['front_inks'] + '/' + estimate['back_inks'];
                    if (!inkNames.includes(auxInkName)) {
                        inkNames.push(auxInkName);
                    }
                }

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else if (parseInt(estimate['type']) === 3) {
                digitalMachineName = estimate['digital_machine_name'];
                digitalSides = estimate['digital_sides'];
                paperNames = estimate['paper_names'];
                foldingNames = estimate['folding_machine_name'];

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else if (parseInt(estimate['type']) === 4) {
                largeFormatMachineName = estimate['large_format_machine_name'];
                substrateName = estimate['substrate_name'];
                laminateName = estimate['laminate_name'];
                accessoriesNames = estimate['accessories_names'];

                quantities = [estimateResult1['quantity'], estimateResult2['quantity'], estimateResult3['quantity']];
            } else if (parseInt(estimate['type']) === 5) {
                dataProcessingNames = this.getMailingNames(estimate, 'data_processing_elements_ids', 'dataProcessingElements');
                quantities = [1, 1, 1];
            } else if (parseInt(estimate['type']) === 6) {
                inkjetNames = inkjetNames.concat(this.getMailingNames(estimate, 'inkjet_elements_ids', 'inkjetElements'));
                inkjetNames = inkjetNames.concat(this.getMailingNames(estimate, 'wafer_seal_elements_ids', 'waferSealElements'));
                inkjetNames = inkjetNames.concat(this.getMailingNames(estimate, 'affix_elements_ids', 'affixElements'));

                quantities = [1, 1, 1];
            } else if (parseInt(estimate['type']) === 7) {
                insertingNames = insertingNames.concat(this.getMailingNames(estimate, 'inserting_elements_ids', 'insertingElements'));
                insertingNames = insertingNames.concat(this.getMailingNames(estimate, 'perfect_match_elements_ids', 'perfectMatchElements'));

                quantities = [1, 1, 1];
            } else if (parseInt(estimate['type']) === 8) {
                certMailNames = this.getMailingNames(estimate, 'cert_mail_elements_ids', 'certMailElements');
                quantities = [1, 1, 1];
            } else if (parseInt(estimate['type']) === 9) {
                artNames = this.getMailingNames(estimate, 'art_elements_ids', 'artElements');
                quantities = [1, 1, 1];
            } else if (parseInt(estimate['type']) === 10) {
                handworkNames = this.getMailingNames(estimate, 'handwork_elements_ids', 'handworkElements');
                quantities = [1, 1, 1];
            } else if (parseInt(estimate['type']) === 11) {
                pressureSealNames = this.getMailingNames(estimate, 'pressure_seal_elements_ids', 'pressureSealElements');
                quantities = [1, 1, 1];
            } else if (parseInt(estimate['type']) === 12) {
                indexTabNames = this.getMailingNames(estimate, 'index_tab_elements_ids', 'indexTabElements');
                quantities = [1, 1, 1];
            } else if (parseInt(estimate['type']) === 13) {
                labelNames = this.getMailingNames(estimate, 'label_elements_ids', 'labelElements');
                quantities = [1, 1, 1];
            } else if (parseInt(estimate['type']) === 14) {
                ncrFormNames = this.getMailingNames(estimate, 'ncr_form_elements_ids', 'ncrFormElements');
                quantities = [1, 1, 1];
            }

            let pressRow = null;
            if (pressNames != null && pressNames.length > 0) {
                pressRow = <div>Press: {pressNames.join(', ')}</div>;
            }

            let digitalMachineRow = null;
            if (digitalMachineName != null) {
                digitalMachineRow = <div>Digital Machine: {digitalMachineName}</div>;
            }

            let inkRow = null;
            if (inkNames != null && inkNames.length > 0) {
                inkRow = <div>Inks: {inkNames.join(', ')}</div>;
            }

            let paperRow = null;
            if (paperNames != null && paperNames.length > 0) {
                paperRow = <div>Paper: {paperNames.join(', ')}</div>;
            }

            let foldingRow = null;
            if (foldingNames != null && foldingNames.length > 0) {
                foldingRow = <div>Folding: {foldingNames.join(', ')}</div>;
            }

            let bindingRow = null;
            if (bindingName != null) {
                bindingRow = <div>Binding: {bindingName}</div>;
            }

            let largeFormatMachineRow = null;
            if (largeFormatMachineName != null) {
                largeFormatMachineRow = <div>Large Format Machine: {largeFormatMachineName}</div>;
            }

            let substrateRow = null;
            if (substrateName != null) {
                substrateRow = <div>Substrate: {substrateName}</div>;
            }

            let laminateRow = null;
            if (laminateName != null) {
                laminateRow = <div>Laminate: {laminateName}</div>;
            }

            let digitalSidesRow = null;
            if (digitalSides != null) {
                digitalSidesRow = <div>Sides: {digitalSides}</div>;
            }

            let accessoriesRow = null;
            if (accessoriesNames != null && accessoriesNames.length > 0) {
                accessoriesRow = <div>Accessories: {accessoriesNames.join(', ')}</div>;
            }

            let dataProcessingRow = null;
            if (dataProcessingNames.length > 0) {
                dataProcessingRow = <div>{dataProcessingNames.join(', ')}</div>;
            }

            let inkjetRow = null;
            if (inkjetNames.length > 0) {
                inkjetRow = <div>{inkjetNames.join(', ')}</div>;
            }

            let insertingRow = null;
            if (insertingNames.length > 0) {
                insertingRow = <div>{insertingNames.join(', ')}</div>;
            }

            let certMailRow = null;
            if (certMailNames.length > 0) {
                certMailRow = <div>{certMailNames.join(', ')}</div>;
            }

            let artRow = null;
            if (artNames.length > 0) {
                artRow = <div>{artNames.join(', ')}</div>;
            }

            let handworkRow = null;
            if (handworkNames.length > 0) {
                handworkRow = <div>{handworkNames.join(', ')}</div>;
            }

            let pressureSealRow = null;
            if (pressureSealNames.length > 0) {
                pressureSealRow = <div>{pressureSealNames.join(', ')}</div>;
            }

            let indexTabRow = null;
            if (indexTabNames.length > 0) {
                indexTabRow = <div>{indexTabNames.join(', ')}</div>;
            }

            let labelRow = null;
            if (labelNames.length > 0) {
                labelRow = <div>{labelNames.join(', ')}</div>;
            }

            let ncrFormRow = null;
            if (ncrFormNames.length > 0) {
                ncrFormRow = <div>{ncrFormNames.join(', ')}</div>;
            }

            return (
                <tr key={index}>
                    <td>
                        <div className="bold"><b>{estimate['product_category_name']}</b></div>
                        <div className="bold">{estimate['product_description']}</div>
                        {pressRow}
                        {digitalMachineRow}
                        {largeFormatMachineRow}
                        {paperRow}
                        {digitalSidesRow}
                        {inkRow}
                        {foldingRow}
                        {bindingRow}
                        {substrateRow}
                        {laminateRow}
                        {accessoriesRow}
                        {dataProcessingRow}
                        {inkjetRow}
                        {insertingRow}
                        {certMailRow}
                        {artRow}
                        {handworkRow}
                        {pressureSealRow}
                        {indexTabRow}
                        {labelRow}
                        {ncrFormRow}
                    </td>
                    <td className="price">
                        <div>
                            <input type="radio" className="mr-1" checked={quantities[0] === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, quantities[0])} />
                            {numeral(quantities[0]).format('0,0')}
                        </div>
                        <div>{numeral(totals[0]).format('$0,0.00')}</div>
                    </td>
                    <td className="price">
                        <div>
                            <input type="radio" className="mr-1" checked={quantities[1] === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, quantities[1])} />
                            {numeral(quantities[1]).format('0,0')}
                        </div>
                        <div>{numeral(totals[1]).format('$0,0.00')}</div>
                    </td>
                    <td className="price">
                        <div>
                            <input type="radio" className="mr-1" checked={quantities[2] === parseInt(estimate['selected_quantity'])}
                                onClick={handleSetItemQuantity.bind(this, index, quantities[2])} />
                            {numeral(quantities[2]).format('0,0')}
                        </div>
                        <div>{numeral(totals[2]).format('$0,0.00')}</div>
                    </td>
                    <td className="actions">
                        <Tooltip title="Edit" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-edit" onClick={handleShowForm.bind(this, estimate['id'])} />
                        </Tooltip>
                        <Tooltip title="Delete" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-trash" onClick={handleShowDelete.bind(this, estimate['id'])} />
                        </Tooltip>
                    </td>
                </tr>
            );
        });
    }

    renderCustomers() {
        const { information } = this.props;
        return information['customers'].map((customer, index) => {
            return <option key={index} value={customer['id']}>{customer['name']}</option>;
        });
    }

    render() {
        const { information, quote, customerId, handleShowForm, quoteId, handleSaveElement, isFetchingQuote,
            isFetchingInformation, isSavingForm, isSavingOrder, handleConvertToOrder, handleSetCustomerId,
            options, formValues } = this.props;

        if (isFetchingQuote || isFetchingInformation) {
            return (
                <div className="loading">
                    <i className="fas fa-circle-notch fa-spin" /> Loading...
                </div>
            );
        }

        let customer = null;
        for (let i = 0; i < information['customers'].length; i++) {
            if (parseInt(information['customers'][i]['id']) === parseInt(customerId)) {
                customer = information['customers'][i];
                break;
            }
        }

        let items = null;
        if (quoteId != null) {
            let totals = null;
            if (information['estimates'].length > 0) {
                let subtotals = [0, 0, 0];
                let customerDiscounts = [0, 0, 0];
                let quoteDiscounts = [0, 0, 0];
                let taxes = [0, 0, 0];
                let rushFees = [0, 0, 0];
                let totalPrices = [0, 0, 0];
                for (let i = 0; i < information['estimates'].length; i++) {
                    subtotals[0] += information['estimates'][i]['estimateResults'][0]['subtotal'] + information['estimates'][i]['estimateResults'][0]['discount'];
                    subtotals[1] += information['estimates'][i]['estimateResults'][1]['subtotal'] + information['estimates'][i]['estimateResults'][1]['discount'];
                    subtotals[2] += information['estimates'][i]['estimateResults'][2]['subtotal'] + information['estimates'][i]['estimateResults'][2]['discount'];

                    customerDiscounts[0] += information['estimates'][i]['estimateResults'][0]['customer_discount'];
                    customerDiscounts[1] += information['estimates'][i]['estimateResults'][1]['customer_discount'];
                    customerDiscounts[2] += information['estimates'][i]['estimateResults'][2]['customer_discount'];

                    quoteDiscounts[0] += information['estimates'][i]['estimateResults'][0]['quote_discount'];
                    quoteDiscounts[1] += information['estimates'][i]['estimateResults'][1]['quote_discount'];
                    quoteDiscounts[2] += information['estimates'][i]['estimateResults'][2]['quote_discount'];

                    taxes[0] += information['estimates'][i]['estimateResults'][0]['tax'];
                    taxes[1] += information['estimates'][i]['estimateResults'][1]['tax'];
                    taxes[2] += information['estimates'][i]['estimateResults'][2]['tax'];

                    rushFees[0] += information['estimates'][i]['estimateResults'][0]['rush_fee'];
                    rushFees[1] += information['estimates'][i]['estimateResults'][1]['rush_fee'];
                    rushFees[2] += information['estimates'][i]['estimateResults'][2]['rush_fee'];

                    totalPrices[0] += information['estimates'][i]['estimateResults'][0]['total'];
                    totalPrices[1] += information['estimates'][i]['estimateResults'][1]['total'];
                    totalPrices[2] += information['estimates'][i]['estimateResults'][2]['total'];
                }

                let hasCustomerDiscount = customerDiscounts[0] > 0;
                let hasCustomerMarkup = customerDiscounts[0] < 0;
                let hasQuoteDiscount = quoteDiscounts[0] > 0;
                let hasTax = taxes[0] > 0;
                let hasRushFee = rushFees[0] > 0;

                totals = (
                    <tr>
                        <td className="bold text-right">
                            Subtotal <br />
                            {hasTax ? <div>Tax</div> : null}
                            {hasRushFee ? <div>Rush Fee</div> : null}
                            {hasCustomerDiscount ? <div>Customer Discount</div> : null}
                            {hasCustomerMarkup ? <div>Customer Markup</div> : null}
                            {hasQuoteDiscount ? <div>Quote Discount</div> : null}
                            Total <br />
                        </td>
                        <td className="text-right">
                            {numeral(subtotals[0]).format('$0,0.00')} <br />
                            {hasTax ? <div>{numeral(taxes[0]).format('$0,0.00')}</div> : null}
                            {hasRushFee ? <div>{numeral(rushFees[0]).format('$0,0.00')}</div> : null}
                            {hasCustomerDiscount ? <div>-{numeral(customerDiscounts[0]).format('$0,0.00')}</div> : null}
                            {hasCustomerMarkup ? <div>{numeral(-customerDiscounts[0]).format('$0,0.00')}</div> : null}
                            {hasQuoteDiscount ? <div>-{numeral(quoteDiscounts[0]).format('$0,0.00')}</div> : null}
                            {numeral(totalPrices[0]).format('$0,0.00')} <br />
                        </td>
                        <td className="text-right">
                            {numeral(subtotals[1]).format('$0,0.00')} <br />
                            {hasTax ? <div>{numeral(taxes[1]).format('$0,0.00')}</div> : null}
                            {hasRushFee ? <div>{numeral(rushFees[1]).format('$0,0.00')}</div> : null}
                            {hasCustomerDiscount ? <div>-{numeral(customerDiscounts[1]).format('$0,0.00')}</div> : null}
                            {hasCustomerMarkup ? <div>{numeral(-customerDiscounts[1]).format('$0,0.00')}</div> : null}
                            {hasQuoteDiscount ? <div>-{numeral(quoteDiscounts[1]).format('$0,0.00')}</div> : null}
                            {numeral(totalPrices[1]).format('$0,0.00')} <br />
                        </td>
                        <td className="text-right">
                            {numeral(subtotals[2]).format('$0,0.00')} <br />
                            {hasTax ? <div>{numeral(taxes[2]).format('$0,0.00')}</div> : null}
                            {hasRushFee ? <div>{numeral(rushFees[2]).format('$0,0.00')}</div> : null}
                            {hasCustomerDiscount ? <div>-{numeral(customerDiscounts[2]).format('$0,0.00')}</div> : null}
                            {hasCustomerMarkup ? <div>{numeral(-customerDiscounts[2]).format('$0,0.00')}</div> : null}
                            {hasQuoteDiscount ? <div>-{numeral(quoteDiscounts[2]).format('$0,0.00')}</div> : null}
                            {numeral(totalPrices[2]).format('$0,0.00')} <br />
                        </td>
                        <td></td>
                    </tr>
                );
            }

            let exportButton = null;
            if (formValues['id'] !== '') {
                exportButton = (
                    <div className="add-button float-right mb-3 mr-2" style={{ width: 140 }}
                        onClick={() => window.open(apiUrls.exportPdf + '?id=' + quoteId, '_blank')}>
                        <div><i className="far fa-file-pdf" /> Export PDF</div>
                    </div>
                );
            }

            let orderButton = null;
            let orderButtonLabel = <div><i className="fas fa-calendar-alt" /> Convert to Order</div>;
            if (isSavingOrder) {
                orderButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
            }
            orderButton = (
                <div className="add-button float-right mb-3 mr-2" onClick={handleConvertToOrder} style={{ width: 160 }}>
                    {orderButtonLabel}
                </div>
            );

            if (information['estimates'].length === 0) {
                orderButton = null;
                exportButton = null;
            }

            items = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="float-left mt-1">Items</div>
                            <div className="add-button float-right mr-0 mb-0" onClick={handleShowForm.bind(this, null)}><i className="fas fa-plus" /> Add</div>
                        </div>
                        <div className="card-body p-0">
                            <table id="estimates-table" className="table">
                                <tbody>
                                    {this.renderEstimates()}
                                    {totals}
                                </tbody>
                            </table>
                            {orderButton}
                            {exportButton}
                        </div>
                    </div>
                </div>
            );
        }

        let saveButtonLabel = <div><i className="fas fa-save" /> Save</div>;
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let address = '';
        let resellerId = null;
        let taxCode = null;
        let industry = '';
        let discountTableName = 'None';
        let discountType = 'Discount';
        if (customer != null) {
            address = customer['street'] + ', ' + customer['city'] + ', ' + customer['state'] + ', '
                + customer['zip'];

            if (customer['reseller']) {
                resellerId = <div><b>Reseller ID:</b> {customer == null ? null : customer['reseller_id']}</div>;
                taxCode = <div><b>Tax Code:</b> {customer == null ? null : customer['tax_code']}</div>;
            }

            for (let i = 0; i < information['industries'].length; i++) {
                if (parseInt(information['industries'][i]['id']) === parseInt(customer['industry_id'])) {
                    industry = information['industries'][i]['name'];
                    break;
                }
            }

            discountTableName = customer['discountName'];
            discountType = customer['discount'] < 0 ? 'Markup' : 'Discount'
        }

        return (
            <div id="quote-container" className="production-container">
                <div className="table-container mt-3">
                    <div className="row">
                        <div className="col-7">
                            <div className="card mb-3">
                                <div className="card-header">
                                    Quote Information
                                </div>
                                <div className="card-body">
                                    <div id="quote-information" className="row">
                                        {this.renderInput('Title', 'name', 'text', true)}
                                        {this.renderSelect('Status', 'status_id', options['statuses'], true)}
                                        <div className="w-100"></div>
                                        {this.renderSelect('Turnaround', 'turnaround_id', options['turnaround'], true)}
                                        {this.renderInput('Customer PO', 'customer_po', 'text', false)}
                                        <div className="w-100"></div>
                                        {this.renderSelect('Sales Rep', 'sales_rep_id', options['salesReps'], true)}
                                        {this.renderSelect('Customer Service Rep', 'customer_service_rep_id', options['customerServiceReps'], true)}
                                        <div className="w-100"></div>
                                        {this.renderInput('Discount', 'discount', 'text', true, '', 'col-6')}
                                        <div className="w-100"></div>
                                        {this.renderTextArea('Notes', 'notes', false)}
                                        {this.renderTextArea('Private Notes', 'private_notes', false)}
                                        <div className="w-100"></div>
                                        <div className="clearfix"></div>
                                        <div className="col float-right">
                                            <div className="add-button float-right mb-0" onClick={handleSaveElement}>
                                                {saveButtonLabel}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-5">
                            <div className={'card mb-3' + (quote == null ? ' hidden' : '')}>
                                <div className="card-header">
                                    Quote Information
                                </div>
                                <div className="card-body">
                                    <div><b>Quote ID:</b> {quote == null ? null : quote['id']}</div>
                                    <div><b>Created At:</b> {quote == null ? null : moment(quote['created_at']).format('MM/DD/YYYY hh:mm a')}</div>
                                    <div><b>Last Updated At:</b> {quote == null ? null : moment(quote['updated_at']).format('MM/DD/YYYY hh:mm a')}</div>
                                    <div><b>Created By:</b> {quote == null ? null : quote['created_by_name']}</div>
                                    <div><b>Last Updated By:</b> {quote == null ? null : quote['updated_by_name']}</div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    Customer Information
                                </div>
                                <div className="card-body">
                                    <div id="customer-information">
                                        <div>
                                            <b>Customer:</b>
                                            <select name="active" value={customerId} onChange={handleSetCustomerId} className="ml-1">
                                                <option value="">Select customer</option>
                                                {this.renderCustomers()}
                                            </select>
                                        </div>
                                        <div><b>Customer ID:</b> {customer == null ? null : customer['id']}</div>
                                        <div><b>Company:</b> {customer == null ? null : customer['company']}</div>
                                        <div><b>Email:</b> {customer == null ? null : customer['email']}</div>
                                        <div><b>Phone:</b> {customer == null ? null : customer['phone']}</div>
                                        <div><b>Discount Table:</b> {discountTableName}</div>
                                        <div><b>{discountType}:</b> {customer == null ? null : numeral(Math.abs(customer['discount'])).format('0.[00]%')}</div>
                                        <div><b>Industry:</b> {industry}</div>
                                        {resellerId}
                                        {taxCode}
                                        <div><b>Address:</b> {customer == null ? null : address}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    Customer Statistics
                                </div>
                                <div className="card-body">
                                    <div><b>Total Quotes</b>: {customer == null ? null : customer['total_quotes']}</div>
                                    <div><b>Total Orders</b>: {customer == null ? null : customer['total_orders']}</div>
                                </div>
                            </div>
                        </div>
                        <div className="w-100"></div>
                        {items}
                        <div className="w-100"></div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Quote;
