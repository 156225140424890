import React from 'react';
import PropTypes from 'prop-types';
import { Layer, Rect, Stage } from 'react-konva';

class MultiPageDrawImposition extends React.Component {
    static propTypes = {
        imposition: PropTypes.object,
        quoteInformation: PropTypes.object
    };

    drawPoints() {
        const { imposition, quoteInformation } = this.props;

        let originWidth = ((imposition.paper_information.pss.pss.width * 6 - imposition.paper_information.pss.ps.width * 6) / 2) + 1;
        let originLeft = ((imposition.paper_information.pss.pss.length * 6 - imposition.paper_information.pss.ps.length * 6) / 2) + 1;

        let simpleBleed = (imposition.paper_information.fss_with_bleed.width - imposition.paper_information.fss.width) / 2;
        let bleed = simpleBleed * 20;
        let offset = simpleBleed * 6;
        let hasBleed = true;
        if (bleed === 0) {
            bleed = 2;
            offset = 1;
            hasBleed = false;
        }
        return imposition.paper_information.pss.points.map((point) => {
            let rectangles = [];
            rectangles.push(
                <Rect
                    x={point.origin[0] * 6 + originLeft}
                    y={point.origin[1] * 6 + originWidth}
                    width={point.rectangle[0] * 6}
                    height={point.rectangle[1] * 6}
                    fill={hasBleed ? '#F48FB1' : '#ffffff'}
                    stroke={hasBleed ? '#F48FB1' : '#000000'}
                />
            );

            rectangles.push(
                <Rect
                    x={point.origin[0] * 6 + originLeft + offset}
                    y={point.origin[1] * 6 + originWidth + offset}
                    width={point.rectangle[0] * 6 - bleed}
                    height={point.rectangle[1] * 6 - bleed}
                    fill={'#ffffff'}
                    stroke={'#ffffff'}
                />
            );

            if (quoteInformation.publication_type <= 2) {
                let x = point.origin[0] * 6 + originLeft + offset - 1;
                let y = point.origin[1] * 6 + originWidth + offset - 1;
                let width = point.rectangle[0] - simpleBleed * 2;
                let length = point.rectangle[1] - simpleBleed * 2;
                let fssWidth = quoteInformation.fss_width;
                let fssLength = quoteInformation.fss_length;
                let dividerWidth = null;
                let dividerLength = null;
                if (quoteInformation.binding_edge === 'Width') {
                    if (fssWidth === width) {
                        y += fssLength * 6;
                        dividerWidth = width * 6 + 3;
                        dividerLength = 0.2;
                    } else {
                        x += fssLength * 6;
                        dividerWidth = 0.2;
                        dividerLength = width * 6 + 3;
                    }
                } else {
                    if (fssLength === length) {
                        x += fssWidth * 6;
                        dividerWidth = 0.2;
                        dividerLength = length * 6 + 3;
                    } else {
                        y += fssWidth * 6;
                        dividerWidth = length * 6 + 3;
                        dividerLength = 0.2;
                    }
                }

                rectangles.push(
                    <Rect
                        x={x}
                        y={y}
                        width={dividerWidth}
                        height={dividerLength}
                        fill={'#000000'}
                        strokeEnabled={false}
                    />
                );
            }

            return rectangles;
        });
    }

    render() {
        const { imposition } = this.props;
        let width = imposition.paper_information.pss.pss.width * 6;
        let length = imposition.paper_information.pss.pss.length * 6;
        return (
            <Stage width={length + 2} height={width + 2}>
                <Layer>
                    <Rect
                        x={1}
                        y={1}
                        width={length}
                        height={width}
                        fill="#ededed"
                        stroke="#000000"
                    />
                    {this.drawPoints()}
                </Layer>
            </Stage>
        );
    }
}

export default MultiPageDrawImposition;
