import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Select from './../../common/components/Select';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import Input from './../../common/components/Input';
import TextArea from './../../common/components/TextArea';
import apiUrls from './../apiUrls';

class Order extends Component {
    static propTypes = {
        elements: PropTypes.array,
        handleSort: PropTypes.func,
        handleUpdateFormValue: PropTypes.func,
        isFetchingElements: PropTypes.bool,
        handleShowDelete: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        handleChangeAttribute: PropTypes.func,
        handleInlineChange: PropTypes.func,
        handleDayChange: PropTypes.func,
        handleViewProperty: PropTypes.func,
        handleShowForm: PropTypes.func,
        formValues: PropTypes.object,
        formErrors: PropTypes.object,
        formErrorMessages: PropTypes.object,
        salesReps: PropTypes.array,
        customerServiceReps: PropTypes.array,
        order: PropTypes.object,
        customer: PropTypes.object,
        estimates: PropTypes.array,
        handleSaveElement: PropTypes.func,
        isFetchingOrder: PropTypes.bool,
        isFetchingInformation: PropTypes.bool,
        isSavingOrder: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        editingCustomer: PropTypes.bool,
        handleSetCustomerId: PropTypes.func,
        handleEditingCustomer: PropTypes.func,
        handleSetItemQuantity: PropTypes.func,
        handleShowUploadArtworks: PropTypes.func,
        customerId: PropTypes.number,
        orderId: PropTypes.number,
        statuses: PropTypes.array,
        productionStatuses: PropTypes.array,
        handleShowDeleteFile: PropTypes.func,
        handleProduction: PropTypes.func,
        isSendingToProduction: PropTypes.func,
        information: PropTypes.object,
        options: PropTypes.object,
        handleChangeEstimateStatus: PropTypes.func,
        handleChangeEstimateHaveArtworks: PropTypes.func,
        handleUpdateFormDateValue: PropTypes.func
    };

    renderInput(label, name, type, required, extraMessage = '', col = 'col') {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div key={'order-information-' + name} className={col}>
                <div className="labelColumn align-top bold" style={{ width: 160 }}><label>{required ? <span className="required">*</span> : null} {label}: </label></div>
                <div className="inputColumn">
                    <Input
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, type, required)}
                    />
                    &nbsp;
                    {extraMessage}
                </div>
            </div>
        );
    }

    renderTextArea(label, name, required) {
        const { formValues, formErrors, handleUpdateFormValue, formErrorMessages } = this.props;
        return (
            <div key={'order-information-' + name} className="col">
                <div className="labelColumn bold" style={{ width: 160 }}><label>{required ? <span className="required">*</span> : null} {label}: </label></div>
                <div className="inputColumn">
                    <TextArea
                        name={name}
                        required={required}
                        formValues={formValues}
                        formErrors={formErrors}
                        formErrorMessages={formErrorMessages}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                </div>
            </div>
        );
    }

    renderSelect(label, name, options, required, extraMessage = '') {
        const { formValues, formErrors, handleUpdateFormValue } = this.props;
        return (
            <div key={'order-information-' + name} className="col">
                <div className="labelColumn bold" style={{ width: 160 }}><label>{required ? <span className="required">*</span> : null} {label}: </label></div>
                <div className="inputColumn">
                    <Select
                        name={name}
                        required={required}
                        options={options}
                        formValues={formValues}
                        formErrors={formErrors}
                        handleUpdateFormValue={handleUpdateFormValue.bind(this, 'text', required)}
                    />
                    &nbsp;
                    {extraMessage}
                </div>
            </div>
        );
    }

    getMailingNames(estimate, name, optionsName) {
        const { options } = this.props;

        if (estimate[name] == null) {
            return null;
        }

        let names = [];
        let elements = JSON.parse(estimate[name]);
        for (let i = 0; i < elements.length; i++) {
            let selectedElement = null;
            for (let j = 0; j < options[optionsName].length; j++) {
                let element = options[optionsName][j]['element'];
                if (element != null && parseInt(element['id']) === parseInt(elements[i]['element_id'])) {
                    selectedElement = element;
                    break;
                }
            }

            if (selectedElement != null) {
                names.push(selectedElement['name']);
            }
        }
        return names;
    }

    renderEstimates() {
        const { information, handleShowForm, handleShowDeleteFile, formValues } = this.props;
        return information['estimates'].map((estimate, index) => {
            let pressNames = [];
            let digitalMachineName = null;
            let largeFormatMachineName = null;
            let paperNames = [];
            let inkNames = [];
            let foldingNames = [];
            let bindingName = null;
            let substrateName = null;
            let laminateName = null;
            let accessoriesNames = [];
            let dataProcessingNames = [];
            let inkjetNames = [];
            let insertingNames = [];
            let certMailNames = [];
            let artNames = [];
            let handworkNames = [];
            let pressureSealNames = [];
            let indexTabNames = [];
            let labelNames = [];
            let ncrFormNames = [];
            let quantity = null;
            let total = null;
            let largeFormatShippingRow = null;

            let estimateResult = estimate['estimateResults'][0];
            total = estimateResult['subtotal'] + estimateResult['discount'];

            if (parseInt(estimate['type']) === 1) {
                pressNames = estimate['presses_names'];
                paperNames = estimate['paper_names'];
                inkNames.push(estimate['front_inks'] + '/' + estimate['back_inks']);
                foldingNames = estimate['folding_machine_name'];

                quantity = estimateResult['quantity'];
            } else if (parseInt(estimate['type']) === 2) {
                pressNames = estimate['presses_names'];
                paperNames = estimate['paper_names'];
                foldingNames = estimate['folding_machine_name'];
                bindingName = estimate['binding_machine_name'];

                let auxInkName = estimate['interior_inks'] + '/' + estimate['interior_inks'];
                if (!inkNames.includes(auxInkName)) {
                    inkNames.push(auxInkName);
                }

                if ([2, 3, 6, 7].includes(parseInt(estimate['multi_page_type']))) {
                    auxInkName = estimate['front_inks'] + '/' + estimate['back_inks'];
                    if (!inkNames.includes(auxInkName)) {
                        inkNames.push(auxInkName);
                    }
                }

                quantity = estimateResult['quantity'];
            } else if (parseInt(estimate['type']) === 3) {
                digitalMachineName = estimate['digital_machine_name'];
                paperNames = estimate['paper_names'];
                foldingNames = estimate['folding_machine_name'];

                quantity = estimateResult['quantity'];
            } else if (parseInt(estimate['type']) === 4) {
                largeFormatMachineName = estimate['large_format_machine_name'];
                substrateName = estimate['substrate_name'];
                laminateName = estimate['laminate_name'];
                accessoriesNames = estimate['accessories_names'];

                quantity = estimateResult['quantity'];

                if (formValues['shipping_method'] != null && formValues['shipping_method'] !== '') {
                    largeFormatShippingRow = <div style={{ color: '#43a047' }}>Shipping to be determined</div>;
                }
            } else if (parseInt(estimate['type']) === 5) {
                dataProcessingNames = this.getMailingNames(estimate, 'data_processing_elements_ids', 'dataProcessingElements');
                quantity = 1;
            } else if (parseInt(estimate['type']) === 6) {
                inkjetNames = inkjetNames.concat(this.getMailingNames(estimate, 'inkjet_elements_ids', 'inkjetElements'));
                inkjetNames = inkjetNames.concat(this.getMailingNames(estimate, 'wafer_seal_elements_ids', 'waferSealElements'));
                inkjetNames = inkjetNames.concat(this.getMailingNames(estimate, 'affix_elements_ids', 'affixElements'));

                quantity = 1;
            } else if (parseInt(estimate['type']) === 7) {
                insertingNames = insertingNames.concat(this.getMailingNames(estimate, 'inserting_elements_ids', 'insertingElements'));
                insertingNames = insertingNames.concat(this.getMailingNames(estimate, 'perfect_match_elements_ids', 'perfectMatchElements'));

                quantity = 1;
            } else if (parseInt(estimate['type']) === 8) {
                certMailNames = this.getMailingNames(estimate, 'cert_mail_elements_ids', 'certMailElements');
                quantity = 1;
            } else if (parseInt(estimate['type']) === 9) {
                artNames = this.getMailingNames(estimate, 'art_elements_ids', 'artElements');
                quantity = 1;
            } else if (parseInt(estimate['type']) === 10) {
                handworkNames = this.getMailingNames(estimate, 'handwork_elements_ids', 'handworkElements');
                quantity = 1;
            } else if (parseInt(estimate['type']) === 11) {
                pressureSealNames = this.getMailingNames(estimate, 'pressure_seal_elements_ids', 'pressureSealElements');
                quantity = 1;
            } else if (parseInt(estimate['type']) === 12) {
                indexTabNames = this.getMailingNames(estimate, 'index_tab_elements_ids', 'indexTabElements');
                quantity = 1;
            } else if (parseInt(estimate['type']) === 13) {
                labelNames = this.getMailingNames(estimate, 'label_elements_ids', 'labelElements');
                quantity = 1;
            } else if (parseInt(estimate['type']) === 14) {
                ncrFormNames = this.getMailingNames(estimate, 'ncr_form_elements_ids', 'ncrFormElements');
                quantity = 1;
            }

            let pressRow = null;
            if (pressNames != null && pressNames.length > 0) {
                pressRow = <div>Press: {pressNames.join(', ')}</div>;
            }

            let digitalMachineRow = null;
            if (digitalMachineName != null) {
                digitalMachineRow = <div>Digital Machine: {digitalMachineName}</div>;
            }

            let inkRow = null;
            if (inkNames != null && inkNames.length > 0) {
                inkRow = <div>Inks: {inkNames.join(', ')}</div>;
            }

            let paperRow = null;
            if (paperNames != null && paperNames.length > 0) {
                paperRow = <div>Paper: {paperNames.join(', ')}</div>;
            }

            let foldingRow = null;
            if (foldingNames != null && foldingNames.length > 0) {
                foldingRow = <div>Folding: {foldingNames.join(', ')}</div>;
            }

            let bindingRow = null;
            if (bindingName != null) {
                bindingRow = <div>Binding: {bindingName}</div>;
            }

            let largeFormatMachineRow = null;
            if (largeFormatMachineName != null) {
                largeFormatMachineRow = <div>Large Format Machine: {largeFormatMachineName}</div>;
            }

            let substrateRow = null;
            if (substrateName != null) {
                substrateRow = <div>Substrate: {substrateName}</div>;
            }

            let laminateRow = null;
            if (laminateName != null) {
                laminateRow = <div>Laminate: {laminateName}</div>;
            }

            let accessoriesRow = null;
            if (accessoriesNames != null && accessoriesNames.length > 0) {
                accessoriesRow = <div>Accessories: {accessoriesNames.join(', ')}</div>;
            }

            let dataProcessingRow = null;
            if (dataProcessingNames.length > 0) {
                dataProcessingRow = <div>{dataProcessingNames.join(', ')}</div>;
            }

            let inkjetRow = null;
            if (inkjetNames.length > 0) {
                inkjetRow = <div>{inkjetNames.join(', ')}</div>;
            }

            let insertingRow = null;
            if (insertingNames.length > 0) {
                insertingRow = <div>{insertingNames.join(', ')}</div>;
            }

            let certMailRow = null;
            if (certMailNames.length > 0) {
                certMailRow = <div>{certMailNames.join(', ')}</div>;
            }

            let artRow = null;
            if (artNames.length > 0) {
                artRow = <div>{artNames.join(', ')}</div>;
            }

            let handworkRow = null;
            if (handworkNames.length > 0) {
                handworkRow = <div>{handworkNames.join(', ')}</div>;
            }

            let pressureSealRow = null;
            if (pressureSealNames.length > 0) {
                pressureSealRow = <div>{pressureSealNames.join(', ')}</div>;
            }

            let indexTabRow = null;
            if (indexTabNames.length > 0) {
                indexTabRow = <div>{indexTabNames.join(', ')}</div>;
            }

            let labelRow = null;
            if (labelNames.length > 0) {
                labelRow = <div>{labelNames.join(', ')}</div>;
            }

            let ncrFormRow = null;
            if (ncrFormNames.length > 0) {
                ncrFormRow = <div>{ncrFormNames.join(', ')}</div>;
            }

            let estimateFilesLabel = <div>No Artworks Uploaded</div>;
            let estimateFilesThumbnails = [];
            let estimateFiles = [];
            if (estimate['estimateFiles'].length > 0) {
                for (let i = 0; i < estimate['estimateFiles'].length; i++) {
                    if (estimate['estimateFiles'][i]['thumbnail_url']) {
                        estimateFilesThumbnails.push(
                            <div className="artwork-link">
                                <a href={apiUrls.downloadFile + '?id=' + estimate['estimateFiles'][i]['id']}>
                                    <img className="thumbnail" src={estimate['estimateFiles'][i]['thumbnail_url']} alt="Artwork" />
                                </a>
                                <i className="fas fa-trash float-trash" onClick={handleShowDeleteFile.bind(this, estimate['estimateFiles'][i]['id'])} />
                            </div>
                        );
                    } else {
                        estimateFiles.push(
                            <li className="artwork-li">
                                <a href={apiUrls.downloadFile + '?id=' + estimate['estimateFiles'][i]['id']}>
                                    {estimate['estimateFiles'][i]['filename']}
                                </a>
                                <i className="fas fa-trash" onClick={handleShowDeleteFile.bind(this, estimate['estimateFiles'][i]['id'])} />
                            </li>
                        );
                    }
                }
                estimateFilesLabel = (
                    <div>
                        <div>Uploaded Artworks:</div>
                        <ol>{estimateFiles}</ol>
                        <div>{estimateFilesThumbnails}</div>
                    </div>
                );
            }

            let approvedBy = null;
            if (estimate['approved_by_name'] != null && estimate['approved_by_name'] !== '') {
                approvedBy = <div>Approved By: {estimate['approved_by_name']}</div>;
            }

            let approvedAt = null;
            if (estimate['approved_at'] != null && estimate['approved_at'] !== '') {
                approvedAt = <div>Approved At: {moment(estimate['approved_at']).format('MM/DD/YYYY')}</div>;
            }

            return (
                <tr key={index}>
                    <td>
                        <div className="bold"><b>{estimate['product_category_name']}</b></div>
                        <div className="bold">{estimate['product_description']}</div>
                        {pressRow}
                        {digitalMachineRow}
                        {largeFormatMachineRow}
                        {paperRow}
                        {inkRow}
                        {foldingRow}
                        {bindingRow}
                        {substrateRow}
                        {laminateRow}
                        {accessoriesRow}
                        {largeFormatShippingRow}
                        {dataProcessingRow}
                        {inkjetRow}
                        {insertingRow}
                        {certMailRow}
                        {artRow}
                        {handworkRow}
                        {pressureSealRow}
                        {indexTabRow}
                        {labelRow}
                        {ncrFormRow}
                    </td>
                    <td className="artworks">
                        {estimateFilesLabel}
                    </td>
                    <td className="approval">
                        {approvedBy}
                        {approvedAt}
                    </td>
                    <td className="price">
                        <div>{numeral(quantity).format('0,0')}</div>
                        <div>{numeral(total).format('$0,0.00')}</div>
                    </td>
                    <td className="actions">
                        <Tooltip title="Information" position="bottom" arrow size="small" className="action-tooltip">
                            <i className="fas fa-info-circle" onClick={handleShowForm.bind(this, estimate['id'])} />
                        </Tooltip>
                    </td>
                </tr>
            );
        });
    }

    renderCustomers() {
        const { information } = this.props;
        return information['customers'].map((customer, index) => {
            return <option key={index} value={customer['id']}>{customer['name']}</option>;
        });
    }

    render() {
        const { information, order, formValues, handleSaveElement, isFetchingOrder,
            isFetchingInformation, isSavingForm, handleSetCustomerId, editingCustomer, customerId, orderId,
            options, handleUpdateFormDateValue } = this.props;

        if (isFetchingOrder || isFetchingInformation) {
            return (
                <div className="loading">
                    <i className="fas fa-circle-notch fa-spin" /> Loading...
                </div>
            );
        }

        let items = null;
        if (formValues['id'] !== '') {
            let subtotal = 0;
            let customerDiscount = 0;
            let orderDiscount = 0;
            let rushFee = 0;
            let tax = 0;
            let totalPrice = 0;
            for (let i = 0; i < information['estimates'].length; i++) {
                subtotal += information['estimates'][i]['estimateResults'][0]['subtotal'] + information['estimates'][i]['estimateResults'][0]['discount'];
                customerDiscount += information['estimates'][i]['estimateResults'][0]['customer_discount'];
                orderDiscount += information['estimates'][i]['estimateResults'][0]['order_discount'];
                rushFee += information['estimates'][i]['estimateResults'][0]['rush_fee'];
                tax += information['estimates'][i]['estimateResults'][0]['tax'];
                totalPrice += information['estimates'][i]['estimateResults'][0]['total'];
            }

            let totals = null;
            if (information['estimates'].length > 0) {
                let shippingTotal = formValues['shipping'];
                if (shippingTotal == null || shippingTotal === '') {
                    shippingTotal = 0;
                }
                let shippingRow = <div>{numeral(shippingTotal).format('$0,0.00')}</div>;

                totals = (
                    <tr>
                        <td className="bold text-right" colSpan="3">
                            Subtotal <br />
                            Shipping <br />
                            {rushFee > 0 ? <div>Rush Fee</div> : null}
                            {tax > 0 ? <div>Tax</div> : null}
                            {customerDiscount > 0 ? <div>Customer Discount</div> : null}
                            {orderDiscount > 0 ? <div>Order Discount</div> : null}
                            Total
                        </td>
                        <td className="text-right">
                            {numeral(subtotal).format('$0,0.00')} <br />
                            {shippingRow}
                            {rushFee > 0 ? <div>{numeral(rushFee).format('$0,0.00')}</div> : null}
                            {tax > 0 ? <div>{numeral(tax).format('$0,0.00')}</div> : null}
                            {customerDiscount > 0 ? <div>-{numeral(customerDiscount).format('$0,0.00')}</div> : null}
                            {orderDiscount > 0 ? <div>-{numeral(orderDiscount).format('$0,0.00')}</div> : null}
                            {numeral(totalPrice + shippingTotal).format('$0,0.00')}
                        </td>
                        <td></td>
                    </tr>
                );
            }

            items = (
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">
                            Items
                        </div>
                        <div className="card-body p-0">
                            <table id="estimates-table" className="table">
                                <tbody>
                                    {this.renderEstimates()}
                                    {totals}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        }

        let saveButtonLabel = <div><i className="fas fa-save" /> Save</div>;
        if (isSavingForm) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let customer = null;
        for (let i = 0; i < information['customers'].length; i++) {
            if (parseInt(information['customers'][i]['id']) === parseInt(customerId)) {
                customer = information['customers'][i];
                break;
            }
        }

        let address = '';
        let resellerId = null;
        let taxCode = null;
        let industry = '';
        let discountTableName = 'None';
        if (customer != null) {
            address = customer['street'] + ', ' + customer['city'] + ', ' + customer['state'] + ', '
                + customer['country'] + ', ' + customer['zip'];

            if (customer['reseller']) {
                resellerId = <div><b>Reseller ID:</b> {customer == null ? null : customer['reseller_id']}</div>;
                taxCode = <div><b>Tax Code:</b> {customer == null ? null : customer['tax_code']}</div>;
            }

            for (let i = 0; i < information['industries'].length; i++) {
                if (parseInt(information['industries'][i]['id']) === parseInt(customer['industry_id'])) {
                    industry = information['industries'][i]['name'];
                    break;
                }
            }

            discountTableName = customer['discountName'];
        }

        let billingAddress = null;
        let shippingAddress = null;
        let customerDiscount = 0;
        let customerDiscountName = '';
        if (formValues['id'] === '' && customer != null) {
            billingAddress = customer['billingAddress']['street'] + ' '
                + customer['billingAddress']['city'] + ', '
                + customer['billingAddress']['state'] + ', '
                + customer['billingAddress']['zip'];

            shippingAddress = customer['shippingAddress']['street'] + ' '
                + customer['shippingAddress']['city'] + ', '
                + customer['shippingAddress']['state'] + ', '
                + customer['shippingAddress']['zip'];

            discountTableName = customer['discountName'];
            customerDiscount = customer['discount'];
        } else if (formValues['id'] !== '' && order != null) {
            billingAddress = order['billingAddress']['street'] + ' '
                + order['billingAddress']['city'] + ', '
                + order['billingAddress']['state'] + ', '
                + order['billingAddress']['zip'];

            shippingAddress = order['shippingAddress']['street'] + ' '
                + order['shippingAddress']['city'] + ', '
                + order['shippingAddress']['state'] + ', '
                + order['shippingAddress']['zip'];

            discountTableName = order['customer_discount_name'];
            customerDiscount = order['customer_discount'];
        }

        if (customerDiscount >= 0) {
            customerDiscountName = 'Discount';
        } else {
            customerDiscountName = 'Markup';
        }

        return (
            <div id="tables-container" className="production-container">
                <div className="table-container mt-3">
                    <div className="row">
                        <div className="col-7">
                            <div className="card mb-3">
                                <div className="card-header">
                                    Order Information
                                </div>
                                <div className="card-body">
                                    <div id="order-information" className="row">
                                        <div className="col">
                                            <div className="labelColumn align-top bold" style={{ width: 160 }}>Order ID:</div>
                                            <div className="inputColumn"><b>{formValues['id']}</b></div>
                                        </div>
                                        <div className="col">
                                            <div className="labelColumn align-top bold" style={{ width: 160 }}>Title:</div>
                                            <div className="inputColumn"><b>{formValues['name']}</b></div>
                                        </div>
                                        <div className="w-100"></div>
                                        <div className="col">
                                            <div className="labelColumn align-top bold" style={{ width: 160 }}>Turnaround:</div>
                                            <div className="inputColumn"><b>{formValues['turnaround_name']}</b></div>
                                        </div>
                                        <div className="col">
                                            <div className="labelColumn align-top bold" style={{ width: 160 }}>Customer PO:</div>
                                            <div className="inputColumn"><b>{formValues['customer_po'] == null ? 'N/A' : formValues['customer_po']}</b></div>
                                        </div>
                                        <div className="w-100"></div>
                                        {this.renderSelect('Production Status', 'production_status_id', options['productionStatuses'], true)}
                                        <div key="due-date" className="col" id="due-date">
                                            <div className="labelColumn align-top bold" style={{ width: 160 }}><label><span className="required">*</span> Due Date: </label></div>
                                            <div className="inputColumn">
                                                <DayPickerInput
                                                    formatDate={formatDate}
                                                    parseDate={parseDate}
                                                    placeholder=""
                                                    className="date-picker-field"
                                                    onDayChange={handleUpdateFormDateValue.bind(this, 'due_date', true)}
                                                    value={formValues['due_date'] == null || formValues['due_date'] === '0000-00-00' ? '' : moment(formValues['due_date']).format('MM/DD/YYYY')}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-100"></div>
                                        {this.renderSelect('Sales Rep', 'sales_rep_id', options['salesReps'], true)}
                                        {this.renderSelect('Customer Service Rep', 'customer_service_rep_id', options['customerServiceReps'], true)}
                                        <div className="w-100"></div>
                                        {this.renderInput('Discount', 'discount', 'text', true, null, 'col-6')}
                                        <div className="w-100"></div>
                                        {this.renderTextArea('Notes', 'notes', false)}
                                        <div className="w-100"></div>
                                        <div className="clearfix"></div>
                                        <div className="col">
                                            <div className="add-button float-right mb-0" onClick={handleSaveElement}>
                                                {saveButtonLabel}
                                            </div>
                                            <div className="add-button float-right mb-0 mr-3" style={{ width: 180 }}
                                                onClick={() => window.open(apiUrls.exportJobTicket + '?id=' + orderId, '_blank')}>
                                                <div><i className="far fa-file-pdf" /> Export Job Ticket</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="card mb-3">
                                <div className="card-header">
                                    Customer Information
                                </div>
                                <div className="card-body">
                                    <div className={'edit-customer' + (editingCustomer ? '' : ' hidden')}>
                                        <b>Customer:</b>
                                        <select name="active" value={customerId} onChange={handleSetCustomerId}>
                                            {this.renderCustomers()}
                                        </select>
                                    </div>
                                    <div className={editingCustomer ? ' hidden' : ''}>
                                        <div><b>Customer ID:</b> {customer == null ? null : customer['id']}</div>
                                        <div><b>Name:</b> {customer == null ? null : customer['name']}</div>
                                        <div><b>Company:</b> {customer == null ? null : customer['company']}</div>
                                        <div><b>Email:</b> {customer == null ? null : customer['email']}</div>
                                        <div><b>Phone:</b> {customer == null ? null : customer['phone']}</div>
                                        <div><b>Discount Table:</b> {discountTableName}</div>
                                        <div><b>{customerDiscountName}:</b> {customer == null ? null : numeral(Math.abs(customer['discount'])).format('0.[00]%')}</div>
                                        <div><b>Industry:</b> {industry}</div>
                                        {resellerId}
                                        {taxCode}
                                        <div><b>Address:</b> {customer == null ? null : address}</div>
                                        <div><b>Billing Address:</b> {billingAddress}</div>
                                        <div><b>Shipping Address:</b> {shippingAddress}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-100"></div>
                        {items}
                        <div className="w-100"></div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        );
    }
}

export default Order;
