import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tabs from './../common/components/Tabs';
import AdminHeader from './../common/components/AdminHeader';
import { Table, Form, Delete, SpeedTable, MakeReadyMarkups, BhrMarkups, ActivePapers } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import moment from 'moment';
import numeral from 'numeral';
import { Helmet } from 'react-helmet';
import cloneDeep from 'lodash/cloneDeep';
import { showNotification } from './../../utils';
import Layout from './../Layout';

class Container extends Component {
    static propTypes = {
        isFetchingElements: PropTypes.bool,
        isSavingForm: PropTypes.bool,
        formValues: PropTypes.object,
        user: PropTypes.object,
        elements: PropTypes.array,
        saveElement: PropTypes.func,
        setSort: PropTypes.func,
        updateFormValue: PropTypes.func,
        fetchElements: PropTypes.func,
        totalPages: PropTypes.number,
        selectedPage: PropTypes.number,
        elementsPerPage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortType: PropTypes.string,
        save: PropTypes.func,
        addElement: PropTypes.func,
        showForm: PropTypes.func,
        hideForm: PropTypes.func,
        resetForm: PropTypes.func,
        showDelete: PropTypes.func,
        hideDelete: PropTypes.func,
        setIdForDelete: PropTypes.func,
        deleteElement: PropTypes.func,
        idForDelete: PropTypes.number,
        changeAttribute: PropTypes.func,
        updateAttribute: PropTypes.func,
        formErrors: PropTypes.object,
        isFetchingElement: PropTypes.bool,
        isDeleting: PropTypes.bool,
        setActiveElement: PropTypes.func,
        setFormError: PropTypes.func,
        showInfoNotification: PropTypes.func,
        formErrorMessages: PropTypes.object,
        showSpeedTable: PropTypes.func,
        activeElement: PropTypes.func,
        hideSpeedTable: PropTypes.func,
        setSpeedTable: PropTypes.func,
        setMakeReadyMarkups: PropTypes.func,
        setBhrMarkups: PropTypes.func,
        saveSpeedTable: PropTypes.func,
        speedTable: PropTypes.array,
        isSavingSpeedTable: PropTypes.bool,
        makeReadyMarkups: PropTypes.array,
        isSavingMakeReadyMarkups: PropTypes.bool,
        bhrMarkups: PropTypes.array,
        isSavingBhrMarkups: PropTypes.bool,
        activePapers: PropTypes.array,
        isSavingActivePapers: PropTypes.bool,
        hideMakeReadyMarkups: PropTypes.func,
        showActivePapers: PropTypes.func,
        fetchInformation: PropTypes.func,
        hideBhrMarkups: PropTypes.func,
        hideActivePapers: PropTypes.func,
        isFetchingInformation: PropTypes.bool,
        paperCategories: PropTypes.array,
        paperWeights: PropTypes.array,
        papers: PropTypes.array,
        saveActivePapers: PropTypes.func,
        showBhrMarkups: PropTypes.func,
        showMakeReadyMarkups: PropTypes.func,
        saveMakeReadyMarkups: PropTypes.func,
        saveBhrMarkups: PropTypes.func,
        concatActiveData: PropTypes.func,
        deleteActiveData: PropTypes.func,
        clearMultipleValue: PropTypes.func,
        concatMultipleValue: PropTypes.func,
        resetState: PropTypes.func,
        changeFilter: PropTypes.func,
        processedElements: PropTypes.array,
        processElements: PropTypes.func,
        options: PropTypes.object,
        fetching: PropTypes.object,
        fetchPaperCategories: PropTypes.func,
        fetchPaperWeights: PropTypes.func,
        fetchPapers: PropTypes.func,
        resetOptions: PropTypes.func,
        updateState: PropTypes.func,
        information: PropTypes.object
    };

    componentDidMount() {
        const { fetchElements } = this.props;
        fetchElements();
    }

    componentWillUnmount() {
        const { resetState } = this.props;
        resetState();
    }

    handleUpdateFormValue(type, required, event) {
        const { setFormError, formValues, fetchPaperWeights, fetchPapers, updateState } = this.props;

        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        let values = {};
        values[event.target.name] = value;
        let newValues = cloneDeep(formValues);
        newValues[event.target.name] = value;

        let error = false;
        let errorMessage = '';
        if (required && value === '') {
            error = true;
            errorMessage = 'This field is required';
        } else {
            if (value !== '' && type === 'number' && value.toString().match(/^[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            } else if (value !== '' && type === 'decimal' && value.toString().match(/^([0-9]*[.])?[0-9]+$/) === null) {
                error = true;
                errorMessage = 'This value needs to be a number';
            }
        }

        let fetching = {};
        let options = {};
        if (event.target.name === 'paper_category_id') {
            fetchPaperWeights(value);
            fetching.activePaperWeights = true;
            options.activePaperWeights = [];
            options.activePapers = [{ label: 'Select option', value: '' }];
        } else if (event.target.name === 'paper_weight') {
            fetchPapers(formValues['paper_category_id'], value);
            fetching.activePapers = true;
            options.activePapers = [];
            options.activePapers = [{ label: 'Select option', value: '' }];
        }

        let newState = { formValues: values };

        let keys = Object.keys(fetching);
        if (keys.length > 0) {
            newState.fetching = fetching;
        }

        keys = Object.keys(options);
        if (keys.length > 0) {
            newState.options = options;
        }

        updateState(newState);
        setFormError(event.target.name, error, errorMessage);
    }

    handleMultipleChange(name, event) {
        const { clearMultipleValue, concatMultipleValue } = this.props;
        clearMultipleValue(name);

        let values = [];
        for (let i = 0; i < event.target.options.length; i++) {
            if (event.target.options[i].selected) {
                values.push(parseInt(event.target.options[i].value));
            }
        }

        concatMultipleValue(name, values);
    }

    handleSort(column) {
        const { setSort, sortColumn, sortType, processElements } = this.props;
        if (sortColumn === column) {
            setSort(column, sortType === 'asc' ? 'desc' : 'asc');
        } else {
            setSort(column, 'asc');
        }
        processElements();
    }

    handleInlineChange(index, attribute, value) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(value, elements, index, attribute);
    }

    handleDayChange(index, attribute, day) {
        const { elements, changeAttribute } = this.props;
        changeAttribute(moment(day).format('YYYY-MM-DD'), elements, index, attribute);
    }

    handleSaveElement() {
        const { saveElement, formValues, elements } = this.props;
        saveElement(formValues, elements);
    }

    handleShowForm(id) {
        const { showForm, resetForm, setActiveElement, elements } = this.props;

        if (id == null) {
            resetForm(id);
        } else {
            setActiveElement(elements, id);
        }

        showForm();
    }

    handleUpdateDayValue(name, day) {
        const { updateFormValue, setFormError } = this.props;
        updateFormValue(name, day);
        setFormError(name, day === '');
    }

    handleShowDelete(id, event) {
        const { showDelete, setIdForDelete } = this.props;
        event.preventDefault();
        showDelete();
        setIdForDelete(id);
    }

    handleDeleteElement() {
        const { deleteElement, idForDelete, elements } = this.props;
        deleteElement(idForDelete, elements);
    }

    handleChangeAttribute(index, attribute, type, value) {
        const { processedElements, changeAttribute, updateAttribute } = this.props;

        let valueToSave = value;
        if (type === 'money') {
            valueToSave = numeral(value).format('0.00');
            value = numeral(value).format('$0,0.00');
        } else if (type === 'number') {
            if (parseInt(value).toString().match(/^[0-9]+$/) === null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        } else if (type === 'date') {
            valueToSave = moment(value).format('YYYY-MM-DD');
            value = valueToSave;
        } else if (type === 'decimal') {
            if (parseInt(value).toString().match(/^([0-9]*[.])?[0-9]+$/) == null) {
                showNotification('Wrong Value', 'This value needs to be a number', 'info');
                return;
            }
            valueToSave = numeral(value).format('0');
            value = numeral(value).format('0,0');
        }

        changeAttribute(value, processedElements, index, attribute);
        updateAttribute(processedElements[index].id, attribute, valueToSave, processedElements, index);
    }

    handleShowSpeedTable(id) {
        const { showSpeedTable, setActiveElement, elements } = this.props;
        setActiveElement(elements, id);
        showSpeedTable();
    }

    handleShowActivePapers(id) {
        const { showActivePapers, setActiveElement, elements, fetchPaperCategories } = this.props;
        setActiveElement(elements, id);
        fetchPaperCategories();
        showActivePapers();
    }

    handleShowBhrMarkups(id) {
        const { showBhrMarkups, setActiveElement, elements } = this.props;
        setActiveElement(elements, id);
        showBhrMarkups();
    }

    handleShowMakeReadyMarkups(id) {
        const { showMakeReadyMarkups, setActiveElement, elements } = this.props;
        setActiveElement(elements, id);
        showMakeReadyMarkups();
    }

    handleAddSpeedTable() {
        let { setSpeedTable, speedTable } = this.props;
        speedTable.push({ id: '', run_length: '', speed: '' });
        setSpeedTable(speedTable);
    }

    handleUpdateSpeedTable(index, attribute, event) {
        let { setSpeedTable, speedTable } = this.props;
        speedTable[index][attribute] = event.target.value;
        setSpeedTable(speedTable);
    }

    handleDeleteSpeedTable(index) {
        let { setSpeedTable, speedTable } = this.props;
        speedTable.splice(index, 1);
        setSpeedTable(speedTable);
    }

    handleUpdateMakeReadyMarkups(index, attribute, event) {
        let { setMakeReadyMarkups, makeReadyMarkups } = this.props;
        makeReadyMarkups[index][attribute] = event.target.value;
        setMakeReadyMarkups(makeReadyMarkups);
    }

    handleUpdateBhrMarkups(index, attribute, event) {
        let { setBhrMarkups, bhrMarkups } = this.props;
        bhrMarkups[index][attribute] = event.target.value;
        setBhrMarkups(bhrMarkups);
    }

    handleSaveSpeedTable() {
        const { saveSpeedTable, speedTable, activeElement, elements } = this.props;
        saveSpeedTable(speedTable, elements, activeElement['id']);
    }

    handleSaveMakeReadyMarkups() {
        const { saveMakeReadyMarkups, makeReadyMarkups, activeElement, elements } = this.props;
        saveMakeReadyMarkups(makeReadyMarkups, elements, activeElement['id']);
    }

    handleSaveBhrMarkups() {
        const { saveBhrMarkups, bhrMarkups, activeElement, elements } = this.props;
        saveBhrMarkups(bhrMarkups, elements, activeElement['id']);
    }

    handleSaveActivePapers() {
        const { saveActivePapers, activePapers, activeElement, elements } = this.props;
        saveActivePapers(activePapers, elements, activeElement['id']);
    }

    handleAddActivePaper() {
        let { formValues, concatActiveData, information, showInfoNotification, updateFormValue } = this.props;
        let newPapers = [];
        for (let i = 0; i < information['papers'].length; i++) {
            if (formValues['active_paper_ids'].includes(information['papers'][i]['id'])) {
                newPapers.push(information['papers'][i]);
            }
        }

        if (newPapers.length === 0) {
            showInfoNotification('Select a paper');
        } else {
            concatActiveData('activePapers', newPapers);
            updateFormValue('active_paper_ids', []);
        }
    }

    handleDeleteActivePaper(id) {
        let { activePapers, deleteActiveData } = this.props;

        for (let i = 0; i < activePapers.length; i++) {
            if (activePapers[i]['id'] === id) {
                deleteActiveData(i, 'activePapers');
                break;
            }
        }
    }

    handleChangeFilter(name, event) {
        const { changeFilter, processElements } = this.props;
        changeFilter(name, event.target.value);
        processElements();
    }

    render() {
        const { isFetchingElements, elements, sortColumn, sortType, totalPages, selectedPage, elementsPerPage, user,
            isSavingForm, formValues, formErrors, isFetchingElement, hideForm, isDeleting, hideDelete, formErrorMessages,
            speedTable, hideSpeedTable, isSavingSpeedTable, isSavingMakeReadyMarkups, isSavingBhrMarkups,
            isSavingActivePapers, makeReadyMarkups, bhrMarkups, activePapers, hideMakeReadyMarkups, activeElement,
            hideBhrMarkups, hideActivePapers, isFetchingInformation,
            processedElements, information, options, fetching } = this.props;
        return (
            <Layout>
                <div id="admin-dashboard-container" className="container-fluid">
                    <Helmet>
                        <title>Dashboard</title>
                        <meta name="description" content="" />
                    </Helmet>
                    <AdminHeader
                        title="Presses"
                    />
                    <div className="row">
                        <div className="col">
                            <Tabs
                                user={user}
                            />
                            <div className="upload-button" onClick={this.handleShowForm.bind(this, null)}><i className="fas fa-plus" /> Add</div>
                            <Table
                                elements={elements}
                                isFetchingElements={isFetchingElements}
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                                elementsPerPage={elementsPerPage}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                user={user}
                                processedElements={processedElements}
                                handleSort={this.handleSort.bind(this)}
                                handleChangeAttribute={this.handleChangeAttribute.bind(this)}
                                handleInlineChange={this.handleInlineChange.bind(this)}
                                handleShowDelete={this.handleShowDelete.bind(this)}
                                handleShowForm={this.handleShowForm.bind(this)}
                                handleDayChange={this.handleDayChange.bind(this)}
                                handleShowSpeedTable={this.handleShowSpeedTable.bind(this)}
                                handleShowActivePapers={this.handleShowActivePapers.bind(this)}
                                handleShowBhrMarkups={this.handleShowBhrMarkups.bind(this)}
                                handleShowMakeReadyMarkups={this.handleShowMakeReadyMarkups.bind(this)}
                                handleChangeFilter={this.handleChangeFilter.bind(this)}
                            />
                            <Form
                                formValues={formValues}
                                formErrors={formErrors}
                                formErrorMessages={formErrorMessages}
                                isSavingForm={isSavingForm}
                                isFetchingElement={isFetchingElement}
                                options={options}
                                information={information}
                                fetching={fetching}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleUpdateDayValue={this.handleUpdateDayValue.bind(this)}
                                hideForm={hideForm.bind(this)}
                                handleSaveElement={this.handleSaveElement.bind(this)}
                            />
                            <SpeedTable
                                speedTable={speedTable}
                                isSavingSpeedTable={isSavingSpeedTable}
                                hideSpeedTable={hideSpeedTable.bind(this)}
                                handleAddSpeedTable={this.handleAddSpeedTable.bind(this)}
                                handleUpdateSpeedTable={this.handleUpdateSpeedTable.bind(this)}
                                handleDeleteSpeedTable={this.handleDeleteSpeedTable.bind(this)}
                                handleSaveSpeedTable={this.handleSaveSpeedTable.bind(this)}
                            />
                            <MakeReadyMarkups
                                activeElement={activeElement}
                                makeReadyMarkups={makeReadyMarkups}
                                isSavingMakeReadyMarkups={isSavingMakeReadyMarkups}
                                hideMakeReadyMarkups={hideMakeReadyMarkups.bind(this)}
                                handleUpdateMakeReadyMarkups={this.handleUpdateMakeReadyMarkups.bind(this)}
                                handleSaveMakeReadyMarkups={this.handleSaveMakeReadyMarkups.bind(this)}
                            />
                            <BhrMarkups
                                activeElement={activeElement}
                                bhrMarkups={bhrMarkups}
                                isSavingBhrMarkups={isSavingBhrMarkups}
                                hideBhrMarkups={hideBhrMarkups.bind(this)}
                                handleUpdateBhrMarkups={this.handleUpdateBhrMarkups.bind(this)}
                                handleSaveBhrMarkups={this.handleSaveBhrMarkups.bind(this)}
                            />
                            <ActivePapers
                                activePapers={activePapers}
                                isSavingActivePapers={isSavingActivePapers}
                                isFetchingInformation={isFetchingInformation}
                                information={information}
                                options={options}
                                fetching={fetching}
                                formValues={formValues}
                                hideActivePapers={hideActivePapers.bind(this)}
                                handleSaveActivePapers={this.handleSaveActivePapers.bind(this)}
                                handleUpdateFormValue={this.handleUpdateFormValue.bind(this)}
                                handleAddActivePaper={this.handleAddActivePaper.bind(this)}
                                handleDeleteActivePaper={this.handleDeleteActivePaper.bind(this)}
                                handleMultipleChange={this.handleMultipleChange.bind(this)}
                            />
                            <Delete
                                isDeleting={isDeleting}
                                hideDelete={hideDelete.bind(this)}
                                handleDeleteElement={this.handleDeleteElement.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getFormValues,
    formErrors: selectors.getFormErrors,
    formErrorMessages: selectors.getFormErrorsMessages,
    isSavingForm: selectors.getIsSavingForm,
    elements: selectors.getElements,
    processedElements: selectors.getProcessedElements,
    isFetchingElements: selectors.getIsFetchingElements,
    isDeleting: selectors.getIsDeleting,
    idForDelete: selectors.getIdForDelete,
    totalPages: selectors.getTotalPages,
    selectedPage: selectors.getSelectedPage,
    elementsPerPage: selectors.getElementsPerPage,
    sortColumn: selectors.getSortColumn,
    sortType: selectors.getSortType,
    activeElement: selectors.getActiveElement,
    speedTable: selectors.getSpeedTable,
    isSavingSpeedTable: selectors.getIsSavingSpeedTable,
    makeReadyMarkups: selectors.getMakeReadyMarkups,
    isSavingMakeReadyMarkups: selectors.getIsSavingMakeReadyMarkups,
    bhrMarkups: selectors.getBhrMarkups,
    isSavingBhrMarkups: selectors.getIsSavingBhrMarkups,
    activePapers: selectors.getActivePapers,
    isSavingActivePapers: selectors.getIsSavingActivePapers,
    isFetchingInformation: selectors.getIsFetchingInformation,
    information: selectors.getInformation,
    options: selectors.getOptions,
    fetching: selectors.getFetching,
    user: loginSelectors.getUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchElements: () => dispatch(actions.fetchElements()),
        updateFormValue: (name, value) => dispatch(actions.updateFormValue(name, value)),
        setSort: (column, type) => dispatch(actions.setSort(column, type)),
        deleteElement: (elements, index) => dispatch(actions.deleteElement(elements, index)),
        showDelete: () => dispatch(dialog.actions.showDialog('delete')),
        hideDelete: () => dispatch(dialog.actions.hideDialog('delete')),
        showForm: () => dispatch(dialog.actions.showDialog('form')),
        hideForm: () => dispatch(dialog.actions.hideDialog('form')),
        showSpeedTable: () => dispatch(dialog.actions.showDialog('speed-table')),
        hideSpeedTable: () => dispatch(dialog.actions.hideDialog('speed-table')),
        showMakeReadyMarkups: () => dispatch(dialog.actions.showDialog('make-ready-markups')),
        hideMakeReadyMarkups: () => dispatch(dialog.actions.hideDialog('make-ready-markups')),
        showBhrMarkups: () => dispatch(dialog.actions.showDialog('bhr-markups')),
        hideBhrMarkups: () => dispatch(dialog.actions.hideDialog('bhr-markups')),
        showActivePapers: () => dispatch(dialog.actions.showDialog('active-papers')),
        hideActivePapers: () => dispatch(dialog.actions.hideDialog('active-papers')),
        setIdForDelete: (value) => dispatch(actions.setIdForDelete(value)),
        saveElement: (values, elements) => dispatch(actions.saveElement(values, elements)),
        setFormError: (name, value, message) => dispatch(actions.setFormError(name, value, message)),
        resetForm: () => dispatch(actions.resetForm()),
        changeAttribute: (value, elements, index, attribute) => dispatch(actions.changeAttribute(value, elements, index, attribute)),
        updateAttribute: (id, attribute, value, elements, index) => dispatch(actions.updateAttribute(id, attribute, value, elements, index)),
        setActiveElement: (elements, id) => dispatch(actions.setActiveElement(elements, id)),
        setSpeedTable: (speedTable) => dispatch(actions.setSpeedTable(speedTable)),
        saveSpeedTable: (speedTable, elements, pressId) => dispatch(actions.saveSpeedTable(speedTable, elements, pressId)),
        saveMakeReadyMarkups: (makeReadyMarkups, elements, pressId) => dispatch(actions.saveMakeReadyMarkups(makeReadyMarkups, elements, pressId)),
        saveBhrMarkups: (bhrMarkups, elements, pressId) => dispatch(actions.saveBhrMarkups(bhrMarkups, elements, pressId)),
        saveActivePapers: (activePapers, elements, pressId) => dispatch(actions.saveActivePapers(activePapers, elements, pressId)),
        fetchInformation: () => dispatch(actions.fetchInformation()),
        setMakeReadyMarkups: (makeReadyMarkups) => dispatch(actions.setMakeReadyMarkups(makeReadyMarkups)),
        setBhrMarkups: (bhrMarkups) => dispatch(actions.setBhrMarkups(bhrMarkups)),
        addActiveData: (data, dataName) => dispatch(actions.addActiveData(data, dataName)),
        deleteActiveData: (index, dataName) => dispatch(actions.deleteActiveData(index, dataName)),
        concatActiveData: (name, data) => dispatch(actions.concatActiveData(name, data)),
        addMultipleValue: (name, value) => dispatch(actions.addMultipleValue(name, value)),
        clearMultipleValue: (name) => dispatch(actions.clearMultipleValue(name)),
        concatMultipleValue: (name, data) => dispatch(actions.concatMultipleValue(name, data)),
        resetState: () => dispatch(actions.resetState()),
        setLoading: (name, value) => dispatch(actions.setLoading(name, value)),
        resetOptions: (name) => dispatch(actions.resetOptions(name)),
        fetchPaperCategories: () => dispatch(actions.fetchPaperCategories()),
        fetchPaperWeights: (paperCategoryId) => dispatch(actions.fetchPaperWeights(paperCategoryId)),
        fetchPapers: (paperCategoryId, paperWeight) => dispatch(actions.fetchPapers(paperCategoryId, paperWeight)),
        updateState: (values) => dispatch(actions.updateState(values)),
        changeFilter: (name, value) => dispatch(actions.changeFilter(name, value)),
        processElements: () => dispatch(actions.processElements())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
