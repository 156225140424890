import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification } from './../../utils';
import axios from 'axios';
import * as loginActions from './../Login/Login.actions';
import moment from 'moment';

export const fetchElements = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.elements, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

function processNewData(elements, element, type) {
    if (type === 1) {     // Insert
        elements.push(element);
    } else if (type === 2) {     // Update
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element.id) {
                elements.splice(i, 1, element);
                break;
            }
        }
    } else if (type === 3) {     // Delete
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element) {
                elements.splice(i, 1);
                break;
            }
        }
    }
    return elements;
}

export const saveElement = (values, elements) => dispatch => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['invoice_size_id', 'invoice_stock_id', 'ink',
        'quantity', 'landrys', 'larry', 'ps', 'pm', 'date',
        'invoice_machine_id', 'description'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let endpoint = null;
    let method = null;
    if (values.id === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + values.id;
        method = 'PUT';
    }

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: values,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, response.data, values.id === '' ? 1 : 2);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function updateAttribute(id, attribute, value) {
    return (dispatch, getState) => {
        let values = {};
        values[attribute] = value;

        dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
        axios.put(apiUrls.elements + '/' + id, values, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then(response => {
                dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
                dispatch(processElements());
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(loginActions.logout());
                } else {
                    dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                    showNotification('Error', 'An error has occurred!', 'danger');
                }
            });
    };
}

export const deleteElement = (id, elements) => dispatch => {
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, id, 3);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function changeAttribute(value, elements, index, attribute) {
    elements[index][attribute] = value;
    elements[index]['modified'] = 1;

    return {
        type: actionTypes.CHANGE_ATTRIBUTE,
        payload: elements
    };
}

export function setActiveElement(elements, id) {
    let element = null;
    for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === id) {
            element = elements[i];
            break;
        }
    }
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function changeFilter(name, value) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value }
    };
}

export function processElements() {
    return (dispatch, getState) => {
        const elements = selectors.getElements(getState());
        const filterFields = selectors.getFilterFields(getState());
        const sortColumn = selectors.getSortColumn(getState());
        const sortType = selectors.getSortType(getState());

        let filteredElements = [];
        for (let i = 0; i < elements.length; i++) {
            let add = true;
            let startDate = null;
            let endDate = null;
            for (let j = 0; j < filterFields.length; j++) {
                if (filterFields[j]['name'] === 'start_date') {
                    startDate = filterFields[j]['value'];
                }
                if (filterFields[j]['name'] === 'end_date') {
                    endDate = filterFields[j]['value'];
                }

                if (filterFields[j]['name'] !== 'start_date' && filterFields[j]['name'] !== 'end_date') {
                    let attribute = String(elements[i][filterFields[j]['name']]).toLowerCase();
                    let filterValue = String(filterFields[j]['value']).toLowerCase();
                    if (!attribute.includes(filterValue)) {
                        add = false;
                        break;
                    }
                }
            }

            if (startDate !== null && startDate !== '' && (endDate == null || endDate === '')) {
                let attribute = elements[i]['date'];
                if (moment(attribute).diff(startDate, 'minutes') < 0) {
                    add = false;
                }
            } else if (endDate !== null && endDate !== '' && (startDate === null || startDate === '')) {
                let attribute = elements[i]['date'];
                if (moment(endDate).diff(attribute, 'minutes') < 0) {
                    add = false;
                }
            } else if (startDate !== null && startDate !== '' && endDate !== null && endDate !== '') {
                let attribute = elements[i]['date'];
                if (moment(attribute).diff(startDate, 'minutes') < 0 || moment(endDate).diff(attribute, 'minutes') < 0) {
                    add = false;
                }
            }

            if (add) {
                filteredElements.push(elements[i]);
            }
        }

        let sortedElements = filteredElements.sort((a, b) => {
            if (typeof a[sortColumn] === 'number') {
                if (sortType === 'asc') {
                    if (a[sortColumn] < b[sortColumn]) return -1;
                    if (a[sortColumn] > b[sortColumn]) return 1;
                    if (a['id'] < b['id']) return -1;
                    if (a['id'] > b['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn] < a[sortColumn]) return -1;
                    if (b[sortColumn] > a[sortColumn]) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            } else {
                if (sortType === 'asc') {
                    if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) return -1;
                    if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn].toLowerCase() < a[sortColumn].toLowerCase()) return -1;
                    if (b[sortColumn].toLowerCase() > a[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            }
        });

        dispatch({
            type: actionTypes.PROCESS_ELEMENTS,
            payload: sortedElements
        });
    };
}

export const fetchData = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_DATA, payload: null });
    axios.get(apiUrls.data, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_DATA_SUCCESS, payload: response.data });
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_DATA_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const addSize = (values, elements) => dispatch => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['name'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    dispatch({ type: actionTypes.ADD_SIZE, payload: null });
    axios.get(apiUrls.sizes, values,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.ADD_SIZE_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('add-size'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(fetchData());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.ADD_SIZE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const addStock = (values, elements) => dispatch => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['name'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    dispatch({ type: actionTypes.ADD_STOCK, payload: null });
    axios.get(apiUrls.stocks, values,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.ADD_STOCK_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('add-stock'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(fetchData());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.ADD_STOCK_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const addMachine = (values, elements) => dispatch => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['name'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    dispatch({ type: actionTypes.ADD_MACHINE, payload: null });
    axios.get(apiUrls.machines, values,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.ADD_MACHINE_SUCCESS, payload: response.data });
            dispatch(dialog.actions.hideDialog('add-machine'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(fetchData());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.ADD_MACHINE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function exportExcel() {
    return (dispatch, getState) => {
        const sortColumn = selectors.getSortColumn(getState());
        const sortType = selectors.getSortType(getState());
        const filterFields = selectors.getFilterFields(getState());

        let filterNames = [];
        let filterValues = [];
        for (let i = 0; i < filterFields.length; i++) {
            filterNames.push(filterFields[i]['name']);
            filterValues.push(filterFields[i]['value']);
        }

        window.location.href = apiUrls.export + '?sort-column=' + sortColumn + '&sort-type='
            + sortType + '&filter-names=' + filterNames.join(',') + '&filter-values='
            + filterValues.join(',');
    };
}
