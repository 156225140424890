const API_URL = process.env.REACT_APP_API_URL;

let apiUrls =  {
    elements: `${API_URL}/substrates`,
    information: `${API_URL}/substrate/information`,
    saveActiveLaminates: `${API_URL}/substrate/save-active-laminates`,
    saveActiveAccessories: `${API_URL}/substrate/save-active-accessories`,
    saveDiscountTable: `${API_URL}/substrate/save-discount-table`
};

export default apiUrls;
