import Immutable from 'immutable';
import * as actionTypes from './actionTypes';
import { SET_ACTIVE_PAGE } from './../common/Pagination/actionTypes';

const flatInitialConfiguration = {
    product_options: {
        product_category_id: { show: 1, default: '', lock: 1 },
        press_id: { show: 1, default: '', lock: 1 },
        running_style: { show: 1, default: '', lock: 1 },
        flat_width: { show: 1, default: '', lock: 1 },
        flat_height: { show: 1, default: '', lock: 1 },
        finish_width: { show: 1, default: '', lock: 1 },
        finish_height: { show: 1, default: '', lock: 1 },
        front_inks: { show: 1, default: 4, lock: 1 },
        back_inks: { show: 1, default: 4, lock: 1 },
        bleed: { show: 1, default: 0.125, lock: 1 },
        paper_category_id: { show: 1, default: '', lock: 1 },
        paper_weight: { show: 1, default: '', lock: 1 },
        paper_id: { show: 1, default: '', lock: 1 },
        front_coating: { show: 1, default: '', lock: 1 },
        back_coating: { show: 1, default: '', lock: 1 }
    },
    bindery_options: {
        fold_type_id: { show: 1, default: '', lock: 1 },
        requires_scoring: { show: 1, default: 0, lock: 1 },
        drilling_holes: { show: 1, default: 0, lock: 1 },
        shrink_wrapping_type_id: { show: 1, default: '', lock: 1 },
        requires_padding: { show: 1, default: 0, lock: 1 },
        requires_die_cutting: { show: 1, default: 0, lock: 1 },
        sheets_to_pad: { show: 1, default: 10, lock: 1 }
    },
    handling_options: {
        banding_type_id: { show: 1, default: '', lock: 1 },
        wrapping_type_id: { show: 1, default: '', lock: 1 },
        package_id: { show: 1, default: '', lock: 1 },
        use_hand_gathering: { show: 1, default: 0, lock: 1 }
    },
    product_description: { show: 1, default: '', lock: 1 },
    outside_services: { show: 1, default: '', lock: 1 },
    production_notes: { show: 1, default: '', lock: 1 },
    imposition: { show: 1, default: '', lock: 1 },
    customer_discount: { show: 1, default: '', lock: 1 }
};

const multiPageInitialConfiguration = {
    product_options: {
        multi_page_type: { show: 1, default: 1, lock: 1 },
        product_category_id: { show: 1, default: '', lock: 1 },
        pages: { show: 1, default: '', lock: 1 },
        binding_edge: { show: 1, default: 1, lock: 1 },
        bleed: { show: 1, default: 0.125, lock: 1 },
        interior_inks: { show: 1, default: 4, lock: 1 },
        front_inks: { show: 1, default: 4, lock: 1 },
        back_inks: { show: 1, default: 4, lock: 1 },
        finish_width: { show: 1, default: '', lock: 1 },
        finish_height: { show: 1, default: '', lock: 1 },
        coil_option: { show: 1, default: '', lock: 1 },
        coil_color: { show: 1, default: '', lock: 1 },
        staples_number: { show: 1, default: '', lock: 1 },
        staples_location: { show: 1, default: '', lock: 1 }
    },
    press_options: {
        cover_press_id: { show: 1, default: '', lock: 1 },
        cover_running_style: { show: 1, default: '', lock: 1 },
        press_id: { show: 1, default: '', lock: 1 },
        running_style: { show: 1, default: '', lock: 1 },
        second_press_id: { show: 1, default: '', lock: 1 },
        second_running_style: { show: 1, default: '', lock: 1 }
    },
    paper_options: {
        cover_paper_category_id: { show: 1, default: '', lock: 1 },
        cover_paper_weight: { show: 1, default: '', lock: 1 },
        cover_paper_id: { show: 1, default: '', lock: 1 },
        paper_category_id: { show: 1, default: '', lock: 1 },
        paper_weight: { show: 1, default: '', lock: 1 },
        paper_id: { show: 1, default: '', lock: 1 },
        interior_coating: { show: 1, default: '', lock: 1 },
        front_coating: { show: 1, default: '', lock: 1 },
        back_coating: { show: 1, default: '', lock: 1 }
    },
    bindery_options: {
        drilling_holes: { show: 1, default: 0, lock: 1 },
        shrink_wrapping_type_id: { show: 1, default: '', lock: 1 }
    },
    handling_options: {
        banding_type_id: { show: 1, default: '', lock: 1 },
        wrapping_type_id: { show: 1, default: '', lock: 1 },
        package_id: { show: 1, default: '', lock: 1 },
        use_hand_gathering: { show: 1, default: 0, lock: 1 }
    },
    product_description: { show: 1, default: '', lock: 1 },
    outside_services: { show: 1, default: '', lock: 1 },
    production_notes: { show: 1, default: '', lock: 1 },
    imposition: { show: 1, default: '', lock: 1 },
    customer_discount: { show: 1, default: '', lock: 1 }
};

const digitalInitialConfiguration = {
    product_options: {
        product_category_id: { show: 1, default: '', lock: 1 },
        digital_machine_id: { show: 1, default: '', lock: 1 },
        flat_width: { show: 1, default: '', lock: 1 },
        flat_height: { show: 1, default: '', lock: 1 },
        finish_width: { show: 1, default: '', lock: 1 },
        finish_height: { show: 1, default: '', lock: 1 },
        digital_inks: { show: 1, default: 2, lock: 1 },
        digital_sides: { show: 1, default: 2, lock: 1 },
        bleed: { show: 1, default: 0.125, lock: 1 },
        paper_category_id: { show: 1, default: '', lock: 1 },
        paper_weight: { show: 1, default: '', lock: 1 },
        paper_id: { show: 1, default: '', lock: 1 },
        front_coating: { show: 1, default: '', lock: 1 },
        back_coating: { show: 1, default: '', lock: 1 }
    },
    bindery_options: {
        fold_type_id: { show: 1, default: '', lock: 1 },
        requires_scoring: { show: 1, default: 0, lock: 1 },
        drilling_holes: { show: 1, default: 0, lock: 1 },
        shrink_wrapping_type_id: { show: 1, default: '', lock: 1 },
        requires_padding: { show: 1, default: 0, lock: 1 },
        requires_die_cutting: { show: 1, default: 0, lock: 1 },
        sheets_to_pad: { show: 1, default: 10, lock: 1 }
    },
    handling_options: {
        banding_type_id: { show: 1, default: '', lock: 1 },
        wrapping_type_id: { show: 1, default: '', lock: 1 },
        package_id: { show: 1, default: '', lock: 1 },
        use_hand_gathering: { show: 1, default: 0, lock: 1 }
    },
    product_description: { show: 1, default: '', lock: 1 },
    outside_services: { show: 1, default: '', lock: 1 },
    production_notes: { show: 1, default: '', lock: 1 },
    imposition: { show: 1, default: '', lock: 1 },
    customer_discount: { show: 1, default: '', lock: 1 }
};

const largeFormatInitialConfiguration = {
    product_options: {
        product_category_id: { show: 1, default: '', lock: 1 },
        large_format_machine_id: { show: 1, default: '', lock: 1 }
    },
    substrate_options: {
        flat_width: { show: 1, default: '', lock: 1 },
        flat_height: { show: 1, default: '', lock: 1 },
        unit: { show: 1, default: 1, lock: 1 },
        substrate_id: { show: 1, default: '', lock: 1 },
        laminate_id: { show: 1, default: '', lock: 1 }
    },
    product_description: { show: 1, default: '', lock: 1 },
    outside_services: { show: 1, default: '', lock: 1 },
    production_notes: { show: 1, default: '', lock: 1 },
    accessories: { show: 1, default: [], lock: 1 },
    customer_discount: { show: 1, default: '', lock: 1 }
};

const defaultOptions = {
    presses: [{ label: 'Select option', value: '' }],
    paperCategories: [{ label: 'Select option', value: '' }],
    paperWeights: [{ label: 'Select option', value: '' }],
    papers: [{ label: 'Select option', value: '' }],
    coverPaperCategories: [{ label: 'Select option', value: '' }],
    coverPaperWeights: [{ label: 'Select option', value: '' }],
    coverPapers: [{ label: 'Select option', value: '' }],
    foldTypes: [{ label: 'None', value: '' }],
    digitalMachines: [{ label: 'Select option', value: '' }],
    largeFormatMachines: [{ label: 'Select option', value: '' }],
    substrates: [{ label: 'Select option', value: '' }],
    laminates: [{ label: 'None', value: '' }],
    accessories: [{ label: 'None', value: '' }],
    inks: [{ label: 'None', value: '' }],
    bandingTypes: [{ label: 'None', value: '' }],
    wrappingTypes: [{ label: 'None', value: '' }],
    shrinkWrappingTypes: [{ label: 'None', value: '' }],
    packages: [{ label: 'None', value: '' }],
    productCategories: [{ label: 'Select option', value: '' }],
    customerDiscounts: [{ label: 'None', value: '' }]
};

const defaultInformation = {
    presses: [],
    largeFormatMachines: [],
    substrates: [],
    laminates: [],
    accessories: [],
    inks: [],
    customerDiscounts: [],
    isFetching: false
};

const INITIAL_STATE = Immutable.fromJS({
    form: {
        formValues: {
            id: '',
            name: '',
            product_type: 1,
            product_category_id: '',
            configuration_json: flatInitialConfiguration
        },
        formErrors: {},
        isSaving: false
    },
    activeElement: {
        data: null,
        isFetching: false,
        editing: false
    },
    delete: {
        id: null,
        isDeleting: false
    },
    elements: {
        data: [],
        processedElements: [],
        isFetching: false
    },
    pagination: {
        totalPages: 0,
        selectedPage: 1,
        elementsPerPage: 20
    },
    sort: {
        column: 'name',
        type: 'asc'
    },
    filter: {
        fields: []
    },
    options: defaultOptions,
    information: defaultInformation,
    productCategories: []
});

export default function (state = INITIAL_STATE, action) {
    state = Immutable.fromJS(state);

    if (window.location['pathname'] !== '/products') {
        state = INITIAL_STATE;
        return state.toJS();
    }

    switch (action.type) {
        case actionTypes.FETCH_ELEMENTS:
            state = state.setIn(['elements', 'isFetching'], true)
                .setIn(['elements', 'data'], [])
                .setIn(['elements', 'processedElements'], [])
                .setIn(['pagination', 'totalPages'], 0)
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_SUCCESS:
            state = state.setIn(['elements', 'isFetching'], false)
                .setIn(['elements', 'data'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20))
                .setIn(['pagination', 'selectedPage'], 1);
            break;

        case actionTypes.FETCH_ELEMENTS_FAILURE:
            state = state.setIn(['elements', 'isFetching'], false);
            break;

        case actionTypes.UPDATE_FORM_VALUE:
            state = state.setIn(['form', 'formValues', action.payload.name], action.payload.value);
            break;

        case SET_ACTIVE_PAGE:
            state = state.setIn(['pagination', 'selectedPage'], action.payload);
            break;

        case actionTypes.SET_SORT:
            state = state.setIn(['sort', 'column'], action.payload.column)
                .setIn(['sort', 'type'], action.payload.type);
            break;

        case actionTypes.SET_ELEMENTS:
            state = state.setIn(['elements', 'data'], action.payload);
            break;

        case actionTypes.SAVE:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.SAVE_ELEMENT:
            state = state.setIn(['form', 'isSaving'], true);
            break;

        case actionTypes.SAVE_ELEMENT_SUCCESS:
            state = state.setIn(['form', 'isSaving'], false)
                .setIn(['form', 'formValues', 'configuration_json'], JSON.parse(action.payload.configuration_json));
            break;

        case actionTypes.SAVE_ELEMENT_FAILURE:
            state = state.setIn(['form', 'isSaving'], false);
            break;

        case actionTypes.DELETE_ELEMENT:
            state = state.setIn(['delete', 'isDeleting'], true);
            break;

        case actionTypes.DELETE_ELEMENT_SUCCESS:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.DELETE_ELEMENT_FAILURE:
            state = state.setIn(['delete', 'isDeleting'], false);
            break;

        case actionTypes.SET_ID_FOR_DELETE:
            state = state.setIn(['delete', 'id'], action.payload);
            break;

        case actionTypes.RESET_FORM:
            state = state.setIn(['form', 'formValues'], {
                id: '',
                name: '',
                product_type: 1,
                product_category_id: '',
                configuration_json: flatInitialConfiguration
            }).setIn(['form', 'formErrors'], {});
            break;

        case actionTypes.SET_FORM_ERRORS:
            state = state.setIn(['form', 'formErrors'], action.payload.errors)
                .setIn(['form', 'formErrorMessages'], action.payload.errorMessages);
            break;

        case actionTypes.SET_FORM_ERROR:
            state = state.setIn(['form', 'formErrors', action.payload.name], action.payload.value)
                .setIn(['form', 'formErrorMessages', action.payload.name], action.payload.errorMessage);
            break;

        case actionTypes.SET_ACTIVE_ELEMENT:
            state = state.setIn(['form', 'formErrors'], {})
                .setIn(['activeElement', 'data'], action.payload)
                .setIn(['form', 'formValues', 'id'], action.payload.id)
                .setIn(['form', 'formValues', 'name'], action.payload.name)
                .setIn(['form', 'formValues', 'product_type'], action.payload.product_type)
                .setIn(['form', 'formValues', 'product_category_id'], action.payload.product_category_id)
                .setIn(['form', 'formValues', 'configuration_json'], JSON.parse(action.payload.configuration_json));
            break;

        case actionTypes.SET_INITIAL_CONFIGURATION:
            console.log(action.payload)
            if (parseInt(action.payload) === 1) {      // Flat
                state = state.setIn(['form', 'formValues', 'configuration_json'], flatInitialConfiguration);
            } else if (parseInt(action.payload) === 2) {   // Multi-page
                state = state.setIn(['form', 'formValues', 'configuration_json'], multiPageInitialConfiguration);
            } else if (parseInt(action.payload) === 3) {   // Digital
                state = state.setIn(['form', 'formValues', 'configuration_json'], digitalInitialConfiguration);
            } else if (parseInt(action.payload) === 4) {   // Large Format
                state = state.setIn(['form', 'formValues', 'configuration_json'], largeFormatInitialConfiguration);
            }

            break;

        case actionTypes.RESET_STATE:
            state = INITIAL_STATE;
            break;

        case actionTypes.CHANGE_FILTER:
            state = state.setIn(['pagination', 'selectedPage'], 1)
                .updateIn(['filter', 'fields'], (fields) => {
                    const index = fields.findIndex(field => field.get('name') === action.payload.name);
                    if (action.payload.value === '') {
                        return fields.delete(index);
                    } else {
                        if (index === -1) {
                            return fields.push({ name: action.payload.name, value: action.payload.value });
                        } else {
                            return fields.set(index, { name: action.payload.name, value: action.payload.value });
                        }
                    }
                });
            break;

        case actionTypes.PROCESS_ELEMENTS:
            state = state.setIn(['elements', 'processedElements'], action.payload)
                .setIn(['pagination', 'totalPages'], Math.ceil(action.payload.length / 20));
            break;

        case actionTypes.SET_EDITING:
            state = state.setIn(['activeElement', 'editing'], action.payload);

            if (action.payload === false) {
                state = state.setIn(['information'], defaultInformation)
                    .setIn(['options'], defaultOptions)
                    .setIn(['form', 'formValues', 'configuration_json'], null);
            }
            break;

        case actionTypes.UPDATE_CONFIGURATION:
            state = state.setIn(action.payload.attributes, action.payload.value);
            break;

        case actionTypes.FETCH_INFORMATION:
            state = state.setIn(['information', 'isFetching'], true)
                .setIn(['information', 'presses'], [])
                .setIn(['information', 'largeFormatMachines'], [])
                .setIn(['information', 'substrates'], [])
                .setIn(['information', 'laminates'], [])
                .setIn(['information', 'accessories'], [])
                .setIn(['information', 'inks'], []);
            break;

        case actionTypes.FETCH_INFORMATION_SUCCESS:
            state = state.setIn(['information', 'isFetching'], false)
                .setIn(['information', 'presses'], action.payload.presses)
                .setIn(['information', 'foldTypes'], action.payload.foldTypes)
                .setIn(['information', 'largeFormatMachines'], action.payload.largeFormatMachines)
                .setIn(['information', 'substrates'], action.payload.substrates)
                .setIn(['information', 'laminates'], action.payload.laminates)
                .setIn(['information', 'accessories'], action.payload.accessories)
                .setIn(['information', 'inks'], action.payload.inks);
            break;

        case actionTypes.FETCH_INFORMATION_FAILURE:
            state = state.setIn(['information', 'isFetching'], false);
            break;

        case actionTypes.SET_OPTIONS:
            state = state.updateIn(['options', action.payload.name], (data) => Immutable.List(action.payload.data));
            break;

        case actionTypes.RESET_OPTIONS:
            state = state.setIn(['options', action.payload], []);
            break;

        case actionTypes.UPDATE_STATE:
            if (action.payload.formValues) {
                state = state.updateIn(['form', 'formValues'], (formValues) => {
                    for (let [element, value] of Object.entries(action.payload.formValues)) {
                        formValues = formValues.set(element, value);
                    }
                    return formValues;
                });
            }
            if (action.payload.options) {
                state = state.updateIn(['options'], (options) => {
                    for (let [element, value] of Object.entries(action.payload.options)) {
                        options = options.set(element, value);
                    }
                    return options;
                });
            }
            if (action.payload.fetching) {
                state = state.updateIn(['fetching'], (fetching) => {
                    for (let [element, value] of Object.entries(action.payload.fetching)) {
                        fetching = fetching.set(element, value);
                    }
                    return fetching;
                });
            }
            break;

        case actionTypes.FETCH_CONFIGURATION:
            state = state.setIn(['form', 'formValues', 'configuration_json'], null);
            break;

        case actionTypes.FETCH_CONFIGURATION_SUCCESS:
            state = state.setIn(['form', 'formValues', 'configuration_json'], JSON.parse(action.payload.configuration_json));
            break;

        case actionTypes.FETCH_CONFIGURATION_FAILURE:
            state = state.setIn(['form', 'formValues', 'configuration_json'], null);
            break;

        case actionTypes.CHECK_ACCESSORY:
            state = state.updateIn(['form', 'formValues', 'configuration_json', 'accessories', 'default'], (accessories) => {
                const accessoryId = accessories.find((accessory) => parseInt(accessory) === action.payload);
                if (accessoryId === undefined) {
                    return accessories.push(action.payload);
                } else {
                    const index = accessories.indexOf(accessoryId);
                    return accessories.delete(index);
                }
            });
            break;

        case actionTypes.FETCH_PRODUCT_CATEGORIES:
            state = state.setIn(['productCategories'], []);
            break;

        case actionTypes.FETCH_PRODUCT_CATEGORIES_SUCCESS:
            state = state.setIn(['productCategories'], action.payload);
            break;

        case actionTypes.FETCH_PRODUCT_CATEGORIES_FAILURE:
            break;

        default:
            break;
    }

    return state.toJS();
}
