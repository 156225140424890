import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './../../common/dialog';

class MakeReadyMarkups extends Component {
    static propTypes = {
        makeReadyMarkups: PropTypes.array,
        hideMakeReadyMarkups: PropTypes.func,
        handleUpdateMakeReadyMarkups: PropTypes.func,
        isSavingMakeReadyMarkups: PropTypes.bool,
        handleSaveMakeReadyMarkups: PropTypes.func,
        handleAddMakeReadyMarkups: PropTypes.func,
        handleDeleteMakeReadyMarkups: PropTypes.func,
        activeElement: PropTypes.object
    };

    renderData() {
        const { makeReadyMarkups, handleUpdateMakeReadyMarkups, activeElement } = this.props;
        return makeReadyMarkups.map((markup, index) => {
            let runningStyle = null;
            if (index === 0) {
                runningStyle = 'SW';
            } else if (index === 1) {
                runningStyle = 'WT';
            } else if (index === 2) {
                return null;
            } else if (index === 3) {
                if (activeElement['is_perfecting']) {
                    runningStyle = 'Perfecting';
                } else {
                    return null;
                }
            }

            return (
                <tr key={index}>
                    <td>{runningStyle}</td>
                    <td>
                        <input type="text" value={markup.markup}
                            onChange={handleUpdateMakeReadyMarkups.bind(this, index, 'markup')} />
                    </td>
                </tr>
            );
        });
    }

    render() {
        const { hideMakeReadyMarkups, handleSaveMakeReadyMarkups, isSavingMakeReadyMarkups } = this.props;

        let data = (
            <div>
                <table className="table table-sm table-striped data-table">
                    <thead>
                        <tr>
                            <th style={{ width: '50%' }}>Running Style</th>
                            <th style={{ width: '50%' }}>Markup</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderData()}</tbody>
                </table>
            </div>
        );

        let saveButtonLabel = 'Save';
        if (isSavingMakeReadyMarkups) {
            saveButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let actions = [
            <button key="save-button" className="btn btn-light btn-sm action-button"
                onClick={handleSaveMakeReadyMarkups} disabled={isSavingMakeReadyMarkups}>{saveButtonLabel}</button>,
            <button key="close-button" className="btn btn-light btn-sm action-button-cancel" onClick={hideMakeReadyMarkups}>Close</button>
        ];
        return (
            <Dialog
                name="make-ready-markups"
                modal={false}
                actions={actions}
                contentStyle={{ width: 800 }}
                bodyStyle={{ overflow: 'initial' }}
            >
                <div className="form-container">{data}</div>
            </Dialog>
        );
    }
}

export default MakeReadyMarkups;
