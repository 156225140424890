import * as actionTypes from './actionTypes';
import apiUrls from './apiUrls';
import * as dialog from './../common/dialog';
import * as selectors from './selectors';
import { showNotification } from './../../utils';
import axios from 'axios';
import * as loginActions from './../Login/Login.actions';

export const fetchElements = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_ELEMENTS, payload: null });
    axios.get(apiUrls.elements, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_ELEMENTS_SUCCESS, payload: response.data });
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_ELEMENTS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

function processNewData(elements, element, type) {
    if (type === 1) {     // Insert
        elements.push(element);
    } else if (type === 2) {     // Update
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element.id) {
                elements.splice(i, 1, element);
                break;
            }
        }
    } else if (type === 3) {     // Delete
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === element) {
                elements.splice(i, 1);
                break;
            }
        }
    }
    return elements;
}

export const saveElement = (values, elements) => dispatch => {
    let errors = {};
    let errorMessages = {};
    let attributes = ['name', 'labor_rate', 'labor_markup_rate', 'min_paper_width', 'min_paper_length',
        'max_paper_width', 'max_paper_length', 'setup_time', 'waste', 'is_active', 'is_default',
        'cost_click_black', 'cost_click_color', 'printable_area_width', 'printable_area_length'];

    for (let i = 0; i < attributes.length; i++) {
        if (values[attributes[i]] === '' || values[attributes[i]] === null) {
            errors[attributes[i]] = true;
        }
    }

    if (Object.keys(errors).length > 0) {
        dispatch(setFormErrors(errors, errorMessages));
        return;
    }

    let endpoint = null;
    let method = null;
    if (values.id === '') {
        endpoint = apiUrls.elements;
        method = 'POST';
    } else {
        endpoint = apiUrls.elements + '/' + values.id;
        method = 'PUT';
    }

    dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
    axios(
        {
            method: method,
            url: endpoint,
            data: values,
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, response.data, values.id === '' ? 1 : 2);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('form'));
            showNotification('Element Saved!', 'The element has been saved successfully', 'success');
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function updateAttribute(id, attribute, value) {
    return (dispatch, getState) => {
        let values = {};
        values[attribute] = value;

        dispatch({ type: actionTypes.SAVE_ELEMENT, payload: null });
        axios.put(apiUrls.elements + '/' + id, values, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
            .then(response => {
                dispatch({ type: actionTypes.SAVE_ELEMENT_SUCCESS, payload: response.data });
                dispatch(processElements());
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(loginActions.logout());
                } else {
                    dispatch({ type: actionTypes.SAVE_ELEMENT_FAILURE, payload: null });
                    showNotification('Error', 'An error has occurred!', 'danger');
                }
            });
    };
}

export const deleteElement = (id, elements) => dispatch => {
    dispatch({ type: actionTypes.DELETE_ELEMENT, payload: null });
    axios.delete(apiUrls.elements + '/' + id, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.DELETE_ELEMENT_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, id, 3);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('delete'));
            showNotification('Element Deleted!', 'The element has been deleted successfully', 'success');
            dispatch(processElements());
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.DELETE_ELEMENT_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function resetForm() {
    return {
        type: actionTypes.RESET_FORM,
        payload: null
    };
}

export function setFormErrors(errors, errorMessages) {
    return {
        type: actionTypes.SET_FORM_ERRORS,
        payload: { errors, errorMessages }
    };
}

export function setFormError(name, value, errorMessage) {
    return {
        type: actionTypes.SET_FORM_ERROR,
        payload: { name, value, errorMessage }
    };
}

export function setIdForDelete(value) {
    return {
        type: actionTypes.SET_ID_FOR_DELETE,
        payload: value
    };
}

export function updateFormValue(name, value) {
    return {
        type: actionTypes.UPDATE_FORM_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
}

export function setSort(column, type) {
    return {
        type: actionTypes.SET_SORT,
        payload: { column, type }
    };
}

export function setElements(elements) {
    return {
        type: actionTypes.SET_ELEMENTS,
        payload: elements
    };
}

export function changeAttribute(value, elements, index, attribute) {
    elements[index][attribute] = value;
    elements[index]['modified'] = 1;

    return {
        type: actionTypes.CHANGE_ATTRIBUTE,
        payload: elements
    };
}

export function setActiveElement(elements, id) {
    let element = null;
    for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === id) {
            element = elements[i];
            break;
        }
    }
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        payload: element
    };
}

export const saveActivePapers = (activePapers, elements, digitalMachineId) => dispatch => {
    dispatch({ type: actionTypes.SAVE_ACTIVE_PAPERS, payload: null });
    axios.post(apiUrls.saveActivePapers, { digitalMachineId, activePapers },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_ACTIVE_PAPERS_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, response.data, 2);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('active-papers'));
            showNotification('Active Papers Saved!', 'The active papers has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_ACTIVE_PAPERS_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchInformation = () => dispatch => {
    dispatch({ type: actionTypes.FETCH_INFORMATION, payload: null });
    axios.get(apiUrls.information, { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_INFORMATION_SUCCESS, payload: response.data });
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_INFORMATION_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function setSpeedTable(speedTable) {
    return {
        type: actionTypes.SET_SPEED_TABLE,
        payload: speedTable
    };
}

export const saveSpeedTable = (speedTable, elements, digitalMachineId) => dispatch => {
    for (let i = 0; i < speedTable.length; i++) {
        if (speedTable[i]['run_length'] === '' || speedTable[i]['speed'] === '') {
            showNotification('Complete Information', 'Fill all the data', 'info');
            return;
        }
    }

    dispatch({ type: actionTypes.SAVE_SPEED_TABLE, payload: null });
    axios.post(apiUrls.saveSpeedTable, { digitalMachineId, speedTable },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_SPEED_TABLE_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, response.data, 2);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('speed-table'));
            showNotification('Speed Table Saved!', 'The speed table has been saved successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_SPEED_TABLE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function addActiveData(data, dataName) {
    return {
        type: actionTypes.ADD_ACTIVE_DATA,
        payload: { name: dataName, value: data }
    };
}

export function deleteActiveData(index, dataName) {
    return {
        type: actionTypes.DELETE_ACTIVE_DATA,
        payload: { name: dataName, value: index }
    };
}

export function concatActiveData(name, data) {
    return {
        type: actionTypes.CONCAT_ACTIVE_DATA,
        payload: { name, data }
    };
}

export function addMultipleValue(name, value) {
    return {
        type: actionTypes.ADD_MULTIPLE_VALUE,
        payload: { name, value }
    };
}

export function clearMultipleValue(name) {
    return {
        type: actionTypes.CLEAR_MULTIPLE_VALUE,
        payload: name
    };
}

export function concatMultipleValue(name, data) {
    return {
        type: actionTypes.CONCAT_MULTIPLE_VALUE,
        payload: { name, data }
    };
}

export function resetState() {
    return {
        type: actionTypes.RESET_STATE,
        payload: null
    };
}

export function setOptions(name, data) {
    return {
        type: actionTypes.SET_OPTIONS,
        payload: { name, data }
    };
}

export function loadOptions(data) {
    let options = [{ label: 'Select option', value: '' }];
    for (let i = 0; i < data.length; i++) {
        options.push({ label: data[i]['name'], value: data[i]['id'] });
    }
    return options;
}

export function loadPaperCategoriesOptions(data, type = 1) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({ label: data[i]['category_name'], value: data[i]['id'] });
        }

        let typeName = null;
        if (type === 1) {
            typeName = 'paperCategories';
        } else if (type === 2) {
            typeName = 'coverPaperCategories';
        } else if (type === 3) {
            typeName = 'activePaperCategories';
        }
        dispatch(setOptions(typeName, options));
    };
}

export function loadPaperWeightsOptions(data, type = 1) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({ label: data[i] + '#', value: data[i] });
        }

        let typeName = null;
        if (type === 1) {
            typeName = 'paperWeights';
        } else if (type === 2) {
            typeName = 'coverPaperWeights';
        } else if (type === 3) {
            typeName = 'activePaperWeights';
        }
        dispatch(setOptions(typeName, options));
    };
}

export function loadPapersOptions(data, type = 1) {
    return (dispatch, getState) => {
        let options = [{ label: 'Select option', value: '' }];
        for (let i = 0; i < data.length; i++) {
            options.push({ label: data[i]['paper_name'] + ' ' + data[i]['width'] + 'x' + data[i]['length'], value: data[i]['id'] });
        }

        let typeName = null;
        if (type === 1) {
            typeName = 'papers';
        } else if (type === 2) {
            typeName = 'coverPapers';
        } else if (type === 3) {
            typeName = 'activePapers';
        }
        dispatch(setOptions(typeName, options));
    };
}

export function setLoading(name, value) {
    return {
        type: actionTypes.SET_LOADING,
        payload: { name, value }
    };
}

export function resetOptions(name) {
    return {
        type: actionTypes.RESET_OPTIONS,
        payload: name
    };
}

export const fetchPaperCategories = () => dispatch => {
    dispatch(setLoading('activePaperCategories', true));
    dispatch(resetOptions('activePaperCategories'));
    dispatch({ type: actionTypes.FETCH_PAPER_CATEGORIES, payload: null });
    axios.get(apiUrls.paperCategories + '?fields=id,category_name',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_PAPER_CATEGORIES_SUCCESS, payload: response.data });
            dispatch(setLoading('activePaperCategories', false));
            dispatch(loadPaperCategoriesOptions(response.data, 3));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_PAPER_CATEGORIES_FAILURE, payload: null });
                dispatch(setLoading('activePaperCategories', false));
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchPaperWeights = paperCategoryId => dispatch => {
    dispatch({ type: actionTypes.FETCH_PAPER_WEIGHTS, payload: null });
    axios.get(apiUrls.papers + '?fields=basis_weight&paper_category_id=' + paperCategoryId,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_PAPER_WEIGHTS_SUCCESS, payload: response.data });
            dispatch(setLoading('activePaperWeights', false));
            let weights = [];
            for (let i = 0; i < response.data.length; i++) {
                let basisWeight = parseFloat(response.data[i]['basis_weight']);
                if (!weights.includes(basisWeight)) {
                    weights.push(basisWeight);
                }
            }
            weights.sort();
            dispatch(loadPaperWeightsOptions(weights, 3));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_PAPER_WEIGHTS_FAILURE, payload: null });
                dispatch(setLoading('activePaperWeights', false));
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export const fetchPapers = (paperCategoryId, paperWeight) => dispatch => {
    dispatch({ type: actionTypes.FETCH_PAPERS, payload: null });
    axios.get(apiUrls.papers + '?fields=id,paper_name,basis_weight,width,length&paper_category_id=' + paperCategoryId + '&basis_weight=' + paperWeight,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.FETCH_PAPERS_SUCCESS, payload: response.data });
            dispatch(setLoading('activePapers', false));
            dispatch(loadPapersOptions(response.data, 3));
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.FETCH_PAPERS_FAILURE, payload: null });
                dispatch(setLoading('activePapers', false));
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function updateState(values) {
    return {
        type: actionTypes.UPDATE_STATE,
        payload: values
    };
}

export function changeFilter(name, value) {
    return {
        type: actionTypes.CHANGE_FILTER,
        payload: { name, value }
    };
}

export function processElements() {
    return (dispatch, getState) => {
        const elements = selectors.getElements(getState());
        const filterFields = selectors.getFilterFields(getState());
        const sortColumn = selectors.getSortColumn(getState());
        const sortType = selectors.getSortType(getState());

        let filteredElements = [];
        for (let i = 0; i < elements.length; i++) {
            let add = true;
            for (let j = 0; j < filterFields.length; j++) {
                let attribute = String(elements[i][filterFields[j]['name']]).toLowerCase();
                let filterValue = String(filterFields[j]['value']).toLowerCase();
                if (!attribute.includes(filterValue)) {
                    add = false;
                    break;
                }
            }

            if (add) {
                filteredElements.push(elements[i]);
            }
        }

        let sortedElements = filteredElements.sort((a, b) => {
            if (typeof a[sortColumn] === 'number') {
                if (sortType === 'asc') {
                    if (a[sortColumn] < b[sortColumn]) return -1;
                    if (a[sortColumn] > b[sortColumn]) return 1;
                    if (a['id'] < b['id']) return -1;
                    if (a['id'] > b['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn] < a[sortColumn]) return -1;
                    if (b[sortColumn] > a[sortColumn]) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            } else {
                if (sortType === 'asc') {
                    if (a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase()) return -1;
                    if (a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                } else {
                    if (b[sortColumn].toLowerCase() < a[sortColumn].toLowerCase()) return -1;
                    if (b[sortColumn].toLowerCase() > a[sortColumn].toLowerCase()) return 1;
                    if (b['id'] < a['id']) return -1;
                    if (b['id'] > a['id']) return 1;
                    return 0;
                }
            }
        });

        dispatch({
            type: actionTypes.PROCESS_ELEMENTS,
            payload: sortedElements
        });
    };
}

export const saveDiscountTable = (discountTable, elements, elementId) => dispatch => {
    dispatch({ type: actionTypes.SAVE_DISCOUNT_TABLE, payload: null });
    axios.post(apiUrls.saveDiscountTable, { elementId, discountTable },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then(response => {
            dispatch({ type: actionTypes.SAVE_DISCOUNT_TABLE_SUCCESS, payload: response.data });
            let newElements = processNewData(elements, response.data, 2);
            dispatch(setElements(newElements));
            dispatch(dialog.actions.hideDialog('discount-table'));
            showNotification('Discount Table Saved!', 'The discount table was saves successfully', 'success');
        })
        .catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(loginActions.logout());
            } else {
                dispatch({ type: actionTypes.SAVE_DISCOUNT_TABLE_FAILURE, payload: null });
                showNotification('Error', 'An error has occurred!', 'danger');
            }
        });
}

export function addDiscount() {
    return {
        type: actionTypes.ADD_DISCOUNT,
        payload: null
    };
}

export function deleteDiscount(index) {
    return {
        type: actionTypes.DELETE_DISCOUNT,
        payload: index
    };
}

export function updateDiscount(index, field, value) {
    return {
        type: actionTypes.UPDATE_DISCOUNT,
        payload: { index, field, value }
    };
}
